import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/StrategicManagedServices_cover.png'
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import Strategic_img1 from '../../assets/Capabilities/Strategic_img1.png'
import Strategic_img2 from '../../assets/Capabilities/Strategic_img2.png'
import Strategic_img3 from '../../assets/Capabilities/Strategic_img3.png'
import Strategic_img4 from '../../assets/Capabilities/Strategic_img4.png'
import Strategic_img5 from '../../assets/Capabilities/Strategic_img5.png'
import Strategic_img6 from '../../assets/Capabilities/Strategic_img6.png'
import Strategic_img7 from '../../assets/Capabilities/Strategic_img7.png'
import Strategic_img8 from '../../assets/Capabilities/Strategic_img8.png'
import Strategic_img9 from '../../assets/Capabilities/Strategic_img9.png'
import Strategic_img10 from '../../assets/Capabilities/Strategic_img10.png'
import Strategic_img11 from '../../assets/Capabilities/Strategic_img11.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { motion, AnimatePresence } from 'framer-motion';
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import { useDocumentTitle } from '../../utils/useDocumentTitle';
function CloudServices() {

    useDocumentTitle("Strategic Managed Services");

    const SalesAndCommerceStatsComponent = () => {
        return (
            <div className="bg-[#08082A] text-white p-4 sm:p-8 font-sans flex flex-col sm:flex-row">
                <div className="w-full sm:w-1/3 sm:pr-8 mb-6 sm:mb-0 flex items-center justify-center">
                    <img
                        src={Strategic_img1}
                        alt="Why managed services illustration"
                        className="w-3/4 sm:w-full max-w-xs sm:max-w-full object-cover rounded-lg"
                    />
                </div>
                <div className="w-full sm:w-2/3">
                    <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 md:mb-12 lg:mb-16 text-center sm:text-left">Why Choose Managed Services?</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                        <StatCard
                            value="1.5x"
                            description="Increased operational efficiency and faster service delivery."
                        />
                        <StatCard
                            value="2.5x"
                            description="Enhanced return on investment (ROI) and improved financial performance."
                        />
                        <StatCard
                            value="38%"
                            description="Boost in overall productivity and innovation."
                        />
                        <StatCard
                            value="25%"
                            description="Reduction in carbon footprint and operational costs."
                        />
                    </div>
                </div>
            </div>
        );
    };

    const StatCard = ({ value, description }) => (
        <div className="bg-gradient-to-br from-[#21213B] to-[#08082A] rounded-lg p-6 relative overflow-hidden">
            <div className="absolute top-0 left-0 w-16 h-16 bg-purple-600 opacity-20 rounded-br-full"></div>
            <div className="absolute bottom-0 right-0 w-16 h-16 bg-purple-600 opacity-20 rounded-tl-full"></div>
            <p className="text-3xl font-bold mb-2">{value}</p>
            <p className="text-sm">{description}</p>
        </div>
    );

    const ReinventWithStrategicManagedServices = () => {
        const cards = [
            {
                title: 'Business Process Optimization',
                description: 'Drive efficiency with smart business operations, streamlining core processes for innovation and growth.',
                hasButton: true,
                image: 'path/to/business-process-services-image.png'
            },
            {
                title: 'Global Innovation Hubs',
                description: 'Access global talent and cutting-edge solutions to unlock new value and foster continuous improvement.',
                hasButton: true,
                image: 'path/to/global-capability-centers-image.png'
            },
            {
                title: 'Data, AI, and Automation Excellence',
                description: 'Leverage real-time data insights powered by AI and automation to enhance decision-making and drive business agility.',
                hasButton: true,
                image: 'path/to/managed-data-ai-automation-image.png'
            },
            {
                title: 'Comprehensive Cybersecurity Solutions',
                description: 'Stay ahead of evolving threats with robust cybersecurity services that safeguard your organization’s assets and data.',
                hasButton: true,
                image: 'path/to/cybersecurity-managed-services-image.png'
            },
            {
                title: 'End-to-End Technology Transformation',
                description: 'Create a tailored roadmap for seamless operational and technological transformations that support long-term success.',
                hasButton: true,
                image: 'path/to/managed-technology-transformation-image.png'
            },
            {
                title: 'Infrastructure & Application Modernization',
                description: 'Accelerate your transformation by modernizing your infrastructure and applications to meet strategic goals and achieve greater efficiency.',
                hasButton: true,
                image: 'path/to/managed-infrastructure-applications-image.png'
            }
        ];

        return <ReinventGrid title="Transform with Strategic Managed Services" cards={cards} />;
    };
    const slides = [
        {
            title: "AI-Driven Automation",
            description: "Companies are increasingly leveraging AI and machine learning to automate complex workflows, reduce manual intervention, and improve operational efficiency. AI-driven managed services are becoming a key enabler for real-time data insights and decision-making.",
            image: Strategic_img3,
            gradient: "from-blue-400 to-blue-600"
        },
        {
            title: "Cloud-Native Managed Services",
            description: "The adoption of cloud technologies continues to grow, with businesses seeking cloud-native managed services to drive scalability, flexibility, and lower infrastructure costs. Cloud solutions are being integrated across all business operations to support digital transformation.",
            image: Strategic_img4,
            gradient: "from-purple-400 to-purple-600"
        },
        {
            title: "Cybersecurity & Risk Management",
            description: "As cyber threats evolve, businesses are prioritizing managed cybersecurity services to protect sensitive data, maintain compliance, and safeguard against growing security risks. Security operations centers (SOCs) are becoming more critical to mitigate threats proactively.",
            image: Strategic_img5,
            gradient: "from-pink-400 to-pink-600"
        },
        {
            title: "Data Governance & Compliance",
            description: "With data becoming an essential asset, managing and securing data in line with regulatory frameworks is a priority. Managed services are evolving to provide robust data governance, ensuring organizations remain compliant with local and global standards.",
            image: Strategic_img10,
            gradient: "from-green-400 to-green-600"
        },
        {
            title: "End-to-End Business Process Outsourcing (BPO)",
            description: "The scope of managed services is expanding to include end-to-end business process outsourcing (BPO). By managing everything from customer service to finance, companies are optimizing their operations and improving cost-effectiveness.",
            image: Strategic_img11,
            gradient: "from-yellow-400 to-yellow-600"
        }
    ];

    const CloudTrendingCarousel = () => {
        return <TrendingCarousel title="What's Trending in Strategic Managed Services?" slides={slides} />;
    };



    const GlobalRecognition = () => {
        const awards = [
            {
                title: "DIAA CORP Named Overall Leader in the Group Consulting Services Peak",
                image: Strategic_img6,
                alt: "DIAA CORP Overall Leader"
            },
            {
                title: "A Leader in Mortgage Operations",
                image: Strategic_img7,
                alt: "Leader in Mortgage Operations"
            },
            {
                title: "A Leader in life science sales and marketing",
                image: Strategic_img8,
                alt: "Leader in Life Science Sales and Marketing"
            },
            {
                title: "A Leader in IDC MarketScape for worldwide cloud",
                image: Strategic_img9,
                alt: "Leader in IDC MarketScape for Worldwide Cloud"
            }
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };
    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Johnson", title: "Lead - Data & AI", imageUrl: Cloud_leader_img1 },
            { name: "Shakriya", title: "Chief AI Officer", imageUrl: Cloud_leader_img1 },
            { name: "Chandran", title: "Chief Responsible AI Officer", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    const segments = [
        { 
            title: 'Supply Chain Optimization',
            description: 'Enhance operational efficiency, reduce costs, and improve the agility of your supply chain to meet customer demands and market changes.',
            expanded: false 
        },
        { 
            title: 'Financial Operations Transformation',
            description: 'Streamline financial processes, optimize cash flow, and ensure compliance with advanced technology solutions for financial operations.',
            expanded: false 
        },
        { 
            title: 'Procurement & Sourcing Excellence',
            description: 'Leverage data-driven insights and innovative strategies to transform procurement processes, enhance supplier relationships, and maximize value.',
            expanded: false 
        },
        { 
            title: 'Digital Engineering & Smart Manufacturing',
            description: 'Innovate and streamline manufacturing processes with cutting-edge digital engineering solutions to drive productivity and reduce time-to-market.',
            expanded: false 
        },
        { 
            title: 'Talent and Human Resources Innovation',
            description: 'Build a workforce for the future by embracing AI-driven HR strategies, employee engagement programs, and talent development solutions.',
            expanded: false 
        },
        { 
            title: 'Customer Experience & Sales Transformation',
            description: 'Revolutionize your marketing, sales, and customer service strategies to engage customers, drive sales, and enhance brand loyalty.',
            expanded: false 
        },
        { 
            title: 'Banking & Financial Services Solutions',
            description: 'Deliver secure, compliant, and customer-centric services with technology-driven solutions tailored to the evolving needs of the banking and capital markets sector.',
            expanded: false 
        },
        { 
            title: 'Insurance Industry Modernization',
            description: 'Improve underwriting, claims processing, and customer engagement while reducing risk and operational costs with advanced digital solutions.',
            expanded: false 
        },
        { 
            title: 'Healthcare & Life Sciences Innovation',
            description: 'Harness data and AI to transform patient care, streamline operations, and improve health outcomes in an ever-evolving healthcare landscape.',
            expanded: false 
        },
        { 
            title: 'Telecommunications & Network Optimization',
            description: 'Enhance connectivity, optimize network operations, and drive growth with next-gen technologies in the telecommunications sector.',
            expanded: false 
        },
        { 
            title: 'Regulatory Compliance & Risk Management',
            description: 'Ensure compliance with industry regulations while mitigating risks through smart governance, auditing, and data management solutions.',
            expanded: false 
        },
        { 
            title: 'Legal & Contract Management',
            description: 'Modernize legal operations with automated workflows, compliance tracking, and document management to drive efficiency and mitigate legal risks.',
            expanded: false 
        },
        { 
            title: 'Captive and Retained Services',
            description: 'Deliver specialized, scalable services that meet organizational needs while optimizing costs and enhancing service quality.',
            expanded: false 
        },
        { 
            title: 'Operational Activation & Change Management',
            description: 'Ensure successful execution of strategies and operational plans with expert change management and performance optimization services.',
            expanded: false 
        },
        { 
            title: 'Trust, Safety & Security',
            description: 'Build and maintain a secure and compliant environment for your business by implementing proactive safety measures, security protocols, and trusted frameworks.',
            expanded: false 
        }
    ];
      
    const SegmentsWeSupport = () => {
        const [expandedIndex, setExpandedIndex] = React.useState(0);

        const toggleExpand = (index) => {
            setExpandedIndex(expandedIndex === index ? null : index);
        };

        return (
            <div className="text-white p-4 sm:p-8 2xl:w-3/4 mx-auto">
                <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-center">Segments we support</h2>
                <div className="space-y-2">
                    {segments.map((segment, index) => (
                        <div 
                            key={index} 
                            className={`bg-[#14192E] rounded-xl overflow-hidden cursor-pointer transition-all duration-300 ease-in-out
                                ${expandedIndex === index ? 'p-3 sm:p-4' : 'p-2'}`}
                            onClick={() => toggleExpand(index)}
                        >
                            <h3 className={`font-semibold text-center py-2 transition-colors duration-300
                                ${expandedIndex === index ? 'text-purple-500' : 'text-white'} text-sm sm:text-base`}>
                                {segment.title}
                            </h3>
                            <AnimatePresence>
                                {expandedIndex === index && (
                                    <motion.div
                                        initial={{ height: 0, opacity: 0 }}
                                        animate={{ height: "auto", opacity: 1 }}
                                        exit={{ height: 0, opacity: 0 }}
                                        transition={{ duration: 0.3 }}
                                        className="mt-2"
                                    >
                                        <div className="flex flex-col sm:flex-row items-center sm:items-start">
                                            {/* <div className="flex-shrink-0 mb-3 sm:mb-0 sm:mr-4">
                                                <img src={Strategic_img2} alt={segment.title} className="w-full sm:w-32 h-auto sm:h-24 object-cover rounded-lg" />
                                            </div> */}
                                            <p className="text-xs sm:text-sm text-center sm:text-left">{segment.description}</p>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    return (
        <div className='bg-bg text-white '>
            <Header
                title="Accelerating Growth with Managed Services"
                description="In today’s fast-evolving business landscape, managed services empower organizations to streamline operations, reduce costs, and enhance scalability. By adopting a proactive approach, businesses can unlock greater value, accelerate innovation, and drive sustainable growth."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <SalesAndCommerceStatsComponent />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithStrategicManagedServices />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <SegmentsWeSupport />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <CloudTrendingCarousel />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <GlobalRecognition />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20 mb-8 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>

    )
}

export default CloudServices