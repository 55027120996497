import React, { useEffect, useState } from 'react'
import image1 from '../assets/SapS4hana/SAP-SuccessFactors.png'
import imageCover from '../assets/SapS4hana/SapAribaCover.png'; 
import logo1 from '../assets/SapS4hana/logo1.png';
import logo2 from '../assets/SapS4hana/logo-1-dummy.png';
import logo3 from '../assets/SapS4hana/logo-2-dummy.png';
import logo4 from '../assets/SapS4hana/logo-3-dummy.png';
// import Footer from '../components/Footer';

import { Header } from '../components/resuable/SapSolution';
import { InfoCards } from '../components/resuable/SapSolution';
import { Gallery } from '../components/resuable/SapSolution';
import { PaymentCards } from '../components/resuable/SapSolution';
import { BenefitsSection } from '../components/resuable/SapSolution';
import { PartnerLogos } from '../components/resuable/SapSolution';
import { FooterComponent } from '../components/resuable/SapSolution';
import { useLocation } from 'react-router-dom';
import { useDocumentTitle } from '../utils/useDocumentTitle';

const SapAriba = () => {
    const headerData = {
        title: "What is SAP Ariba?",
        description: "SAP Ariba is a cloud-based solution designed to digitally connect suppliers and buyers on a unified platform. It streamlines procurement, supply chain management, and contract processes for Diaa Corp, enabling seamless collaboration, greater transparency, and end-to-end process optimization. By transforming procurement workflows, SAP Ariba helps Diaa Corp achieve efficiency, reduce costs, and drive smarter business decisions."
    };
    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const infoCardsData = [
        {
            title: "SAP Ariba Solutions for Diaa Corp:",
            items: [
                'Energy & Natural Resources',
                'Government & Public Sector',
                'Wholesale & Distribution',
                'Retail & Hospitality',
                'Engineering, Construction & Operations (EC&O)',
                'Manufacturing'
            ]
        },
        {
            title: "Scalable for Enterprises:",
            items: ['Built to support growing enterprises like Diaa Corp, SAP Ariba ensures seamless procurement operations, enhanced collaboration, and enterprise-level scalability to drive business efficiency and growth.']
        }
    ];

    const galleryData = {
        images: [image1],
        description: "Explore the key features and capabilities of SAP Ariba through our interactive gallery. See how Diaa Corp can streamline procurement, enhance supplier collaboration, and optimize supply chain processes with innovative cloud-based solutions."
    };

    const benefitsData = {
        title: "Key Benefits of SAP Ariba:",
        description: "",
        benefits: [
            {
                icon: '⏱️',
                title: 'Streamlined Procurement',
                description: 'Simplifies procurement processes, reduces complexity, and lowers overall sales costs.'
            },
            {
                icon: '📊',
                title: 'Access to New Suppliers',
                description: 'Connect with a wider network of suppliers to enhance and accelerate the purchasing cycle.'
            },
            {
                icon: '📄',
                title: 'Optimized RFx and Contracts',
                description: 'Efficiently manage RFx processes, pricing negotiations, and contract agreements on a single platform.'
            },
            {
                icon: '📈',
                title: 'Simplified Catalog Management',
                description: 'Easily manage and maintain supplier catalogs for improved accuracy and accessibility.'
            },
        ]
    };

    const partnerLogosData = {
        title: "SAP Ariba Partners",
        logos: [logo1, logo2, logo3, logo4, logo1]
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} backgroundImage={imageCover} />
            <InfoCards cards={infoCardsData} />
            <Gallery {...galleryData} noLaptop={true} />
            <PaymentCards title={`SAP Ariba Payment Models`} />
            <BenefitsSection {...benefitsData} />
            <PartnerLogos {...partnerLogosData} />
            <FooterComponent />
        </div>
    );
};

export default SapAriba;