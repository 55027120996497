import React, { useMemo, useState, memo, lazy, Suspense } from 'react'
import { Header } from '../components/resuable/SapSolution'
import headerImage from '../assets/Industries/Energy_img1.png'
// Import all images
import wethink1 from '../assets/WeThink/wethink1.png'
import wethink2 from '../assets/WeThink/wethink2.png'
import wethink3 from '../assets/WeThink/wethink3.png'
import wethink4 from '../assets/WeThink/wethink4.png'
import wethink5 from '../assets/WeThink/wethink5.png'
import wethink6 from '../assets/WeThink/wethink6.png'
import wethink7 from '../assets/WeThink/wethink7.png'
import wethink8 from '../assets/WeThink/wethink8.png'
import wethink9 from '../assets/WeThink/wethink9.png'
import Footer from '../components/Footer'
import { scrollToBottom } from '../components/resuable/SapComponents'
import { motion } from 'framer-motion'

const ContentModal = lazy(() => import('../components/ContentCards/ContentModal'));

function WeThink() {
  const topics = ["Topic", "Industry", "Content Type"]

  const cards = useMemo(() => [
    {
      type: "RESEARCH REPORT", 
      title: "AI for Human-Centric Growth",
      image: wethink1,
      description: "This report explores strategies for leveraging technology while managing financial obligations effectively."
    },
    {
      type: "RESEARCH REPORT",
      title: "DIAA Corp Future Focus 2025",
      image: wethink2,
      description: "An insightful analysis of emerging life trends that will shape the future of businesses by 2025."
    },
    {
      type: "CASE STUDY",
      title: "Transforming Data into Actionable Insights",
      image: wethink3,
      description: "A detailed case study showcasing how data analytics can transform historical data into actionable insights."
    },
    {
      type: "PERSPECTIVE",
      title: "Securing Tomorrow: Building Resilience in Sustainable Growth",
      image: wethink4,
      description: "A thought-provoking perspective on the evolving landscape of cybersecurity amidst advancements in generative AI."
    },
    {
      type: "CASE STUDY",
      title: "Delivering Access for All: DIAA Corp Launches Community Energy Hub",
      image: wethink5,
      description: "This case study highlights the challenges and solutions in creating a versatile streaming service that caters to diverse audiences."
    },
    {
      type: "RESEARCH REPORT",
      title: "Driving Sustainable Innovation with Integrated Operations",
      image: wethink6,
      description: "A comprehensive report on how AI-driven operations can facilitate rapid business transformation and growth."
    },
    {
      type: "RESEARCH REPORT",
      title: "Empowering Progress with a Sustainable Core",
      image: wethink7,
      description: "An exploration of the importance of a digital core in modern business strategies for sustainable growth."
    },
    {
      type: "PERSPECTIVE",
      title: "Building a Network of Change: Harnessing the Power of Collaborative Sustainability",
      image: wethink8,
      description: "Insights on how collective intelligence can drive innovation and problem-solving in organizations."
    },
    {
      type: "RESEARCH REPORT",
      title: "Sustainable Celebrations 2025",
      image: wethink9,
      description: "A forward-looking report analyzing trends and predictions for the holiday shopping season in 2024."
    }
  ], []);

  const contentData = useMemo(() => ({
    "DIAA Corp Future Focus 2025": {
      title: "DIAA Corp Future Focus 2025",
      brief: "Exploring innovations that drive sustainable progress in energy, environment, and community well-being...",
      highlights: {
        "Renewable Energy Leadership": "DIAA Corp has introduced scalable, modular solar solutions...",
        "Commitment to Environmental Restoration": "Beyond reducing emissions, DIAA Corp is launching initiatives...",
        "Empowering Communities": "Through education and employment programs...",
        "Pioneering Tech for Sustainability": "Leveraging AI, IoT, and blockchain...",
        "The Road Ahead": "DIAA Corp's vision for 2025 centers on collaborative innovation..."
      }
    },
    "Delivering Access for All: DIAA Corp Launches Community Energy Hub": {
      title: "Delivering Access for All: DIAA Corp Launches Community Energy Hub",
      type: "case-study",
      mainContent: "When DIAA Corp set out to create the Community Energy Hub—a centralized platform offering affordable and renewable energy solutions to underserved communities—it knew the challenge would be monumental. Leveraging innovative partnerships and advanced technologies, DIAA Corp successfully launched the initiative in under a year.\n\nThe Energy Hub integrates solar microgrids, AI-driven power management, and localized energy storage systems, ensuring reliable, clean energy for millions of people across three continents. Today, the Hub powers homes, schools, and small businesses in over 50 countries, impacting nearly 40 million lives globally.",
      milestones: {
        "Rapid Deployment": "The platform was operational within 12 months, providing energy access to regions that had none before.",
        "Scaling Impact": "The Hub has expanded to three continents, serving diverse geographies with tailored energy solutions.",
        "Innovative Features": "AI-powered analytics optimize energy distribution, while the platform enables local users to monitor and manage consumption."
      },
      conclusion: "The Community Energy Hub is more than just an infrastructure—it's a bridge to a better future, empowering communities with sustainable energy and driving progress worldwide."
    },
    "Driving Sustainable Innovation with Integrated Operations": {
      title: "Driving Sustainable Innovation with Integrated Operations",
      type: "research-report",
      brief: "Organizations prioritizing sustainability and operational integration are achieving 3x greater efficiency in implementing renewable energy solutions.\n\nThey report 2.5x higher resource optimization from 2020-2024 compared to peers with fragmented operations.\n\nOnly 18% of organizations have reached the highest level of operational integration, highlighting untapped opportunities.",
      insights: {
        "Operational Integration Powers Growth": "Companies with integrated operations are better equipped to deploy impactful renewable technologies and adapt to changing market demands.",
        "Efficient Resource Utilization": "Organizations with advanced operational frameworks report significantly improved resource management, reducing waste and costs.",
        "The Path to Excellence": "Achieving operational integration requires investment in skilled talent, cutting-edge tools, and adaptive processes."
      }
    },
    "Empowering Progress with a Sustainable Core": {
      title: "Empowering Progress with a Sustainable Core",
      type: "chapter",
      subtitle: "Chapter 1: Accelerating Growth Through Sustainability",
      brief: "To thrive in a rapidly changing world and address global challenges, organizations need a sustainability-focused core that drives innovation and long-term growth.",
      pillars: [
        "Build a resilient and sustainable operational foundation",
        "Increase investments in green technologies",
        "Foster community engagement and collaboration"
      ],
      keyMetric: {
        title: "70:30 impact",
        description: "70% stronger environmental resilience and 30% higher community trust and profitability"
      },
      conclusion: "Organizations that prioritize sustainability not only enhance their bottom line but also position themselves as leaders in creating a balanced, prosperous future for all."
    },
    "Building a Network of Change: Harnessing the Power of Collaborative Sustainability": {
      title: "Building a Network of Change",
      subtitle: "Harnessing the Power of Collaborative Sustainability",
      type: "perspective",
      brief: "Collaborative sustainability is a strategic approach that brings together diverse stakeholders—communities, organizations, and governments—to address global challenges collectively.\n\nThis approach enhances resource sharing, innovation, and the overall effectiveness of sustainable initiatives.\n\nOrganizations adopting collaborative sustainability must establish strong partnerships and shared-value frameworks to achieve transformative results.",
      mainConcept: {
        title: "The Power of Collective Action",
        content: "Imagine a world where renewable energy projects are co-developed by corporations and local communities, supply chains are optimized collaboratively to reduce waste, and global organizations unite to combat climate change with shared data and resources. Collaborative sustainability leverages these partnerships to accelerate impactful solutions."
      },
      analogy: {
        title: "The Beehive Analogy",
        description: "Think of a beehive. Each bee plays a unique role—workers, drones, and the queen—all critical for the hive's productivity. Similarly, collaborative sustainability relies on diverse stakeholders contributing in distinct but interconnected ways:",
        components: {
          "Community Initiatives": "Grassroots efforts to identify and address local needs.",
          "Corporate Responsibility": "Businesses integrating sustainability into operations and innovation.",
          "Policy Support": "Governments creating frameworks that enable collective progress."
        }
      }
    },
    "Sustainable Celebrations 2025": {
      title: "Sustainable Celebrations 2025",
      type: "infographic",
      subtitle: "A Season of Meaningful Choices",
      brief: "This holiday season, consumers are embracing more intentional spending, with 68% prioritizing eco-friendly and ethically sourced gifts, according to DIAA Corp's Annual Sustainable Shopping Survey. While the average holiday budget has risen slightly to $520, buyers are becoming more discerning, seeking products that align with their values and contribute to a better future.",
      challenge: "However, this shift brings challenges for businesses. Many shoppers report feeling \"choice fatigue\" as they navigate an increasing number of sustainable options. Nearly 80% say they're looking for clarity and guidance to make informed, impactful decisions.",
      question: "How can businesses connect with conscious consumers and make their offerings stand out?",
      strategies: {
        "Simplify Sustainability": "Clearly communicate the environmental and social benefits of your products, making it easier for consumers to choose confidently.",
        "Offer Tailored Recommendations": "Use technology like AI-driven personalization to match buyers with items that resonate with their values and needs.",
        "Reward Responsible Choices": "Provide incentives like discounts or loyalty points for choosing sustainable products, reinforcing their importance."
      }
    },
    "AI for Human-Centric Growth": {
      title: "AI for Human-Centric Growth",
      type: "perspective",
      subtitle: "Empowering Businesses to Bridge the Gap",
      brief: "Artificial intelligence is not just a technological evolution—it's a revolution in how businesses connect with people. Companies that integrate human-centric AI into customer engagement initiatives see a 30% higher satisfaction rate and a stronger brand affinity after five years compared to those focused only on automation.\n\nAI doesn't merely enhance productivity; it rebuilds trust and bridges the widening gap between what businesses offer and what customers value.",
      mainConcept: {
        title: "Navigating the New Normal",
        content: "The increasing disconnect between customer expectations and business offerings has created a pivotal moment for leaders in marketing, sales, and customer service. With economic pressures and resource constraints, the challenge is to do more with less—while maintaining relevance in a rapidly changing world."
      },
      analogy: {
        title: "The Role of Human-Centric AI",
        description: "Unlike traditional technologies, human-centric AI redefines relationships:",
        components: {
          "Personalized Engagement": "AI adapts to unique customer needs, fostering genuine connections.",
          "Enhanced Decision-Making": "By analyzing vast datasets, AI helps businesses predict trends and make customer-driven decisions.",
          "Responsible Innovation": "Embedding ethical frameworks ensures AI supports people's values and aspirations."
        }
      },
      conclusion: "Human-centric AI is not another layer in the stack—it's the foundation for sustainable, people-first growth. By prioritizing purpose and innovation, businesses can close the relevance gap, create lasting impact, and shape a future where technology serves humanity, not the other way around."
    },
    "Navigating the Future of Finance": {
      title: "Navigating the Future of Finance",
      type: "research-report",
      subtitle: "Top Trends for the Commercial Banking Sector in 2025",
      brief: "Commercial banks are recalibrating their strategies to ensure resilience amid regulatory pressures while driving future growth. With an emphasis on compliance, risk management, and financial stability, banks are increasingly looking to technology to fuel innovation.\n\nIn response to evolving customer needs and market conditions, banks are turning to AI and sustainable finance models to enhance efficiency and create new growth avenues.",
      insights: {
        "Strengthening Risk and Compliance": "In a landscape of heightened regulation, banks are enhancing their frameworks to safeguard against evolving financial risks.",
        "AI-Driven Innovation": "Generative AI and data analytics are being harnessed to create smarter, more efficient financial products, enhancing customer service and streamlining operations.",
        "Sustainable Financial Solutions": "Banks are investing in green finance, aligning their portfolios with environmental goals while tapping into new markets focused on sustainability."
      },
      conclusion: "By balancing short-term stability with long-term innovation, commercial banks are positioning themselves to thrive in a rapidly changing financial ecosystem."
    },
    "The Future of Trust in Autonomous AI": {
      title: "The Future of Trust in Autonomous AI",
      type: "perspective",
      subtitle: "Technology Vision 2025",
      brief: "As AI becomes a pervasive force, its autonomy will redefine the relationship between technology, people, and businesses. To harness AI's full potential, leaders must foster trust, ensuring AI operates transparently and ethically, empowering both employees and consumers.\n\nThe true power of AI lies not just in its capabilities but in its responsible use, where trust becomes the cornerstone of engagement. Without this trust, the potential of autonomous AI could remain untapped.",
      mainConcept: {
        title: "Key Takeaways for 2025",
        content: "AI will move beyond traditional roles, acting autonomously on behalf of people, while systems adapt to work in harmony with human needs."
      },
      analogy: {
        title: "Building Trust in AI",
        description: "For AI to succeed, businesses must focus on three key areas:",
        components: {
          "Trust as a Business Imperative": "For AI to be successful, businesses must cultivate trust—through transparency, ethical standards, and clear communication—both internally with employees and externally with consumers.",
          "The Promise and Challenge of AI": "AI has the potential to solve complex challenges, but it also introduces new risks. Business leaders must navigate this duality, balancing innovation with responsibility."
        }
      }
    },
    "Transforming Data into Actionable Insights": {
      title: "Transforming Data into Actionable Insights",
      type: "default",
      subtitle: "DIAA Corp unveils a groundbreaking analytics platform empowering industries with real-time intelligence.",
      brief: "DIAA Corp has reimagined industry benchmarks by launching an AI-powered platform that turns complex data into actionable insights for business leaders and decision-makers.\n\nThis innovative platform integrates historical and real-time data, enabling organizations to identify trends, assess performance, and make data-driven decisions at unprecedented speed and accuracy.",
      highlights: {
        "Comprehensive Industry Metrics": "The platform aggregates data across multiple sectors, providing a unified view of key performance indicators.",
        "AI-Driven Insights": "Advanced AI algorithms analyze years of data to uncover patterns, predict outcomes, and offer strategic recommendations tailored to each industry.",
        "User-Centric Design": "Intuitive dashboards and interactive visualizations ensure insights are accessible and actionable for all levels of leadership."
      }
    },
    "Securing Tomorrow: Building Resilience in Sustainable Growth": {
      title: "Securing Tomorrow: Building Resilience in Sustainable Growth",
      type: "default",
      brief: "Organizations striving for sustainable growth face evolving challenges, including risks from inadequate environmental safeguards and resource mismanagement.\n\nIndustries heavily reliant on natural resources or emerging technologies are particularly vulnerable to disruptions from environmental and operational risks.\n\nTo mitigate these challenges, organizations must prioritize resilience by embedding sustainable practices and proactive risk management into their growth strategies.",
      highlights: {
        "Anticipating Disruptions": "Identifying and addressing vulnerabilities, such as resource depletion and supply chain risks, ensures long-term operational stability.",
        "Sustainability by Design": "Embedding sustainable frameworks into every aspect of operations helps mitigate risks and foster community trust.",
        "Leveraging Technology": "AI and IoT-driven monitoring systems enable real-time insights, helping organizations respond swiftly to emerging threats."
      }
    }
  }), []);

  const [selectedContent, setSelectedContent] = useState(null);

  return (
    <div className='bg-bg text-white min-h-screen'>
      <Header
        title="Stay ahead of change"
        description="Explore our research, insights, and examples of real client impact, designed to help you embrace the key forces of change and get to value faster."
        backgroundImage={headerImage}
        isExploreBtn={false}
      />

      {/* <div className="flex flex-wrap justify-between items-center gap-4 px-8 xl:px-12 py-4 xl:py-8 md:w-11/12 mx-auto md:my-12">
        {topics.map((topic, index) => (
          <button
            key={index}
            className="px-6 xl:px-12 py-2 xl:py-4 rounded-full bg-[#0C122D] flex items-center gap-2 hover:opacity-90 transition-all duration-300 text-md font-semibold"
          >
            <span className="bg-custom-gradient text-transparent bg-clip-text text-2xl">+</span>
            {topic}
          </button>
        ))}
      </div> */}

      {/* Cards Grid - Updated for better responsiveness */}
      <div className="px-4 sm:px-6 lg:px-8 py-6 sm:py-8 lg:py-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 lg:gap-12 max-w-7xl mx-auto">
          {cards.map((card, index) => (
            <motion.div
              key={index}
              className="group cursor-pointer"
              whileHover={{ scale: 1.02 }}
              onClick={() => setSelectedContent(contentData[card.title])}
              loading="lazy"
            >
              <div className="relative h-40 sm:h-48 rounded-lg overflow-hidden mb-4 sm:mb-6">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  loading="lazy"
                  decoding="async"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-black/20 to-black/60" />
                <div className="absolute inset-0 p-3 sm:p-4 flex flex-col justify-between">
                  <div className="text-xs sm:text-sm font-light bg-black/30 text-white w-fit px-2 py-1 rounded">
                    {card.type}
                  </div>
                  <h3 className="text-white text-base sm:text-lg font-semibold line-clamp-2">
                    {card.title}
                  </h3>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Contact section - Updated for better responsiveness */}
      <div className="flex flex-col items-center py-6 sm:py-8 lg:py-12 w-11/12 sm:w-3/4 lg:w-1/2 mx-auto bg-[#494D624D] bg-opacity-40 backdrop-blur-md rounded-full mt-8 sm:mt-12">
        <h2 className="text-lg sm:text-xl lg:text-2xl font-bold text-white text-center mb-4 sm:mb-6 px-4">
          Experience SAP And Its Impact On Business Transformation
        </h2>
        <motion.button
          className="bg-custom-gradient text-white font-medium px-6 sm:px-8 py-2 sm:py-3 rounded-full hover:opacity-90 transition-all duration-300"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => window.location.href = '/contact-us'}
        >
          Contact us
        </motion.button>
      </div>

      <Footer />

      {/* Lazy load the modal only when needed */}
      {selectedContent && (
        <Suspense fallback={<div>Loading...</div>}>
          <ContentModal
            isOpen={!!selectedContent}
            onClose={() => setSelectedContent(null)}
            content={selectedContent}
          />
        </Suspense>
      )}
    </div>
  );
}

export default memo(WeThink);