import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiSearch, FiFilter, FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const statusColors = {
  pending: 'bg-yellow-500',
  inProgress: 'bg-blue-500',
  completed: 'bg-green-500',
  rejected: 'bg-red-500'
};

const AdminDashboard = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');

  const fetchEnquiries = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(
        `https://diaacorp-backend.ew.r.appspot.com/api/admin/enquiries?page=${page}&search=${search}&status=${status}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (!response.ok) throw new Error('Failed to fetch enquiries');

      const data = await response.json();
      setEnquiries(data.enquiries);
      setTotalPages(data.totalPages);
    } catch (err) {
      setError('Failed to load enquiries');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEnquiries();
  }, [page, search, status]);

  const updateStatus = async (id, newStatus) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`https://diaacorp-backend.ew.r.appspot.com/api/admin/enquiries/${id}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ status: newStatus })
      });

      if (!response.ok) throw new Error('Failed to update status');

      fetchEnquiries();
    } catch (err) {
      setError('Failed to update status');
    }
  };

  return (
    <div className="min-h-screen bg-bg p-6">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-[#1A1F31] rounded-xl p-6"
        >
          <h1 className="text-3xl font-bold text-white mb-8">Enquiries Dashboard</h1>

          {/* Search and Filter */}
          <div className="flex flex-col md:flex-row gap-4 mb-6">
            <div className="flex-1 relative">
              <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search enquiries..."
                className="w-full pl-10 pr-4 py-2 bg-[#242942] rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 text-white"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <select
              className="bg-[#242942] text-white px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">All Status</option>
              <option value="pending">Pending</option>
              <option value="inProgress">In Progress</option>
              <option value="completed">Completed</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>

          {/* Enquiries Table */}
          <div className="overflow-x-auto">
            <table className="w-full text-white">
              <thead className="text-left bg-[#242942]">
                <tr>
                  <th className="p-4">Name</th>
                  <th className="p-4">Email</th>
                  <th className="p-4">Subject</th>
                  <th className="p-4">Status</th>
                  <th className="p-4">Actions</th>
                </tr>
              </thead>
              <tbody>
                {enquiries.map((enquiry) => (
                  <motion.tr
                    key={enquiry._id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="border-b border-[#242942]"
                  >
                    <td className="p-4">{enquiry.name}</td>
                    <td className="p-4">{enquiry.email}</td>
                    <td className="p-4">{enquiry.subject}</td>
                    <td className="p-4">
                      <span className={`px-3 py-1 rounded-full text-sm ${statusColors[enquiry.status]}`}>
                        {enquiry.status}
                      </span>
                    </td>
                    <td className="p-4">
                      <select
                        className="bg-[#242942] text-white px-3 py-1 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                        value={enquiry.status}
                        onChange={(e) => updateStatus(enquiry._id, e.target.value)}
                      >
                        <option value="pending">Pending</option>
                        <option value="inProgress">In Progress</option>
                        <option value="completed">Completed</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex justify-between items-center mt-6">
            <button
              className="bg-[#242942] text-white px-4 py-2 rounded-lg disabled:opacity-50"
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              <FiChevronLeft />
            </button>
            <span className="text-white">
              Page {page} of {totalPages}
            </span>
            <button
              className="bg-[#242942] text-white px-4 py-2 rounded-lg disabled:opacity-50"
              disabled={page === totalPages}
              onClick={() => setPage(page + 1)}
            >
              <FiChevronRight />
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AdminDashboard; 