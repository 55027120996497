import React, { useEffect, useState } from 'react'
import { Header } from '../components/resuable/SapSolution'
import MigrationInfo from '../components/SAP/MigrantInfo'
import EnterpriseCover from '../assets/SAP/EnterpriseMangedCover.png'
import cloudImg1 from '../assets/SAP/cloudManagedimg.png'
import mysqlImg1 from '../assets/SAP/mysql.png'
import gcpImg from '../assets/SAP/sap-cloud-gcp.webp';
import awsImg from '../assets/SAP/sap-cloud-aws.webp';
import azureImg from '../assets/SAP/sap-cloud-migration-azure.webp';
import Enterprise_img1 from '../assets/Capabilities/businessDisruption.png'
import Enterprise_app_img1 from '../assets/Capabilities/Enterprise_app_img1.png'
import Enterprise_app_img2 from '../assets/Capabilities/Enterprise_app_img2.png'
import Enterprise_app_img3 from '../assets/Capabilities/Enterprise_app_img3.png'
import Enterprise_app_img4 from '../assets/Capabilities/Enterprise_app_img4.png'
import Enterprise_app_img5 from '../assets/Capabilities/Enterprise_app_img5.png'

// Import more images as needed
import ourSuccessImg3 from '../assets/SAP/ourSuccessImg3.png'
import { motion, AnimatePresence } from 'framer-motion'
import { ContactContent, FAQItem, FeatureGrid, Slider } from '../components/resuable/SapComponents'
import LocationMap from '../components/SAP/Map'
import { FaGlobe, FaCloud, FaLock, FaClock, FaGraph, FaRobot, FaDatabase, FaChartBar } from 'react-icons/fa';
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'

const images = [
    { src: mysqlImg1, alt: "MySQL" },
    { src: gcpImg, alt: "GcpImg" },
    { src: awsImg, alt: "awsImg" },
    { src: azureImg, alt: "azureImg" },
    // Add more image objects as needed
];

function EnterpriseApplications() {

    
    const headerData = {
        title: "Cloud-Native Application Management Services",
        description: "Effortlessly optimize, manage, and scale your business-critical applications on a secure, high-performance cloud platform. Achieve unmatched agility, maximum uptime, and seamless business continuity tailored to your needs."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const migrationInfoData = {
        stats: [
            { title: "$7000", subtitle: "The average cost for a single minute of downtime for enterprise applications." },
            { title: "48 %", subtitle: "Enterprise Application Managed Services can enhance system efficiency and minimize downtime, significantly reducing business losses." },
            { title: "60 %", subtitle: "Organizations using managed services experience faster issue resolution and increased application performance." },
            { title: "35 %", subtitle: "Reduction in operational costs for businesses leveraging enterprise application managed services." },
        ],
        mainContent: {
            heading: "Empowering Your Enterprise: Seamless Application Management on a Future-Ready Cloud",
            paragraphs: [
                "In today’s fast-paced business environment, enterprise applications drive critical operations such as supply chain management, customer engagement, data governance, project execution, and strategic decision-making. However, managing these applications on traditional IT infrastructure is becoming increasingly complex and inefficient.",
                "With DIAA Corp, a leader in application-focused cloud managed services, transform the way you run your enterprise applications. From infrastructure hosting and server management to networks, middleware, and platform optimization, we ensure your applications like ERP, CRM, CMS, and database systems operate at peak performance.",
                "Achieve unparalleled availability, enhanced security, and seamless business continuity, while unlocking the full potential of advanced analytics and intelligence. Elevate your business operations with a scalable, agile, and future-ready cloud platform designed to meet your organization’s evolving needs."
            ]
        },
    };

    const contentData = [
        {
            title: "Rising Maintenance Costs",
            content: `Streamline your application management, maintenance, and security lifecycle with a fully managed service approach. 
            Eliminate redundancies, adopt tailored automation solutions, and significantly reduce costs throughout your application journey.`,
            image: Enterprise_app_img4
        },
        {
            title: "Lack of Integration and Visibility",
            content: `Gain complete visibility and control over your IT ecosystem with a unified dashboard for all applications, whether hosted on private IT environments, multiple clouds (Azure, AWS, Google Cloud), hybrid clouds, or third-party enterprise systems. 
            Manage diverse application landscapes seamlessly through leading tools like Azure Arc, AWS Outposts, and private or hybrid cloud platforms of your choice.`,
            image: Enterprise_app_img2,
        },
        {
            title: "Backend Limitations Stunting Performance",
            content: `Break free from the constraints of legacy on-prem IT infrastructure by adopting robust virtual backend solutions. 
            Scale application performance dynamically, achieve unparalleled uptime, and ensure hyper-agility to support business growth and innovation.`,
            image: Enterprise_img1
        },
        {
            title: "Business Disruptions and Downtime",
            content: "Achieve operational excellence like global enterprises that leverage advanced cloud managed services to support over 200,000 users concurrently with zero data loss and 99.95% uptime. Guarantee smooth operations with industry-leading solutions.",
            image: Enterprise_app_img3
        },
        {
            title: "Delays in Resolving Application Issues",
            content: `Leverage 24/7 monitoring, expert consulting, and automated issue resolution protocols to address application challenges instantly. 
            Proactive alerts and rapid remedy execution ensure your applications remain operational and your business uninterrupted.`,
            image: Enterprise_app_img1
        },
        {
            title: "Lacks End-to-End Application Administration",
            content: `Bid adieu to all application management resource hassles with dedicated DevOps, infra engineering, maintenance, risk handling, application migration, delivery experts acting as an extended client team. 
            Gain in-depth strategic, advisory, and consulting for application management end-to-end.`,
            image: Enterprise_app_img5
        }
    ];



    const DIAACorpCloudComponent = () => {
        const [currentIndex, setCurrentIndex] = useState(2);
        const [visibleTitles, setVisibleTitles] = useState(5);

        useEffect(() => {
            const handleResize = () => {
                if (window.innerWidth < 640) {
                    setVisibleTitles(1);
                } else if (window.innerWidth < 768) {
                    setVisibleTitles(3);
                } else {
                    setVisibleTitles(5);
                }
            };

            handleResize();
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        const nextSlide = () => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % contentData.length);
        };

        const prevSlide = () => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + contentData.length) % contentData.length);
        };

        const getVisibleTitles = () => {
            const start = Math.max(0, currentIndex - Math.floor(visibleTitles / 2));
            const end = Math.min(contentData.length, start + visibleTitles);
            return contentData.slice(start, end);
        };

        return (
            <div className="w-full max-w-7xl text-white mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-xl sm:text-3xl lg:text-4xl font-bold text-center my-6 sm:my-12 lg:my-24">
                    Break the Legacy Barriers: Why Opt for Application Managed Services?
                </h1>

                <div className="relative mb-8">
                    <div className="flex justify-between items-center mb-4 sm:mb-8 lg:mb-12 overflow-x-auto no-scrollbar">
                        {getVisibleTitles().map((item, index) => (
                            <span
                                key={index}
                                className={`text-xs sm:text-sm lg:text-md whitespace-nowrap px-2 py-1 ${item.title === contentData[currentIndex].title
                                    ? 'bg-gradient-to-r from-[#a100ff] to-[#57CFFF] text-transparent bg-clip-text font-bold'
                                    : 'text-gray-500'
                                    }`}
                            >
                                {item.title}
                            </span>
                        ))}
                    </div>
                    <div className='mobile:mb-24'>

                        <button onClick={prevSlide} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-theme pb-2 pt-1 px-2 rounded-full z-10 text-xl font-semibold">
                            ←
                        </button>
                        <button onClick={nextSlide} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-theme p-2 pt-1 px-2 rounded-full z-10 text-xl font-semibold">
                            →
                        </button>

                    </div>

                    <AnimatePresence mode="wait">
                        <motion.div
                            key={currentIndex}
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -100 }}
                            transition={{ duration: 0.5 }}
                            className="flex flex-col sm:flex-row items-center justify-between sm:mx-4 lg:mx-12"
                        >
                            <div className="w-full sm:w-1/2 mb-4 sm:mb-0">
                                <div className="rounded-lg overflow-hidden border-2 border-[#a100ff]">
                                    <img
                                        src={contentData[currentIndex].image}
                                        alt={contentData[currentIndex].title}
                                        className="w-full h-auto"
                                    />
                                </div>
                            </div>
                            <div className="w-full sm:w-1/2 sm:pl-4 lg:pl-8">
                                <h2 className="text-xl sm:text-2xl font-bold mb-4 bg-gradient-to-r from-[#a100ff] to-[#57CFFF] text-transparent bg-clip-text">
                                    {contentData[currentIndex].title}
                                </h2>
                                <p className="text-gray-300 text-sm sm:text-base">
                                    {contentData[currentIndex].content}
                                </p>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>
        );
    };



    const ApplicationMap = () => {
        const applications = [
            { name: 'Enterprise App', items: 4, icons: [
                'https://img.icons8.com/fluency/96/company.png',
                'https://img.icons8.com/fluency/96/business-network.png',
                'https://img.icons8.com/fluency/96/workflow.png',
                'https://img.icons8.com/fluency/96/business-report.png'
            ]},
            { name: 'Core Banking', items: 4, icons: [
                'https://img.icons8.com/fluency/96/bank-building.png',
                'https://img.icons8.com/fluency/96/mobile-payment.png',
                // 'https://img.icons8.com/fluency/96/online-banking.png',
                // 'https://img.icons8.com/fluency/96/credit-card.png',
                'https://img.icons8.com/fluency/96/bank-building.png',
                'https://img.icons8.com/fluency/96/mobile-payment.png'
            ]},
            { name: 'E-Commerce', items: 4, icons: [
                'https://img.icons8.com/fluency/96/shopping-cart.png',
                'https://img.icons8.com/fluency/96/online-shop.png',
                'https://img.icons8.com/fluency/96/shopping-cart.png',
                // 'https://img.icons8.com/fluency/96/card-payment.png',
                'https://img.icons8.com/fluency/96/delivery.png'
            ]},
            { name: 'Information Mgmt.', items: 4, icons: [
                'https://img.icons8.com/fluency/96/database.png',
                'https://img.icons8.com/fluency/96/database.png',
                'https://img.icons8.com/fluency/96/database.png',
                'https://img.icons8.com/fluency/96/database.png',
                // 'https://img.icons8.com/fluency/96/data-sheet.png',
                // 'https://img.icons8.com/fluency/96/data-protection.png',
                // 'https://img.icons8.com/fluency/96/data-backup.png'
            ]},
        ];
        
        const databases = [
            { name: 'SQL OpenSource Database', items: 4, icons: [
                'https://img.icons8.com/fluency/96/mysql-logo.png',
                'https://img.icons8.com/fluency/96/mysql-logo.png',
                // 'https://img.icons8.com/fluency/96/postgresql.png',
                'https://img.icons8.com/fluency/96/database.png',
                'https://img.icons8.com/fluency/96/database-restore.png'
            ]},
            { name: 'SQL Commercial Database', items: 4, icons: [
                'https://img.icons8.com/fluency/96/database.png',
                'https://img.icons8.com/fluency/96/database.png',
                'https://img.icons8.com/fluency/96/database.png',
                'https://img.icons8.com/fluency/96/database.png',

                // 'https://img.icons8.com/fluency/96/microsoft-sql-server.png',
                // 'https://img.icons8.com/fluency/96/oracle-logo.png',
                // 'https://img.icons8.com/fluency/96/database-administrator.png',
                // 'https://img.icons8.com/fluency/96/database-export.png'
            ]},
            { name: 'NoSQL Database', items: 4, icons: [
                // 'https://img.icons8.com/fluency/96/mongodb.png',
                'https://img.icons8.com/fluency/96/redis.png',
                'https://img.icons8.com/fluency/96/redis.png',
                'https://img.icons8.com/fluency/96/cloud-database.png',
                'https://img.icons8.com/fluency/96/cloud-database.png',
                // 'https://img.icons8.com/fluency/96/elasticsearch.png'
            ]},
        ];
        

        const renderItems = (icons) => (
            icons.map((icon, index) => (
                <div key={index} className="transform transition-all duration-300 hover:scale-110">
                    <div className="w-12 h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-lg bg-gradient-to-br from-[#1a1f31] to-[#2a3042] shadow-lg overflow-hidden p-2 border border-[#a100ff]/20">
                        <img 
                            src={icon} 
                            alt="Application Icon"
                            className="w-full h-full object-contain filter drop-shadow-lg" 
                            loading="lazy"
                        />
                    </div>
                </div>
            ))
        );

        const renderSection = (item) => (
            <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-6 justify-center group">
                <div className="w-full sm:w-48 md:w-56 lg:w-64 py-3 px-6 rounded-xl text-sm md:text-base text-center bg-gradient-to-r from-[#1a1f31] to-[#2a3042] shadow-lg transform transition-all duration-300 group-hover:shadow-purple-500/20" 
                    style={{
                        border: "2px solid",
                        borderImage: "linear-gradient(90deg, #a100ff, #57CFFF) 1",
                    }}>
                    <span className="bg-gradient-to-r from-[#a100ff] to-[#57CFFF] bg-clip-text text-transparent font-semibold">
                        {item.name}
                    </span>
                </div>
                <div className="flex flex-wrap justify-center gap-4 sm:gap-6">
                    {renderItems(item.icons)}
                </div>
            </div>
        );

        return (
            <div className="p-6 sm:p-8 md:p-12 mt-8 md:mt-16 w-full max-w-7xl mx-auto bg-[#14192E]/50 rounded-2xl backdrop-blur-sm">
                <div className="space-y-12 sm:space-y-16 md:space-y-20">
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-8 sm:mb-12 text-center">
                        Sneak Map: <span className='bg-gradient-to-r from-[#a100ff] to-[#57CFFF] bg-clip-text text-transparent'>Key Enterprise Applications Managed by DIAA Corp</span>
                    </h2>
                    <div className="space-y-8 sm:space-y-12">
                        {applications.map((app, index) => (
                            <React.Fragment key={index}>
                                {renderSection(app)}
                            </React.Fragment>
                        ))}
                    </div>
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-8 sm:mb-12 text-center">
                        Overview: <span className='bg-gradient-to-r from-[#a100ff] to-[#57CFFF] bg-clip-text text-transparent'>Key Application Databases Managed by DIAA Corp</span>
                    </h2>
                    <div className="space-y-8 sm:space-y-12">
                        {databases.map((db, index) => (
                            <React.Fragment key={index}>
                                {renderSection(db)}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const AutomationEnabled = () => {
        return (
            <div className="bg-[#14192E] text-white flex justify-center items-center mobile:px-2 py-6 md:py-16">
                <div className="max-w-6xl mx-auto">
                    <div className="flex flex-col md:flex-row justify-between items-center mb-8">
                        <div className="md:w-1/2 pr-4">
                            <h1 className="text-xl md:text-3xl font-bold mb-4 mobile:text-center">DIAA Corp: Revolutionizing Enterprise Application Management with Automation and AI</h1>
                        </div>
                        <div className="md:w-1/2 pl-4 mt-8 md:mt-0">
                            <p className='mobile:text-sm'>DIAA Corp, a global leader in cloud-focused enterprise application managed services, is your trusted partner for end-to-end application management and modernization. With our proprietary Self-Healing Operations Platform (SHOP) and expertise in cloud architecture, we simplify application transformation and migration to any public, private, hybrid, or multi-cloud environment of your choice.</p>
                        </div>
                    </div>
                    <div className="text-left mb-8 mobile:text-sm">
                        <p>Our Zero Friction Migration Framework ensures a seamless transition of mission-critical applications and databases, minimizing disruption and maximizing efficiency. Modernize your application ecosystem with automated, AI-powered operations that deliver unparalleled availability, agility, scalability, and security. Leverage 24/7 monitoring for application health and infrastructure, backed by military-grade security to protect against advanced threats. Ensure compliant, uninterrupted operations with a unified SLA that guarantees world-class performance. Experience the next level of enterprise application management with DIAA Corp.</p>
                    </div>
                </div>
            </div>
        );
    };

    const ServiceImpactManagement = () => {
        const hexagonItems = [
            { text: 'Service Performance', position: 'col-start-2 row-start-1 mobile:col-start-1' },
            { text: 'IT Support Desk', position: 'col-start-3 row-start-1 mobile:col-start-2' },
            { text: 'Resource Planning', position: 'col-start-1 row-start-2 mobile:col-start-1 mobile:row-start-2' },
            { text: 'Asset Management', position: 'col-start-4 row-start-2 mobile:col-start-2 mobile:row-start-2' },
            { text: 'Change Control', position: 'col-start-2 row-start-3 mobile:col-start-1 mobile:row-start-3' },
            { text: 'Event Management', position: 'col-start-3 row-start-3 mobile:col-start-2 mobile:row-start-3' },
            { text: 'IT Service Operations', position: 'col-start-2 col-span-2 row-start-4 mobile:col-start-1 mobile:col-span-2 mobile:row-start-4' },
        ];

        const infrastructureItems = [
            'Application Workloads: Batch and Real-Time', 'Data Storage Optimization', 'Database Performance Tuning', 
            'Middleware Efficiency', 'Network Reliability', 'Server Management and Uptime', 
            'Desktop Support and Optimization', 'Cloud Infrastructure Scalability and Agility'
        ];

        return (
            <div className="p-4 flex flex-col items-center justify-center my-4 md:my-12">
                <div className="max-w-4xl w-full">
                    <div className="grid grid-cols-4 gap-4 mb-8 mobile:grid-cols-2 mobile:gap-2">
                        {hexagonItems.map((item, index) => (
                            <div key={index} className={`${item.position} flex items-center justify-center`}>
                                <div className="relative w-40 h-48 mobile:w-32 mobile:h-36">
                                    <svg viewBox="0 0 150 150" className="w-full h-full">
                                        <polygon
                                            points="75 1.5 142.5 37.5 142.5 112.5 75 148.5 7.5 112.5 7.5 37.5"
                                            fill="transparent"
                                            stroke="url(#blue-gradient)"
                                            strokeWidth="2"
                                        />
                                        <text x="75" y="75" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="12" className="">
                                            {item.text}
                                        </text>
                                        <defs>
                                            <linearGradient id="blue-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                <stop offset="0%" stopColor="#a100ff" />
                                                <stop offset="100%" stopColor="#57CFFF" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        ))}
                        <div className="col-start-2 col-span-2 row-start-2 flex items-center justify-center mobile:col-start-1 mobile:col-span-2 mobile:row-start-5">
                            <p className="text-xl font-bold mobile:text-lg">Service Impact management</p>
                        </div>
                    </div>

                    <div className="mt-8 ">
                        <div className="border-t-2 border-blue-400 w-1/6 mx-auto mb-4"></div>
                        <div className="bg-[#1D233A] rounded-lg p-4 md:mt-24 md:py-12">
                            <p className="text-center mb-4 mobile:text-sm ">Batch & Online applications performance and availability</p>
                            <div className="flex flex-wrap justify-center gap-4 mobile:gap-2 mt-8">
                                {infrastructureItems.map((item, index) => (
                                    <div key={index} className="border border-theme rounded-full px-4 py-2 text-sm mobile:text-xs mobile:px-2 mobile:py-1">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const tab1 = [
        'Cloud Migration and Transformation',
        'Comprehensive Application Deployment and Management', 
        'Advanced Database and Application Operations',
        'End-to-End Application Modernization'
    ];

    const tab2 = [
        'DevOps and DevSecOps Excellence',
        'Cloud Cost Optimization',
        'Comprehensive Application Security', 
        'Proactive Application Monitoring and Incident Management'
    ];

    const tab3 = [
        'Controlled Application Change Management',
        'Automated Patch and Continuity Management',
        'Disaster Recovery and Business Continuity',
        '24/7 Support and Expert Consulting'
    ];

    const content1 = [
        <p>Utilize DIAA Corp's Migration Framework for seamless, step-by-step application migration and transformation. Design tailored transformation strategies supported by lean, metric-driven automated processes and intuitive dashboards for efficient management.</p>,
        <p>Deploy and manage cloud infrastructure across private, hybrid, public, or multi-cloud environments. Leverage on-demand provisioning to support application operations and integrate with your ITSM catalog for rapid setup in test or production environments.</p>,
        <p>Streamline the management and maintenance of business applications with DIAA Corp's Self-Healing Operations Platform (SHOP). Ensure maximum availability and security across infrastructure and application layers using AIOps-driven automation.</p>,
        <p>Migrate and modernize applications, dataflows, and databases with DIAA Corp's expertise. Ensure secure and compliant data operations with ultra-low latency, robust governance, and seamless aggregation across cloud platforms.</p>
    ];

    const content2 = [
        <p>Revolutionize your application lifecycle with robust DevOps and DevSecOps practices. Implement microservices architectures, containerization, Kubernetes orchestration, and Infrastructure as Code (IaC). Leverage DIAA Corp's expertise in CI/CD pipelines, automation, system building, and continuous testing for seamless application delivery and enhanced security.</p>,
        <p>Maximize cloud efficiency by running applications with optimal performance at minimized costs. Receive detailed monthly reports on key operational metrics, events, and impacts. Employ DIAA Corp's specialized Cloud Cost Optimization strategies to enhance cloud usage efficiency without sacrificing performance.</p>,
        <p>Secure your applications with 24/7 monitoring to identify vulnerabilities, prevent malware attacks, and address potential intrusions. Strengthen security with advanced solutions like Managed Detection and Response (MDR), SOC services, Threat Intelligence, Security Analytics, Incident Management, and Access Controls for a fortified application environment.</p>,
        <p>Ensure continuous health and availability of your application infrastructure with 24/7 monitoring. Analyze logs for vulnerabilities, prevent data breaches, and resolve threats through automated remediation protocols. Achieve complete visibility into application performance and risk posture with intuitive dashboards for proactive decision-making.</p>
    ];

    const content3 = [
        <p>DIAA Corp offers a secure and efficient process for managing changes to your application infrastructure. Ensure continuous performance improvements, security upgrades, and compliance with automated approvals and self-service scheduling through our streamlined change management engine.</p>,
        <p>Maintain the integrity and security of your application resources, databases, and workloads with automated patching and backup processes. Critical security patches are applied immediately, while routine patches are scheduled based on your preferences, ensuring vulnerability-free, up-to-date systems.</p>,
        <p>Ensure the highest levels of availability for your cloud-hosted applications with DIAA Corp's disaster recovery (DR) and backup solutions, offering up to 99.95% uptime. Benefit from cost-effective, automated data backup and recovery strategies with DRaaS frameworks tailored to meet your enterprise's specific needs for uninterrupted services.</p>,
        <p>Access round-the-clock enterprise application support and consulting with DIAA Corp's concierge-like service. Our expert engineers and advanced tools provide continuous technical support, manage system health, and assist in complex change requests, ensuring that your team is empowered to maintain seamless operations.</p>
    ];

    const CloudManagedTabs = ({ }) => {
        return (
            <div className="w-full md:w-11/12 mx-auto p-4 md:p-6 bg-darkBlue-900 text-white rounded-lg mt-8 md:mt-24">
                <h2 className="text-xl md:text-3xl font-bold text-center mb-4 md:mb-8">
                    DIAA Corp Comprehensive Managed Services for Critical Enterprise Applications
                </h2>
            </div>
        );
    }


    const KnowledgeDesk = () => {
        const [activeStory, setActiveStory] = useState('Falconpack');

        const cards = [
            {
                title: 'Enterprise Application Hosting',
                description: 'Managing and securing enterprise applications within private data centers can be complex and resource-intensive. DIAA Corp offers secure, reliable hosting for all your mission-critical applications on the cloud, ensuring high availability, scalability, and top-tier security, so you can focus on business growth while we manage your infrastructure.',
            },
            {
                title: 'Application Modernization with the 6R Approach',
                description: "Utilize DIAA Corp's comprehensive 6R methodology to modernize your enterprise applications. Our expert team of developers, engineers, and security professionals will collaborate with you to revamp your applications, ensuring they are future-proof, high-performing, and optimized for cloud environments.",
            },
            {
                title: 'Compliance-as-a-Service (CaaS)',
                description: 'With global data regulations becoming more stringent, DIAA Corp helps you navigate complex compliance requirements. We ensure that your data is hosted in compliance with local laws while running your applications on public cloud infrastructure for optimal performance, providing a perfect balance between compliance and innovation.',
            },
        ];
        return (
            <div className="text-white p-8 mt-12 md:mt-24 md:w-11/12 mx-auto">
                <h2 className="text-xl md:text-3xl font-bold mb-6 md:text-center ">Transforming Enterprise Applications with DIAA Corp: Advanced Cloud Solutions</h2>

                <div className="grid grid-cols-1 md:grid-cols-3 my-12 mobile:mb-12 md:mb-24 md:mt-24">
                    {cards.map((card, index) => (
                        <div key={index} className="bg-[#14192E] p-6 rounded-2xl md:w-11/12 mx-auto mobile:mb-4">
                            <h3 className="text-xl font-semibold mb-4">{card.title}</h3>
                            <p className="mb-4">{card.description}</p>
                            <button className="text-theme hover:text-blue-300 transition-colors duration-300">
                                Read more <span className="ml-2">&raquo;</span>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        );
    };
    const OurSuccessStories = () => {
        const [activeStory, setActiveStory] = useState('BESCOM');

        const stories = {
            BESCOM: {
                title: 'BESCOM',
                description: 'Leading electricity and utility supplier organization obtains uninterrupted application availability and seamless performance through Multilayer security and pre-integrated DR.',
                image: ourSuccessImg3,
            },
            'Oil and Gas major': {
                title: 'Oil and Gas major',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg3,
            },
            'Global Ceramics Brand': {
                title: 'Global Ceramics Brand',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg3,
            },
            'Jaleel Holdings': {
                title: 'Jaleel Holdings',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg3,
            },
            // Add other stories here
        };


        return (
            <div className='md:w-11/12 mx-auto px-4 lg:px-0'>

                <h2 className="text-3xl font-bold mb-6 text-center">Our Success Stories</h2>

                <div className="flex flex-wrap gap-4 mb-8 justify-center mt-12 md:my-12 md:space-x-8">
                    {Object.keys(stories).map((storyKey) => (
                        <button
                            key={storyKey}
                            className={`px-4 md:px-6 py-2 rounded-full text-[#F1F1F1] ${activeStory === storyKey
                                ? 'bg-custom-gradient'
                                : 'border border-[#F1F1F1] hover:bg-gray-600'
                                } transition-colors duration-300`}
                            onClick={() => setActiveStory(storyKey)}
                        >
                            {storyKey}
                        </button>
                    ))}
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeStory}
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.3 }}
                        className="md:flex items-center"
                    >
                        <img
                            src={stories[activeStory].image}
                            alt={stories[activeStory].title}
                            className="md:w-1/2 rounded-lg mr-8 mobile:mb-2"
                        />
                        <div>
                            <h3 className="text-2xl font-semibold mb-4">{stories[activeStory].title}</h3>
                            <p>{stories[activeStory].description}</p>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        )
    }

    const features = [
        { icon: <FaGlobe />, text: "With over a decade of experience, DIAA Corp stands as a trusted leader in providing cloud and application managed services across APAC, MEA, and the Americas, ensuring innovative, reliable, and efficient cloud solutions for enterprises globally." },
        { icon: <FaCloud />, text: "We specialize in AIOps-powered managed services, enhancing cloud infrastructure management and performance. As the largest provider focused on application-centric cloud services, we guarantee seamless, automated operations with intelligent insights for continuous improvement." },
        { icon: <FaClock />, text: "Our 2000+ certified cloud professionals are available 24/7 to provide unparalleled support. Equipped with expertise in ITIL, ITSM, and COBIT, our team leverages advanced tools and methodologies to resolve challenges and optimize your cloud environment." },
        { icon: <FaLock />, text: "Through our cutting-edge Cloud Migration Factory, we ensure smooth transitions to cloud environments, migrating over 25,000 applications and databases without disruptions. Our approach focuses on zero friction and minimal downtime, enabling businesses to scale quickly and securely." },
        { icon: <FaGlobe />, text: "DIAA Corp serves over 4,000 enterprise clients, including 60 Fortune 500 companies and five of the world's top 20 global banks. Our solutions have consistently met the needs of large-scale organizations, helping them unlock the full potential of cloud technologies." },
        { icon: <FaCloud />, text: "With a presence in 26 countries, we provide tailored cloud solutions across various regions. Whether deploying on public, private, hybrid, multi-cloud, or community cloud platforms, we ensure the right infrastructure for your business needs." },
        { icon: <FaChartBar />, text: "Achieve 99.95% uptime and scalability for your enterprise applications. DIAA Corp's solutions offer industry-leading reliability, enabling businesses to scale effortlessly while maintaining peak performance in all environments." },
        { icon: <FaDatabase />, text: "DIAA Corp is a recognized leader in SAP cloud solutions, managing over 10,000 SAP instances and 3,000+ TB of HANA databases. Our expertise ensures seamless SAP migration, modernization, and performance optimization in the cloud." },
        { icon: <FaCloud />, text: "We specialize in managing IBM, Oracle, OpenText, Infosys, and cloud-native enterprise applications. Whether SaaS, PaaS, or IaaS, we provide end-to-end solutions that drive efficiency, security, and scalability for your business." },
        { icon: <FaGlobe />, text: "Deploy your applications in the ideal region for your business. With DIAA Corp, you can ensure high availability and disaster recovery, regardless of geographical location, to keep your operations running without interruption." },
        { icon: <FaLock />, text: "Transform your legacy applications with DIAA Corp's full-stack modernization services. From development and engineering to ongoing security and maintenance, we provide a comprehensive approach to keep your applications at the forefront of technology." },
        { icon: <FaCloud />, text: "Ensure business continuity with DIAA Corp's automated disaster recovery solutions. Our cloud-based DRaaS strategies offer failover and backup mechanisms to guarantee uptime and protect data integrity in even the most complex environments." },
        { icon: <FaLock />, text: "We offer end-to-end security management with over 40 security controls, including a dedicated Security Operations Center (SOC). Our security-first approach ensures that your applications are protected against advanced threats and vulnerabilities." },
        { icon: <FaChartBar />, text: "We adhere to global data residency regulations and industry compliance standards. DIAA Corp's cloud solutions ensure that your data remains secure and compliant with local laws, providing peace of mind in an ever-evolving regulatory landscape." },
    ];


    const CloudDifferenceBoxes = () => {
        return (
            <FeatureGrid
                title="Why Choose DIAA Corp for Managed Cloud Solutions?"
                features={features}
            />
        );
    };

    const FAQSection = () => {
        const faqs = [
            {
                question: "What is an Application Management System?",
                answer: `An Application Management System is a comprehensive platform that helps organizations manage the lifecycle of their enterprise applications. It enables monitoring, automation, and maintenance of all applications while ensuring optimal performance, scalability, and security. DIAA Corp’s application managed services ensure the continuous availability and smooth operation of your applications, backend architectures, and networks, allowing seamless access to critical business functionalities.`
            },
            {
                question: "What is included in Application Managed Services?",
                answer: `Application Managed Services encompass a range of services to ensure the efficient operation of your enterprise applications. These services include cloud migration, application hosting, performance optimization, security management, monitoring, incident response, and ongoing maintenance. DIAA Corp’s managed services also provide data management, disaster recovery solutions, and seamless integration with other IT systems, ensuring high availability, performance, and security for all your applications.`
            },
            {
                question: "What are the responsibilities of Application Management?",
                answer: `The primary responsibility of Application Management is to ensure the continuous performance, security, and scalability of enterprise applications. This includes tasks such as application monitoring, issue resolution, updates, backups, and security patch management. DIAA Corp’s application management services also involve providing proactive support, enhancing application performance, and optimizing cloud environments to ensure that business-critical applications run smoothly without disruptions.`
            },
        ];

        return (
            <div className="flex items-center justify-center p-4">
                <div className="max-w-3xl w-full">
                    <h1 className="text-3xl font-bold text-white text-center mb-8 md:mb-16">
                        Enterprise Application Managed Services - FAQs
                    </h1>
                    {faqs.map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} isExploreBtn={true} backgroundImage={EnterpriseCover} />

            <div>
                <MigrationInfo {...migrationInfoData} isMigrationFactory={false} />
            </div>

            <div className='bg-[#14192E]'>
                <DIAACorpCloudComponent />
            </div>

            <div className=''>
                <ApplicationMap />
            </div>

            <div className='mt-12 md:mt-24'>
                <ContactContent title={`Eager to transform your applications and assets on the cloud?`} />
            </div>

            <div className='mt-12 md:mt-24 md:py-24 md:w-11/12 mx-auto mobile:px-2'>
                <h2 className="text-xl md:text-3xl font-bold text-center mb-4 md:mb-8">
                    What Drives Us to Excel: Words from Our Partners
                </h2>
                <p className='mt-2 md:mt-6 text-center mb-12 text-gray-300'>
                    “Jaleel Holdings is proud to collaborate with DIAA Corp, a cloud service provider that goes above and beyond to address our business challenges seamlessly. Their certified cloud experts conducted an in-depth analysis of our operations and recommended a cost-efficient architecture tailored to our needs. DIAA Corp has been instrumental in ensuring smooth and uninterrupted business processes. Their unwavering commitment to customer success and exceptional service delivery truly sets them apart.”
                </p>
            </div>

            <div>
                <AutomationEnabled />
            </div>

            <div>
                <ServiceImpactManagement />
            </div>

            <div>
                <CloudManagedTabs />

                <Slider tabs={tab1} content={content1} />
                <Slider tabs={tab2} content={content2} />
                <Slider tabs={tab3} content={content3} />
            </div>

            <div>
                <KnowledgeDesk />
            </div>

            <div>
                <ContactContent title={`Connect with our Application Managed Service Experts`} />
            </div>

            <div className='mx-auto 4xl:py-16'>
                <LocationMap />
            </div>

            <div className=''>
                <OurSuccessStories />
            </div>

            <div className='md:w-11/12 mx-auto'>
                <CloudDifferenceBoxes />
            </div>

            <div className='mt-12 md:mt-24'>
                <FAQSection />
            </div>

            <Footer />

        </div>
    )
}

export default EnterpriseApplications