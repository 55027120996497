import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/EnterprisePlatforms_cover.png'
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import dataAi_img1 from '../../assets/Capabilities/dataAi_img1.png'
import Enterprise_img1 from '../../assets/Capabilities/Enterprise_img1.png'
import Enterprise_img2 from '../../assets/Capabilities/Enterprise_img2.png'
import Enterprise_img3 from '../../assets/Capabilities/Enterprise_img3.png'
import Enterprise_img4 from '../../assets/Capabilities/Enterprise_img4.png'
import dataAi_img4 from '../../assets/Capabilities/dataAi_img4.png'
import dataAi_img5 from '../../assets/Capabilities/dataAi_img5.png'
import dataAi_img6 from '../../assets/Capabilities/dataAi_img6.png'
import dataAi_img7 from '../../assets/Capabilities/dataAi_img7.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { motion, AnimatePresence } from 'framer-motion';
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import microsoftLogo from '../../assets/Capabilities/Cloud_partner_img2.png'
import awsLogo from '../../assets/Capabilities/Cloud_partner_img3.png'
import googleLogo from '../../assets/Capabilities/Cloud_partner_img4.png'
import sapLogo from '../../assets/Capabilities/sap-logo.png'
import ibmLogo from '../../assets/Capabilities/ibm-logo.png'
import servicenowLogo from '../../assets/Capabilities/Cloud_partner_img1.png'
import databricksLogo from '../../assets/Capabilities/databricks-logo.png'
import oracleLogo from '../../assets/Capabilities/oracle-logo.png'
import { useDocumentTitle } from '../../utils/useDocumentTitle';

const PartnersSection = () => {
  const partners = [
    { name: 'Microsoft', logo: microsoftLogo },
    { name: 'AWS', logo: awsLogo },
    { name: 'Google', logo: googleLogo },
    { name: 'SAP', logo: sapLogo },
    { name: 'IBM', logo: ibmLogo },
    { name: 'ServiceNow', logo: servicenowLogo },
    { name: 'Databricks', logo: databricksLogo },
    { name: 'Oracle', logo: oracleLogo },
  ];

  return (
    <div className="bg-[#08082A] py-8 sm:py-12">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-white text-2xl sm:text-3xl font-bold text-center mb-6 sm:mb-10">Our extended ecosystem partners</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6 sm:gap-8 items-center justify-items-center">
          {partners.map((partner) => (
            <img
              key={partner.name}
              src={partner.logo}
              alt={`${partner.name} logo`}
              className="h-6 sm:h-8 object-contain"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

function EnterprisePlatforms() {
    useDocumentTitle("Enterprise platforms");
    const EnterprisePlatformsComponent = () => {
        return (
            <div className="bg-[#08082A] text-white p-4 sm:p-8 font-sans flex flex-col md:flex-row">
                <div className="w-full md:w-1/3 mb-6 md:mb-0 md:pr-8 flex items-center justify-center">
                    <img
                        src={Enterprise_img4}
                        alt="Enterprise platforms illustration"
                        className="w-full max-w-xs sm:max-w-sm md:max-w-full object-cover rounded-lg"
                    />
                </div>
                <div className="w-full md:w-2/3">
                    <h2 className="text-xl sm:text-2xl font-bold mb-6 md:mb-12 lg:mb-16">Enterprise Platforms Now</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                        <StatCard
                            value="5x"
                            description="increase in revenue growth for businesses that invest in integrated enterprise platforms and capabilities."
                        />
                        <StatCard
                            value="78%"
                            description="of organizations report that enterprise platforms have enhanced their resilience to supply chain disruptions."
                        />
                        <StatCard
                            value="3/4"
                            description="of companies say enterprise platforms have significantly improved their ability to adapt to evolving customer expectations."
                        />
                        <StatCard
                            value="20%"
                            description="higher productivity achieved by organizations utilizing unified enterprise platforms."
                        />
                    </div>
                </div>
            </div>
        );
    };

    const StatCard = ({ value, description }) => (
        <div className="bg-gradient-to-br from-[#21213B] to-[#08082A] rounded-lg p-6 relative overflow-hidden">
            <div className="absolute top-0 left-0 w-16 h-16 bg-purple-600 opacity-20 rounded-br-full"></div>
            <div className="absolute bottom-0 right-0 w-16 h-16 bg-purple-600 opacity-20 rounded-tl-full"></div>
            <p className="text-3xl font-bold mb-2">{value}</p>
            <p className="text-sm">{description}</p>
        </div>
    );

    const ReinventWithEnterprisePlatforms = () => {
        const cards = [
            {
                title: 'Diaa corp and Oracle',
                description: 'Strengthen your digital foundation with cloud, data, and AI, enabling rapid adaptation and unlocking new growth opportunities.',
                hasButton: true,
            },
            {
                title: 'Diaa corp and Salesforce',
                description: 'Empower your organization with unified data and streamlined workflows, delivering personalized, impactful customer experiences at scale.',
                hasButton: true,
            },
            {
                title: 'Diaa corp and SAP',
                description: 'Revamp your enterprise platforms to enhance flexibility, resilience, and adaptability, driving future-ready operations.',
                hasButton: true,
            },
            {
                title: 'Diaa corp and Adobe',
                description: 'Integrate commerce and content seamlessly to create unified, scalable customer journeys that elevate brand engagement.',
                hasButton: true,
            },
            {
                title: 'Diaa corp and Blue Yonder',
                description: 'Optimize your supply chain for greater agility, intelligence, and growth, making data-driven decisions to stay ahead of the curve.',
                hasButton: true,
            },
            {
                title: 'Diaa corp and Workday',
                description: 'Foster a culture of continuous learning and modernization to enhance talent capabilities, streamline processes, and boost organizational performance.',
                hasButton: true,
            },
        ];

        return <ReinventGrid title="Transform Your Business with Leading Enterprise Platforms" cards={cards} />;
    };

    const slides = [
        {
            title: "What's New in Enterprise Platforms?",
            description: "Whitepaper: Oracle CloudWorld 2024 Insights - Explore the latest trends and innovations revealed at Oracle CloudWorld 2024, focusing on cloud transformation, data strategies, and AI-driven capabilities.",
            image: Enterprise_img1,
            gradient: "from-blue-400 to-blue-600"
        },
        {
            title: "Whitepaper: Workday Rising 2024 Highlights",
            description: "Discover how Workday's new advancements in AI, automation, and workforce management are reshaping enterprise platforms for the future.",
            image: Enterprise_img2,
            gradient: "from-purple-400 to-purple-600"
        },
        {
            title: "Whitepaper: Dreamforce 2024 Takeaways",
            description: "Learn about the cutting-edge developments from Dreamforce 2024, showcasing the future of customer experience, CRM, and enterprise collaboration.",
            image: Enterprise_img3,
            gradient: "from-pink-400 to-pink-600"
        }
    ];

    const CloudTrendingCarousel = () => {
        return <TrendingCarousel title="What's New in Enterprise Platforms?" slides={slides} />;
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Leader in Multicloud Managed Services - Forrester",
                image: dataAi_img5,
                alt: "Multicloud Managed Services"
            },
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Johnson", title: "Lead - Data & AI", imageUrl: Cloud_leader_img1 },
            { name: "Shakriya", title: "Chief AI Officer", imageUrl: Cloud_leader_img1 },
            { name: "Chandran", title: "Chief Responsible AI Officer", imageUrl: Cloud_leader_img1 },
            { name: "Emma Thompson", title: "Head of Enterprise Platforms", imageUrl: Cloud_leader_img1 },
            { name: "Michael Chen", title: "Director of Platform Integration", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Accelerate Value with Integrated Enterprise Platforms"
                description="Transform your business with agile platforms and applications that seamlessly integrate industry-specific capabilities, harness the power of data and AI, and empower your workforce to drive greater value faster."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 mx-auto mt-8 sm:mt-12 md:mt-16 lg:mt-20'>
                <EnterprisePlatformsComponent />
            </div>
            <div className='w-full px-4 mx-auto mt-8 sm:mt-12 md:mt-16 lg:mt-20'>
                <ReinventWithEnterprisePlatforms />
            </div>
            <div className='w-full px-4 mx-auto mt-8 sm:mt-12 md:mt-16 lg:mt-20'>
                <CloudTrendingCarousel />
            </div>
            <div className='w-full mt-8 sm:mt-12 md:mt-16 lg:mt-20'>
                <PartnersSection />
            </div>
            <div className='w-full px-4 mx-auto mt-8 sm:mt-12 md:mt-16 lg:mt-20'>
                <GlobalRecognition />
            </div>
            <div className='w-full px-4 mx-auto mt-8 sm:mt-12 md:mt-16 lg:mt-20 mb-8 sm:mb-12 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 sm:mt-12 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>
    )
}

export default EnterprisePlatforms