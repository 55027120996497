import React, { useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Industries/Health/health_coverImg.png' // Replace with health-specific image
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents'
import { ContactContent } from '../../components/resuable/SapComponents'
import Footer from '../../components/Footer'
import { useDocumentTitle } from '../../utils/useDocumentTitle'
import { ChevronRight, ChevronLeft } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
// Import other necessary images (reusing existing ones for now)
import health_img1 from '../../assets/Industries/Health/health_img1.png'
import health_img2 from '../../assets/Industries/Health/health_img2.png'
import health_img3 from '../../assets/Industries/Health/health_img3.png'
import health_img4 from '../../assets/Industries/Health/health_img4.png'
import health_img5 from '../../assets/Industries/Health/health_img5.png'
import health_img6 from '../../assets/Industries/Health/health_img6.png'
import health_img7 from '../../assets/Industries/Health/health_img7.png'
import health_img8 from '../../assets/Industries/Health/health_img8.png'
import health_img9 from '../../assets/Industries/Health/health_img9.png'
import health_img10 from '../../assets/Industries/Health/health_img10.png'
import health_img11 from '../../assets/Industries/Health/health_img11.png'
import health_img12 from '../../assets/Industries/Health/health_img12.png'
import health_img13 from '../../assets/Industries/Health/health_img13.png'
import health_img14 from '../../assets/Industries/Health/health_img14.png'
import health_img15 from '../../assets/Industries/Health/health_img15.png'

import communications_img1 from '../../assets/Capabilities/communications_img1.png'
import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import Cloud_awards_img1 from '../../assets/Capabilities/Cloud_awards_img1.png'
import Cloud_awards_img2 from '../../assets/Capabilities/Cloud_awards_img2.png'
import Cloud_awards_img3 from '../../assets/Capabilities/Cloud_awards_img3.png'
import Cloud_awards_img4 from '../../assets/Capabilities/Cloud_awards_img4.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'

function Health() {
    useDocumentTitle("Health");

    const HealthStats = () => {
        const stats = [
            { value: '75%', description: 'of healthcare executives plan to invest in AI and automation to improve patient care.' },
            { value: '50%', description: 'of patients prefer telehealth as their primary method of consultation post-pandemic.' },
            { value: '5M', description: 'healthcare workers are expected to retire in the next decade, exacerbating workforce challenges.' },
            { value: '80%', description: 'of healthcare organizations are focusing on data-driven insights to enhance patient outcomes and operational efficiency.' },
        ];

        return <StatsGrid title="The Future of Healthcare" stats={stats} />;
    };

    // Special HLTH24 Section
    const HLTH24Section = () => {
        return (
            <div className="rounded-2xl p-8 flex flex-col md:flex-row items-center gap-8">
                <div className="w-full md:w-1/2">
                    <img 
                        src={health_img1} // Replace with HLTH24 specific image
                        alt="Diaa corp Health at HLTH 24"
                        className="rounded-lg w-full h-auto"
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <h3 className="text-2xl font-bold mb-4">Diaa corp Health at HLTH 24!</h3>
                    <p className="text-gray-300 mb-6">
                        We are excited to participate and share our vision for the future of healthcare.
                    </p>
                    <button className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-full">
                        Learn more
                    </button>
                </div>
            </div>
        );
    };

    const SegmentsWeSupport = () => {
        const [currentSlide, setCurrentSlide] = React.useState(0);
        const slides = [
            {
                category: "HEALTH PROVIDERS",
                title: "Health Providers",
                description: "Enabling healthcare providers to deliver better patient care through digital transformation and innovative solutions.",
                image: health_img3,
            },
            {
                category: "DIGITAL HEALTH",
                title: "Digital Health Solutions",
                description: "Transforming healthcare delivery through innovative digital tools to increase care access and streamline processes.",
                image: health_img11,
            },
            {
                category: "HEALTHCARE TECH",
                title: "Healthcare Technology",
                description: "Modernizing systems to reduce costs and improve healthcare delivery.",
                image: health_img12,
            },
        ];

        const nextSlide = () => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 }
        };

        return (
            <>
                <h2 className="text-2xl font-bold mb-6 xl:mb-12 text-center">Segments we support</h2>
                <div className="bg-[#14192E] text-white p-4 sm:p-8 rounded-2xl">
                    <div className="bg-navy-800 rounded-lg p-4 sm:p-6">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentSlide}
                                variants={slideVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                            >
                                <div className="flex items-center mb-4">
                                    <div className="w-3 h-3 bg-purple-500 rounded-full mr-2"></div>
                                    <span className="text-purple-500 font-semibold">{slides[currentSlide].category}</span>
                                </div>
                                <div className="flex flex-col sm:flex-row items-start">
                                    <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                        <div className="bg-purple-900 rounded-lg overflow-hidden">
                                            <img
                                                src={slides[currentSlide].image}
                                                alt={slides[currentSlide].title}
                                                className="w-full h-auto object-cover"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-grow">
                                        <h3 className="text-xl font-semibold mb-2">{slides[currentSlide].title}</h3>
                                        <p className="text-gray-400 mb-4">
                                            {slides[currentSlide].description}
                                        </p>
                                        <div className="flex justify-end">
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="border-2 border-theme/50 rounded-full p-2"
                                            >
                                                <ChevronRight
                                                    className="text-theme cursor-pointer"
                                                    size={24}
                                                    onClick={nextSlide}
                                                />
                                            </motion.div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </>
        );
    };

    const ReinventHealthcare = () => {
        const cards = [
            {
                title: 'Patient Data Protection',
                description: 'Strengthen cybersecurity to safeguard healthcare information.',
                hasButton: true,
            },
            {
                title: 'Virtual Care',
                description: 'Use digital tools to increase care access and streamline processes.',
                hasButton: true,
            },
            {
                title: 'Healthcare Data Management',
                description: 'Shift to cloud-based systems for better efficiency and privacy.',
                hasButton: true,
            },
            {
                title: 'Clinician Empowerment',
                description: 'Use tech to reduce burnout and increase productivity.',
                hasButton: true,
            },
            {
                title: 'AI in Healthcare',
                description: 'Apply artificial intelligence to enhance decision-making and reduce disparities.',
                hasButton: true,
            },
            {
                title: 'Patient-Centric Care',
                description: 'Personalize experiences to improve patient retention and satisfaction.',
                hasButton: true,
            },
            {
                title: 'Operational Efficiency',
                description: 'Modernize systems to reduce costs and improve healthcare delivery.',
                hasButton: true,
            }
        ];

        return <ReinventGrid title="Transforming Healthcare Delivery" cards={cards} />;
    };

    const HealthTrendingCarousel = () => {
        const slides = [
            {
                title: "Transforming Data into Patient-Centric Care",
                image: health_img4,
                gradient: "from-purple-600 to-pink-500",
                description: "Artificial intelligence is revolutionizing the healthcare landscape by enabling truly patient-centric care. This topic explores how AI-driven solutions are transforming chronic disease management with predictive analytics, offering personalized treatment plans tailored to individual needs. By leveraging vast datasets and machine learning, healthcare providers can anticipate patient outcomes, optimize care delivery, and proactively address potential health risks. This patient-focused approach ensures better health outcomes, reduced hospital readmissions, and a significant improvement in the overall quality of care."
            },
            {
                title: "Expanding Access to Senior Healthcare",
                image: health_img5,
                gradient: "from-blue-600 to-cyan-500",
                description: "As the aging population grows, the need for accessible and specialized senior healthcare is more critical than ever. This topic highlights how Diaa corp collaborates with healthcare providers to enhance post-acute care services, ensuring seniors receive high-quality support after hospital discharge. By implementing innovative technologies and streamlining care pathways, these partnerships improve access to skilled nursing, rehabilitation, and in-home care services. The result is a holistic approach to senior healthcare, addressing both medical needs and quality of life."
            },
            {
                title: "Revolutionizing Healthcare with Generative AI",
                image: health_img13,
                gradient: "from-indigo-600 to-purple-500",
                description: "Generative AI is driving a paradigm shift in the healthcare industry by delivering groundbreaking solutions for patient care and operational efficiency. This topic examines how generative AI is used to develop personalized treatment plans, simulate complex surgeries, and enhance drug discovery. Additionally, it optimizes hospital operations by automating workflows, predicting staffing needs, and managing resources more effectively. The integration of generative AI is not just improving healthcare delivery but also redefining the patient experience."
            },
            {
                title: "Building Trust to Drive Patient Loyalty",
                image: health_img14,
                gradient: "from-indigo-600 to-purple-500",
                description: "Trust is the foundation of strong patient-provider relationships and a critical factor in patient retention. This topic focuses on the importance of transparency, effective communication, and delivering high-quality care to foster trust. By actively involving patients in their care decisions, addressing their concerns, and ensuring consistent follow-ups, healthcare providers can build long-lasting loyalty. Trust not only improves patient satisfaction but also strengthens the reputation and reliability of healthcare organizations."
            },
            {
                title: "Strengthening Post-Acute Care for Seniors",
                image: health_img15,
                gradient: "from-indigo-600 to-purple-500",
                description: "The transition from hospital to home or skilled nursing facilities can be challenging for seniors, making post-acute care a vital part of the healthcare continuum. This topic delves into how Diaa corp's collaboration with healthcare providers is enhancing care accessibility for aging populations. By focusing on improving skilled nursing services, home-based care, and rehabilitation options, these efforts ensure seniors receive the right support to recover and thrive. This approach addresses the unique challenges of senior healthcare, promoting independence and well-being."
            }
        ];

        return <TrendingCarousel title="Current Trends in Healthcare" slides={slides} />;
    };

    const ScrollableCards = () => {
        const [activeIndex, setActiveIndex] = useState(2);

        const cards = [
            { id: 1, logo: Cloud_partner_img1, content: 'Leading healthcare technology solutions provider' },
            { id: 2, logo: Cloud_partner_img2, content: 'Innovative digital health platform partner' },
            { id: 3, logo: Cloud_partner_img3, content: 'Healthcare AI and analytics solutions' },
            { id: 4, logo: Cloud_partner_img4, content: 'Medical device and IoT integration partner' },
            { id: 5, logo: Cloud_partner_img1, content: 'Healthcare data management solutions' },
        ];

        const handlePrev = () => {
            setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cards.length - 1));
        };

        const handleNext = () => {
            setActiveIndex((prevIndex) => (prevIndex < cards.length - 1 ? prevIndex + 1 : 0));
        };

        return (
            <div className="flex flex-col items-center justify-center p-4 overflow-hidden">
                <h1 className="text-white text-2xl sm:text-3xl font-bold md:mb-12 text-center">Partners in Healthcare Innovation</h1>
                <div className="relative w-full max-w-5xl">
                    <div className="flex justify-center items-center h-64 sm:h-80 md:h-96 overflow-visible">
                        <AnimatePresence initial={false}>
                            {cards.map((card, index) => {
                                const isActive = index === activeIndex;
                                const offset = (index - activeIndex) * (window.innerWidth < 640 ? 200 : 300);
                                const zIndex = cards.length - Math.abs(activeIndex - index);
                                return (
                                    <motion.div
                                        key={card.id}
                                        className={`absolute bg-[#14192E] rounded-lg shadow-lg pt-4 sm:pt-8 ${
                                            isActive ? 'w-4/5 sm:w-96 h-40 sm:h-48 md:h-64' : 'w-2/3 sm:w-80 h-32 sm:h-40 md:h-56'
                                        }`}
                                        initial={{ opacity: 0, scale: 0.8 }}
                                        animate={{
                                            opacity: isActive ? 1 : 0.6,
                                            scale: isActive ? 1 : 0.8,
                                            x: offset,
                                            zIndex: zIndex,
                                        }}
                                        exit={{ opacity: 0, scale: 0.8 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <div className="p-2 sm:p-4 md:p-6 h-full flex flex-col">
                                            <img 
                                                src={card.logo} 
                                                alt={`Partner logo ${index + 1}`} 
                                                className="h-8 sm:h-12 object-contain mb-2 sm:mb-4 mx-auto" 
                                            />
                                            <p className="text-gray-300 text-xs sm:text-sm flex-grow overflow-hidden text-center">
                                                {card.content}
                                            </p>
                                        </div>
                                    </motion.div>
                                );
                            })}
                        </AnimatePresence>
                    </div>
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex gap-4 mt-4 sm:mt-8">
                        <motion.button
                            onClick={handlePrev}
                            className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                            aria-label="Previous card"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <ChevronLeft size={20} />
                        </motion.button>
                        <motion.button
                            onClick={handleNext}
                            className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                            aria-label="Next card"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <ChevronRight size={20} />
                        </motion.button>
                    </div>
                </div>
            </div>
        );
    };

    const PartnersInChange = () => {
        return <ScrollableCards />;
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Digital Health Innovation Award 2024",
                image: health_img7,
                alt: "Digital Health Innovation"
            },
            {
                title: "Healthcare Technology Leader - Forrester",
                image: health_img8,
                alt: "Healthcare Technology Leadership"
            },
            {
                title: "Best Healthcare Solutions Provider",
                image: health_img9,
                alt: "Healthcare Solutions"
            },
            {
                title: "Excellence in Healthcare Digital Transformation",
                image: health_img10,
                alt: "Digital Transformation Excellence"
            }
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew Smith", title: "Global Healthcare Lead", imageUrl: Cloud_leader_img1 },
            { name: "Sarah Johnson", title: "Digital Health Innovation Director", imageUrl: Cloud_leader_img1 },
            { name: "Michael Chen", title: "Healthcare Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Emma Davis", title: "Patient Care Solutions Lead", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    // Update the return statement to include all sections
    return (
        <div className='bg-bg text-white'>
            <Header
                title="Transforming Healthcare"
                description="Patients demand seamless access and tailored experiences. Organizations that innovate to provide simplified access and meaningful interactions will thrive while improving health outcomes."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <HLTH24Section />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <HealthStats />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventHealthcare />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <HealthTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <PartnersInChange />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <GlobalRecognition />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent title="Health careers" subtitle="Grow your career at the heart of healthcare transformation" />
            </div>
            <Footer />
        </div>
    );
}

export default Health