import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import headerImage from '../assets/SAP/SAPHostingCover.png'
import { ContactContent } from '../components/resuable/SapComponents';
import { FaArrowCircleUp, FaChartBar, FaClipboardCheck, FaClock, FaCloud, FaCogs, FaGraduationCap, FaHeadset, FaLink, FaMoneyBillWave, FaSearch, FaServer, FaShieldAlt, FaWrench } from 'react-icons/fa';
import Footer from '../components/Footer';
import { MdCloudDone } from 'react-icons/md';
import { IoCloudOutline } from 'react-icons/io5';
import { useDocumentTitle } from '../utils/useDocumentTitle';

function SAPHosting() {

    useDocumentTitle("SAP Hosting Services");


    const InformationSystemOverview = () => {
        // const [fadeIn, setFadeIn] = useState(true);
        // console.log(cards[0].items.length, "cards");
        return (
            <div className={`relative z-10 mt-8 sm:mt-10 md:-mt-20 lg:-mt-32 xl:-mt-24 container mx-auto px-4 bg-[#49537780] rounded-lg mb-8 sm:mb-12 md:w-11/12 xl:w-10/12`}>
                <div className="lg:px-12 py-12 text-center ">
                    <p className="text-sm sm:text-base lg:text-lg">A huge number of enterprises are on SAP landscape today, and they are constantly looking for better and secure ways to manage their systems. Also, with the rapidly changing business needs, it has become an imperative to have options to scale up or down based on demand. Therefore, organizations today have strong driving factors in moving to the Cloud, which offers security, elasticity, improved performance, and all that at predictable costs. However, as most of the SAP applications are mission- critical for the businesses, the Cloud migrations have to be well planned and executed, so that there is near zero downtime, with no undesirable outcomes.</p>
                </div>
            </div>
        );
    }

    const certifications = [
        { title: 'SAP Certified', subtitle: 'in SAP HANA Operations' },
        { title: 'SAP Certified', subtitle: 'in Hosting Services' },
        { title: 'SAP Certified', subtitle: 'in Cloud Services' },
        { title: 'SAP Certified', subtitle: 'in DevOps' },
        { title: 'SAP Certified', subtitle: 'in Application Management Services' },
    ];

    const CertificationCard = ({ title, subtitle }) => (
        <div className="bg-[#51566E4D] bg-opacity-40 backdrop-blur-md p-4 sm:p-6 rounded-2xl shadow-md h-[154px] text-center flex flex-col justify-center">
            <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-white mb-2">{title}</h3>
            <p className="text-sm sm:text-base md:text-lg font-medium text-gray-300">{subtitle}</p>
        </div>
    );

    const Certifications = () => (
        <div className="py-8 sm:py-12 2xl:py-24 flex flex-col items-center justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
                {certifications.map((cert, index) => (
                    <CertificationCard key={index} title={cert.title} subtitle={cert.subtitle} />
                ))}
            </div>
        </div>
    );



    const FeatureCard = ({ number, features }) => (
        <div className="bg-gray-900 rounded-lg p-6 border border-theme shadow-lg relative">
            <div className="absolute -top-4 -left-4 w-10 h-10 bg-custom-gradient rounded-full flex items-center justify-center text-white font-bold text-xl">
                {number}
            </div>
            <ul className="space-y-2 mt-4">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-center text-white">
                        <span className="w-2 h-2 bg-theme rounded-full mr-3"></span>
                        {feature}
                    </li>
                ))}
            </ul>
        </div>
    );

    const SAPHANAFeatures = () => {
        const features = [
            [
                "On-demand SAP HANA servers",
                "Immediate implementation",
                "E5-26xx processors with external SAN",
                "SAP-certified hardware/storage",
                "SAP-defined KPIs",
                "Hybrid cloud integration",
                "Faster internal storage",
                "Lower TCO for HANA T-shirt sizes up to 1.5 TB"
            ],
            [
                "Customer private cloud model",
                "On-demand SAP HANA servers",
                "Immediate implementation",
                "Compute and storage resources",
                "E5-26xx processors with external SAN",
                "SAP-certified hardware/storage",
                "SAP-defined KPIs",
                "Hybrid cloud integration",
                "Lower TCO for HANA T-shirt sizes up to 1.5 TB"
            ],
            [
                "Wide range of appliance sizes",
                "Pre-configured hardware set-up from leading OEMs",
                "Preinstalled software",
                "SAP-certified configurations",
                "Hybrid cloud integration",
                "Ideal for HANA size > 1.5 TB"
            ]
        ];

        return (
            <div className="bg-[#14192E80] p-8 py-12 2xl:py-24 flex justify-center items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl">
                    {features.map((featureSet, number) => (
                        <div key={number} className="bg-[#1A1F31] rounded-lg p-6 border border-theme shadow-lg relative">
                            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 w-8 h-8 bg-gradient-to-r from-[#4F75FF] to-[#A100FF] rounded-full flex items-center justify-center text-white font-bold text-lg">
                                {number + 1}
                            </div>
                            <ul className="space-y-2 mt-6">
                                {featureSet.map((feature, index) => (
                                    <li key={index} className="flex items-center text-white">
                                        <span className="w-2 h-2 bg-[#A100FF] rounded-full mr-3"></span>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const expertiseData = [
        { title: 'SAP Certified', subtitle: 'in multiple areas' },
        { title: 'Serving 180+', subtitle: 'Enterprise customers & SAP HANA industries on cloud' },
        { title: 'Delivering 99.99%', subtitle: 'availability & production uptime' },
        { title: 'Over 600B+', subtitle: 'SAP systems managed' },
        { title: '100+ of successful SAP', subtitle: 'migrations to the Cloud' },
        { title: 'Over 2000+ TB', subtitle: 'managed HANA' },
    ];

    const ExpertiseCard = ({ title, subtitle }) => (
        <div className="bg-opacity-10 bg-white p-4 sm:p-6 rounded-2xl shadow-md h-[154px] text-center flex flex-col justify-center">
            <h3 className="text-base sm:text-lg md:text-xl font-bold text-white mb-2">{title}</h3>
            <p className="text-xs sm:text-sm md:text-base font-medium text-gray-300">{subtitle}</p>
        </div>
    );

    const Expertise = () => (
        <div className="flex flex-col items-center justify-center mt-8 sm:mt-12 md:mt-24">
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-extrabold text-white mb-6 sm:mb-8 md:mb-12 text-center px-4">Our Expertise in handling SAP HANA workloads</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4 max-w-6xl mx-auto">
                {expertiseData.map((item, index) => (
                    <ExpertiseCard key={index} title={item.title} subtitle={item.subtitle} />
                ))}
            </div>
        </div>
    );

    const MappingYourSuccess = () => {
        return (
            <div className="text-white flex items-center justify-center p-4 mt-8 sm:mt-12 xl:mt-24">
                <div className="w-11/12 2xl:w-10/12 mx-auto">
                    <div className="rounded-3xl p-4 sm:p-8 mb-8 sm:mb-12">
                        <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold text-center mb-4 sm:mb-8">
                            Empowering Your Business with DIAA CORP SAP Hosting Solutions
                        </h2>
                        <p className="text-lg sm:text-xl md:text-2xl text-center mb-6 sm:mb-8 md:mb-12 text-gray-400">
                            At DIAA Corp, we provide SAP Hosting Services that deliver value and efficiency for your business. Our tailored solutions are designed to enhance performance, improve security, and ensure scalability without the burden of large upfront costs.
                        </p>
                        <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-center mb-4">How DIAA CORP SAP Hosting Services Can Benefit Your Business:</h3>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8 w-full max-w-5xl mx-auto">
                            {[
                                { title: "No Initial Infrastructure Investment", description: "Transition from CapEx to OpEx with our cloud infrastructure, eliminating the need for costly upfront investments.", Icon: IoCloudOutline },
                                { title: "Optimized Performance", description: "DIAA Corp ensures top-tier performance with a hosting environment specifically designed for the needs of your SAP applications.", Icon: FaChartBar },
                                { title: "Reliable and Redundant Storage", description: "We offer secure, high-availability storage to maintain data integrity and ensure your data is always accessible.", Icon: MdCloudDone },
                                { title: "Flexible Scalability", description: "Scale your SAP infrastructure seamlessly based on demand, with the flexibility to adjust without incurring capital expenditure.", Icon: FaArrowCircleUp },
                                { title: "Cost-Efficiency", description: "Save on total cost of ownership with a pay-as-you-go pricing model and optimized resource allocation.", Icon: FaClipboardCheck },
                                { title: "Enhanced Security", description: "Our security protocols protect your SAP systems from potential threats, ensuring that your data remains safe at all times.", Icon: FaLink },
                                { title: "Pay-As-You-Use Model", description: "Only pay for what you use, providing cost flexibility and optimizing your IT expenditure.", Icon: FaWrench },
                                { title: "Comprehensive Backup Solutions", description: "DIAA Corp offers reliable backup services, guaranteeing that your SAP data is always protected and easily recoverable.", Icon: FaCloud },
                                { title: "Disaster Recovery Services", description: "Safeguard your business continuity with our disaster recovery services, ensuring minimal disruption in case of unexpected events.", Icon: FaCogs },
                                { title: "High Availability", description: "Achieve 99.95% uptime with our reliable hosting services, minimizing any downtime and business interruptions.", Icon: FaServer },
                                { title: "Quick Deployment", description: "Leverage our automated provisioning to deploy SAP environments swiftly and efficiently in minutes.", Icon: FaClock },
                                { title: "Predictable IT Maintenance Costs", description: "With our managed services, control and predict your IT maintenance costs, optimizing budget management.", Icon: FaMoneyBillWave },
                                { title: "24/7 Support with Single SLA", description: "Access round-the-clock support, backed by a single, comprehensive Service Level Agreement (SLA) covering all aspects of your SAP hosting.", Icon: FaHeadset },
                                { title: "Business Continuity Assurance", description: "Our disaster recovery solutions and robust infrastructure ensure your business runs smoothly, even in the face of unforeseen disruptions.", Icon: FaShieldAlt }
                            ].map((item, index) => (
                                <div key={index} className="relative group">
                                    <div className="absolute -inset-0.5 bg-custom-gradient rounded-3xl blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                                    <div className="relative bg-[#1C2237] rounded-3xl p-4 sm:p-8 flex flex-col items-center space-y-4 sm:space-y-6 h-full">
                                        <div className="w-16 h-16 sm:w-24 sm:h-24 rounded-full bg-custom-gradient flex items-center justify-center">
                                            <item.Icon className="text-white text-3xl sm:text-5xl" />
                                        </div>
                                        <span className="text-xs sm:text-sm font-medium text-center text-white">{item.title}</span>
                                        <p className="text-xs text-center text-gray-400">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="DIAA CORP Cloud Hosting Solutions for SAP: Enhancing Scalability, Security, and Performance"
                description="DIAA CORP’s Cloud Hosting Solutions empower businesses to seamlessly migrate their SAP applications to the cloud, boosting availability, security, and performance. Our services are designed to adapt to evolving business needs, ensuring your SAP environment is scalable, resilient, and future-ready."
                backgroundImage={headerImage}
                isExploreBtn={true}
            />
            <InformationSystemOverview />

            <div>
                <div className="px-4 md:w-11/12 2xl:w-10/12 text-white md:p-8 rounded-lg mx-auto text-center mt-8 sm:mt-12 xl:mt-24">
                    <h2 className="text-xl sm:text-2xl font-semibold text-center mb-4">
                        DIAA CORP - Your Trusted SAP Hosting Partner
                    </h2>

                    <p className="text-sm sm:text-base leading-6 sm:leading-7">
                        At DIAA Corp, we are a certified SAP Hosting Partner, offering a comprehensive range of services to seamlessly host and manage your SAP applications. Our expertise combines cutting-edge technology, skilled professionals, and industry best practices to ensure your SAP environment operates efficiently and securely.
                    </p>

                </div>
            </div>

            <div className="mt-8 sm:mt-12 md:mt-24">
                <Certifications />
            </div>

            <div>
                <div className="px-4 md:w-11/12 2xl:w-10/12 text-white md:p-8 rounded-lg mx-auto text-center mt-8 sm:mt-12 xl:mt-24">
                    <h2 className="text-2xl font-semibold text-center mb-4">
                        Your Trusted Partner for SAP HANA Ecosystem Hosting
                    </h2>

                    <p className="text-base leading-7">
                        At DIAA Corp, we specialize in building, deploying, and supporting SAP HANA environments that perfectly align with your unique business needs. As a certified SAP hosting partner, we offer a variety of SAP-compliant hosting models, SAP implementation services, and comprehensive SAP Basis support.
                    </p>

                </div>
            </div>

            <div className='mt-8 sm:mt-12 md:mt-24 2xl:mt-32'>
                <SAPHANAFeatures />
            </div>

            <div className='mt-8 sm:mt-12 md:mt-24 2xl:mt-32'>
                <ContactContent title='Contact us to explore a variety of SAP HANA deployment models and flexible solutions tailored to your business needs.' />
            </div>

            <div className='mt-8 sm:mt-12 md:mt-24 2xl:mt-32'>
                <Expertise />
            </div>

            <div>
                <div className="md:w-11/12 2xl:w-10/12 text-white md:p-8 rounded-lg mx-auto text-center mt-8 sm:mt-12 md:mt-24 2xl:mt-32 px-4">
                    <h2 className="text-2xl font-semibold text-center mb-4">
                        Business Benefits of DIAA CORP SAP Hosting Services
                    </h2>

                    <p className="text-base leading-7">
                        With over 20 years of experience in colocation infrastructure, data center migration, and security, DIAA Corp offers scalable and flexible SAP hosting services that adapt to your business needs. Our solutions are designed to ensure high availability, robust security, and seamless performance for your SAP systems.
                    </p>

                </div>
            </div>

            <div className='mt-8 sm:mt-12 md:mt-24 2xl:mt-32'>
                <MappingYourSuccess />
            </div>

            <div className='mt-8 sm:mt-12 md:mt-24 2xl:mt-32'>
                <ContactContent title='Looking to host your SAP applications on the cloud with guaranteed performance and availability? Let us help you achieve seamless, secure, and scalable cloud hosting solutions for your SAP environment. Reach out today!' />
            </div>

            <Footer />
        </div>
    )
}

export default SAPHosting