import React, { useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/SalesAndCommerce_cover.png'
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import Sales_Img1 from '../../assets/Capabilities/Sales_Img1.png'
import Sales_Img2 from '../../assets/Capabilities/Sales_img2.png'
import Sales_Img3 from '../../assets/Capabilities/Sales_img3.png'
import Sales_Img4 from '../../assets/Capabilities/Sales_img4.png'
import Sales_Img5 from '../../assets/Capabilities/Sales_img5.png'
import Sales_Img6 from '../../assets/Capabilities/Sales_img6.png'
import Sales_Img7 from '../../assets/Capabilities/Sales_img7.png'
import Enterprise_img4 from '../../assets/Capabilities/Enterprise_img4.png'
import dataAi_img4 from '../../assets/Capabilities/dataAi_img4.png'
import dataAi_img5 from '../../assets/Capabilities/dataAi_img5.png'
import dataAi_img6 from '../../assets/Capabilities/dataAi_img6.png'
import dataAi_img7 from '../../assets/Capabilities/dataAi_img7.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { motion, AnimatePresence } from 'framer-motion';
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import microsoftLogo from '../../assets/Capabilities/Cloud_partner_img2.png'
import awsLogo from '../../assets/Capabilities/Cloud_partner_img3.png'
import googleLogo from '../../assets/Capabilities/Cloud_partner_img4.png'
import sapLogo from '../../assets/Capabilities/sap-logo.png'
import ibmLogo from '../../assets/Capabilities/ibm-logo.png'
import servicenowLogo from '../../assets/Capabilities/Cloud_partner_img1.png'
import databricksLogo from '../../assets/Capabilities/databricks-logo.png'
import oracleLogo from '../../assets/Capabilities/oracle-logo.png'
import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import { scrollToBottom } from '../ApplicationDev';
import { useDocumentTitle } from '../../utils/useDocumentTitle';

const cards = [
    { id: 1, logo: Cloud_partner_img1, content: 'ServiceNow content here' },
    { id: 2, logo: Cloud_partner_img2, content: 'Bringing together 65,000 dedicated professionals, the DXC and Microsoft alliance delivers comprehensive services to help organizations transform and thrive' },
    { id: 3, logo: Cloud_partner_img4, content: 'Our unflinching mission is to combine extraordinary new technologies with human ingenuity to meet business challenges and drive growth.' },
    { id: 4, logo: Cloud_partner_img3, content: 'The DXC-COM Google Practice is turning your cloud into an enterprise solution and making your business applications run like a cloud.' },
    { id: 5, logo: Cloud_partner_img1, content: 'Another ServiceNow content here' },
];


const ScrollableCards = () => {
    useDocumentTitle("Sales and Commerce");
    const [activeIndex, setActiveIndex] = useState(2);

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cards.length - 1));
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex < cards.length - 1 ? prevIndex + 1 : 0));
    };

    return (
        <div className="flex flex-col items-center justify-center p-4 overflow-hidden">
            <h1 className="text-white text-2xl sm:text-3xl font-bold md:mb-12 text-center">Partners in change</h1>
            <div className="relative w-full max-w-5xl">
                <div className="flex justify-center items-center h-64 sm:h-80 md:h-96 overflow-visible">
                    <AnimatePresence initial={false}>
                        {cards.map((card, index) => {
                            const isActive = index === activeIndex;
                            const offset = (index - activeIndex) * (window.innerWidth < 640 ? 200 : 300); // Adjust offset for mobile
                            const zIndex = cards.length - Math.abs(activeIndex - index);
                            return (
                                <motion.div
                                    key={card.id}
                                    className={`absolute bg-[#14192E] rounded-lg shadow-lg pt-4 sm:pt-8 ${isActive ? 'w-4/5 sm:w-96 h-40 sm:h-48 md:h-64' : 'w-2/3 sm:w-80 h-32 sm:h-40 md:h-56'
                                        }`}
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{
                                        opacity: isActive ? 1 : 0.6,
                                        scale: isActive ? 1 : 0.8,
                                        x: offset,
                                        zIndex: zIndex,
                                    }}
                                    exit={{ opacity: 0, scale: 0.8 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <div className="p-2 sm:p-4 md:p-6 h-full flex flex-col">
                                        <img src={card.logo} alt={`${card.logo.split('_')[0]} logo`} className="h-8 sm:h-12 object-contain mb-2 sm:mb-4 mx-auto" />
                                        <p className="text-gray-300 text-xs sm:text-sm flex-grow overflow-hidden text-center">{card.content}</p>
                                    </div>
                                </motion.div>
                            );
                        })}
                    </AnimatePresence>
                </div>
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex gap-4 mt-4 sm:mt-8">
                    <motion.button
                        onClick={handlePrev}
                        className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                        aria-label="Previous card"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <ChevronLeft size={20} />
                    </motion.button>
                    <motion.button
                        onClick={handleNext}
                        className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                        aria-label="Next card"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <ChevronRight size={20} />
                    </motion.button>
                </div>
            </div>
        </div>
    );
};
function SalesAndCommerce() {

    const SalesAndCommerceStatsComponent = () => {
        return (
            <div className="bg-[#08082A] text-white p-4 sm:p-8 font-sans flex flex-col sm:flex-row">
                <div className="w-full sm:w-1/3 sm:pr-8 mb-6 sm:mb-0 flex items-center justify-center">
                    <img
                        src={Sales_Img1}
                        alt="Sales and Commerce illustration"
                        className="w-3/4 sm:w-full max-w-xs sm:max-w-full object-cover rounded-lg"
                    />
                </div>
                <div className="w-full sm:w-2/3">
                    <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 md:mb-12 lg:mb-16 text-center sm:text-left">Sales and Commerce in Today's Digital Landscape</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                        <StatCard
                            value="96%"
                            description="of consumers now engage with brands through multiple digital touchpoints."
                        />
                        <StatCard
                            value="87%"
                            description="of consumers believe that personalized shopping experiences are key to brand loyalty."
                        />
                        <StatCard
                            value="40%"
                            description="of businesses cite retaining customers as their top challenge in a competitive market."
                        />
                        <StatCard
                            value="62%"
                            description="of executives have prioritized investments in digital tools to enhance customer engagement over the last 18 months."
                        />
                    </div>
                </div>
            </div>
        );
    };

    const StatCard = ({ value, description }) => (
        <div className="bg-gradient-to-br from-[#21213B] to-[#08082A] rounded-lg p-6 relative overflow-hidden">
            <div className="absolute top-0 left-0 w-16 h-16 bg-purple-600 opacity-20 rounded-br-full"></div>
            <div className="absolute bottom-0 right-0 w-16 h-16 bg-purple-600 opacity-20 rounded-tl-full"></div>
            <p className="text-3xl font-bold mb-2">{value}</p>
            <p className="text-sm">{description}</p>
        </div>
    );

    const ReinventWithSalesAndCommerce = () => {
        const cards = [
            {
                title: 'Sales & Commerce Strategy',
                description: 'Build a resilient foundation for long-term success.',
                hasButton: true,
                image: 'path/to/purple-waves-image.png'
            },
            {
                title: 'B2B & B2C Commerce',
                description: 'Innovate for the future and enhance customer journeys.',
                hasButton: true,
                image: 'path/to/b2c-commerce-image.png'
            },
            {
                title: 'Experience-Driven Technology',
                description: 'Leverage smart solutions to drive growth and brand loyalty.',
                hasButton: true,
                image: 'path/to/technology-powered-image.png'
            },
            {
                title: 'Optimized Operations',
                description: 'Unlock new levels of efficiency and performance across all touchpoints.',
                hasButton: true,
                image: 'path/to/intelligent-operations-image.png'
            }
        ];

        return <ReinventGrid title="Transform with Sales & Commerce" cards={cards} />;
    };

    const slides = [
        {
            title: "AI-Powered Personalization",
            description: "Tailor experiences at scale by leveraging artificial intelligence to understand and anticipate customer needs.",
            image: Sales_Img2,
            gradient: "from-blue-400 to-blue-600"
        },
        {
            title: "Omnichannel Shopping Experiences",
            description: "Seamlessly blend online and offline interactions to meet customers wherever they are.",
            image: Sales_Img3,
            gradient: "from-purple-400 to-purple-600"
        },
        {
            title: "Sustainable Commerce",
            description: "Consumers are increasingly prioritizing sustainability, pushing brands to adopt responsible practices across their supply chains.",
            image: Sales_Img4,
            gradient: "from-pink-400 to-pink-600"
        },
        {
            title: "Blockchain for Transparency",
            description: "Use blockchain to ensure product authenticity and increase transparency in the supply chain.",
            image: Sales_Img6,
            gradient: "from-green-400 to-green-600"
        },
        {
            title: "Voice and Visual Search",
            description: "Revolutionize the shopping experience by incorporating voice assistants and visual search technologies.",
            image: Sales_Img7,
            gradient: "from-yellow-400 to-yellow-600"
        }
    ];

    const CloudTrendingCarousel = () => {
        return <TrendingCarousel title="What's Trending in Sales & Commerce" slides={slides} />;
    };

    const ContactContent2 = ({ title, sub }) => {
        return (
            <div className='flex flex-col justify-center items-center text-center bg-[#14192E] mobile:h-[293px] md:h-[350px] px-4 relative'>
                <div className='absolute top-0 left-0 w-full h-full'
                    style={{
                        background: `url(${Sales_Img5})`,
                        backgroundSize: 'cover',
                        backgroundPosition: '25% 25%',
                        backgroundRepeat: 'no-repeat',
                        filter: 'blur(5px)',
                    }}></div>
                <div className='md:w-11/12 relative z-10'>
                    <h2 className="text-lg md:text-2xl font-bold mb-4">{title}</h2>
                    <p className='mb-12 mobile:text-sm'>{sub}</p>
                    <motion.button
                        className="bg-custom-gradient text-white font-normal py-2 px-12 rounded-full hover:bg-blue-600 transition duration-300"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={scrollToBottom}
                    >
                        Meet DIAA CORP
                    </motion.button>
                </div>
            </div>
        );
    }

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Commerce in the Era of Instant Connectivity"
                description="In today’s fast-paced world, commerce has become deeply integrated into everyday life, with preferences evolving in real-time. The key challenge is staying agile. By embracing innovation, you can keep up with these rapid shifts, turning complexity into seamless, customer-centric experiences."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <SalesAndCommerceStatsComponent />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithSalesAndCommerce />
            </div>
            <div>
                <ContactContent2 title={`Your Ultimate Sales & Commerce Partner`} sub={`We are the world’s leading tech-powered solutions provider.\n\nMeet DIAA CORP — your trusted partner for innovative sales and commerce transformation. Our approach blends creativity with cutting-edge technology to unlock new business opportunities and drive growth.`} />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <CloudTrendingCarousel />
            </div>
            <div className='w-full mt-8 md:mt-16 lg:mt-20'>
                <ScrollableCards />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>
    )
}

export default SalesAndCommerce
