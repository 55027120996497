import React from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Industries/Energy_img1.png'
import Energy_img2 from '../../assets/Industries/Energy_img2.png'
import Energy_img3 from '../../assets/Industries/Energy_img3.png'
import Energy_img4 from '../../assets/Industries/Energy_img4.png'
import Energy_img5 from '../../assets/Industries/Energy_img5.png'
import Energy_img6 from '../../assets/Industries/Energy_img6.png'
import Energy_img7 from '../../assets/Industries/Energy_img7.png'
import Energy_Trending_img1 from '../../assets/Industries/Energy_Trending_img1.png'
import Energy_Trending_img2 from '../../assets/Industries/Energy_Trending_img2.png'
import Energy_Trending_img3 from '../../assets/Industries/Energy_Trending_img3.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import { ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Communications_img2 from '../../assets/Capabilities/communications_img2.png'
import Communications_img3 from '../../assets/Capabilities/communications_img3.png'
import Communications_img4 from '../../assets/Capabilities/communications_img4.png'
import communications_img5 from '../../assets/Capabilities/communications_img5.png'
import communications_img6 from '../../assets/Capabilities/communications_img6.png'
import communications_img7 from '../../assets/Capabilities/communications_img7.png'
import communications_img8 from '../../assets/Capabilities/communications_img8.png'
import Communications_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { useDocumentTitle } from '../../utils/useDocumentTitle'


function Energy() {
    useDocumentTitle("Energy");
    const CommunicationsAndMediaStats = () => {
        const stats = [
            { value: '60%', description: 'anticipated increase in renewable energy demand over the next 30 years as nations accelerate their shift to cleaner sources.' },
            { value: '52%', description: 'of energy leaders believe AI-driven analytics will play a key role in optimizing energy efficiency and operations.' },
            { value: '95%', description: 'of industry executives agree that embracing cutting-edge technologies is essential to maintaining global competitiveness.' },
            { value: '93%', description: 'foresee the integration of digital innovation and physical infrastructure as a game-changer for energy transformation within the next decade.' },
        ];

        return <StatsGrid title="Energy now" stats={stats} />;
    };


    const SegmentsWeSupport = () => {
        const [currentSlide, setCurrentSlide] = React.useState(0);
        const slides = [
            {
                category: "Exploration and production",
                title: "Exploration and production",
                description: "Communication providers enable mobile, scalable connectivity and information sharing between consumers, businesses and governments.",
                image: Communications_img2,
            },
            {
                category: "Refining and processing",
                title: "Refining and processing",
                description: "Media and entertainment companies create, produce, and distribute content across various platforms to engage and entertain audiences.",
                image: Communications_img3,
            },
            {
                category: "Distribution and retail",
                title: "Distribution and retail",
                description: "Digital service providers offer innovative solutions and platforms that enhance connectivity, productivity, and user experiences.",
                image: Communications_img4,
            },
        ];

        const nextSlide = () => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 }
        };

        return (
            <>
                <h2 className="text-2xl font-bold mb-6 xl:mb-12 text-center">Segments we support</h2>
                <div className="bg-[#14192E] text-white p-4 sm:p-8 rounded-2xl">
                    <div className="bg-navy-800 rounded-lg p-4 sm:p-6">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentSlide}
                                variants={slideVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                            >
                                <div className="flex items-center mb-4">
                                    <div className="w-3 h-3 bg-purple-500 rounded-full mr-2"></div>
                                    <span className="text-purple-500 font-semibold">{slides[currentSlide].category}</span>
                                </div>
                                <div className="flex flex-col sm:flex-row items-start">
                                    <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                        <div className="bg-purple-900 rounded-lg overflow-hidden">
                                            <img
                                                src={slides[currentSlide].image}
                                                alt={slides[currentSlide].title}
                                                className="w-full h-auto object-cover"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-grow">
                                        <h3 className="text-xl font-semibold mb-2">{slides[currentSlide].title}</h3>
                                        <p className="text-gray-400 mb-4">
                                            {slides[currentSlide].description}
                                        </p>
                                        <div className="flex justify-end">
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="border-2 border-theme/50 rounded-full p-2"
                                            >
                                                <ChevronRight
                                                    className="text-theme cursor-pointer"
                                                    size={24}
                                                    onClick={nextSlide}
                                                />
                                            </motion.div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </>
        );
    };

    const ReinventWithCloud = () => {
        const cards = [
            {
                title: 'Energy Transition: Deliver measurable outcomes for decarbonization and net-zero goals.',
                description: 'Deliver measurable outcomes for decarbonization and net-zero goals.',
                hasButton: false,
            },
            {
                title: 'Operational Excellence: Leverage AI and data to boost agility and efficiency.',
                description: 'Leverage AI and data to boost agility and efficiency.',
                hasButton: false,
            },
            {
                title: 'Cybersecurity: Strengthen defenses for digital and physical infrastructure.',
                description: 'Strengthen defenses for digital and physical infrastructure.',
                hasButton: false,
            },
            {
                title: 'Workforce Transformation: Empower talent with technology and innovative practices.',
                description: 'Empower talent with technology and innovative practices.',
                hasButton: false,
            },
            {
                title: 'Cost Optimization: Reinvent cost structures for sustainable growth.',
                description: 'Reinvent cost structures for sustainable growth.',
                hasButton: false,
            },
            {
                title: 'Customer Engagement: Use AI to create personalized, loyalty-driven experiences.',
                description: 'Create personalized, loyalty-driven experiences using AI.',
                hasButton: false,
            },
            {
                title: 'Supply Chain Reinvention: Build sustainable, resilient, and circular supply chains.',
                description: 'Build sustainable, resilient, and circular supply chains.',
                hasButton: false,
            },
            {
                title: 'Enterprise Evolution: Adopt cloud solutions to stay competitive and innovative.',
                description: 'Adopt cloud solutions to stay competitive and innovative.',
                hasButton: false,
            },
        ];

        return <ReinventGrid title="Reimagining the Future of Energy" cards={cards} />;
    };
    const slides = [
        {
            title: "Redefining Convenience: Key Strategies Shaping Growth in Fuel Retail and Convenience Sectors",
            description: "As consumer expectations evolve, the fuel retail and convenience sectors are undergoing a profound transformation. This topic explores the innovative strategies redefining convenience—such as contactless payments, loyalty programs, and the integration of food and beverage offerings—to enhance customer experiences. By adopting technology-driven solutions like digital apps and advanced supply chain models, companies are setting new standards for speed, accessibility, and personalization.",
            image: Energy_img2,
            gradient: "from-purple-600 to-pink-500"
        },
        {
            title: "Collaborative Energy Future: Uniting Efforts for Industry-Wide Growth and Sustainability",
            description: "The energy industry's future hinges on collaboration, innovation, and a shared commitment to sustainability. This topic emphasizes the importance of partnerships among stakeholders, including governments, corporations, and communities, to foster industry-wide growth. From renewable energy projects to shared infrastructure development, collective action is critical for addressing climate change while meeting growing energy demands.",
            image: Energy_img3,
            gradient: "from-blue-600 to-cyan-500"
        },
        {
            title: "AI for Human Potential: Leveraging AI to Empower Innovation and Human Ingenuity in Energy",
            description: "Artificial intelligence is not just a tool—it’s a transformative force unlocking human potential across the energy sector. This topic delves into how AI empowers innovation by automating repetitive tasks, optimizing energy production, and enhancing decision-making.",
            image: Energy_Trending_img1,
            gradient: "from-indigo-600 to-purple-500"
        },
        {
            title: "Fleet Sustainability: Prioritizing Decarbonization in Corporate Transportation",
            description: "Corporate fleets play a pivotal role in global emissions, making decarbonization a critical priority for businesses worldwide. This topic explores strategies for enhancing fleet sustainability, such as transitioning to electric vehicles, adopting alternative fuels, and leveraging telematics for route optimization.",
            image: Energy_Trending_img2,
            gradient: "from-indigo-600 to-purple-500"
        },
        {
            title: "Generative AI Revolution: Driving Value in Fuel and Retail with Next-Gen AI Solutions",
            description: "Generative AI is revolutionizing the fuel and retail industries, offering next-generation solutions that drive operational efficiency and customer engagement. This topic examines how generative AI is being used to personalize marketing, optimize inventory management, and design innovative customer experiences.",
            image: Energy_Trending_img3,
            gradient: "from-indigo-600 to-purple-500"
        }
    ];

    const CommunicationsMediaTrendingCarousel = () => {
        return <TrendingCarousel title="Emerging Trends in Energy" slides={slides} />;
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Fleet decarbonization: Ready to make it a corporate priority?",
                image: Energy_img5,
                alt: "Fleet decarbonization"
            },
            {
                title: "Leader in IDC MarketScape for Upstream Oil & Gas Services",
                image: Energy_img6,
                alt: "IDC MarketScape for Upstream Oil & Gas Services"
            },
            {
                title: "No. 1 Provider for Energy Services",
                image: Energy_img7,
                alt: "Energy Services"
            },
            {
                title: "Leader service providers for energy transaction",
                image: communications_img8,
                alt: "Energy transaction services"
            }
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} showSeeMore={false} />;
    };
    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Communications_leader_img1 },
            { name: "Johnson", title: "Lead - Data & AI", imageUrl: Communications_leader_img1 },
            { name: "Shakriya", title: "Chief AI Officer", imageUrl: Communications_leader_img1 },
            { name: "Chandran", title: "Chief Responsible AI Officer", imageUrl: Communications_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };


    return (
        <div className='bg-bg text-white'>
            <Header
                title="Powering the Future of Energy"
                description="In the face of growing energy needs, advancing green initiatives, and navigating economic and logistical hurdles, energy companies must embrace innovation and agility to lead the transition toward a sustainable and resilient future."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <CommunicationsAndMediaStats />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventWithCloud />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <CommunicationsMediaTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <GlobalRecognition />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>
    )
}

export default Energy
