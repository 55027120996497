import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import { useDocumentTitle } from '../utils/useDocumentTitle';
import coverImage from "../assets/SAP/ManagedServiceAutomation/_coverImg.png"
import _img1 from "../assets/SAP/ManagedServiceAutomation/_img1.png"
import _img2 from "../assets/SAP/ManagedServiceAutomation/_img2.png"
import _img3 from "../assets/SAP/ManagedServiceAutomation/_img3.png"
import Footer from '../components/Footer';

const patchingItems = [
    // Left side items
    {
        id: 1,
        text: "Underlying Network",
        position: "left",
        className: "top-[15%]"
    },
    {
        id: 2,
        text: "IT Architecture, Infrastructure and Devices",
        position: "left",
        className: "top-[35%]"
    },
    {
        id: 3,
        text: "Types of Software and Tools used",
        position: "left",
        className: "top-[55%]"
    },
    {
        id: 4,
        text: "User Interfaces",
        position: "left",
        className: "top-[75%]"
    },
    // Right side items
    {
        id: 5,
        text: "Application (External & Internal)",
        position: "right",
        className: "top-[15%]"
    },
    {
        id: 6,
        text: "BYOD (Bring your Own Device) Policies",
        position: "right",
        className: "top-[35%]"
    },
    {
        id: 7,
        text: "Information Security Management Systems and policies",
        position: "right",
        className: "top-[55%]"
    },
    {
        id: 8,
        text: "Access Control and Authentication techniques",
        position: "right",
        className: "top-[75%]"
    }
];

function ManagedServiceAutomation() {
    const headerData = {
        title: "Managed Services Automation",
        // description: "Power your SAP Transformation on Cloud with an Advanced, Standardized Step-by-Step Procedure. Gain High Performance, Maximum Availability Outcomes at Zero Disruption."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} isExploreBtn={false} backgroundImage={coverImage} />

            {/* Gartner Quote Section */}
            <div className="text-center px-3 sm:px-4 py-8 sm:py-12 md:py-16 max-w-6xl mx-auto">
                <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-8">
                    By 2025, AI is projected to handle 95% of customer interactions signifying reliance on AI for customer service tasks. (Gartner)
                </h2>
                <p className="text-base md:text-lg mb-16">
                    As a leading Managed Services provider, Diaa Corp has made automation a core part of their business model. It not only makes our day-to-day activities easier but also improves our customer's overall business operations, which has resulted in higher customer satisfaction and word-of-mouth recommendations that is helping us grow exponentially.
                </p>
            </div>

            {/* Words That Influence Section */}
            <div className="text-center px-3 sm:px-4 py-8 sm:py-12 md:py-16 max-w-6xl mx-auto">
                <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-8">
                    <span className="text-theme">Words That Influence</span> Our Extra Mile Of Effort
                </h2>
                <p className="text-base md:text-lg">
                    We subscribed Diaa Corp cloud services to support our critical applications, and right from the word go, they delivered services surpassing our expectation. We would summarize our total experience with Diaa Corp as 'Exemplary'.
                </p>
            </div>

            {/* Automation Services Section */}
            <div className="text-center px-3 sm:px-4 py-8 sm:py-12 md:py-16 max-w-6xl mx-auto">
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
                    MANAGED SERVICES AUTOMATION BY DIAA CORP
                </h2>
                <p className="text-base md:text-lg mb-12 max-w-4xl mx-auto">
                    The strategic use of automation enables us to protect our customers from harm. The automation of managed services directly affects your Top-line and Bottom-line. No downtimes, always secure data and infra translates to cost savings. Automating any repeatable and routine tasks allows you to save on resources.
                </p>

                <h3 className="text-lg md:text-xl lg:text-2xl font-semibold mb-8">
                    At Diaa Corp, we have automated some of the key functions of managed services
                </h3>

                <div className="grid gap-4 sm:gap-6 md:gap-8 mt-6 sm:mt-8">
                    {/* Card 1 */}
                    <div className="bg-gradient-to-br from-[#0f172a] to-[#1e293b] p-4 sm:p-6 rounded-lg shadow-xl hover:scale-105 transition-transform duration-300 border border-purple-500/20">
                        <h4 className="text-lg md:text-xl font-bold mb-4 text-purple-500">
                            Remote Monitoring and Management (RMM)
                        </h4>
                        <p className="text-sm md:text-base text-gray-300">
                            This enables the tracking of important IT functions like diagnostic alerts and performance reviews so that the team is aware of the issues with the infra. It reduces risk of failure or on-site visits for correcting the issues.
                        </p>
                    </div>

                    {/* Card 2 */}
                    <div className="bg-gradient-to-br from-[#0f172a] to-[#1e293b] p-4 sm:p-6 rounded-lg shadow-xl hover:scale-105 transition-transform duration-300 border border-purple-500/20">
                        <h4 className="text-lg md:text-xl font-bold mb-4 text-purple-500">
                            Professional Service Automation (PSA)
                        </h4>
                        <p className="text-sm md:text-base text-gray-300">
                            Administrative tasks, billing and customer account management is automated so that we concentrate more on our service offering.
                        </p>
                    </div>

                    {/* Card 3 */}
                    <div className="bg-gradient-to-br from-[#0f172a] to-[#1e293b] p-4 sm:p-6 rounded-lg shadow-xl hover:scale-105 transition-transform duration-300 border border-purple-500/20">
                        <h4 className="text-lg md:text-xl font-bold mb-4 text-purple-500">
                            Integration of RMM and PSA
                        </h4>
                        <p className="text-sm md:text-base text-gray-300">
                            We ensure that both RMM and PSA are working in coherence and complement each other well. The workflow of PSS can be leveraged with the visibility of RMM in order to get a true end-to-end managed service solution. This creates more efficient managed services.
                        </p>
                    </div>
                </div>
            </div>

            {/* Virtual Patching Section */}
            <div className="relative px-3 sm:px-4 py-8 sm:py-12 md:py-16 max-w-7xl mx-auto">
                <h2 className="text-center text-xl md:text-2xl lg:text-3xl font-bold mb-8">
                    <span className="text-purple-500">Vir</span>tual Patching
                </h2>
                <p className="text-center text-base md:text-lg mb-16 max-w-4xl mx-auto">
                    Automated Patch deployment at Diaa Corp enables enterprises to focus on their priorities while being assured of the mitigation of threats arising due to vulnerabilities. It also accelerates the Vulnerability remediation for enterprises across hybrid environments.
                </p>

                {/* Desktop View (Hidden on mobile/tablet) */}
                <div className="hidden lg:block relative h-[600px] w-full">
                    {/* Center Image Container */}
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[300px] h-[400px]">
                        <div className="relative w-full h-full bg-gradient-to-b from-blue-400/20 to-purple-500/20 rounded-2xl border border-purple-500/30 backdrop-blur-sm">
                            <img
                                src={_img1}
                                alt="Virtual Patching Visualization"
                                className="w-full h-full object-cover rounded-2xl opacity-90"
                            />
                            <div className="absolute inset-0 bg-gradient-to-b from-blue-500/10 to-purple-500/10 rounded-2xl animate-pulse" />
                        </div>
                    </div>

                    {/* Desktop Connection Lines and Items */}
                    {patchingItems.map((item) => (
                        <div
                            key={item.id}
                            className={`absolute ${item.position === 'left' ? 'right-[65%]' : 'left-[65%]'} ${item.className} transform`}
                        >
                            <div className={`flex items-center ${item.position === 'left' ? 'flex-row' : 'flex-row-reverse'} gap-4`}>
                                <div className="text-base max-w-[250px] p-4 rounded-lg backdrop-blur-sm border border-purple-500/20">
                                    {item.text}
                                </div>
                                <div className={`h-[2px] w-20 bg-gradient-to-${item.position === 'left' ? 'l' : 'r'} from-purple-500 to-transparent`} />
                                <div className="h-3 w-3 rounded-full bg-purple-500 animate-pulse" />
                            </div>
                        </div>
                    ))}
                </div>

                {/* Mobile/Tablet View (Hidden on desktop) */}
                <div className="lg:hidden">
                    {/* Center Image */}
                    <div className="mx-auto w-[200px] h-[250px] mb-8">
                        <div className="relative w-full h-full bg-gradient-to-b from-blue-400/20 to-purple-500/20 rounded-2xl border border-purple-500/30">
                            <img
                                src={_img1}
                                alt="Virtual Patching Visualization"
                                className="w-full h-full object-cover rounded-2xl opacity-90"
                            />
                        </div>
                    </div>

                    {/* Mobile/Tablet Items Grid */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
                        {patchingItems.map((item) => (
                            <div
                                key={item.id}
                                className="bg-gradient-to-br from-[#0f172a] to-[#1e293b] p-4 rounded-lg border border-purple-500/20"
                            >
                                <div className="flex items-center gap-3">
                                    <div className="h-3 w-3 rounded-full bg-purple-500 animate-pulse flex-shrink-0" />
                                    <p className="text-xs md:text-sm">{item.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Automated Backup Section */}
            <div className="px-3 sm:px-4 py-8 sm:py-12 md:py-16 max-w-7xl mx-auto">
                <div className="grid md:grid-cols-2 items-center">
                    <div className="order-2 md:order-1">
                        <img
                            src={_img2}
                            alt="Automated Backup and Recovery"
                            className="max-w-[500px] mx-auto rounded-lg mt-4 xl:mt-0"
                        />
                    </div>
                    <div className="order-1 md:order-2">
                        <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-6">
                            Automated Backup and Recovery
                        </h2>
                        <div className="space-y-4">
                            <p className="text-sm md:text-base text-gray-300">
                                We implement strategies that help reduce your backup footprint using a method of deduplicating data at each node, job, and site. As a result, you get speed backup and faster recoveries. Diaa Corp is known for its global deduplication systems that efficiently execute bare metal recoveries.
                            </p>
                            <p className="text-sm md:text-base text-gray-300">
                                We offer agent-less, script-less and a multi-tenant software integrated with our managed services platform that gives you the added edge of having a fully-customizable orchestration for applications, DR recovery over cloud or enterprise DR. You get to experience the power of a complete DR framework with single click switchover, switch back or failover.
                            </p>
                            <p className="text-sm md:text-base text-gray-300">
                                Diaa Corp enables customers to manage and monitor the application recovery with automated switchovers and DR drills. You can monitor RPO, data lag and recovery time while automating end-to-end application recovery in case of DR in a single click.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Automated Malware Protection Section */}
            <AutomatedMalwareProtection />

            <div className='mx-auto  py-16'>
                <h2 className="text-center text-xl md:text-2xl lg:text-3xl font-bold mb-8">
                    <span className="text-purple-500">Automated</span> Network Security
                </h2>
                <p className="text-center text-base md:text-lg mb-16 max-w-4xl mx-auto">
                    Automated Patch deployment at Diaa Corp enables enterprises to focus on their priorities while being assured of the mitigation of threats arising due to vulnerabilities. It also accelerates the Vulnerability remediation for enterprises across hybrid environments.
                </p>

            </div>

            <div className="bg-[#14192E] py-8 sm:py-12 md:py-16 px-3 sm:px-4">
                <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-white mb-4">
                        Diaa Corp PROPRIETARY AUTOMATION TOOLS FOR 
                        CLOUD MANAGED SERVICES
                    </h2>
                    
                    <p className="text-sm md:text-base text-gray-300 mb-16">
                        We leverage our proprietary tools to aid us in delivering Managed Services automation
                    </p>

                    <div className="space-y-8 sm:space-y-12">
                        {/* CMP Section */}
                        <div>
                            <h3 className="text-xl font-bold text-white mb-4">
                                <span className="text-theme">| </span> 
                                Diaa Corp Cloud Management Portal (CMP)
                                <span className="text-theme"> |</span>
                            </h3>
                            <p className="text-sm md:text-base text-gray-300 mb-4">
                                Diaa Corp CMP is a comprehensive solution that simplifies management and maximizes value to the business by consolidating all core IT processes including incident, problem, and knowledge management
                            </p>
                            <ul className="space-y-2">
                                <li className="flex items-center justify-center gap-2">
                                    <span className="w-2 h-2 rounded-full bg-custom-gradient"></span>
                                    <span className="text-gray-300">Incident Management</span>
                                </li>
                                <li className="flex items-center justify-center gap-2">
                                    <span className="w-2 h-2 rounded-full bg-custom-gradient"></span>
                                    <span className="text-gray-300">Operational and TAT Reports</span>
                                </li>
                                <li className="flex items-center justify-center gap-2">
                                    <span className="w-2 h-2 rounded-full bg-custom-gradient"></span>
                                    <span className="text-gray-300">Integration with CSP Reports</span>
                                </li>
                            </ul>
                        </div>

                        {/* CSP Section */}
                        <div>
                            <h3 className="text-xl font-bold text-white mb-4">
                                <span className="text-theme">| </span>
                                Diaa Corp Customer Support Portal (CSP)
                                <span className="text-theme"> |</span>
                            </h3>
                            <p className="text-sm md:text-base text-gray-300">
                                Diaa Corp CSP contains customer related infrastructure view, operations, monitoring and support of services. The Dashboard contains devices assigned to the customer, support tickets summary, customer user management and reports
                            </p>
                        </div>

                        {/* CMDB Section */}
                        <div>
                            <h3 className="text-xl font-bold text-white mb-4">
                                <span className="text-theme">| </span>
                                Diaa Corp Configuration Management Database Portal (CMDB)
                                <span className="text-theme"> |</span>
                            </h3>
                            <p className="text-sm md:text-base text-gray-300">
                                Diaa Corp CMDB is a repository that acts as a data ware house for IT installations. All the hardware utilized for order implementation is recorded in this CMDB application. Complete Asset management is recorded in this application
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <BenefitsSection />

            <Footer />
        </div>
    )
}

function AutomatedMalwareProtection() {
    const protectionItems = [
        {
            id: 1,
            text: "Web site analysis for vulnerable and/or erroneous code"
        },
        {
            id: 2,
            text: "Monitoring malicious activity upon request from customer"
        },
        {
            id: 3,
            text: "On-demand scans for quick review after malware removal"
        },
        {
            id: 4,
            text: "Detailed report on malicious content and malicious web pages"
        },
        {
            id: 5,
            text: "Provision of recommendations for malware removal"
        }
    ];

    return (
        <div className="bg-navy-900 text-white py-8 sm:py-12 md:py-16 px-3 sm:px-4">
            <div className="max-w-7xl mx-auto">
                <div className="grid md:grid-cols-2 gap-8 items-center">
                    <div>
                        <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-6">
                            Automated Malware Protection
                        </h2>
                        <p className="text-sm md:text-base text-gray-300 mb-8">
                            Diaa Corp Malware Detection Scanning can cover main external and internal web sites and sub-domains of web. The scope of the MDS service includes following:
                        </p>
                        <div className="space-y-6">
                            {protectionItems.map((item) => (
                                <div key={item.id} className="flex items-center gap-4">
                                    <div className="w-6 h-6 flex-shrink-0">
                                        <svg className="w-6 h-6 text-purple-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>
                                    <p className="text-sm md:text-base text-gray-300">{item.text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="relative">
                        <div className="rounded-lg p-1">
                            <img
                                src={_img3}
                                alt="Malware Protection Visualization"
                                className="rounded-lg max-w-[500px] mx-auto"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function BenefitsSection() {
    const benefits = [
        {
            id: 1,
            text: "Increased productivity: As several IT and administrative tasks are automated, resources can spend their time and efforts on more strategic initiatives where they can add more value."
        },
        {
            id: 2,
            text: "Automated and proactive monitoring helps you improve the quality of your services. Errors and vulnerabilities are caught early and team member concerned is alerted for a quicker resolution"
        },
        {
            id: 3,
            text: "Easy payments and reviews: Automated monitoring, account management and billing also makes the job of an enterprise's accountants easy. They can quickly review the invoices and payments."
        },
        {
            id: 4,
            text: "Opting for automated managed services from Diaa Corp helps enterprises to reduce the number of errors and handle sudden changes to their staff effectively."
        },
        {
            id: 5,
            text: "A proactive approach: Proactive monitoring and resolution not only helps us be prepared but spares the teams from any unforeseen events such as outages."
        }
    ];

    return (
        <div className="pt-8 sm:pt-12 md:pt-16 px-3 sm:px-4 max-w-7xl mx-auto">
            <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-8">
                <span className="text-theme">Benefits</span> of Managed Service Automation
            </h2>
            
            <p className="text-center text-sm md:text-base text-gray-300 mb-12">
                The main advantages of managed service automation include:
            </p>

            <div className="space-y-3 sm:space-y-4">
                {benefits.map((benefit) => (
                    <div 
                        key={benefit.id}
                        className="flex items-center gap-4 sm:gap-6 p-4 sm:p-6 rounded-xl bg-gradient-to-r from-[#0f172a] to-[#1e293b] border border-purple-500/20 hover:scale-[1.02] transition-transform duration-300"
                    >
                        <div className="flex-shrink-0">
                            <div className="w-6 h-6 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full flex items-center justify-center">
                                <svg 
                                    className="w-4 h-4 text-white" 
                                    fill="none" 
                                    viewBox="0 0 24 24" 
                                    stroke="currentColor"
                                >
                                    <path 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round" 
                                        strokeWidth={2} 
                                        d="M9 5l7 7-7 7" 
                                    />
                                </svg>
                            </div>
                        </div>
                        <p className="text-sm md:text-base text-gray-300">{benefit.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ManagedServiceAutomation;