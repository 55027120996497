import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/Cloud_services_cover.png'
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import Cloud_img1 from '../../assets/Capabilities/Cloud_img1.png'
import Cloud_img2 from '../../assets/Capabilities/Cloud_img2.png'
import Cloud_img3 from '../../assets/Capabilities/Cloud_img3.png'
import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import Cloud_awards_img1 from '../../assets/Capabilities/Cloud_awards_img1.png'
import Cloud_awards_img2 from '../../assets/Capabilities/Cloud_awards_img2.png'
import Cloud_awards_img3 from '../../assets/Capabilities/Cloud_awards_img3.png'
import Cloud_awards_img4 from '../../assets/Capabilities/Cloud_awards_img4.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { motion, AnimatePresence } from 'framer-motion';
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import { useDocumentTitle } from '../../utils/useDocumentTitle';
function CloudServices() {

    useDocumentTitle("Cloud Services");


    const CloudNowStats = () => {
        const stats = [
            { value: '90%', description: 'of businesses have accelerated their cloud adoption in the past 12 months.' },
            { value: '60%', description: 'of IT spending is now allocated to cloud and AI innovations, shifting focus from traditional maintenance.' },
            { value: '3x', description: 'faster delivery of new products and services with cloud-based infrastructure.' },
            { value: '$15B+', description: 'potential increase in profits for Fortune 500 companies transitioning to the cloud.' },
        ];

        return <StatsGrid title="Cloud now" stats={stats} />;
    };

    const ReinventWithCloud = () => {
        const cards = [
            {
                title: 'Cloud Data & AI',
                description: 'Data is the engine, and AI is the catalyst for innovation, helping businesses make smarter decisions faster.',
                hasButton: true,
            },
            {
                title: 'Application Transformation',
                description: 'Elevate your software capabilities and become a leading-edge organization by modernizing your applications.',
                hasButton: true,
            },
            {
                title: 'Edge Computing',
                description: 'Reimagine digital experiences that drive innovation, efficiency, and profitability, bringing data processing closer to the source.',
                hasButton: true,
            },
            {
                title: 'Modern Infrastructure',
                description: '57% of traditional infrastructures are falling behind. Embrace a modern, flexible infrastructure to unlock growth and stay ahead in the digital age.',
                hasButton: true,
            },
            {
                title: 'Cloud Infrastructure',
                description: 'Boost resilience and accelerate innovation with a cloud-native infrastructure that scales with your business.',
                hasButton: true,
            },
            {
                title: 'Application Managed Services',
                description: 'Outpace change and ensure continuous innovation with modern, efficient application management services.',
                hasButton: true,
            },
            {
                title: 'Network Optimization',
                description: 'Build, secure, and monetize agile, software-defined networks to support your digital transformation.',
                hasButton: true,
            },
            {
                title: 'Managed Services for Cloud Success',
                description: '82% of companies that achieve their cloud goals leverage managed services to drive greater efficiency and outcomes.',
                hasButton: true,
            },
            {
                title: 'Infrastructure Managed Services',
                description: 'Simplify complex cloud ecosystems and optimize performance with expert-managed infrastructure services.',
                hasButton: true,
            },
            {
                title: 'Cloud Strategy & Design',
                description: 'Maximize business value with tailored cloud strategies and innovative design solutions that align with your goals.',
                hasButton: true,
            },
            {
                title: 'Sustainable Technology',
                description: 'Create a sustainable future by integrating eco-friendly technologies and practices into your cloud infrastructure.',
                hasButton: true,
            },
            {
                title: 'Cloud Security',
                description: 'Operate with peace of mind, knowing your cloud environment is secure, compliant, and built for resilience.',
                hasButton: true,
            },
        ];




        return <ReinventGrid title="Reinvent with cloud" cards={cards} />;
    };
    const slides = [
        {
            title: "Reinventing with a digital core",
            image: Cloud_img1,
            gradient: "from-purple-600 to-pink-500"
        },
        {
            title: "Reinventing with a digital core",
            image: Cloud_img2,
            gradient: "from-blue-600 to-cyan-500"
        },
        {
            title: "Reinventing with a digital core",
            image: Cloud_img3,
            gradient: "from-indigo-600 to-purple-500"
        }
    ];


    const CloudTrendingCarousel = () => {
        return <TrendingCarousel title="Future of Cloud" slides={[
            {
                title: "Cloud-Native Architectures",
                image: Cloud_img1,
                gradient: "from-purple-600 to-pink-500",
                description: "Cloud-native development leveraging microservices, containers, and Kubernetes enables faster innovation, improved scalability, and enhanced operational efficiency. Organizations can deploy applications faster and adapt to market demands in real time."
            },
            {
                title: "Serverless Computing", 
                image: Cloud_img2,
                gradient: "from-blue-600 to-cyan-500",
                description: "Serverless computing minimizes infrastructure management overhead, allowing developers to focus on code while providers handle scaling and resources. This improves cost efficiency and reduces time-to-market."
            },
            {
                title: "Multi-Cloud Strategies",
                image: Cloud_img3, 
                gradient: "from-indigo-600 to-purple-500",
                description: "Multi-cloud strategies distribute workloads across providers to enhance performance, ensure availability, and maintain control. This approach optimizes costs and increases security in modern cloud adoption."
            }
        ]} />;
    };

    const cards = [
        { id: 1, logo: Cloud_partner_img1, content: 'ServiceNow content here' },
        { id: 2, logo: Cloud_partner_img2, content: 'Bringing together 65,000 dedicated professionals, the DXC and Microsoft alliance delivers comprehensive services to help organizations transform and thrive' },
        { id: 3, logo: Cloud_partner_img4, content: 'Our unflinching mission is to combine extraordinary new technologies with human ingenuity to meet business challenges and drive growth.' },
        { id: 4, logo: Cloud_partner_img3, content: 'The DXC-COM Google Practice is turning your cloud into an enterprise solution and making your business applications run like a cloud.' },
        { id: 5, logo: Cloud_partner_img1, content: 'Another ServiceNow content here' },
    ];


    const ScrollableCards = () => {
        const [activeIndex, setActiveIndex] = useState(2);

        const handlePrev = () => {
            setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cards.length - 1));
        };

        const handleNext = () => {
            setActiveIndex((prevIndex) => (prevIndex < cards.length - 1 ? prevIndex + 1 : 0));
        };

        return (
            <div className="flex flex-col items-center justify-center p-4 overflow-hidden">
                <h1 className="text-white text-2xl sm:text-3xl font-bold md:mb-12 text-center">Partners in change</h1>
                <div className="relative w-full max-w-5xl">
                    <div className="flex justify-center items-center h-64 sm:h-80 md:h-96 overflow-visible">
                        <AnimatePresence initial={false}>
                            {cards.map((card, index) => {
                                const isActive = index === activeIndex;
                                const offset = (index - activeIndex) * (window.innerWidth < 640 ? 200 : 300); // Adjust offset for mobile
                                const zIndex = cards.length - Math.abs(activeIndex - index);
                                return (
                                    <motion.div
                                        key={card.id}
                                        className={`absolute bg-[#14192E] rounded-lg shadow-lg pt-4 sm:pt-8 ${isActive ? 'w-4/5 sm:w-96 h-40 sm:h-48 md:h-64' : 'w-2/3 sm:w-80 h-32 sm:h-40 md:h-56'
                                            }`}
                                        initial={{ opacity: 0, scale: 0.8 }}
                                        animate={{
                                            opacity: isActive ? 1 : 0.6,
                                            scale: isActive ? 1 : 0.8,
                                            x: offset,
                                            zIndex: zIndex,
                                        }}
                                        exit={{ opacity: 0, scale: 0.8 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <div className="p-2 sm:p-4 md:p-6 h-full flex flex-col">
                                            <img src={card.logo} alt={`${card.logo.split('_')[0]} logo`} className="h-8 sm:h-12 object-contain mb-2 sm:mb-4 mx-auto" />
                                            <p className="text-gray-300 text-xs sm:text-sm flex-grow overflow-hidden text-center">{card.content}</p>
                                        </div>
                                    </motion.div>
                                );
                            })}
                        </AnimatePresence>
                    </div>
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex gap-4 mt-4 sm:mt-8">
                        <motion.button
                            onClick={handlePrev}
                            className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                            aria-label="Previous card"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <ChevronLeft size={20} />
                        </motion.button>
                        <motion.button
                            onClick={handleNext}
                            className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                            aria-label="Next card"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <ChevronRight size={20} />
                        </motion.button>
                    </div>
                </div>
            </div>
        );
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Leader in Multicloud Managed Services - Forrester",
                image: Cloud_awards_img1,
                alt: "Multicloud Managed Services"
            },
            {
                title: "Leader for Worldwide Cloud Professional Services - IDC",
                image: Cloud_awards_img2,
                alt: "Worldwide Cloud Professional Services"
            },
            {
                title: "Leader in data center outsourcing & hybrid",
                image: Cloud_awards_img3,
                alt: "Data Center Outsourcing & Hybrid"
            },
            {
                title: "Diaa corp named a Leader in Everest Group Cloud Services PEAK Matrix®",
                image: Cloud_awards_img4,
                alt: "Everest Group Cloud Services"
            }
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };
    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Cloud_leader_img1 },
            // { name: "Johnson", title: "Lead - Data & AI", imageUrl: Cloud_leader_img1 },
            // { name: "Shakriya", title: "Chief AI Officer", imageUrl: Cloud_leader_img1 },
            // { name: "Chandran", title: "Chief Responsible AI Officer", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };



    return (
        <div className='bg-bg text-white '>
            <Header
                title="Embrace the Power of Cloud"
                description="Cloud computing is transforming how businesses operate, enabling agility and innovation to meet the evolving demands of a global market. Unlock new possibilities and stay ahead of the curve with cloud-driven solutions that empower your organization to thrive."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <CloudNowStats />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithCloud />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <CloudTrendingCarousel />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ScrollableCards />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <GlobalRecognition />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20 mb-8 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>

    )
}

export default CloudServices