import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const jobCategories = [
  {
    title: "Technology",
    roles: ["Software Engineering", "Cloud Architecture", "DevOps", "Security"],
    available: 0
  },
  {
    title: "Consulting",
    roles: ["SAP Consulting", "Digital Transformation", "Business Strategy"],
    available: 0
  },
  {
    title: "Data & AI",
    roles: ["Data Science", "Machine Learning", "AI Engineering"],
    available: 0
  },
  {
    title: "Management",
    roles: ["Project Management", "Product Management", "Program Management"],
    available: 0
  }
];

const ExploreJobs = () => {
  return (
    <div className="bg-bg min-h-screen text-white py-16 px-4">
      <div className="max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Explore Career Opportunities
          </h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Discover your next role by area of expertise
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          {jobCategories.map((category, index) => (
            <motion.div
              key={category.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-[#1A1F31] rounded-xl p-6 hover:bg-[#242942] transition-colors duration-300"
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">{category.title}</h2>
                <span className="text-purple-500">
                  {category.available} positions
                </span>
              </div>
              <ul className="space-y-2 text-gray-300">
                {category.roles.map((role) => (
                  <li key={role}>• {role}</li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>

        {/* No Positions Available Notice */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="bg-[#1A1F31] rounded-xl p-8 max-w-2xl mx-auto text-center"
        >
          <img
            src="https://illustrations.popsy.co/gray/work-from-home.svg"
            alt="No open positions"
            className="w-48 h-48 mx-auto mb-6"
          />
          <h2 className="text-2xl font-bold mb-4">No Open Positions Currently</h2>
          <p className="text-gray-400 mb-6">
            We're currently not hiring for these positions, but we're always looking for talented individuals. Check back soon for new opportunities.
          </p>
          <div className="space-x-4">
            <Link
              to="/search-all-jobs"
              className="inline-block bg-gradient-to-r from-purple-600 to-purple-800 text-white px-8 py-3 rounded-lg hover:opacity-90 transition-opacity"
            >
              View All Jobs
            </Link>
            <Link
              to="/contact-us"
              className="inline-block bg-[#242942] text-white px-8 py-3 rounded-lg hover:bg-[#2d345a] transition-colors"
            >
              Stay Connected
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ExploreJobs; 