import React from 'react';
import Footer from '../components/Footer';

function WorkshopSignup() {
    return (
        <div className="min-h-screen bg-bg text-white">
            <div className="container mx-auto px-4 py-16 md:py-24">
                <div className="max-w-3xl mx-auto text-center">
                    <div className="mb-12">
                        <h1 className="text-2xl md:text-4xl font-bold mb-6">
                            SAP Workshop Registration
                        </h1>
                        <div className="w-24 h-1 bg-theme mx-auto mb-8"></div>
                    </div>

                    <div className="bg-[#14192E] rounded-lg p-8 md:p-12 shadow-lg border border-gray-800">
                        <div className="mb-8">
                            <span className="text-5xl mb-4 block">📅</span>
                            <h2 className="text-xl md:text-2xl font-semibold mb-4">
                                No Workshops Currently Scheduled
                            </h2>
                            <p className="text-gray-400 mb-6">
                                We're currently planning our next round of workshops. Sign up for our waiting list to be notified when new workshops become available.
                            </p>
                        </div>

                        <form className="max-w-md mx-auto">
                            <div className="mb-6">
                                <input
                                    type="email"
                                    placeholder="Enter your email address"
                                    className="w-full px-4 py-3 bg-[#1C2333] border border-gray-700 rounded-lg focus:outline-none focus:border-theme"
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full py-3 px-6 bg-theme text-white rounded-lg hover:bg-opacity-90 transition-all duration-200"
                            >
                                Join Waiting List
                            </button>
                        </form>

                        <div className="mt-12 pt-8 border-t border-gray-800">
                            <h3 className="text-lg font-semibold mb-4">
                                Alternative Resources
                            </h3>
                            <div className="grid md:grid-cols-2 gap-4">
                                <a
                                    href="/contact-us"
                                    className="p-4 bg-[#1C2333] rounded-lg hover:bg-opacity-80 transition-all duration-200"
                                >
                                    <h4 className="font-semibold mb-2">Schedule a Consultation</h4>
                                    <p className="text-sm text-gray-400">
                                        Speak with our SAP experts one-on-one
                                    </p>
                                </a>
                                <a
                                    href="/we-think"
                                    className="p-4 bg-[#1C2333] rounded-lg hover:bg-opacity-80 transition-all duration-200"
                                >
                                    <h4 className="font-semibold mb-2">Resource Center</h4>
                                    <p className="text-sm text-gray-400">
                                        Access our library of SAP insights
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default WorkshopSignup; 