import React from 'react';
import { motion } from 'framer-motion';
import { Header } from '../components/resuable/SapSolution';
import Footer from '../components/Footer';
import { LightbulbIcon } from 'lucide-react';

// Import images (replace with actual paths)
import headerImage from '../assets/SAP/sap-architectural-consulting-header.png';
import architectureImage from '../assets/SAP/architecture-image.png';
import evaluatingImage from '../assets/SAP/evaluating-image.png';
import assessingImage from '../assets/SAP/assessing-image.jpg';
import evaluateImage from '../assets/SAP/Evaluate.jpg';
import { ContactContent } from '../components/resuable/SapComponents';
import { useDocumentTitle } from '../utils/useDocumentTitle';

const SAPArchitecturalConsulting = () => {

  useDocumentTitle('SAP Architectural Consulting');


  const ArchitectureCard = () => {
    return (
      <div className="bg-gradient-to-r from-[#1D1F3F] via-[#070727] to-[#1D1F3F] text-white p-4 sm:p-6 rounded-lg shadow-lg mx-auto">
        <div className="flex flex-col lg:flex-row items-center gap-4 sm:gap-6">
          {/* Left Image */}
          <div className="lg:w-1/3">
            <div className="bg-blue-500 h-0 pb-[100%] w-full rounded-lg overflow-hidden relative">
              <img
                src={architectureImage}
                alt="Architecture illustration 1"
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Center Content */}
          <div className="lg:w-1/3 text-center">
            <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4">We Start With the Architecture</h2>
            <p className="text-sm sm:text-base mb-2 sm:mb-4">
              When an organization frequently faces issues with their software - glitches, crashes, user dissatisfaction, or even falling short of deadlines - DIAA Corp typically encourages the stakeholders to take a good look at the underlying architecture.
            </p>
            <p className="text-sm sm:text-base">
              As an SAP Architecture consulting partner, DIAA Corp works with organizations to co-develop and co-architect software solutions, helping them identify right architecture elements and suggestions on process mining. Our teams work closely with your product/solution development teams on how to build a robust architecture which will serve as the foundation for building the SAP solutions.
            </p>
            <div className="mt-4 sm:mt-6 flex items-center justify-center">
              <LightbulbIcon className="w-5 h-5 sm:w-6 sm:h-6 text-yellow-400 mr-2" />
              <span className="text-xs sm:text-sm">DIAA Corp Architecture Consulting</span>
            </div>
          </div>

          {/* Right Image */}
          <div className="lg:w-1/3">
            <div className="bg-purple-500 h-0 pb-[100%] w-full rounded-lg overflow-hidden relative">
              <img
                src={evaluatingImage}
                alt="Architecture illustration 2"
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const InformationSystemOverview = () => {
    // const [fadeIn, setFadeIn] = useState(true);
    // console.log(cards[0].items.length, "cards");
    return (
      <div className={`relative z-10 mobile:mt-10 -mt-20 sm:-mt-24 lg:-mt-32 xl:-mt-24 container mx-auto px-4 bg-[#49537780] rounded-lg mb-12 md:w-11/12 xl:w-10/12`}>
        <div className="px-4 sm:px-8 md:px-12 py-8 sm:py-12 text-center">
          <p className="text-sm sm:text-base md:text-lg">Any information system ought to be built by taking into consideration various aspects, such as business logic, corporate governance, industry practices and market trends. The development of such systems is typically driven by a roadmap and wisely planned timelines.
            DIAA Corp has a team of consultants with expertise in SAP solution architecture that can help your organization achieve its development goals.</p>
        </div>
      </div>
    );
  }


  const EvaluatingArchitectureCard = () => {
    return (
      <div className="flex flex-col md:flex-row bg-gradient-to-r from-[#1D1F3F] to-[#070727] p-4 sm:p-6 rounded-lg shadow-lg mt-8 sm:mt-12 xl:mt-24 mx-auto w-full">
        <div className="md:w-1/4 mb-4 md:mb-0">
          <div className="rounded-lg overflow-hidden border-4 border-blue-900 h-full">
            <img
              src={evaluateImage}
              alt="Person evaluating architecture"
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>
        <div className="md:w-3/4 md:pl-4 sm:pl-6 text-gray-300">
          <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4 text-center md:text-left">Evaluating Integration Effectiveness</h2>
          <p className="text-sm sm:text-base md:text-lg leading-relaxed">
            A well-executed cloud integration strategy undergoes multiple evaluations to ensure success. DIAA Corp’s team of experts provides tools and methodologies to assess your current systems, identify gaps, and recommend enhancements.
            Our consultants consider essential factors such as data security, scalability, and performance. We also address potential risks and limitations, ensuring seamless interaction between all integrated systems. This comprehensive approach guarantees that your organization’s integration strategy is robust, secure, and future-ready.
          </p>
        </div>
      </div>
    );
  };

  const AccessingTheArchitect = () => {
    return (
      <div className="flex flex-col md:flex-row bg-gradient-to-l from-[#1D1F3F] to-[#070727] p-4 sm:p-6 rounded-lg shadow-lg mt-8 sm:mt-12 xl:mt-24 mx-auto w-full">
        <div className="md:w-3/4 md:pr-4 sm:pr-6 text-gray-300 order-2 md:order-1">
          <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4 text-center md:text-left">Accessing Integration Specialists</h2>
          <p className="text-sm sm:text-base md:text-lg leading-relaxed">
            DIAA Corp’s team of cloud integration specialists is readily available to assist you at every stage of your project. Whether you need insights into system compatibility, data migration strategies, or performance optimization, our experts offer tailored advice to meet your specific needs.
            By working closely with your team, our specialists ensure that your cloud ecosystem remains efficient, secure, and aligned with your business objectives. With DIAA Corp as your partner, you gain the expertise and support needed to drive innovation and achieve success in a connected digital world.
          </p>
        </div>
        <div className="md:w-1/4 mb-4 md:mb-0 order-1 md:order-2">
          <div className="rounded-lg overflow-hidden border-4 border-blue-900 h-full">
            <img
              src={assessingImage}
              alt="Accessing the architect"
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>
      </div>
    );
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="bg-bg text-white">
      <Header
        title="Advanced Cloud Integration Consulting"
        description="Designing seamless cloud integration strategies to create reliable, responsive, and scalable digital ecosystems."
        backgroundImage={headerImage}
        isExploreBtn={true}
      />
      <InformationSystemOverview />
      <main className="mx-auto py-12">
        <div className='mb-12 lg:w-11/12 mx-auto'>
          <ArchitectureCard />
        </div>

        <motion.section {...fadeInUp} className="my-16 flex flex-col justify-center items-center text-center bg-[#494D624D] py-12 px-4 sm:px-6 lg:px-8 mx-auto">
          <div className='w-full sm:w-4/5 lg:w-3/4 xl:w-1/2 mx-auto'>
            <h2 className="text-2xl sm:text-3xl font-bold mb-6">Building the Right Cloud Integration Strategy</h2>
            <p className="text-gray-300 text-base sm:text-lg leading-relaxed mb-4">
              When organizations face challenges such as disjointed systems, inefficiencies in data sharing, or underperforming applications, DIAA Corp encourages stakeholders to evaluate their cloud integration roadmap.
            </p>
            <p className="text-gray-300 text-base sm:text-lg leading-relaxed">
              As a trusted cloud consulting partner, DIAA Corp works alongside businesses to design and implement cohesive integration strategies, ensuring seamless communication across platforms.
            </p>
          </div>
        </motion.section>

        <div className='mb-12 lg:w-11/12 mx-auto'>
          <EvaluatingArchitectureCard />
        </div>

        <div className='mb-12 lg:w-11/12 mx-auto'>
          <AccessingTheArchitect />
        </div>

        <div>
          <ContactContent title={`Empower Your Business with Seamless Integration: Transform your digital landscape with DIAA Corp’s advanced cloud integration consulting. Let us help you build systems that enable collaboration, enhance decision-making, and accelerate growth while staying aligned with your long-term goals.`} />
        </div>

      </main>

      <Footer />
    </div>
  );
};

export default SAPArchitecturalConsulting;