import React, { useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Industries/SoftwareAndPlatforms/_coverImg.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents'
import { ContactContent } from '../../components/resuable/SapComponents'
import Footer from '../../components/Footer'
import { useDocumentTitle } from '../../utils/useDocumentTitle'
import { ChevronRight, ChevronLeft } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

// Import images
import _img1 from '../../assets/Industries/SoftwareAndPlatforms/_img1.png'
import _img2 from '../../assets/Industries/SoftwareAndPlatforms/_img2.png'
import _img3 from '../../assets/Industries/SoftwareAndPlatforms/_img3.png'
import _img4 from '../../assets/Industries/SoftwareAndPlatforms/_img4.png'
import _img5 from '../../assets/Industries/SoftwareAndPlatforms/_img5.png'
import _img6 from '../../assets/Industries/SoftwareAndPlatforms/_img6.png'
import _img7 from '../../assets/Industries/SoftwareAndPlatforms/_img7.png'
import software_img1 from '../../assets/Industries/SoftwareAndPlatforms/software_img1.png'
import software_img2 from '../../assets/Industries/SoftwareAndPlatforms/software_img2.png'

import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'

function SoftwareAndPlatforms() {
    useDocumentTitle("SoftwareAndPlatforms");

    const SoftwareAndPlatformsStats = () => {
        const stats = [
            { value: "70%", description: "of software companies are missing growth opportunities by failing to integrate seamless customer experiences across their offerings." },
            { value: "$2.8T", description: "projected revenue for companies that successfully implement subscription-based platform models by 2030." },
            { value: "88%", description: "of tech leaders see AI-powered automation as a critical factor in driving innovation and operational efficiency." },
            { value: "68%", description: "of executives believe a shift to ecosystem partnerships is essential for sustainable growth in the next five years." }
        ];
        return <StatsGrid title="State of Software & Platforms Today" stats={stats} />;
    };

    const ReinventSoftwareAndPlatforms = () => {
        const cards = [
            {
                title: 'Foster Growth by Tailoring Support for SMBs',
                description: 'Small businesses are the backbone of the economy. Create customized solutions that address their challenges, build trust, and unlock mutual growth opportunities.',
                hasButton: false,
            },
            {
                title: 'Redefining Digital Advertising',
                description: 'Succeed in a Privacy-Driven Market. Privacy regulations are reshaping the digital landscape. Adopt innovative advertising and monetization models to maintain relevance and achieve sustainable growth.',
                hasButton: false,
            },
            {
                title: 'AI-Driven Transformation',
                description: 'Integrate AI for Smarter Business Solutions. Generative AI and machine learning offer endless opportunities for transformation. Ensure your data ecosystem is AI-ready to improve efficiency and decision-making.',
                hasButton: false,
            },
            {
                title: 'Innovating for Tomorrow',
                description: 'Build Agile and Resilient Products. Reimagine product development with cross-functional strategies that focus on agility, scalability, and sustainability to thrive in dynamic markets.',
                hasButton: false,
            },
            {
                title: 'Platform Resilience',
                description: 'Adapt to Regulatory Changes with Confidence. Global regulations and consumer demands are evolving rapidly. Platforms must prioritize trust, compliance, and security to sustain long-term growth and success.',
                hasButton: false,
            }
        ];

        return <ReinventGrid title="Revolutionizing the Future of Software & Platforms" cards={cards} />;
    };

    const SoftwareAndPlatformsTrendingCarousel = () => {
        const slides = [
            {
                title: "Revolutionizing Productivity with Intelligent Tools",
                description: "Software innovations are transforming workplace efficiency by enabling smarter workflows and seamless collaboration. From AI-powered project management tools to integrated communication platforms, these advancements are helping teams streamline processes, reduce redundancies, and enhance productivity. Businesses adopting these intelligent tools are empowering their workforce to focus on strategic tasks, driving growth in an increasingly competitive landscape.",
                image: _img2,
                gradient: "from-purple-600 to-pink-500"
            },
            {
                title: "Driving Sustainability Through Tech Solutions",
                description: "Software platforms are at the forefront of sustainability initiatives, enabling organizations to monitor and reduce their environmental impact. From energy management systems that optimize resource usage to carbon tracking tools that provide actionable insights, technology is helping companies meet their sustainability goals. These innovations not only contribute to environmental preservation but also position businesses as responsible leaders in the global effort toward a greener future.",
                image: _img3,
                gradient: "from-blue-600 to-cyan-500"
            },
            {
                title: "AI-Powered Creativity in Content Platforms",
                description: "Artificial intelligence is revolutionizing creativity by enabling software platforms to generate high-quality, personalized content. From media and entertainment to design and marketing, AI-powered tools are automating content creation, enhancing user experiences, and driving innovation. These advancements are unlocking new possibilities for creators and businesses alike, redefining how creative processes are approached in the digital age.",
                image: _img4,
                gradient: "from-indigo-600 to-purple-500"
            },
            {
                title: "Strengthening Digital Ecosystems for Resilience",
                description: "In an era of market disruptions and rapid change, building resilient digital ecosystems is crucial. Software companies are focusing on creating interconnected platforms that enhance scalability, security, and adaptability. By integrating cloud computing, data analytics, and AI, these ecosystems ensure businesses can withstand challenges and capitalize on emerging opportunities, fostering long-term stability and growth.",
                hasButton: true,
                image: software_img1,
            },
            {
                title: "The Rise of Industry-Specific Software Solutions",
                description: "Tailored software solutions are transforming industries by addressing unique challenges and unlocking new opportunities. From advanced healthcare management systems to AI-driven manufacturing platforms and educational tools, these solutions provide specialized features that drive efficiency and innovation. Businesses adopting industry-specific software are gaining a competitive edge, delivering enhanced value to their stakeholders.",
                hasButton: true,
                image: software_img2,
            }
        ];

        return <TrendingCarousel title="What's Trending in the Software Landscape" slides={slides} />;
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Innovation Award 2024",
                image: _img5,
                alt: "Innovation Award"
            },
            {
                title: "Technology Leader - Forrester",
                image: _img6,
                alt: "Technology Leadership"
            },
            {
                title: "Best Solutions Provider",
                image: _img7,
                alt: "Solutions Provider"
            },
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "John Smith", title: "Global SoftwareAndPlatforms Lead", imageUrl: Cloud_leader_img1 },
            { name: "Sarah Johnson", title: "Digital Innovation Director", imageUrl: Cloud_leader_img1 },
            { name: "Michael Chen", title: "Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Emma Davis", title: "Solutions Lead", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Redefining the Future of Software & Platforms"
                description="Technology at the core of how we connect, work, and innovate. The rapid evolution of digital ecosystems and global shifts have opened new doors for software and platform companies to reshape industries, empower communities, and enhance everyday life."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <SoftwareAndPlatformsStats />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventSoftwareAndPlatforms />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <SoftwareAndPlatformsTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <GlobalRecognition />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent 
                    title="Transforming the Future of Technology and Platforms" 
                    subtitle="Empower technology and platform companies to redefine their strategies by driving innovation, creating exceptional and user-centric experiences, ensuring robust security, and building a foundation for long-term sustainable growth. Lead the way in shaping an agile, customer-focused, and future-ready digital ecosystem." 
                />
            </div>
            <Footer />
        </div>
    );
}

export default SoftwareAndPlatforms
