import React, { useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Industries/PublicService/_coverImg.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents'
import { ContactContent } from '../../components/resuable/SapComponents'
import Footer from '../../components/Footer'
import { useDocumentTitle } from '../../utils/useDocumentTitle'
import { ChevronRight, ChevronLeft, ChevronDown } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

// Import images
import _img1 from '../../assets/Industries/Education/_img1.png'
import _img2 from '../../assets/Industries/Education/_img2.png'
import _img3 from '../../assets/Industries/Education/_img3.png'
import _img4 from '../../assets/Industries/Education/_img4.png'
import _img5 from '../../assets/Industries/Education/_img5.png'
import _img6 from '../../assets/Industries/Education/_img6.png'
import _img7 from '../../assets/Industries/Education/_img7.png'
import _img8 from '../../assets/Industries/Education/edu_img1.png'
// import _img10 from '../../assets/Industries/PublicService/_img10.png'

import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'

function Education() {
    useDocumentTitle("Education");

    const EducationStats = () => {
        const stats = [
            { value: "40%", description: "Increase in adoption of AI-powered learning platforms globally" },
            { value: "65%", description: "Of educators report improved student engagement with digital tools" },
            { value: "75%", description: "Of institutions prioritizing digital transformation initiatives" },
            { value: "50%", description: "Growth in demand for personalized learning experiences" }
        ];
        return <StatsGrid title="Education Evolution Today" stats={stats} />;
    };

    const RevolutionizationSection = () => {
        return (
            <div className="rounded-2xl p-8 flex flex-col md:flex-row items-center gap-8">
                <div className="w-full md:w-1/2">
                    <img
                        src={_img1} // Replace with HLTH24 specific image
                        alt="Revolutionizing Learning with Technology"
                        className="rounded-lg w-full h-auto"
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <h3 className="text-2xl font-bold mb-4">Revolutionizing Learning with Technology</h3>
                    <p className="text-gray-300 mb-6">
                        Education is at the forefront of a technological revolution, reimagining how students learn and interact. This transformation goes beyond digitizing traditional methods—it’s about crafting personalized and immersive learning journeys. From AI-driven adaptive platforms and AR/VR-powered experiences to intelligent tutoring systems and tailored mobile learning apps, technology is creating dynamic and engaging education ecosystems.

                    </p>
                </div>
            </div>
        );
    };

    const EducationInnovations = () => {
        const innovations = [
            {
                title: "Smart Task Automation",
                description: "Automating routine tasks to give educators more time for impactful teaching."
            },
            {
                title: "Conversational AI Assistants",
                description: "Providing students with instant support through AI-driven chatbots."
            },
            {
                title: "Next-Gen AR/VR Learning",
                description: "Creating immersive, interactive learning experiences for deeper engagement."
            },
            {
                title: "Expanding the E-Learning Ecosystem",
                description: "Scaling digital education to make learning accessible and innovative globally."
            }
        ];

        return (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {innovations.map((item, index) => (
                        <div 
                            key={index}
                            className="bg-[#14192E] p-6 rounded-lg border-l-4 border-blue-500 hover:border-blue-400 transition-colors"
                        >
                            <h3 className="text-lg font-semibold mb-3">{item.title}</h3>
                            <p className="text-gray-400 text-sm">{item.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const SegmentsWeSupport = () => {
        const [activeIndex, setActiveIndex] = useState(null);
        const [showDetails, setShowDetails] = useState(false);
        const [selectedSegment, setSelectedSegment] = useState(null);

        const toggleSegment = (index) => {
            setActiveIndex(activeIndex === index ? null : index);
        };

        const toggleDetails = (segment) => {
            setSelectedSegment(segment);
            setShowDetails(true);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 },
        };

        const segments = [
            {
                title: "Industrial and Electrical Equipment",
                description: "Crafting the engineered product of tomorrow—from machinery and industrial equipment to electrical components and beyond.",
                image: _img4,
                details: {
                    keyFeatures: [
                        "Advanced automation solutions",
                        "Smart manufacturing integration",
                        "IoT-enabled equipment monitoring",
                        "Predictive maintenance systems"
                    ],
                    benefits: "Increase operational efficiency by 40% with our smart manufacturing solutions",
                    caseStudy: "Leading manufacturer achieved 65% reduction in downtime"
                }
            },
            {
                title: "Industrial and Automotive Suppliers",
                description: "Revolutionizing automotive manufacturing with cutting-edge solutions for suppliers, focusing on precision, efficiency and quality control.",
                image: _img2,
                details: {
                    keyFeatures: [
                        "Supply chain optimization",
                        "Quality control automation",
                        "Just-in-time inventory management",
                        "Connected factory solutions",
                        "Real-time production monitoring"
                    ],
                    benefits: "Streamline production with 30% improved efficiency and 25% reduced inventory costs",
                    caseStudy: "Major auto supplier reduced waste by 45% and improved quality metrics by 60%"
                }
            },
            {
                title: "Heavy Equipment",
                description: "Empowering construction and mining industries with intelligent heavy machinery solutions focused on productivity and safety.",
                image: _img1,
                details: {
                    keyFeatures: [
                        "Equipment tracking and fleet management",
                        "Predictive maintenance analytics",
                        "Performance optimization systems",
                        "Remote monitoring and diagnostics",
                        "Safety compliance automation"
                    ],
                    benefits: "Enhance equipment lifespan by 35% while reducing operational costs by 25%",
                    caseStudy: "Construction firm saved $2M in maintenance costs and improved safety metrics by 40%"
                }
            },
            {
                title: "Consumer Durables",
                description: "Transforming consumer appliance manufacturing with smart technologies and efficient production processes.",
                image: _img4,
                details: {
                    keyFeatures: [
                        "Smart appliance integration",
                        "Energy efficiency optimization",
                        "Quality assurance automation",
                        "Connected device management",
                        "Sustainable manufacturing practices"
                    ],
                    benefits: "Reduce production costs by 30% while improving product quality by 45%",
                    caseStudy: "Appliance manufacturer increased production efficiency by 50%"
                }
            },
            {
                title: "Freight and Logistics",
                description: "Optimizing supply chain operations with intelligent logistics solutions and real-time tracking capabilities.",
                image: _img5,
                details: {
                    keyFeatures: [
                        "Real-time fleet tracking",
                        "Route optimization",
                        "Warehouse automation",
                        "Inventory management",
                        "Delivery optimization"
                    ],
                    benefits: "Improve delivery efficiency by 40% and reduce operational costs by 35%",
                    caseStudy: "Logistics company reduced delivery times by 50% and fuel costs by 30%"
                }
            },
            {
                title: "Construction, Engineering and Real Estate",
                description: "Delivering innovative solutions for construction projects and property management through digital transformation.",
                image: _img6,
                details: {
                    keyFeatures: [
                        "Project management automation",
                        "BIM integration",
                        "Site safety monitoring",
                        "Resource optimization",
                        "Property management systems"
                    ],
                    benefits: "Reduce project delays by 45% and improve resource utilization by 35%",
                    caseStudy: "Engineering firm reduced project completion time by 40%"
                }
            },
            {
                title: "Business Services",
                description: "Enabling service excellence through digital transformation and process automation solutions.",
                image: _img7,
                details: {
                    keyFeatures: [
                        "Service automation",
                        "Customer relationship management",
                        "Process optimization",
                        "Data analytics"
                    ]
                }
            }
        ];

        return (
            <div className="w-full">
                <h2 className="text-4xl font-bold mb-8 text-center">Segments we support</h2>
                <div className="text-white p-4 sm:p-8 rounded-2xl">
                    {segments.map((segment, index) => (
                        <div key={index} className="mb-4">
                            <div
                                className="flex justify-between items-center cursor-pointer p-4 bg-[#14192E] rounded-t-xl"
                                onClick={() => toggleSegment(index)}
                            >
                                <span className="font-semibold">{segment.title}</span>
                                <motion.div
                                    animate={{ rotate: activeIndex === index ? 180 : 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <ChevronDown />
                                </motion.div>
                            </div>
                            <motion.div
                                animate={{
                                    height: activeIndex === index ? "auto" : 0,
                                    opacity: activeIndex === index ? 1 : 0
                                }}
                                initial={{ height: 0, opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                style={{ overflow: "hidden" }}
                            >
                                <div className="p-4 bg-[#14192E] rounded-b-xl">
                                    <div className="flex flex-col sm:flex-row items-start">
                                        {/* <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                            <div className="bg-purple-900 rounded-lg overflow-hidden">
                                                <img
                                                    src={segment.image}
                                                    alt={segment.title}
                                                    className="w-full h-auto object-cover"
                                                />
                                            </div>
                                        </div> */}
                                        <div className="flex-grow">
                                            <p className="text-gray-400 mb-4">{segment.description}</p>
                                            <div className="flex justify-end">
                                                {/* <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="border-2 border-theme/50 rounded-full p-2"
                                                    onClick={() => toggleDetails(segment)}
                                                >
                                                    <ChevronRight className="text-theme cursor-pointer" size={24} />
                                                </motion.div> */}
                                            </div>
                                            {showDetails && selectedSegment === segment && (
                                                <motion.div
                                                    initial="hidden"
                                                    animate="visible"
                                                    exit="exit"
                                                    variants={slideVariants}
                                                    className="mt-4 p-4 bg-[#1a2036] rounded-xl"
                                                >
                                                    <h3 className="text-xl font-bold mb-3">Key Features:</h3>
                                                    <ul className="list-disc pl-5 mb-4">
                                                        {segment.details.keyFeatures.map((feature, i) => (
                                                            <li key={i} className="mb-2">{feature}</li>
                                                        ))}
                                                    </ul>
                                                    <p className="mb-3"><strong>Benefits:</strong> {segment.details.benefits}</p>
                                                    <p><strong>Case Study:</strong> {segment.details.caseStudy}</p>
                                                </motion.div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const ReinventEducation = () => {
        const cards = [
            {
                title: 'Empowering Remote and Hybrid Learning',
                description: 'Adopting cutting-edge cloud technologies and virtual classrooms to enable dynamic, interactive learning experiences from anywhere in the world',
                hasButton: true,
            },
            {
                title: 'Personalized Learning Pathways',
                description: "Integrating AI-powered tools to create customized lesson plans and adaptive content tailored to each student's unique learning pace and preferences",
                hasButton: true,
            },
            {
                title: 'Inclusive Digital Learning',
                description: 'Implementing accessibility-focused technologies like screen readers, voice recognition, and multilingual support to ensure education reaches everyone, everywhere',
                hasButton: true,
            },
            {
                title: 'Actionable Learning Insights',
                description: 'Using advanced analytics to track student performance, identify gaps, and provide targeted strategies for academic improvement',
                hasButton: true,
            },
            {
                title: 'Secure Digital Education Platforms',
                description: 'Fortifying educational systems with top-tier cybersecurity measures to protect student data and maintain a safe digital learning environment',
                hasButton: true,
            }
        ];

        return <ReinventGrid title="Strategic Innovations: IT Goals for Transforming Education" cards={cards} />;
    };

    const EducationTrendingCarousel = () => {
        const slides = [
            {
                title: "🔧 Mastering IT Disaster Recovery Planning",
                description: "A comprehensive guide to developing a robust IT disaster recovery strategy to ensure business resilience and data security during unexpected outages.",
                image: _img5,
                gradient: "from-purple-600 to-pink-500"
            },
            {
                title: "🤖 Transforming Operations with AI-Driven Automation",
                description: "Exploring how generative AI is reshaping business processes, driving efficiency, and fostering innovation across industries.",
                image: _img6,
                gradient: "from-blue-600 to-cyan-500"
            },
            {
                title: "🌐 Building a High-Performance Cloud-Based Content Delivery Network",
                description: "Strategies for deploying scalable and efficient content delivery networks in the cloud to enhance digital media distribution and user experience.",
                image: _img7,
                gradient: "from-indigo-600 to-purple-500"
            },
            {
                title: "📦 Navigating the Container Lifecycle Management",
                description: "An in-depth look at the best practices for managing the lifecycle of containers, from creation to deployment, to optimize application performance and scalability.",
                image: _img8,
                gradient: "from-green-600 to-teal-500"
            }
        ];

        return <TrendingCarousel title="Tech Insights" slides={slides} />;
    };






    return (
        <div className='bg-bg text-white'>
            <Header
                title="Innovation in Action: Transforming the Education Industry"
                description="Pioneering advancements are reshaping the digital future of education. By integrating technology, creativity, and adaptability, the industry is evolving to meet the needs of learners worldwide."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <EducationStats />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <RevolutionizationSection />
            </div>
            <EducationInnovations />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventEducation />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <EducationTrendingCarousel />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent title="Tailored Solution for the Education Industry'sTechnology Needs. Any Cloud,Any Platform, Any Ecosystem" />
            </div>
            <Footer />
        </div>
    );
}

export default Education
