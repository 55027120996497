import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import sapConsultingCover from '../assets/SAP/sapConsultionCover.png'
import SapConsultingImg1 from '../assets/SAP/sapConsultionImg1.png'
import SapConsultingImg2 from '../assets/SAP/SapConsultingImg2.png'
import SapConsultingImg3 from '../assets/SAP/SapConsultingImg3.png'
import SapConsultingImg4 from '../assets/SAP/SapConsultingImg4.png'
import { ContactContent } from '../components/resuable/SapComponents'
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'
import { Link } from 'react-router-dom'

function SapConsulting() {
    const headerData = {
        title: "Expertise in Cloud Transformation and Innovation",
        description: "DIAA Corp, with its cloud solutions and industry expertise, will help revolutionize your business through seamless cloud migration and digital innovation."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const SAPServiceOrg = () => {
        return (
            <div className="text-white p-8">
                <div className="container mx-auto flex flex-col lg:flex-row md:space-x-12">
                    <div className="lg:w-2/3 pr-8 ">
                        <h1 className="text-2xl md:text-3xl font-bold mb-4">DIAA CORP Cloud Service Organization</h1>
                        <p className="mb-4 mobile:text-sm">
                            DIAA CORP provides both traditional business consulting and advanced cloud consulting services. Our cloud consultants primarily focus on cloud architecture and technology consulting, in addition to the implementation of cloud-based solutions. We manage projects across all stages, ensuring successful migration and management of applications in the cloud.
                        </p>
                        <p className="mb-4 mobile:text-sm">
                            This includes strategic cloud consulting, proof of concepts through project management, requirements management, implementation consulting, customization, cloud programming, implementation support, training, migration, data management, cloud security, and business intelligence.
                        </p>
                        <p className='mobile:text-sm'>
                            Our cloud specialists ensure the continuous operation and further development of our clients' cloud environments, using proven IT standards to ensure seamless cloud integration.
                        </p>
                    </div>
                    <div className="lg:w-1/3 mt-8 lg:mt-0">
                        <div className="relative h-80">
                            <img src={SapConsultingImg1} alt="Meeting" className="absolute top-0 right-0 w-3/4 h-3/4 object-cover rounded-lg shadow-lg" />
                            <img src={SapConsultingImg2} alt="Analysis" className="absolute bottom-0 left-0 w-3/4 h-3/4 object-cover rounded-lg shadow-lg" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const SAPConsultingInfos = () => {
        const cards = [
            {
                title: "Building a Robust Cloud Strategy",
                content: "A well-planned cloud strategy should focus on the evolving needs of your business, especially in an era where digital transformation is reshaping business operations. Our cloud consultants place your business priorities at the forefront, offering more than just technical guidance. Our experts assist you in creating a detailed cloud roadmap and enhancing your business processes to pave the way for successful cloud adoption.",
            },
            {
                title: "The Power of Scalable Cloud Architecture",
                content: "An ideal cloud architecture must not only provide high availability and performance but also ensure flexibility, scalability, and cost-effectiveness. With the advent of modern cloud technologies, more powerful and efficient solutions are available at lower costs, allowing businesses to scale quickly while maintaining system performance. Our cloud strategy enables the deployment of cost-effective solutions, including disaster recovery and backup plans, ensuring resilience and scalability.",
            },
            {
                title: "Industry-Specific Expertise for Cloud Transformation",
                content: "To fully harness the potential of cloud technology, businesses need solutions that are tailored to their industry needs. Our cloud consultants bring deep industry expertise to help you align your IT strategy with business goals. We provide cloud solutions that are industry-specific, enabling you to enhance performance, reduce costs, and ensure seamless cloud adoption.",
            },
            {
                title: "Modernizing IT with Cloud-Based Solutions",
                content: "The rapid growth of data and the pressure to adapt to digitalization are driving businesses to rethink their IT strategies. With cutting-edge cloud platforms and technologies like AWS, Azure, and Google Cloud, businesses can modernize their operations, improve data handling, and increase agility.",
            },
        ];



        return (
            <div className="text-white md:w-11/12 mx-auto mt-12 md:mt-24">
                <div className="mx-auto">
                    <h1 className="text-2xl md:text-4xl font-bold text-center mb-2 md:mb-6">SAP Consulting</h1>
                    <p className="text-center text-gray-400 mb-12 mobile:text-md">Approach to align your IT Strategy with business goals</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {cards.map((card, index) => (
                            <div key={index} className="relative p-6 rounded-lg overflow-hidden md:w-4/5 mx-auto">
                                <div className="absolute inset-0 bg-custom-gradient opacity-80 rounded-lg"></div>
                                <div className="absolute inset-[1px] bg-bg rounded-lg"></div>
                                <div className="relative z-10">
                                    <h2 className="md:text-xl font-semibold mb-4">{card.title}</h2>
                                    <p className="text-sm text-gray-300 mb-4">{card.content}</p>
                                    {/* <a href="#" className="text-theme text-sm flex items-center">
                                        Read more
                                        <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </a> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const DifferentiatingPillars = () => {
        return (
            <div className="relative md:h-screen w-full bg-cover bg-center flex items-center justify-center mt-12 md:mt-24 pt-12 md:pt-24">
                <div className='absolute top-0 left-0 w-full h-full'
                    style={{
                        background: `url(${SapConsultingImg4})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        filter: 'blur(2px)',
                    }}></div>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative z-10 text-white text-center md:w-4/5">
                    <h1 className="text-xl md:text-4xl font-bold mb-8">Global Impact with Over 4000 Clients in 25 Countries</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-12 md:my-24">
                        {[ 
                            { icon: '🏦', title: 'Comprehensive Expertise Across Industries', subtitle: 'Our IT consulting team consists of specialists with deep knowledge across diverse sectors. Whether you are in multinational manufacturing, telecommunications with large subscriber bases, wholesale distribution, or healthcare innovation, our consultants understand your unique challenges.' },
                            { icon: '🚗', title: 'Commitment to Full Transparency', subtitle: 'We believe in keeping you informed every step of the way. From regular updates on quality checks and solution prototypes to detailed progress reports, our SAP consultants ensure you know exactly where the project stands.' },
                        ].map((item, index) => (
                            <div key={index} className="bg-[#3C3C3C] bg-opacity-50 p-4 rounded-lg flex items-center md:py-12 md:px-12 w-11/12 mx-auto">
                                <div className="text-left">
                                    <h3 className="font-semibold text-lg md:text-xl mb-6 text-center">{item.title}</h3>
                                    <p className="text-sm md:text-center">{item.subtitle}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };




    const ArchitectureAndTechnology = () => {
        const services = [
            { icon: '🔄', text: 'Deployment of New and Upgraded SAP Systems Including RISE and Trial services' },
            { icon: '🔍', text: 'SAP Technology Consulting Focused on cutting-edge solutions' },
            { icon: '🖥️', text: 'Design of Complex SAP System Landscapes Tailored to your needs' },
            { icon: '🌐', text: 'SAP System Landscape Virtualization Optimizing system performance' },
            { icon: '🔁', text: 'Migration Services Includes HANA and S/4HANA migrations' },
            { icon: '🔗', text: 'External Systems Integration Ensuring seamless data flow' },
            { icon: '📊', text: 'Evaluation and Audits of Existing SAP Systems Including SAP Security Audits' },
        ];

        const partnerFeatures = [
            { icon: '🔧', text: 'Expertise in Managing SAP Workloads' },
            { icon: '🌍', text: 'Serving Over 4000 Customers Globally Including 65 Fortune 500 companies and 50 FT 500 Indian companies' },
            { icon: '🤝', text: 'A Leading Partner in SAP HANA on Cloud Supporting over 1800 enterprises globally' },
            { icon: '📈', text: 'Managing SAP HANA Workloads Handling over 450 clients with up to 25 TB and scale-out nodes up to 64 TB' },
            { icon: '🔄', text: 'Comprehensive SAP Solutions and Database Support' },
        ];

        return (
            <div className="w-full text-white mt-12 md:mt-24 mobile:px-2">
                <div className=" mx-auto">

                    <h1 className="text-2xl md:text-4xl font-bold text-center mb-2">Architecture & Technology</h1>
                    <p className="text-center text-gray-400 mb-12 mobile:text-sm">A bird's eye-view into our SAP services scope</p>

                    <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-12 md:w-11/12 mx-auto ">
                        {services.map((service, index) => (
                            <div key={index} className="flex flex-col items-center">
                                <div className="relative mobile:w-12 mobile:h-12 w-16 h-16 mb-8">
                                    <div className="absolute inset-0 bg-gradient-to-r from-[#a100ff] to-[#57CFFF] rounded-full opacity-80"></div>
                                    <div className="absolute inset-1 bg-[#0F1A42] rounded-full flex items-center justify-center md:text-2xl">
                                        {service.icon}
                                    </div>
                                </div>
                                <p className="text-center text-sm mb-4 md:mb-8">{service.text}</p>
                            </div>
                        ))}
                    </div>

                    <div className='flex flex-col justify-center items-center text-center  bg-[#14192E] h-[293px] px-4 my-12 md:my-24 border-x-4 border-x-theme'>
                        <div className='md:w-4/5'>
                            <p className="text-md md:text-xl font-normal mb-12">
                                DIAA Corp helps in the selection of tools based on business criteria such as TCO Optimization,
                                Design and Architecture consulting, License Optimization and Business Process Outsourcing. Even with complex SAP projects, our SAP consultants support you with their many years of project experience.
                            </p>
                        </div>
                    </div>

                    <div>

                        <h2 className="text-xl md:text-3xl font-bold text-center mb-4">An Experienced and Trusted SAP Partner</h2>
                        <p className="text-center text-gray-400 mb-12 mobile:text-md">
                            DIAA Corp is committed to providing a continuous, secure and effective environment
                            with end-to-end management for your SAP workloads.
                        </p>
                    </div>


                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 md:w-11/12 mx-auto mt-12 md:mt-24">
                        {partnerFeatures.map((feature, index) => (
                            <div key={index} className="flex flex-col items-center">
                                <div className="relative w-16 h-16 mb-4">
                                    <div className="absolute inset-0 bg-gradient-to-r from-[#a100ff] to-[#57CFFF] rounded-full opacity-80"></div>
                                    <div className="absolute inset-1 bg-[#0F1A42] rounded-full flex items-center justify-center text-2xl">
                                        {feature.icon}
                                    </div>
                                </div>
                                <p className="text-center text-sm">{feature.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div >
        );
    };


    const ServiceOfferings = () => {
        const services = [
            {
                title: 'Implementation',
                description: 'Bringing best practices and methodologies to design and implementation',
            },
            {
                title: 'Support',
                description: 'Continuous support, enhancement, and upgrade services to keep your systems running smoothly',
            },
            {
                title: 'Custom Development',
                description: 'Tailored development to match your unique business needs',
            },
            {
                title: 'Integration',
                description: 'Seamless integration solutions to maximize efficiency and interoperability',
            },
        ];

        return (
            <div className="py-12 sm:py-16 md:py-20 lg:py-24 flex items-center justify-center bg-[#14192E80] bg-opacity-50 mt-8 sm:mt-12 md:mt-16 lg:mt-24">
                <div className="container mx-auto px-4">
                    <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white text-center mb-6 sm:mb-8 md:mb-10 lg:mb-12">
                        Other SAP Service Offerings
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mt-8 sm:mt-12 md:mt-16 lg:mt-20 justify-items-center">
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className="bg-[#51566E] bg-opacity-30 rounded-lg p-4 sm:p-6 flex flex-col justify-between w-full max-w-[278px] h-[180px] sm:h-[194px] text-center"
                            >
                                <div>
                                    <h3 className="text-lg sm:text-xl font-semibold text-white mb-2 sm:mb-4">{service.title}</h3>
                                    <p className="text-gray-300 mb-2 sm:mb-4 text-xs sm:text-sm">{service.description}</p>
                                </div>
                                {/* <button className="text-theme hover:text-blue-300 transition-colors text-sm sm:text-base">
                                    Know more →
                                </button> */}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };



    const WinningPath = () => {
        return (
            <div className=" flex items-center justify-center px-4 py-16 md:my-24">
                <div className="max-w-4xl text-center">
                    <h1 className="text-xl md:text-4xl lg:text-5xl font-bold text-white mb-6">
                        Your Path to SAP Success Starts Here
                    </h1>
                    <p className="text-gray-300 mb-8 text-sm md:text-base mt-6 md:mt-12">
                        Ready to optimize your IT systems but need expert guidance? Our consultants help you lay the groundwork for a successful SAP project. With detailed analysis, feasibility studies, and cost estimates, we ensure your project is set up for success from day one.
                    </p>
                    <Link to="/workshop-signup" className="w-56 md:w-72 py-4 px-4 text-sm md:text-base mx-auto hover:bg-custom-gradient" style={{
                        border: "2px solid #a100ff",
                        borderRadius: "9999px",
                        display: "inline-block",
                        backgroundOrigin: "border-box",
                    }}>
                        Sign up for free workshop
                    </Link>
                </div>
            </div>
        );
    };


    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} isExploreBtn={true} backgroundImage={sapConsultingCover} />

            <div className='md:w-11/12 mt-12 md:mt-24'>
                <SAPServiceOrg />
            </div>

            <div>
                <SAPConsultingInfos />
            </div>

            <div>
                <DifferentiatingPillars />
            </div>

            <div>
                <ArchitectureAndTechnology />
            </div>

            <div>
                <ServiceOfferings />
            </div>

            <div>
                <WinningPath />
            </div>

            <div>
                <ContactContent title={`Experience our expertise before you embark upon any transformation journey`} />
            </div>

            <div>
                <Footer />
            </div>
        </div>
    )
}

export default SapConsulting