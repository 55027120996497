import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import headerImage from '../assets/SAP/sap-analytics-cloud_cover.png'
import sapAnalyticsCloud_img1 from '../assets/SAP/sap-analytics-cloud_img1.png'
import sapAnalyticsCloud_img2 from '../assets/SAP/sap-analytics-cloud_img2.png'
import sapAnalyticsCloud_img3 from '../assets/SAP/sap-analytics-cloud_img3.png'
import sapAnalyticsCloud_img4 from '../assets/SAP/sap-analytics-cloud_img4.png'
import { Clock, DollarSign, Cloud, Globe, TrendingUp, Search, PieChart, Target, Users, MessageCircle, UserCheck, FileText } from 'lucide-react';
import { ContactContent } from '../components/resuable/SapComponents'
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'

function SAPAnalyticsCloud() {

    useDocumentTitle("SAP Analytics");


    const SAPDrivenAnalyticsCard = () => {
        return (
            <div className="text-white p-4 sm:p-8 rounded-lg flex flex-col md:flex-row items-center">
                <div className="md:w-1/3 mr-0 md:mr-8 mb-6 md:mb-0">
                    <img
                        src={sapAnalyticsCloud_img1}
                        alt="SAP Powered Analytics"
                        className="rounded-lg w-full h-auto"
                    />
                </div>
                <div className="md:w-2/3">
                    <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-center md:text-left">
                        SAP-Powered Analytics
                    </h2>
                    <p className="text-sm sm:text-base mb-4 sm:mb-6">
                        In today’s dynamic business landscape, organizations demand more from their analytics solutions. The need for customized services and real-time, on-the-fly analysis is critical to staying competitive. With a wealth of customer and operational data streaming through networks, businesses must transform this data into actionable insights that are simple, swift, and intuitive.
                    </p>
                    <p className="text-sm sm:text-base">
                        DIAA Corp combines deep functional and industry expertise with powerful SAP tools to deliver transformative analytics solutions. By enabling exceptional customer experiences, streamlining operations, reducing costs, and driving revenue growth, we empower organizations to thrive in an increasingly data-driven world.
                    </p>
                </div>
            </div>
        );
    };



    const AnalyticsSolutionsPortfolio = () => {
        return (
            <div className="p-4 sm:p-8 text-white">
                <h1 className="text-3xl sm:text-4xl font-bold text-center mb-4">Our Analytics Solutions Portfolio</h1>
                <p className="text-center mb-8 text-gray-300 text-sm sm:text-base">Deploy On-premise, On Cloud or adopt a Hybrid model</p>

                <div className="space-y-6">
                    <div className="bg-custom-gradient rounded-3xl overflow-hidden shadow-lg">
                        <div className="flex flex-col md:flex-row">
                            <div className="bg-gray-900 p-6 md:w-2/5 rounded-l-3xl">
                                <h2 className="text-2xl font-semibold mb-4">SAP Analytics Cloud</h2>
                                <p className="text-sm">
                                    SAP Analytics Cloud is a cutting-edge Software-as-a-Service (SaaS) platform designed to deliver comprehensive analytical capabilities in a single solution. As a trusted SAP Analytics Partner, DIAA Corp collaborates with customers to extract actionable insights from complex data sources. Our streamlined processes empower informed decision-making to enhance organizational objectives.
                                </p>
                            </div>
                            <div className="p-6 md:w-3/5">
                                <ul className="space-y-4">
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Consolidate and transform data: Gain a holistic and integrated view of your business.</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Uncover trends and insights: Identify hidden patterns to make data-driven decisions.</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Enable real-time updates: Schedule automated data refreshes for live updates.</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Enhance mobility: Customize data access for seamless mobile usage.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="bg-custom-gradient rounded-3xl overflow-hidden shadow-lg">
                        <div className="flex flex-col md:flex-row">
                            <div className="bg-gray-900 p-6 md:w-2/5 rounded-l-3xl">
                                <h2 className="text-2xl font-semibold mb-4">SAP BW/4HANA</h2>
                                <p className="text-sm">
                                    SAP BW/4HANA, in conjunction with SAP Data Hub, SAP Analytics Cloud (SAC), and other analytics tools, enables businesses to maximize the value of their data across diverse scenarios. DIAA Corp specializes in deploying BW/4HANA on-premises, in the cloud, or through a hybrid model tailored to your needs.
                                </p>
                            </div>
                            <div className="p-6 md:w-3/5">
                                <ul className="space-y-4">
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Leverage advanced data warehousing: Harness SAP’s state-of-the-art data management solutions.</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Achieve faster ROI: Transition swiftly from ownership to utilization for accelerated returns.</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Mitigate project risks: Benefit from standardized, scalable delivery by experienced teams.</span>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="mr-2 mt-1 bg-purple-400 rounded-full p-1"></div>
                                        <span>Optimize execution: Complete projects faster and at reduced costs and risks compared to traditional methods.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
    };

    const BenefitCard = ({ icon, text }) => (
        <div className="flex flex-col items-center bg-gray-800 rounded-lg p-4 text-center">
            <div className="bg-custom-gradient rounded-full p-3 mb-4">
                {icon}
            </div>
            <p className="text-sm">{text}</p>
        </div>
    );

    const DIAACorpBenefits = () => {
        const benefits = [
            {
                icon: <Clock className="w-6 h-6 text-white" />,
                text: "Achieve up to 40% faster implementation compared to traditional in-house projects."
            },
            {
                icon: <DollarSign className="w-6 h-6 text-white" />,
                text: "Benefit from a highly optimized delivery approach, reducing project expenses by up to 35%."
            },
            {
                icon: <Cloud className="w-6 h-6 text-white" />,
                text: "Leverage the expertise of DIAA Corp's SAP Migration Factory and S/4HANA CoE for top-tier project execution."
            },
            {
                icon: <Globe className="w-6 h-6 text-white" />,
                text: "Access a network of global resources and SAP product specialists."
            }
        ];

        return (
            <div className="text-white p-8">
                <h2 className="text-3xl font-bold text-center mb-4">
                    Quick Benefits You Gain with DIAA Corp
                </h2>
                <p className="text-center mb-8 max-w-3xl mx-auto">
                    DIAA Corp is dedicated to delivering a seamless, secure, and efficient environment with end-to-end management for your SAP workloads.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {benefits.map((benefit, index) => (
                        <BenefitCard key={index} icon={benefit.icon} text={benefit.text} />
                    ))}
                </div>
            </div>
        );
    };


    const DataCard = ({ percentage, description }) => (
        <div className="relative bg-[#14192E] p-6 rounded-lg overflow-hidden">
            <div className="absolute left-0 bottom-0 w-1 h-full bg-gradient-to-b from-blue-500 to-purple-500"></div>
            <div className="absolute left-0 bottom-0 w-full h-1 bg-gradient-to-r from-blue-500 to-purple-500"></div>
            <h3 className="text-5xl font-bold mb-4 bg-custom-gradient bg-gradient-to-b from-theme to-transparent bg-clip-text text-transparent">
                {percentage}%
            </h3>
            <p className="text-sm text-gray-300">{description}</p>
        </div>
    );

    const SAPMasterDataGovernance = () => {
        const data = [
            {
                percentage: 60,
                description: "of CFOs identify clean, reliable data as a cornerstone for strategic decision-making."
            },
            {
                percentage: 25,
                description: "of companies actively measure and maintain master data quality."
            },
            {
                percentage: 65,
                description: "of executives report frequent errors or inconsistencies in the data they rely on."
            }
        ];

        return (
            <div className="text-white p-8">
                <h2 className="text-3xl font-bold text-center mb-2">SAP Master Data Governance</h2>
                <p className="text-center text-gray-400 mb-8">Ensuring high-quality master data is critical to business success.</p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {data.map((item, index) => (
                        <DataCard key={index} percentage={item.percentage} description={item.description} />
                    ))}
                </div>
            </div>
        );
    };

    const FeatureCard = ({ title, subtitle, features }) => (
        <div className=" rounded-lg p-6 border border-theme shadow-lg relative">
            <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
            <p className="text-sm text-gray-300 mb-4">{subtitle}</p>
            <ul className="space-y-2 mt-4">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-center text-white">
                        <span className="w-2 h-2 bg-theme rounded-full mr-3"></span>
                        {feature}
                    </li>
                ))}
            </ul>
        </div>
    );


    const SAPServices = () => {
        const featureCards = [
            {
                title: "SAP Analytics Audit Services",
                subtitle: "Ensuring Compliance and Data Integrity",
                features: [
                    "License Compliance: Ensuring adherence to SAP licensing agreements to avoid penalties.",
                    "Industry Standard Alignment: Verifying that systems comply with regulatory and industry-specific requirements.",
                    "Legal Adherence: Meeting all necessary legal and organizational guidelines.",
                    "Quality Assurance Monitoring: Regular audits to maintain high standards of data quality and performance."
                ]
            },
            {
                title: "Integration and Support Services",
                subtitle: "Enhancing Connectivity and Performance",
                features: [
                    "Proactive System Support: Identifying and resolving potential issues before they impact performance.",
                    "Continuous Monitoring and Upgrades: Offering enhancement services to keep systems optimized and future-ready.",
                    "Comprehensive Suite Implementation: Supporting the deployment and maintenance of the SAP Analytics Suite.",
                    "Custom Dashboards and Reporting: Building intuitive, user-friendly dashboards and reports for better decision-making."
                ]
            }
        ];

        return (
            <div className="p-8 py-12 2xl:py-24 flex justify-center items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl">
                    {featureCards.map((card, index) => (
                        <FeatureCard key={index} title={card.title} subtitle={card.subtitle} features={card.features} />
                    ))}
                </div>
            </div>
        );
    };

    const KeyBenefits = () => {
        const benefits = [
            {
                icon: <TrendingUp className="w-8 h-8 text-purple-500" />,
                title: "Data-Driven Precision",
                text: "Evaluate Success: Accurately assess and refine business strategies with advanced analytics."
            },
            {
                icon: <Search className="w-8 h-8 text-purple-500" />,
                title: "Uncover Insights",
                text: "Dive into intricate business scenarios with speed and clarity."
            },
            {
                icon: <PieChart className="w-8 h-8 text-purple-500" />,
                title: "Faster Decisions",
                text: "Reduce decision-making time through real-time, actionable insights."
            },
            {
                icon: <Target className="w-8 h-8 text-purple-500" />,
                title: "Empowered Decision-Making",
                text: "Smarter Choices: Enable informed, data-driven decisions for better outcomes."
            },
            {
                icon: <Users className="w-8 h-8 text-purple-500" />,
                title: "User-Friendly Tools",
                text: "Access intuitive interfaces that simplify complex analytics."
            },
            {
                icon: <TrendingUp className="w-8 h-8 text-purple-500" />,
                title: "Strategic Planning",
                text: "Enhance forecasting and planning with comprehensive data visibility."
            },
            {
                icon: <MessageCircle className="w-8 h-8 text-purple-500" />,
                title: "Enhanced Collaboration",
                text: "Collaborative Solutions: Improve team communication and alignment with shared insights."
            },
            {
                icon: <UserCheck className="w-8 h-8 text-purple-500" />,
                title: "Proven Expertise",
                text: "Partner with a trusted provider committed to delivering exceptional value."
            }
        ];

        return (
            <div className="text-white p-8">
                <h2 className="text-3xl font-bold text-center mb-4">
                    Key Advantages
                </h2>
                <p className="text-center mb-8 max-w-3xl mx-auto">
                    Leveraging SAP for Superior Business Performance
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {benefits.map((benefit, index) => (
                        <div key={index} className="bg-[#14192E] rounded-lg p-6 flex flex-col items-center text-center">
                            <div className="mb-4">
                                {benefit.icon}
                            </div>
                            <h3 className="text-lg font-semibold mb-2">{benefit.title}</h3>
                            <p className="text-sm text-gray-300">{benefit.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    const HighlightCard = ({ number, text }) => (
        <div className="bg-gradient-to-br from-[#21263B] to-[#0E102B] p-6 rounded-lg text-center w-full h-40 flex flex-col justify-center">
            <p className="text-5xl font-bold bg-custom-gradient bg-gradient-to-b from-theme to-transparent bg-clip-text text-transparent mb-4">{number}</p>
            <p className="text-white text-sm">{text}</p>
        </div>
    );

    const DIAACorpHighlights = () => (
        <div className="text-white py-16 px-8 font-sans">
            <h2 className="text-3xl font-bold mb-4 text-center">
                DIAA Corp SAP Data and Analytics Expertise Highlights
            </h2>
            <p className="text-center mb-12 text-sm text-gray-300">
                We are proud of our skilled team and the impactful projects we’ve delivered to help businesses achieve success with SAP solutions.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
                <HighlightCard number="20+" text="20+ HANA-Based Projects" />
                <HighlightCard number="100+" text="100+ Successful Large-Scale Implementations" />
                <HighlightCard number="50+" text="50+ Certified SAP Experts" />
                <HighlightCard number="10+" text="10+ Projects Exceeding 1000 Person-Days" />
            </div>
        </div>
    );

    const ServiceCard = ({ text }) => (
        <div className="bg-[#14192E] border border-theme/50 rounded-2xl p-4 flex items-center space-x-3 h-[72px] w-[220px]">
            <FileText className="text-purple-500" size={24} />
            <span className="text-white text-sm">{text}</span>
        </div>
    );

    const DIAACorpServices = () => (
        <div className="text-white p-8 font-sans">
            <h2 className="text-2xl font-bold mb-6 text-center max-w-3xl mx-auto">
                Comprehensive SAP Analytics Solutions by DIAA CORP
            </h2>
            <p className="text-center mb-4 text-sm text-gray-300">
                We offer a full suite of services to help businesses optimize their SAP environments and harness the power of data.
            </p>
            <h3 className="text-lg font-semibold mb-4 text-center">Our Key Services Include:</h3>
            <div className="flex flex-wrap justify-center gap-4">
                <ServiceCard text="SAP Migration & Modernization" />
                <ServiceCard text="Performance Optimization" />
                <ServiceCard text="Data Quality Management" />
                <ServiceCard text="Enterprise System Integration" />
                <ServiceCard text="Data Warehousing Solutions" />
                <ServiceCard text="SAP HANA Application Development" />
                <ServiceCard text="Agile Data Visualization" />
                <ServiceCard text="Ongoing Support & Maintenance" />
                <ServiceCard text="Advanced Analytics & Insights" />
            </div>
        </div>
    );

    const CoreExpertise = () => {
        const categories = [
            {
                title: "SAP Business Intelligence & Data Management",
                items: [
                    {
                        name: "SAP BW/4HANA",
                        description: "Advanced data warehousing and analytics with SAP's in-memory platform"
                    },
                    {
                        name: "SAP BOBJ Platform", 
                        description: "Powerful business intelligence solutions for reporting and data analysis"
                    },
                    {
                        name: "SAP Cloud Analytics",
                        description: "Scalable and flexible analytics solutions in the cloud for real-time insights"
                    },
                    {
                        name: "HANA Analytics",
                        description: "High-performance analytics using SAP HANA's in-memory processing capabilities"
                    },
                    {
                        name: "SAP IQ/Sybase",
                        description: "Optimized data storage and management solutions for real-time analytics"
                    }
                ]
            },
            {
                title: "Business Intelligence Tools & Integrations",
                items: [
                    {
                        name: "Power BI / SAP CAR",
                        description: "Seamless integration of Power BI with SAP Customer Activity Repository for advanced analytics"
                    },
                    {
                        name: "Tableau / QlikView",
                        description: "Leading visualization tools for intuitive, interactive reporting"
                    },
                    {
                        name: "Webi / BOA Analytics",
                        description: "Web Intelligence and BusinessObjects Analytics for deep insights into business performance"
                    }
                ]
            }
        ];

        return (
            <div className="flex flex-col items-center justify-center p-4">
                <h2 className="text-2xl font-bold text-white mb-8">Core Expertise at DIAA CORP</h2>
                <div className="flex flex-col gap-8 max-w-7xl w-full">
                    {categories.map((category, catIndex) => (
                        <div key={catIndex} className="w-full">
                            <h3 className="text-xl font-semibold text-white mb-4">{category.title}</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {category.items.map((item, itemIndex) => (
                                    <div
                                        key={itemIndex}
                                        className="relative bg-[#1D1F3F] text-white p-6 rounded-2xl overflow-hidden flex flex-col"
                                    >
                                        <div className="absolute inset-0">
                                            <div className="absolute left-0 top-0 bottom-0 w-1 bg-gradient-to-b from-[#4F75FF] to-[#A100FF]" />
                                            <div className="absolute right-0 top-0 bottom-0 w-1 bg-gradient-to-b from-[#A100FF] to-[#4F75FF]" />
                                        </div>
                                        <h4 className="relative z-10 text-base font-medium mb-2">{item.name}</h4>
                                        <p className="relative z-10 text-sm text-gray-300">{item.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Transforming Business with SAP Analytics"
                description="DIAA CORP and SAP empower leading organizations to harness the potential of analytics, optimizing investments while staying ahead in innovation."
                backgroundImage={headerImage}
                isExploreBtn={true}
            />

            <div className='w-11/12 mx-auto'>
                <SAPDrivenAnalyticsCard />
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <AnalyticsSolutionsPortfolio />
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <DIAACorpBenefits />
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <SAPMasterDataGovernance />
            </div>

            <div className="relative bg-indigo-900 py-24 bg-opacity-90 bg-cover bg-center mt-20" style={{ backgroundImage: `url(${sapAnalyticsCloud_img2})` }}>
                <div className="absolute inset-0 bg-black opacity-10"></div>
                <div className="relative z-10 container mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="bg-[#6161614D] bg-opacity-20 backdrop-filter backdrop-blur-md rounded-lg p-6">
                            <h2 className="text-2xl font-bold text-white text-center mb-6">Unlocking the Power of SAP Master Data Governance (MDG)</h2>
                            <p className="text-white mb-4">SAP MDG provides businesses with a robust framework to manage and maintain high-quality master data, enabling better decision-making and operational efficiency.</p>
                            <h3 className="text-lg font-semibold text-white mb-4">Key Benefits of SAP MDG</h3>
                            <ul className="space-y-4 text-white">
                                <li className="flex items-center">
                                    <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                    <span>Unified Data Management: Create a single source of truth across all business units.</span>
                                </li>
                                <li className="flex items-center">
                                    <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                    <span>Streamlined Operations: Enable faster, automated workflows for data creation and updates.</span>
                                </li>
                                <li className="flex items-center">
                                    <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                    <span>Enhanced Data Accuracy: Ensure consistent and reliable data across SAP and non-SAP systems.</span>
                                </li>
                                <li className="flex items-center">
                                    <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                    <span>Compliance and Governance: Enforce global and local compliance standards with ease.</span>
                                </li>
                                <li className="flex items-center">
                                    <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                    <span>Cost Optimization: Minimize expenses associated with manual data correction and duplication.</span>
                                </li>
                                <li className="flex items-center">
                                    <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                    <span>Improved Insights: Boost decision-making capabilities with reliable and clean data.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="bg-[#6161614D] bg-opacity-20 backdrop-filter backdrop-blur-md rounded-lg p-6">
                            <h2 className="text-2xl font-bold text-white text-center mb-6">Why Choose DIAA Corp for SAP MDG Implementation?</h2>
                            <ul className="space-y-4 text-white">
                                <li className="flex items-center">
                                    <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                    <span>Comprehensive Expertise: Our team excels in areas such as finance, procurement, customer data, and more.</span>
                                </li>
                                <li className="flex items-center">
                                    <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                    <span>End-to-End Solutions: From initial blueprinting to full-scale production, we handle every phase of the project.</span>
                                </li>
                                <li className="flex items-center">
                                    <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                    <span>Innovative Methodologies: Leverage best practices and advanced tools to maximize data quality.</span>
                                </li>
                                <li className="flex items-center">
                                    <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                    <span>Extensive Experience: Successfully delivered numerous MDG projects across diverse industries.</span>
                                </li>
                                <li className="flex items-center">
                                    <span className="w-2 h-2 bg-custom-gradient rounded-full mr-3"></span>
                                    <span>Dedicated Support: Benefit from ongoing guidance to maintain and enhance your MDG framework.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className=''>
                <div className="w-11/12 text-white p-4 md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
                    <h2 className="text-xl sm:text-2xl font-semibold text-center mb-4">
                        Transform Your Reporting and Become Resilient
                    </h2>
                    <h3 className="text-base sm:text-lg font-medium mb-6 text-gray-200">
                        DIAA Corp Business Consulting for SAP Data and Analytics Solutions
                    </h3>

                    <p className="text-sm sm:text-base leading-7 mb-6">
                        At DIAA Corp, we specialize in delivering cutting-edge business intelligence and data visualization solutions to empower organizations. Leveraging SAP Data and Analytics tools, we seamlessly integrate data from diverse sources to develop actionable insights and key metrics for smarter, faster decision-making.
                    </p>
                    <ul className="text-sm sm:text-base leading-7 mb-6">
                        <li>Enhance Operational Efficiency: Streamline processes for improved productivity and quality outcomes.</li>
                        <li>Empower Decision-Making: Enable real-time access to critical insights from structured and unstructured data.</li>
                        <li>Revolutionize Reporting: Redefine reporting strategies, technologies, and KPIs for a modern, competitive edge.</li>
                        <li>Foster Independence: Equip business users to confidently explore, create, and analyze insights without dependency on IT.</li>
                    </ul>
                </div>
            </div>

            <div className='bg-[#14192E]'>
                <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                    <div className="text-white p-8 rounded-lg flex flex-col md:flex-row items-center">
                        <div className="md:w-1/3 mr-8">
                            <img
                                src={sapAnalyticsCloud_img3}
                                alt="SAP Driven Analytics"
                                className="rounded-lg w-full h-auto"
                            />
                        </div>
                        <div className="md:w-2/3 mt-6 md:mt-0">
                            <h2 className="text-3xl font-bold mb-4 text-center">
                                SAP Expert Consulting Services
                            </h2>
                            <p className="text-sm mb-6 text-gray-300">
                                DIAA Corp’s SAP Analytics consulting services empower clients to revolutionize their reporting processes with innovative and tailored solutions. Accurate data forms the cornerstone of robust business processes and analytics. Our Data Modernization services are designed to help businesses achieve their unique objectives by transforming their data landscape.
                            </p>
                            <ul className="text-sm mb-6 text-gray-300">
                                <li>Future-Ready: Built to scale with evolving business needs.</li>
                                <li>Real-Time: Enabling instant access to actionable insights.</li>
                                <li>High-Speed and Agile: Delivering performance and flexibility.</li>
                            </ul>
                            <p className="text-sm">
                                Our Core Offerings Include: Tool Selection | TCO Optimization | Design and Architecture Consulting | License Optimization | Business Process Outsourcing
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="w-11/12 text-white p-4 md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
                    <h2 className="text-xl sm:text-2xl font-semibold text-center mb-4">
                        Comprehensive Implementation Services
                    </h2>
                    <h3 className="text-base sm:text-lg font-medium mb-6 text-gray-300">
                        Tailored Solutions with Proven Expertise
                    </h3>

                    <p className="text-sm sm:text-base leading-7 mb-6">
                        At DIAA Corp, we specialize in designing and delivering end-to-end SAP Analytics solutions that align with your business goals. From legacy systems to the latest advancements in analytics, our team provides seamless integration and customization for maximum impact.
                    </p>
                    <h4 className="text-base sm:text-lg font-medium mb-4 text-gray-300">
                        Our Implementation Expertise Includes:
                    </h4>
                    <ul className="text-sm sm:text-base leading-7 mb-6">
                        <li>Custom Analytics Design: Crafting solutions that address unique business challenges.</li>
                        <li>Next-Gen Analytics Integration: Deploying advanced tools like SAP Analytics Cloud, SAP Predictive Analytics, and SAP BusinessObjects.</li>
                        <li>System Upgrades and Optimization: Modernizing existing systems for improved functionality and performance.</li>
                        <li>Comprehensive Troubleshooting: Addressing technical challenges to ensure smooth operations.</li>
                        <li>Lifecycle Management: Providing continuous support to keep systems efficient and up-to-date.</li>
                    </ul>
                    <h4 className="text-base sm:text-lg font-medium mb-4 text-gray-300">
                        Why Choose DIAA Corp?
                    </h4>
                    <ul className="text-sm sm:text-base leading-7 mb-6">
                        <li>Strategic Customization: Tailor solutions to meet specific organizational needs.</li>
                        <li>Reliable Delivery: High-quality implementations that drive faster results.</li>
                        <li>Expert Guidance: Benefit from our deep knowledge and hands-on experience with SAP’s robust analytics ecosystem.</li>
                    </ul>
                </div>
                <div>
                    <SAPServices />
                </div>
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <KeyBenefits />
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <DIAACorpHighlights />
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <DIAACorpServices />
            </div>

            <div className="relative bg-indigo-900 py-8 sm:py-12 md:py-16 lg:py-24 bg-opacity-90 bg-cover bg-center mt-8 sm:mt-12 md:mt-16 lg:mt-20" style={{ backgroundImage: `url(${sapAnalyticsCloud_img4})` }}>
                <div className="absolute inset-0 bg-black opacity-40"></div>
                <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-center text-white mb-6 sm:mb-8 md:mb-12">Expanded SAP Capabilities by DIAA CORP</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6 lg:gap-8">
                        <div className="bg-[#6161614D]/20 backdrop-blur-md rounded-lg p-4 md:p-6">
                            <h3 className="text-lg sm:text-xl font-semibold text-white mb-3 text-center">SAP Enterprise Information Management</h3>
                            <ul className="text-xs sm:text-sm text-gray-300 list-disc ml-4 space-y-2">
                                <li>SAP Data Hub: Integrating and managing data across disparate systems</li>
                                <li>SAP MDG: Master Data Governance for consistent data</li>
                                <li>SAP Data Services: ETL data for reliable analytics</li>
                                <li>SAP SLT: Real-time data replication and migration</li>
                                <li>SAP Information Steward: Data quality and governance</li>
                                <li>SAP HANA SDI: Real-time data integration</li>
                            </ul>
                        </div>
                        <div className="bg-[#6161614D]/20 backdrop-blur-md rounded-lg p-4 md:p-6">
                            <h3 className="text-lg sm:text-xl font-semibold text-white mb-3 text-center">SAP Enterprise Performance Management</h3>
                            <ul className="text-xs sm:text-sm text-gray-300 list-disc ml-4 space-y-2">
                                <li>SAP BPC: Business Planning and Consolidation</li>
                                <li>SAP BFC: Financial Consolidation</li>
                                <li>SAP IP: Integrated Planning</li>
                                <li>SAP PCM: Profitability and Cost Management</li>
                                <li>SAP EC: Enterprise Consolidation</li>
                            </ul>
                        </div>
                        <div className="bg-[#6161614D]/20 backdrop-blur-md rounded-lg p-4 md:p-6">
                            <h3 className="text-lg sm:text-xl font-semibold text-white mb-3 text-center">SAP Enterprise Data Warehousing</h3>
                            <ul className="text-xs sm:text-sm text-gray-300 list-disc ml-4 space-y-2">
                                <li>SAP HANA: In-memory computing</li>
                                <li>SAP BW: Business Warehouse</li>
                            </ul>
                        </div>
                        <div className="bg-[#6161614D]/20 backdrop-blur-md rounded-lg p-4 md:p-6">
                            <h3 className="text-lg sm:text-xl font-semibold text-white mb-3 text-center">SAP Reporting & Analytics</h3>
                            <ul className="text-xs sm:text-sm text-gray-300 list-disc ml-4 space-y-2">
                                <li>SAP Analytics Cloud: Real-time insights</li>
                                <li>SAP Lumira: Data visualization</li>
                                <li>SAP Discovery & Designer: BI tools</li>
                                <li>SAP BO Web Intelligence: Web reporting</li>
                                <li>SAP BO Universe Design: BI universes</li>
                                <li>SAP Crystal Reports: Data analysis</li>
                                <li>SAP Predictive Analytics: Forecasting</li>
                                <li>SAP Analysis for OLAP: Multidimensional analysis</li>
                                <li>SAP Analysis for Office: Excel integration</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-11/12 mx-auto mt-12 md:mt-20'>
                <CoreExpertise />
            </div>

            <div className='mx-auto mt-12 md:mt-20'>
                <ContactContent title={'Looking to Host Your SAP Applications on the Cloud with Guaranteed Performance and Uptime?'} />
            </div>

            <Footer />
        </div>
    )
}

export default SAPAnalyticsCloud