import React, { useEffect, useState } from 'react'
import image1 from '../assets/SapS4hana/SAP-SuccessFactors.png';
import logo1 from '../assets/SapS4hana/logo1.png';
import logo2 from '../assets/SapS4hana/logo-1-dummy.png';
import logo3 from '../assets/SapS4hana/logo-2-dummy.png';
import logo4 from '../assets/SapS4hana/logo-3-dummy.png';
// import Footer from '../components/Footer';

// pages/SapS4hanaDemo.js
import { Header } from '../components/resuable/SapSolution';
import { InfoCards } from '../components/resuable/SapSolution';
import { Gallery } from '../components/resuable/SapSolution';
import { PaymentCards } from '../components/resuable/SapSolution';
import { BenefitsSection } from '../components/resuable/SapSolution';
import { PartnerLogos } from '../components/resuable/SapSolution';
import { FooterComponent } from '../components/resuable/SapSolution';
import imageCover from '../assets/SapS4hana/SapSuccessCover.png'; 
import { useDocumentTitle } from '../utils/useDocumentTitle';


const SapSuccessFactors = () => {
    const headerData = {
        title: "What Is DIAA Corp's Workforce Management Solution?",
        description: "DIAA Corp’s Workforce Management Solution is a cloud-based platform designed to enhance employee engagement, productivity, and overall work experience. It supports HR functions such as employee experience management, talent development, performance tracking, and analytics, helping businesses optimize their workforce for better results."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const infoCardsData = [
        {
            title: "DIAA Corp's Solutions for Industries",
            items: ['Energy & Natural Resources', 'Government', 'Wholesale & Distribution', 'Retail', 'EC&O', 'Professional Services', 'Manufacturing']
        },
        {
            title: "DIAA Corp's Availability",
            items: ['Cloud']
        },
        {
            title: "DIAA Corp's Solutions for Employees",
            items: ['Enterprise', 'SMB']
        }
    ];

    const galleryData = {
        images: [image1],
        description: "Explore the features and functionalities of SAP Successfactor through an gallery."
    };

    const benefitsData = {
        title: "Key Features",
        description: "",
        benefits: [
            {
                icon: '⏱️',
                title: '',
                description: 'A unified global platform with standardized HR processes that streamline workforce management across all levels.'
            },
            {
                icon: '📊',
                title: '',
                description: 'Self-service functionalities that improve data accuracy, automate workflows, and enhance HR service delivery in real time.'
            },
            {
                icon: '📄',
                title: '',
                description: 'Tailored experiences for employees, offering personalized development opportunities and performance feedback.'
            },
            {
                icon: '📈',
                title: '',
                description: 'Comprehensive guidance and recommendations to support employees at every stage of their career journey, boosting engagement and productivity.'
            },
        ]
    };

    const partnerLogosData = {
        title: "SAP SuccessFactors Partners",
        logos: [logo1, logo2, logo3, logo4, logo1]
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} backgroundImage={imageCover} />
            <InfoCards cards={infoCardsData} />
            <Gallery {...galleryData} noLaptop={true} />
            <PaymentCards title={`SAP SuccessFactors Payment Models`} />
            <BenefitsSection {...benefitsData} />
            <PartnerLogos {...partnerLogosData} />
            <FooterComponent />
        </div>
    );
};

export default SapSuccessFactors;