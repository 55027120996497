import React, { useEffect, useState } from 'react'
import laptop from '../assets/SapS4hana/macbookSkeleton.png'; // Ensure you have a high-quality laptop image
import image1 from '../assets/SapS4hana/loio92c4b32791cf432ab5693d65879a39bc_LowRes.png'; // Replace with your actual image paths
import image2 from '../assets/SapS4hana/sap-s4hana-dashboard-screenshot.png';
import image3 from '../assets/SapS4hana/Customers-Overview-2-1.png';
import logo1 from '../assets/SapS4hana/logo1.png';
import logo2 from '../assets/SapS4hana/logo-1-dummy.png';
import logo3 from '../assets/SapS4hana/logo-2-dummy.png';
import logo4 from '../assets/SapS4hana/logo-3-dummy.png';
import imageCover from '../assets/SapS4hana/SAP-solutions-cover.png';
// import Footer from '../components/Footer';

// pages/SapS4hanaDemo.js
import { Header } from '../components/resuable/SapSolution';
import { InfoCards } from '../components/resuable/SapSolution';
import { Gallery } from '../components/resuable/SapSolution';
import { PaymentCards } from '../components/resuable/SapSolution';
import { BenefitsSection } from '../components/resuable/SapSolution';
import { PartnerLogos } from '../components/resuable/SapSolution';
import { FooterComponent } from '../components/resuable/SapSolution';
import { useDocumentTitle } from '../utils/useDocumentTitle';

const SapS4hana = () => {
    const headerData = {
        title: "What is SAP S/4 HANA?",
        description: "SAP S/4 HANA is an intelligent, next-generation ERP system designed to streamline and optimize business operations. With built-in AI and machine learning, it empowers Diaa Corp to efficiently manage core processes across Finance, Supply Chain, Manufacturing, Sales, and more. By leveraging real-time data and advanced analytics, SAP S/4 HANA enables Diaa Corp to drive innovation, improve decision-making, and stay ahead in a dynamic business landscape."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const infoCardsData = [
        {
            title: "SAP S/4 HANA Industries :",
            items: ['Energy & Natural Resources', 'Government Sector', 'Wholesale & Distribution', 'Retail & Restaurants', 'EC&O', 'Professional Service']
        },
        {
            title: "SAP S/4 HANA Availability:",
            items: ['On Prime', 'Cloud'],
            icon: 'M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z'
        },
        {
            title: "Scalable for Enterprises:",
            items: [
                'Designed to support growing enterprises like Diaa Corp, SAP S/4 HANA empowers teams with advanced tools, intelligent automation, and real-time data insights to drive operational efficiency and business growth.'
            ]
        }
    ];

    const galleryData = {
        images: [image1, image2, image3],
        description: "Dive into the powerful features of SAP S/4 HANA and discover how it can transform Diaa Corp's operations: Feature 1: Real-time data analytics for smarter decision-making. Feature 2: Integrated AI and machine learning for process automation. Feature 3: Seamless management of Finance, Supply Chain, and more."
    };

    const benefitsData = {
        title: "Key Benefits of SAP S/4 HANA:",
        description: "",
        benefits: [
            {
                icon: '⏱️',
                title: 'REAL-TIME INSIGHTS',
                description: 'Gain instant, real-time insights to make faster, data-driven decisions. Reduce maintenance costs while enhancing productivity and efficiency with advanced real-time analytics.'
            },
            {
                icon: '📊',
                title: 'ENHANCED PRODUCTIVITY',
                description: 'Empower every business user with a modern, intuitive, and role-based user experience that improves workflow and adds value across all business functions.'
            },
            {
                icon: '📄',
                title: 'STREAMLINED ARCHITECTURE',
                description: 'Optimize operations and reduce total cost of ownership with a simplified data model. SAP S/4 HANA ensures a single source of truth across all transactions and processes.'
            },
            {
                icon: '📈',
                title: 'SUPERIOR PERFORMANCE',
                description: 'Achieve unmatched performance and responsiveness across your organization. Leverage native integration with SAP solutions like SuccessFactors, Ariba, and more to drive seamless operations.'
            },
        ]
    };

    const partnerLogosData = {
        title: "SAP S/4 HANA Partners",
        logos: [logo1, logo2, logo3, logo4, logo1]
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} backgroundImage={imageCover}/>
            <InfoCards cards={infoCardsData} />
            <Gallery {...galleryData} noLaptop={false} />
            <PaymentCards title={`SAP S/4 HANA Payment Models`} />
            <BenefitsSection {...benefitsData} />
            <PartnerLogos {...partnerLogosData} />
            <FooterComponent />
        </div>
    );
};

export default SapS4hana;