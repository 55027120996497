import React from 'react';
import { motion } from 'framer-motion';
import { FaNewspaper, FaMicrophone, FaRegCalendarAlt } from 'react-icons/fa';
import { MdContactMail } from 'react-icons/md';

const MediaRelations = () => {
  const newsItems = [
    {
      date: "March 15, 2024",
      title: "DIAA Corp Announces New SAP S/4HANA Implementation Success",
      type: "Press Release"
    },
    {
      date: "February 28, 2024",
      title: "Leading the Digital Transformation Wave in UAE",
      type: "News Coverage"
    },
    {
      date: "February 10, 2024",
      title: "DIAA Corp Expands Cloud Services Portfolio",
      type: "Press Release"
    }
  ];

  const mediaResources = [
    {
      icon: <FaNewspaper className="w-8 h-8" />,
      title: "Press Releases",
      description: "Access our latest company announcements and news"
    },
    {
      icon: <FaMicrophone className="w-8 h-8" />,
      title: "Media Kit",
      description: "Download logos, executive bios, and company facts"
    },
    {
      icon: <FaRegCalendarAlt className="w-8 h-8" />,
      title: "Events",
      description: "Find upcoming events and speaking engagements"
    },
    {
      icon: <MdContactMail className="w-8 h-8" />,
      title: "Media Inquiries",
      description: "Connect with our media relations team"
    }
  ];

  return (
    <div className="bg-bg min-h-screen text-white py-16 px-4">
      <div className="max-w-6xl mx-auto">
        {/* Header Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6">Media Relations</h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Stay updated with the latest news and developments from DIAA Corp
          </p>
        </motion.div>

        {/* Media Resources Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {mediaResources.map((resource, index) => (
            <motion.div
              key={resource.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-[#1A1F31] p-6 rounded-xl text-center hover:bg-[#242942] transition-colors duration-300 cursor-pointer"
            >
              <div className="flex justify-center mb-4 text-purple-500">
                {resource.icon}
              </div>
              <h3 className="text-xl font-bold mb-2">{resource.title}</h3>
              <p className="text-gray-400">{resource.description}</p>
            </motion.div>
          ))}
        </div>

        {/* Latest News Section */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="bg-[#1A1F31] rounded-xl p-8"
        >
          <h2 className="text-2xl font-bold mb-6">Latest News</h2>
          <div className="space-y-6">
            {newsItems.map((item, index) => (
              <div
                key={index}
                className="border-b border-gray-700 last:border-0 pb-4 last:pb-0"
              >
                <div className="flex justify-between items-start mb-2">
                  <h3 className="text-lg font-semibold hover:text-purple-500 cursor-pointer transition-colors">
                    {item.title}
                  </h3>
                  <span className="text-purple-500 text-sm px-3 py-1 bg-purple-500/10 rounded-full">
                    {item.type}
                  </span>
                </div>
                <p className="text-gray-400 text-sm">{item.date}</p>
              </div>
            ))}
          </div>
        </motion.div>

        {/* Contact Section */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="mt-16 text-center"
        >
          <h2 className="text-2xl font-bold mb-4">Media Contact</h2>
          <p className="text-gray-400 mb-6">
            For press inquiries, please contact our media relations team
          </p>
          <a
            href="mailto:contact@diaacorp.com"
            className="inline-block bg-gradient-to-r from-purple-600 to-purple-800 text-white px-8 py-3 rounded-lg hover:opacity-90 transition-opacity"
          >
            Contact Media Team
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default MediaRelations; 