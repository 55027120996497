import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const ExecutiveLeaders = () => {
  return (
    <div className="bg-bg min-h-screen text-white py-16 px-4">
      <div className="max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Executive Leadership Opportunities
          </h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Shape the future of technology and innovation with us
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-[#1A1F31] rounded-xl p-6"
          >
            <h2 className="text-2xl font-bold mb-4">Leadership Impact</h2>
            <ul className="space-y-4 text-gray-300">
              <li>• Drive strategic initiatives</li>
              <li>• Lead global teams</li>
              <li>• Shape organizational culture</li>
              <li>• Influence industry direction</li>
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
            className="bg-[#1A1F31] rounded-xl p-6"
          >
            <h2 className="text-2xl font-bold mb-4">Executive Benefits</h2>
            <ul className="space-y-4 text-gray-300">
              <li>• Competitive compensation</li>
              <li>• Global networking opportunities</li>
              <li>• Executive development programs</li>
              <li>• Strategic decision-making role</li>
            </ul>
          </motion.div>
        </div>

        {/* No Positions Available Notice */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="bg-[#1A1F31] rounded-xl p-8 max-w-2xl mx-auto text-center"
        >
          <img
            src="https://illustrations.popsy.co/gray/remote-work.svg"
            alt="No positions"
            className="w-48 h-48 mx-auto mb-6"
          />
          <h2 className="text-2xl font-bold mb-4">No Open Positions Currently</h2>
          <p className="text-gray-400 mb-6">
            We're not currently hiring for executive positions, but we're always interested in connecting with exceptional leaders.
          </p>
          <Link
            to="/contact-us"
            className="inline-block bg-gradient-to-r from-purple-600 to-purple-800 text-white px-8 py-3 rounded-lg hover:opacity-90 transition-opacity"
          >
            Stay Connected
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default ExecutiveLeaders; 