import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { IoMdCheckmark } from 'react-icons/io';
import { MdAccessibility, MdCookie, MdDescription } from 'react-icons/md';

const LegalPages = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('terms');
  const [fontSize, setFontSize] = useState(16);
  const [highContrast, setHighContrast] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    functional: true,
    analytics: true,
    marketing: false
  });

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    if (path === 'cookie-policy') setActiveTab('cookies');
    else if (path === 'accessibility') setActiveTab('accessibility');
    else setActiveTab('terms');

    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, [location]);

  const handleFontSizeChange = (newSize) => {
    setFontSize(newSize);
    document.documentElement.style.fontSize = `${newSize}px`;
  };

  const handleContrastToggle = () => {
    setHighContrast(!highContrast);
    document.body.classList.toggle('high-contrast');
  };

  const handleCookiePreferenceChange = (key) => {
    if (key === 'necessary') return; // Necessary cookies can't be disabled
    setCookiePreferences(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const tabVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { duration: 0.3 }
    }
  };

  const renderTermsAndConditions = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
      <section className="space-y-4">
        <h3 className="text-xl font-semibold">1. Introduction</h3>
        <p>Welcome to DIAA Corp. By accessing our website, you agree to these terms and conditions.</p>
      </section>
      
      <section className="space-y-4">
        <h3 className="text-xl font-semibold">2. Intellectual Property Rights</h3>
        <p>All content on this website is the property of DIAA Corp and is protected by international copyright laws.</p>
      </section>
      
      <section className="space-y-4">
        <h3 className="text-xl font-semibold">3. User Responsibilities</h3>
        <p>Users must not misuse our services or violate any applicable laws while using our website.</p>
      </section>
      
      {/* Add more sections as needed */}
    </div>
  );

  const renderCookiePolicy = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">Cookie Policy</h2>
      
      <div className="space-y-4">
        <div className="bg-white p-6 rounded-lg">
          <h3 className="text-xl font-semibold mb-4">Cookie Preferences</h3>
          
          {Object.entries(cookiePreferences).map(([key, value]) => (
            <div key={key} className="flex items-center justify-between py-3 border-b border-gray-700">
              <div>
                <h4 className="font-semibold capitalize">{key} Cookies</h4>
                <p className="text-sm text-gray-700">
                  {key === 'necessary' 
                    ? 'Required for the website to function properly'
                    : `Used for ${key} purposes and features`}
                </p>
              </div>
              <button
                onClick={() => handleCookiePreferenceChange(key)}
                disabled={key === 'necessary'}
                className={`w-12 h-6 rounded-full transition-colors duration-200 ease-in-out relative ${
                  value ? 'bg-purple-600' : 'bg-gray-600'
                } ${key === 'necessary' ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              >
                <span
                  className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition-transform duration-200 ease-in-out ${
                    value ? 'transform translate-x-6' : ''
                  }`}
                />
              </button>
            </div>
          ))}
        </div>
      </div>
      
      <section className="space-y-4">
        <h3 className="text-xl font-semibold">What are cookies?</h3>
        <p>Cookies are small text files that are placed on your device when you visit our website.</p>
      </section>
      
      <section className="space-y-4">
        <h3 className="text-xl font-semibold">How we use cookies</h3>
        <p>We use cookies to improve your browsing experience and provide personalized services.</p>
      </section>
    </div>
  );

  const renderAccessibilitySettings = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">Accessibility Settings</h2>
      
      <div className="bg-white p-6 rounded-lg space-y-6">
        <div>
          <h3 className="text-xl font-semibold mb-4">Text Size</h3>
          <div className="flex space-x-4">
            {[14, 16, 18, 20].map(size => (
              <button
                key={size}
                onClick={() => handleFontSizeChange(size)}
                className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
                  fontSize === size 
                    ? 'bg-purple-600 text-white' 
                    : 'bg-gray-200 hover:bg-gray-100'
                }`}
              >
                {size}px
              </button>
            ))}
          </div>
        </div>
        
        <div>
          <h3 className="text-xl font-semibold mb-4">Contrast Settings</h3>
          <button
            onClick={handleContrastToggle}
            className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
              highContrast 
                ? 'bg-purple-600 text-white' 
                : 'bg-gray-200 hover:bg-gray-100'
            }`}
          >
            {highContrast ? 'Disable' : 'Enable'} High Contrast
          </button>
        </div>
      </div>
      
      <section className="space-y-4">
        <h3 className="text-xl font-semibold">Our Commitment</h3>
        <p>We are committed to making our website accessible to all users, regardless of their abilities.</p>
      </section>
    </div>
  );

  return (
    <motion.div 
      className={`min-h-screen py-20 px-4 ${highContrast ? 'high-contrast' : ''}`}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="max-w-4xl mx-auto">
        {/* Tab Navigation */}
        <div className="flex flex-wrap gap-4 mb-8">
          {[
            { id: 'terms', icon: <MdDescription />, label: 'Terms & Conditions' },
            { id: 'cookies', icon: <MdCookie />, label: 'Cookie Policy' },
            { id: 'accessibility', icon: <MdAccessibility />, label: 'Accessibility' }
          ].map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex items-center space-x-2 px-6 py-3 rounded-lg transition-colors duration-200 ${
                activeTab === tab.id 
                  ? 'bg-purple-600 text-white' 
                  : 'bg-white hover:bg-gray-100'
              }`}
            >
              {tab.icon}
              <span>{tab.label}</span>
            </button>
          ))}
        </div>

        {/* Content Area */}
        <motion.div
          key={activeTab}
          initial="hidden"
          animate="visible"
          variants={tabVariants}
          className="bg-white p-8 rounded-xl"
        >
          {activeTab === 'terms' && renderTermsAndConditions()}
          {activeTab === 'cookies' && renderCookiePolicy()}
          {activeTab === 'accessibility' && renderAccessibilitySettings()}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default LegalPages; 