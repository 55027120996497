import React from 'react';
import { Header } from '../../components/resuable/SapSolution';
import { LeaderGrid } from '../../components/resuable/CapabilitiesComponents';
import headerImage from '../../assets/WeAre/leadership_header.jpg'; // You'll need to add this image
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { useDocumentTitle } from '../../utils/useDocumentTitle';
// import leaderImage from '../../assets/WeAre/leader_placeholder.png'; // You'll need to add this image
import Communications_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'

function Leaders() {
    useDocumentTitle("Leadership - Diaa Corp");

    const LeadershipSection = () => {
        const leaders = [
            { 
                name: "Sarah Mitchell",
                title: "Chief Executive Officer",
                imageUrl: Communications_leader_img1
            },
            { 
                name: "Michael Chen",
                title: "Chief Technology Officer",
                imageUrl: Communications_leader_img1
            },
            { 
                name: "Elena Rodriguez",
                title: "Chief Financial Officer",
                imageUrl: Communications_leader_img1
            },
            { 
                name: "David Kumar",
                title: "Chief Operations Officer",
                imageUrl: Communications_leader_img1
            },
            { 
                name: "Amanda Foster",
                title: "Chief Marketing Officer",
                imageUrl: Communications_leader_img1
            },
            { 
                name: "James Wilson",
                title: "Chief Strategy Officer",
                imageUrl: Communications_leader_img1
            },
            { 
                name: "Lisa Zhang",
                title: "Chief Innovation Officer",
                imageUrl: Communications_leader_img1
            },
            { 
                name: "Robert Taylor",
                title: "Chief Data Officer",
                imageUrl: Communications_leader_img1
            },
            { 
                name: "Maria Santos",
                title: "Chief Human Resources Officer",
                imageUrl: Communications_leader_img1
            },
            { 
                name: "Thomas Anderson",
                title: "Chief Product Officer",
                imageUrl: Communications_leader_img1
            },
            { 
                name: "Priya Patel",
                title: "Chief Customer Officer",
                imageUrl: Communications_leader_img1
            },
            { 
                name: "Alex Morgan",
                title: "Chief Digital Officer",
                imageUrl: Communications_leader_img1
            }
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Leadership at Diaa Corp"
                description="Our executive leadership team brings together decades of experience and innovative thinking to drive sustainable growth and digital transformation across industries."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="py-12 sm:py-16 lg:py-20">
                    <div className="max-w-3xl mx-auto text-center mb-12">
                        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-6">
                            Meet Our Leadership Team
                        </h2>
                        <p className="text-lg text-gray-300">
                            Our leaders are committed to driving innovation, fostering collaboration, 
                            and delivering exceptional value to our clients and stakeholders.
                        </p>
                    </div>
                    <LeadershipSection />
                </div>
            </div>

            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent 
                    title="Join Our Team" 
                    subtitle="Build your career with industry leaders at Diaa Corp" 
                />
            </div>
            <Footer />
        </div>
    );
}

export default Leaders;
