import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import headerImage from '../assets/SAP/SapSolutionManager_cover.png'
import SapSolutionManager_img1 from '../assets/SAP/SapSolutionManager_img1.png'
import SapSolutionManager_img2 from '../assets/SAP/SapSolutionManager_img2.png'
import { ChevronRight } from 'lucide-react';
import { ContactContent } from '../components/resuable/SapComponents';
import { FaArrowCircleUp, FaChartBar, FaClipboardCheck, FaCloud, FaCogs, FaGraduationCap, FaHeadset, FaLink, FaSearch, FaWrench } from 'react-icons/fa';
import { IoCloudOutline } from 'react-icons/io5';
import { MdCloudDone } from 'react-icons/md';
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'

function SAPSolutionManager() {

    useDocumentTitle("SAP Solution Manager");
    

    const SAPSolutionManagerCard = () => {
        return (
            <div className="text-white p-4 sm:p-8 rounded-lg flex flex-col md:flex-row items-center">
                <div className="md:w-2/3 pr-0 md:pr-8">
                    <h2 className="text-2xl sm:text-3xl font-bold mb-4">
                        Revolutionize Your Business Operations with DIAA CORP’s SAP Solution Manager Services
                    </h2>
                    <p className="text-xs sm:text-sm mb-6">
                        Transform your business with DIAA CORP’s expert SAP Solution Manager services. Our solutions enable you to drive efficiency, enhance system integration, and ensure seamless application lifecycle management. Whether managing on-premise, hybrid, or cloud systems, we help you achieve peak performance without extra licensing costs. Unlock new opportunities and future-proof your operations with our tailored expertise.
                    </p>
                </div>
                <div className="md:w-1/3 mt-6 md:mt-0">
                    <img
                        src={SapSolutionManager_img1}
                        alt="SAP Solution Manager"
                        className="rounded-lg w-full h-auto"
                    />
                </div>
            </div>
        );
    };

    const Card = ({ title, content }) => (
        <div className="relative p-[1px] rounded-2xl overflow-hidden md:w-1/2 xl:w-11/12 mx-auto">
            <div className="absolute inset-0 bg-custom-gradient"></div>
            <div className="bg-bg p-4 sm:p-6 rounded-2xl h-full relative z-10 px-4 sm:px-8 xl:py-8 sm:py-12">
                <h3 className="text-lg sm:text-xl font-semibold mb-4 text-white text-center">{title}</h3>
                <p className="text-xs sm:text-sm text-gray-300">{content}</p>
            </div>
        </div>
    );

    const SAPSolutionManagerCards = () => {
        const cards = [
            {
                title: "Customized SAP Roadmap Development",
                content: "We start with a detailed workshop to evaluate your current IT landscape and identify optimization opportunities. Our experts craft a bespoke roadmap that aligns SAP Solution Manager’s capabilities with your organizational goals. You’ll receive an actionable report with clear benefits, timelines, and effort estimates to drive your SAP transformation."
            },
            {
                title: "Application Lifecycle Optimization",
                content: "Our specialists assess your existing Application Lifecycle Management (ALM) processes, engage with key stakeholders, and analyze tools to uncover inefficiencies. We deliver a comprehensive maturity report with prioritized recommendations, enabling you to streamline workflows and enhance productivity."
            },
            {
                title: "End-to-End Application Support",
                content: "Post-implementation, we provide continuous support to maintain and optimize your SAP Solution Manager. From system monitoring and incident management to solution documentation and reporting, our multi-tiered support ensures maximum uptime and seamless operations."
            },
            {
                title: "Seamless Integration with Third-Party Tools",
                content: "Enhance your SAP Solution Manager’s functionality with our integration services. Whether connecting third-party incident management systems or process monitoring tools, we implement standardized approaches to ensure quick, reliable integrations with minimal impact on operations."
            }
        ];

        return (
            <div className="p-8 mt-12">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
                    {cards.map((card, index) => (
                        <Card key={index} title={card.title} content={card.content} />
                    ))}
                </div>
            </div>
        );
    };

    const FullSuitAMS = () => {
        const services = [
            "Flexible Scalability: Effortlessly adjust capacity as your business demands evolve.",
            "High Availability: Our hosting services are designed to minimize downtime and ensure uninterrupted access to your SAP systems.",
            "Uncompromised Security: Protect your data with state-of-the-art security measures and proactive monitoring.",
            "End-to-End Responsibility: From migration to operations, we manage your SAP applications, freeing your IT teams to focus on innovation and strategic initiatives.",
            "Seamless Migrations: Transition your SAP systems with minimal disruption and downtime.",
            "Advanced Monitoring: Leverage cutting-edge dashboards and analytics to optimize system performance and resolve issues proactively.",
            "Optimized Resource Utilization: Ensure efficient use of infrastructure while keeping costs in check."
        ];

        return (
            <div className="text-white p-4 sm:p-8 w-11/12 mx-auto mt-8 sm:mt-12 3xl:mt-24">
                <h1 className="text-xl sm:text-2xl font-bold mb-8 sm:mb-12 xl:mb-24 text-center">
                    Why Choose DIAA CORP for Your SAP Hosting?
                </h1>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-12 mb-8 sm:mb-12 mx-auto max-w-4xl">
                    <ul className="flex flex-col items-center">
                        {services.slice(0, 4).map((service, index) => (
                            <li key={index} className="flex items-start mb-3 w-full text-xs sm:text-sm md:text-base text-[#AAAAAA]">
                                <span className="w-2 sm:w-3 h-2 sm:h-3 rounded-full mr-2 sm:mr-3 mt-1 bg-gradient-to-r from-purple-400 to-blue-500"></span>
                                {service}
                            </li>
                        ))}
                    </ul>
                    <ul className="flex flex-col items-center">
                        {services.slice(4).map((service, index) => (
                            <li key={index} className="flex items-start mb-3 w-full text-xs sm:text-sm md:text-base text-[#AAAAAA]">
                                <span className="w-2 sm:w-3 h-2 sm:h-3 rounded-full mr-2 sm:mr-3 mt-1 bg-gradient-to-r from-purple-400 to-blue-500"></span>
                                {service}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    };


    const SAPDiagram = () => {
        return (
            <div className="w-full h-auto flex items-center justify-center p-4">
                <div className="relative w-48 sm:w-64 md:w-80 lg:w-[600px] h-48 sm:h-64 md:h-80 lg:h-[600px]">
                    {/* Center circle */}
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-24 sm:w-32 md:w-40 lg:w-48 h-24 sm:h-32 md:h-40 lg:h-48 rounded-full bg-gray-900 flex items-center justify-center">
                        <div className="w-24 md:w-32 lg:w-[190px] h-24 md:h-32 lg:h-[190px] rounded-full bg-gradient-to-br from-blue-500 to-purple-600 p-0.5 flex items-center justify-center">
                            <div className="w-full h-full rounded-full bg-gray-900 flex items-center justify-center">
                                <p className="text-white text-center text-sm sm:text-base  font-semibold">SAP Solution Manager</p>
                            </div>
                        </div>
                    </div>

                    {/* Surrounding cells */}
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 sm:w-24 md:w-28 lg:w-40 h-10 sm:h-12 md:h-16 lg:h-20 bg-[#242941] rounded-full flex items-center justify-center">
                        <p className="text-white text-center text-xs sm:text-sm">Agile Innovation</p>
                    </div>
                    <div className="absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 w-20 sm:w-24 md:w-28 lg:w-40 h-10 sm:h-12 md:h-16 lg:h-20 bg-[#242941] rounded-full flex items-center justify-center">
                        <p className="text-white text-center text-xs sm:text-sm">Cloud & Hybrid</p>
                    </div>
                    <div className="absolute bottom-0 right-1/4 transform translate-x-1/2 translate-y-1/2 w-20 sm:w-24 md:w-28 lg:w-40 h-10 sm:h-12 md:h-16 lg:h-20 bg-[#242941] rounded-full flex items-center justify-center">
                        <p className="text-white text-center text-xs sm:text-sm">SAP HANA</p>
                    </div>
                    <div className="absolute bottom-0 left-1/4 transform -translate-x-1/2 translate-y-1/2 w-20 sm:w-24 md:w-28 lg:w-40 h-10 sm:h-12 md:h-16 lg:h-20 bg-[#242941] rounded-full flex items-center justify-center">
                        <p className="text-white text-center text-xs sm:text-sm">New User Experience</p>
                    </div>
                    <div className="absolute top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2 w-20 sm:w-24 md:w-28 lg:w-40 h-10 sm:h-12 md:h-16 lg:h-20 bg-[#242941] rounded-full flex items-center justify-center">
                        <p className="text-white text-center text-xs sm:text-sm">Process Manage</p>
                    </div>
                </div>
            </div>
        );
    };


    const UpgradeProcessCard = ({ title, description }) => (
        <div className="bg-[#14192E] rounded-lg p-6 py-8 flex flex-col">
            <h2 className="text-white text-xl font-bold mb-4">{title}</h2>
            <p className="text-gray-400 text-sm">{description}</p>
        </div>
    );

    const UpgradeProcessCards = () => {
        const cards = [
            {
                title: "Strategic Planning and Assessment",
                description: "We evaluate your current SAP environment to identify opportunities for upgrades, migrations, or optimizations. Our experts provide tailored recommendations to help you achieve your business objectives efficiently."
            },
            {
                title: "Seamless System Implementation",
                description: "From new installations to system migrations, we handle end-to-end setup with precision. Whether transitioning to SAP HANA or integrating with cloud solutions, our team ensures a smooth and hassle-free process."
            },
            {
                title: "Comprehensive Testing and Validation",
                description: "We conduct rigorous testing to ensure system stability, data integrity, and application performance. Our proactive approach minimizes risks and guarantees a successful deployment."
            },
            {
                title: "Post-Implementation Support",
                description: "Our commitment doesn’t end after deployment. We provide ongoing support, system monitoring, and performance optimization to keep your SAP environment running at peak efficiency."
            },
            {
                title: "Tailored Roadmaps for Growth",
                description: "With DIAA CORP, you get more than just a technical upgrade—you get a strategic partner. We design customized roadmaps to help you maximize ROI and future-proof your SAP investments."
            }
        ];

        return (
            <div className=" p-8">
                <div className="max-w-6xl mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {cards.map((card, index) => (
                            <UpgradeProcessCard key={index} title={card.title} description={card.description} />
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const MappingYourSuccess = () => {
        return (
            <div className="text-white flex items-center justify-center p-4 mt-12 xl:mt-24">
                <div className="w-11/12 2xl:w-10/12 mx-auto">
                    <div className="rounded-3xl p-8 mb-12">
                        <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8">
                            Driving Excellence with DIAA Corp’s Comprehensive SAP Solution Manager Services
                        </h2>
                        <p className="text-xl md:text-2xl text-center mb-12 text-gray-400">Unlocking Value with Tailored Solutions</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-5xl mx-auto">
                            {[
                                { title: "Strategic SAP Landscape Assessment", description: "Analyze your current SAP environment to identify opportunities for optimization and develop a clear, actionable implementation roadmap.", Icon: FaSearch },
                                { title: "End-to-End Deployment Services", description: "Seamlessly implement SAP Solution Manager to enhance IT Service Management (ITSM) and Application Lifecycle Management (ALM) capabilities.", Icon: FaCogs },
                                { title: "Upgrade and Migration Expertise", description: "Effortlessly transition to the latest SAP Solution Manager version with minimal disruption and maximum efficiency.", Icon: FaArrowCircleUp },
                                { title: "Empowering Teams with Knowledge", description: "Deliver customized training and knowledge transfer to ensure your team fully leverages the power of SAP Solution Manager.", Icon: FaGraduationCap },
                                { title: "Continuous Environment Support", description: "Provide ongoing maintenance, troubleshooting, and optimization to keep your SAP systems running smoothly.", Icon: FaHeadset },
                                { title: "Advanced Functionality Configuration", description: "Customize and implement key Solution Manager features tailored to your business processes and objectives.", Icon: FaWrench },
                                { title: "Best Practice Integration", description: "Leverage industry best practices to maximize the efficiency and effectiveness of your SAP Solution Manager.", Icon: FaClipboardCheck },
                                { title: "Seamless System Integration", description: "Integrate SAP Solution Manager with both SAP and third-party systems to create a unified, efficient IT ecosystem.", Icon: FaLink },
                                { title: "Enhanced Reporting and Analytics", description: "Develop custom dashboards and reports for actionable insights and improved decision-making capabilities.", Icon: FaChartBar },
                            ].map((item, index) => (
                                <div key={index} className="relative group">
                                    <div className="absolute -inset-0.5 bg-custom-gradient rounded-3xl blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                                    <div className="relative bg-[#1C2237] rounded-3xl p-8 flex flex-col items-center space-y-6 h-full">
                                        <div className="w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-full bg-custom-gradient flex items-center justify-center">
                                            <item.Icon className="text-white text-3xl md:text-4xl lg:text-5xl" />
                                        </div>
                                        <span className="text-sm font-medium text-center text-white">{item.title}</span>
                                        <p className="text-sm text-center text-gray-400">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const FocusedSolutions = () => {
        return (
            <div className="relative bg-indigo-900 py-24 bg-opacity-90 bg-cover bg-center" style={{ backgroundImage: `url(${SapSolutionManager_img2})`}}>
                <div className="absolute inset-0 bg-gradient-to-br from-purple-900/50 to-indigo-900/50"></div>
                <div className="relative z-10 flex flex-col items-center justify-center h-full px-4 text-white">
                    <h1 className="mb-6 text-4xl font-bold text-center">
                        Tailored SAP Solution Manager Solutions
                    </h1>
                    <p className="max-w-3xl mb-12 text-center">
                        DIAA Corp offers specialized solutions for SAP Solution Manager that are pre-configured, automated, and ready to implement—ensuring a hassle-free experience for your organization. These solutions are designed to meet specific needs without incurring additional coding costs, integration challenges, or upgrade risks.
                    </p>
                    <div className="flex flex-col md:flex-row gap-6 w-full max-w-4xl">
                        {[1, 2].map((index) => (
                            <div key={index} className="flex-1 p-6 bg-gray-800 bg-opacity-50 rounded-lg backdrop-blur-sm">
                                <div className="flex items-center mb-4">
                                    <div className="w-10 h-10 mr-3 bg-purple-500 rounded-full flex items-center justify-center">
                                        <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                    </div>
                                    <h2 className="text-xl font-semibold">SAP Focused Build</h2>
                                </div>
                                <p className="text-sm">
                                    SAP Focused Build is a robust, pre-configured suite within SAP Solution Manager, designed to streamline the management of requirements and software development in large-scale, agile projects. It ensures faster delivery, improved collaboration, and increased efficiency without the need for custom coding.
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col md:flex-row gap-6 w-full max-w-4xl mt-6">
                        {[1, 2].map((index) => (
                            <div key={index} className="flex-1 p-6 bg-gray-800 bg-opacity-50 rounded-lg backdrop-blur-sm">
                                <div className="flex items-center mb-4">
                                    <div className="w-10 h-10 mr-3 bg-purple-500 rounded-full flex items-center justify-center">
                                        <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                    </div>
                                    <h2 className="text-xl font-semibold">SAP Focused Insights</h2>
                                </div>
                                <p className="text-sm">
                                    Focused Insights provides ready-to-use dashboards and analytics within SAP Solution Manager, empowering your team with real-time insights to monitor, analyze, and optimize your SAP landscape. With no additional integration efforts required, you gain instant visibility into system performance and business metrics.
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const SAPFocusedRunBenefits = () => {
        const benefits = [
            { title: "Strategic SAP Landscape Assessment", icon: FaSearch },
            { title: "Streamlined IT Service and Lifecycle Management", icon: FaCogs },
            { title: "Accelerated Implementation with Best Practices", icon: FaClipboardCheck },
            { title: "On-Time Project Delivery", icon: FaArrowCircleUp },
            { title: "Business Continuity through Change and Release Management", icon: FaLink },
            { title: "Proactive IT Infrastructure Maintenance", icon: FaHeadset },
            { title: "Improved IT Service Quality", icon: FaWrench },
            { title: "Real-Time Insights for Better Decision-Making", icon: FaChartBar },
            { title: "Faster Implementation with Pre-Configured Best Practices", icon: FaGraduationCap },
        ];

        return (
            <div className="text-white flex items-center justify-center p-4 mt-12 xl:mt-24">
                <div className="w-11/12 2xl:w-10/12 mx-auto">
                    <div className="rounded-3xl p-8 mb-12">
                        <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8">
                            Benefits of SAP Focused Run
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-5xl mx-auto">
                            {benefits.map((item, index) => (
                                <div key={index} className="relative group">
                                    <div className="absolute -inset-0.5 bg-custom-gradient rounded-3xl blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                                    <div className="relative bg-[#1C2237] rounded-3xl p-8 flex flex-col items-center space-y-6 h-full">
                                        <div className="w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-full bg-custom-gradient flex items-center justify-center">
                                            <item.icon className="text-white text-3xl md:text-4xl lg:text-5xl" />
                                        </div>
                                        <span className="text-sm font-medium text-center text-white">{item.title}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div className='bg-bg text-white'>
            <Header
                title="Optimize Your SAP Environment with DIAA CORP's SAP Solution Manager Expertise"
                description="DIAA CORP's SAP Solution Manager Services empower businesses to streamline operations, enhance performance, and achieve seamless innovation. From managing your enterprise solutions to optimizing application lifecycles, our integrated platform is designed to deliver maximum value—without additional costs! Connect with our experts today and unlock the full potential of your SAP landscape."
                backgroundImage={headerImage}
                isExploreBtn={true}
            />
            {/* Add padding to the main content wrapper */}
            <div className=''>
                <div className='md:w-11/12 2xl:w-10/12 mx-auto'>
                    <SAPSolutionManagerCard />
                </div>

                <div className="md:w-11/12 2xl:w-10/12 text-white md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
                    <h2 className="text-2xl font-semibold text-center mb-4">
                        Empowering Your SAP Journey with DIAA CORP’s Comprehensive Solution Manager Services
                    </h2>
                    <h3 className="text-lg font-medium mb-6">
                        Tailored Strategies and Expert Guidance
                    </h3>

                    <p className="text-base leading-7 mb-6">
                        At DIAA CORP, we are dedicated to helping organizations unlock the full potential of SAP Solution Manager through tailored strategies and expert guidance. From planning and implementation to ongoing support, we ensure your SAP landscape operates at peak efficiency with minimal disruptions.
                    </p>
                </div>

                <div className='md:w-11/12 2xl:w-10/12 mx-auto'>
                    <SAPSolutionManagerCards />
                </div>

                <div className='mt-12 xl:mt-24'>
                    <ContactContent title={`Leverage SAP Solution Manager to manage your IT implementation projects from Requirements to Deployments, and ensure 24/7 system availability.`} />
                </div>

                <div>
                    <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
                        <h2 className="text-2xl font-semibold text-center mb-4">
                            Transform Your SAP Landscape with DIAA CORP’s Comprehensive Hosting and Migration Services
                        </h2>
                        <p className="text-base leading-7 mb-6">
                            With over 20 years of expertise in infrastructure management, data center migration, and security, DIAA CORP offers scalable and robust SAP hosting services designed to meet your business needs. Whether you’re looking to enhance system performance or adapt to dynamic workloads, our end-to-end solutions ensure high availability, top-notch security, and seamless operations.
                        </p>
                    </div>
                </div>

                <div className='mt-12 xl:mt-24 bg-[#14192E]'>
                    <div className='md:pt-4'>
                        <FullSuitAMS />
                    </div>
                    <div className='py-24'>
                        <SAPDiagram />
                    </div>
                </div>

                <div>
                    <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
                        <h2 className="text-2xl font-semibold text-center mb-4">
                            Streamline Your SAP Operations with DIAA CORP’s Expertise
                        </h2>
                        <p className="text-base leading-7 mb-6 max-w-7xl mx-auto text-[#F1F1F1]">
                            At DIAA CORP, we specialize in delivering seamless SAP upgrades, installations, and migrations tailored to your business needs. Our comprehensive services ensure minimal disruption to your operations while aligning with SAP’s latest standards and best practices.
                        </p>
                    </div>
                </div>

                <div className='mt-12 xl:mt-24'>
                    <UpgradeProcessCards />
                </div>

                <div className='mt-12 xl:mt-24'>
                    <ContactContent title={`Don't Wait!`} subtitle={`Upgrade to Solution Manager 7.2 today and unlock the full potential of SAP Solution Manager for your business success.`} />
                </div>

                <div className='mt-12 xl:mt-24'>
                    <MappingYourSuccess />
                </div>

                <div className='mt-12 xl:mt-24'>
                    <FocusedSolutions />
                </div>

                <div className='mt-12 xl:mt-24'>
                    <SAPFocusedRunBenefits />
                </div>

                <div className='mt-12 xl:mt-24 mb-12 xl:mb-24'>
                    <ContactContent title={`Experience SAP And Its Impact On Business Transformation`}/>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default SAPSolutionManager