import React, { useEffect, useState } from 'react'
import laptop from '../assets/SapS4hana/macbookSkeleton.png'; // Ensure you have a high-quality laptop image
import image1 from '../assets/SapS4hana/loio92c4b32791cf432ab5693d65879a39bc_LowRes.png'; // Replace with your actual image paths
import image2 from '../assets/SapS4hana/sap-s4hana-dashboard-screenshot.png';
import image3 from '../assets/SapS4hana/Customers-Overview-2-1.png';
import logo1 from '../assets/SapS4hana/logo1.png';
import logo2 from '../assets/SapS4hana/logo-1-dummy.png';
import logo3 from '../assets/SapS4hana/logo-2-dummy.png';
import logo4 from '../assets/SapS4hana/logo-3-dummy.png';
import imageCover from '../assets/SapS4hana/SapCxCover.png'; 

// import Footer from '../components/Footer';

// pages/SapS4hanaDemo.js
import { Header } from '../components/resuable/SapSolution';
import { InfoCards } from '../components/resuable/SapSolution';
import { Gallery } from '../components/resuable/SapSolution';
import { PaymentCards } from '../components/resuable/SapSolution';
import { BenefitsSection } from '../components/resuable/SapSolution';
import { PartnerLogos } from '../components/resuable/SapSolution';
import { FooterComponent } from '../components/resuable/SapSolution';
import { useDocumentTitle } from '../utils/useDocumentTitle';

const SapCx = () => {
    const headerData = {
        title: "What is SAP CX?",
        description: "SAP Customer Experience (CX) is a comprehensive portfolio of solutions that supports every phase of the customer journey — from initial engagement to final purchase. By integrating data and processes, SAP CX helps Diaa Corp build meaningful connections with customers and deliver exceptional experiences."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const infoCardsData = [
        {
            title: "Industries Supported by SAP CX:",
            items: ['Wholesale & Distribution', 'Retail & Hospitality', 'Professional Services']
        },
        {
            title: "Availability of SAP CX:",
            items: ['Cloud-based model ensuring scalability, security, and real-time access'],
            icon: 'M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z'
        },
        {
            title: "Enterprise Solutions:",
            items: ['Flexible and scalable for businesses'],
            icon: 'M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z'
        }
    ];

    const galleryData = {
        images: [image1, image2, image3],
        description: "Explore the features and functionalities of SAP CX through our interactive gallery."
    };

    const benefitsData = {
        title: "Key Benefits of SAP CX",
        description: "Comprehensive solutions for enhanced customer experience and business growth",
        benefits: [
            {
                icon: '⏱️',
                title: 'UNDERSTAND YOUR AUDIENCE',
                description: 'Gain deep insights into customer behavior by unifying data from every source. SAP CX enables Diaa Corp to anticipate customer needs and provide personalized solutions for better customer satisfaction.'
            },
            {
                icon: '📊',
                title: 'ENGAGE YOUR CUSTOMERS',
                description: 'Leverage actionable insights to deliver hyper-personalized interactions. With SAP CX, Diaa Corp can engage customers at the perfect moment with tailored messages that resonate.'
            },
            {
                icon: '📄',
                title: 'DELIVER ON YOUR PROMISES',
                description: 'From smooth purchasing experiences to on-time services, SAP CX ensures Diaa Corp consistently fulfills its brand promises — driving loyalty and enhancing customer satisfaction.'
            },
            {
                icon: '📈',
                title: 'DELIVER THE RIGHT MESSAGE',
                description: 'Reach your audience with the right message at the right time across the right channels. SAP CX helps Diaa Corp inspire action and drive meaningful customer engagement.'
            },
        ]
    };

    const partnerLogosData = {
        title: "SAP CX Partners",
        logos: [logo1, logo2, logo3, logo4, logo1]
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} backgroundImage={imageCover}/>
            <InfoCards cards={infoCardsData} />
            <Gallery {...galleryData} />
            <PaymentCards title={`SAP CX Payment Models`} />
            <BenefitsSection {...benefitsData} />
            <PartnerLogos {...partnerLogosData} />
            <FooterComponent />
        </div>
    );
};

export default SapCx;