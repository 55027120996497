import { useEffect, useRef, useState } from "react";
import laptop from '../../assets/SapS4hana/macbookSkeleton.png'; // Ensure you have a high-quality laptop image
import Footer from '../../components/Footer';
import imageCover from '../../assets/SapS4hana/SAP-solutions-cover.png';
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";


// components/Header.js
export const Header = ({ title, description, backgroundImage, backgroundVideo, isExploreBtn, isVideo = false, videoZoom = 1.1 }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const location = useLocation();
    const videoRef = useRef(null);
    const EnterprisePage = location.pathname === '/enterprise-applications-managed-services';

    useEffect(() => {
        if (isVideo) {
            setIsLoaded(true);
        } else {
            const img = new Image();
            img.src = backgroundImage;
            img.onload = () => setIsLoaded(true);
        }
    }, [backgroundImage, isVideo]);

    useEffect(() => {
        if (isVideo && videoRef.current) {
            videoRef.current.playbackRate = 0.75; // Slow down video playback
        }
    }, [isVideo]);

    const renderBackground = () => {
        if (!isLoaded) return null;

        if (isVideo) {
            return (
                <div className="absolute inset-0 overflow-hidden">
                    <video
                        ref={videoRef}
                        className={`absolute w-full h-full object-cover ${EnterprisePage ? 'md:blur-sm' : ''}`}
                        style={{
                            transform: `scale(${videoZoom})`,
                            objectPosition: 'center center'
                        }}
                        autoPlay
                        loop
                        muted
                        playsInline
                    >
                        <source src={backgroundVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        }

        return (
            <div
                className={`absolute inset-0 ${EnterprisePage ? 'md:blur-sm' : ''}`}
                style={{
                    backgroundImage: `linear-gradient(to bottom, rgba(7, 7, 39, 1) 0%, rgba(7, 7, 39, 0.7) 0%, rgba(7, 7, 39, 0.7) 90%), url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            />
        );
    };

    return (
        <AnimatePresence>
            <motion.header
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className={`relative pt-20 pb-10 sm:py-32 md:py-44 xl:pb-64 ${EnterprisePage ? 'md:w-2/3 md:mx-auto' : ''}`}
                style={{
                    backgroundColor: 'rgba(7, 7, 39, 1)',
                }}
            >
                {isLoaded && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="absolute inset-0 z-0"
                    >
                        {renderBackground()}
                        <div className="absolute inset-0 bg-gradient-to-b from-[rgba(7,7,39,1)] via-[rgba(7,7,39,0.7)] to-[rgba(7,7,39,0.7)]" />
                    </motion.div>
                )}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    className="container mx-auto px-4 text-center relative z-10"
                >
                    <h1 className="text-3xl sm:text-4xl font-bold text-center text-[#F1F1F1]">{title}</h1>
                    <p className="text-center max-w-3xl mx-auto text-[#F1F1F1] opacity-90 pt-4">{description}</p>
                    {isExploreBtn && (
                        <></>
                        // <motion.button
                        //     className="bg-custom-gradient text-white font-bold py-3 px-6 md:px-12 rounded-full mt-12"
                        //     whileHover={{ scale: 1.05 }}
                        //     whileTap={{ scale: 0.95 }}
                        // >
                        //     Explore more
                        // </motion.button>
                    )}
                </motion.div>
            </motion.header>
        </AnimatePresence>
    );
};
// components/InfoCards.js
export const InfoCard = ({ title, items, icon, isLengthy }) => (
    <div className={`bg-[#14192E] rounded-lg drop-shadow-xl w-full max-w-[325px] md:max-w-[378px] ${isLengthy ? 'md:h-[420px] lg:h-[320px]' : 'md:h-[370px] lg:h-[300px]'} p-6`}>
        <h2 className="text-lg lg:text-xl font-semibold mb-4">{title}</h2>
        <ul className="space-y-2">
            {items.map((item, index) => (
                <li key={index} className="flex items-start">
                    {icon ? (
                        <svg className="w-4 h-4 sm:w-5 sm:h-5 mr-2 text-theme flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                            <path d={icon} />
                        </svg>
                    ) : (
                        <div className="w-1.5 h-1.5 sm:w-2 sm:h-2 bg-custom-gradient rounded-full mr-2 mt-[0.4rem] flex-shrink-0"></div>
                    )}
                    <span className="flex-1 text-sm sm:text-base">{item}</span>
                </li>
            ))}
        </ul>
    </div>
);

export const InfoCards = ({ cards }) => {
    const [fadeIn, setFadeIn] = useState(true);
    console.log(cards[0].items.length, "cards");
    return (
        <div className={`relative z-10 mobile:mt-10 -mt-20 sm:-mt-24 lg:-mt-32 lg:-mt-40 xl:-mt-48 container mx-auto px-4 `}>
            <div className="flex flex-col sm:flex-row justify-center items-center space-y-6 sm:space-y-0 sm:space-x-6">
                {cards.map((card, index) => (
                    <InfoCard key={index} {...card} isLengthy={cards[0].items.length > 6 ?? true} />
                ))}
            </div>
        </div>
    );
}

// components/Gallery.js
export const Gallery = ({ images, description, noLaptop }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const imagesInput = [...images];

    const nextImage = () => {
        if (!isTransitioning) {
            setIsTransitioning(true);
            setCurrentImage((prev) => (prev + 1) % imagesInput.length);
        }
    };

    const prevImage = () => {
        if (!isTransitioning) {
            setIsTransitioning(true);
            setCurrentImage((prev) => (prev - 1 + imagesInput.length) % imagesInput.length);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTransitioning(false);
        }, 500); // Match this with your transition duration

        return () => clearTimeout(timer);
    }, [currentImage]);
    console.log(imagesInput, "images input");
    return (
        <div className="bg-bg text-white p-8 mt-16 md:mt-24">
            <h1 className="text-4xl font-bold text-center mb-16 md:mb-32">Gallery</h1>

            <div className="md:flex items-center md:w-3/4 mx-auto">
                <div className="relative w-full ">

                    {noLaptop === false ? <div>
                        <img src={laptop} alt="Laptop" className="w-full" />
                        <div className="absolute top-[4.5%] left-[7.5%] right-[7.5%] bottom-[14%] overflow-hidden">
                            {imagesInput.map((img, index) => (
                                <img
                                    key={index}
                                    src={img}
                                    alt={`SAP S/4 HANA feature ${index + 1}`}
                                    className={`w-full h-full object-cover absolute top-0 left-0 transition-opacity duration-500 ${index === currentImage ? 'opacity-100' : 'opacity-0'
                                        }`}
                                />
                            ))}
                        </div>

                    </div> : <div className="mr-12">
                        <img
                            src={imagesInput[0]}
                            alt={`SAP S/4 HANA feature`}
                            className={`w-full h-full rounded-lg transition-opacity duration-500`}
                        />
                    </div>}
                </div>

                <div className="flex flex-col justify-between items-center w-full">
                    <p className="text-lg max-w-md">
                        {description}
                    </p>
                    {noLaptop === false ? <div className="flex space-x-8 mt-12">
                        <button
                            onClick={prevImage}
                            className="w-12 h-12 rounded-full border border-white flex items-center justify-center hover:bg-white hover:text-bg transition-colors"
                            disabled={isTransitioning}
                        >
                            &lt;
                        </button>
                        <button
                            onClick={nextImage}
                            className="w-12 h-12 rounded-full border border-white flex items-center justify-center hover:bg-white hover:text-bg transition-colors"
                            disabled={isTransitioning}
                        >
                            &gt;
                        </button>
                    </div> : null}
                </div>
            </div>
        </div>
    );
};

// components/PaymentCards.js
export const PaymentCard = ({ years, isPremium, }) => {
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };
    return (
        <div className={`relative w-64 h-80 rounded-lg md:mx-8 ${isPremium ? 'glow w-72 h-96' : ''}`}>
            <div className={`absolute inset-0 rounded-lg ${isPremium ? 'bg-custom-gradient-anim' : 'bg-gray-800'}`}>
                <div className="absolute inset-0.5 bg-gray-900 rounded-lg flex flex-col justify-between p-6">
                    {isPremium && (
                        <div className="absolute -left-16 top-12 transform -rotate-90 bg-custom-gradient-anim text-white py-1 px-4 rounded-r-md rounded-l-md text-sm font-bold">
                            Premium
                        </div>
                    )}
                    {!isPremium && (
                        <div className="absolute -left-7 top-8 transform -rotate-90 bg-gray-700 text-gray-500 py-1 px-3 rounded-r-md text-sm">
                            Basic
                        </div>
                    )}
                    <div>
                        <h3 className="text-white text-center text-2xl font-bold mb-2">Get Quote</h3>
                        <p className={` text-center mt-12 ${isPremium ? 'font-bold text-2xl' : 'font-semibold text-gray-400 text-lg'}`}>{years} YEAR</p>
                        <p className={` uppercase text-center text-sm ${isPremium ? 'text-gray-300' : 'text-gray-500 '}`}>contract</p>
                    </div>
                    <button
                        onClick={scrollToBottom}
                        className={`w-2/3 mx-auto py-2 rounded-full text-sm font-[600] ${isPremium ? 'bg-custom-gradient-anim text-white' : 'bg-gray-700 text-gray-400'}`}>
                        {isPremium ? 'Get Started' : 'Get Quote'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export const PaymentCards = ({ title }) => (
    <div className="bg-bg flex flex-col items-center justify-center p-8 mt-12 md:mt-24">
        <h1 className="text-white text-3xl font-bold mb-16 md:mb-32">{title}</h1>
        <div className="flex flex-wrap gap-8 justify-center items-center">
            <PaymentCard years={3} isPremium={false} />
            <PaymentCard years={5} isPremium={true} />
        </div>
    </div>
);

// components/BenefitsSection.js
export const BenefitsSection = ({ title, description, benefits }) => {
    const Card = ({ title, description, icon, className }) => (
        <div className={`bg-[#14192E] text-white p-6 rounded-[20px] shadow-xl relative ${className}`}>
            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-bg p-4 rounded-full border-b-2 border-[#a100ff]">
                <span className="text-3xl">{icon}</span>
            </div>
            <h3 className="text-xl font-bold mt-8">{title}</h3>
            <p className="mt-4">{description}</p>
        </div>
    );

    return (
        <div className="bg-bg py-12 px-4 md:px-0 mt-12 md:mt-24">
            <div className="md:w-3/4 mx-auto">
                <div className="mb-12">
                    <h1 className="text-4xl text-white font-bold mb-4">{title}</h1>
                    <p className="text-white">{description}</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-16">
                    {benefits.map((benefit, index) => (
                        <Card
                            key={index}
                            title={benefit.title}
                            description={benefit.description}
                            icon={benefit.icon}
                            className={index % 2 === 1 ? 'md:mt-16' : ''}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

// components/PartnerLogos.js
export const PartnerLogos = ({ title, logos }) => (
    <div className="bg-[#14192E] py-12 md:py-24 mt-12 md:mt-24 mb-6 md:mb-12">
        <div className="container mx-auto px-4">
            <h2 className="text-2xl md:text-3xl font-bold text-white text-center mb-6 md:mb-8">{title}</h2>
            <div className="flex flex-wrap justify-center items-center gap-4 md:gap-8 mt-8 md:mt-24">
                {logos.map((logo, index) => {
                    const totalLogos = logos.length;
                    let sizeClass = '';

                    if (totalLogos === 4) {
                        sizeClass = index === 1 || index === 2 ? 'h-16 md:h-24' : 'h-12 md:h-16';
                    } else {
                        const isCenter = Math.floor(totalLogos / 2) === index;
                        const distanceFromCenter = Math.abs(index - Math.floor(totalLogos / 2));

                        if (isCenter) {
                            sizeClass = 'h-20 md:h-32';
                        } else if (distanceFromCenter === 1) {
                            sizeClass = 'h-14 md:h-20';
                        } else {
                            sizeClass = 'h-10 md:h-16';
                        }
                    }

                    return (
                        <img
                            key={index}
                            src={logo}
                            alt={`Partner logo ${index + 1}`}
                            className={`w-auto ${sizeClass} transition-all duration-300 hover:scale-110 mobile:max-w-[30%]`}
                        />
                    );
                })}
            </div>
        </div>
    </div>
);

// components/Footer.js
export const FooterComponent = () => (
    <Footer />
);