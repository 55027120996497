import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { IoSearchOutline, IoLocationOutline } from 'react-icons/io5';
import { MdLocationOn, MdWork } from 'react-icons/md';
import { toast } from 'react-hot-toast';

const SearchAllJobs = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [location, setLocation] = useState('');
  const [jobType, setJobType] = useState('');
  const [errors, setErrors] = useState({});
  const [isSearching, setIsSearching] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!searchQuery.trim()) newErrors.searchQuery = 'Please enter a job title';
    if (!location.trim()) newErrors.location = 'Please enter a location';
    // if (!jobType) newErrors.jobType = 'Please select a job type';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSearch = () => {
    if (validateForm()) {
      setIsSearching(true);
      // Simulate API call
      setTimeout(() => {
        setIsSearching(false);
        toast.success('Search completed!');
        // Add your actual search logic here
      }, 1500);
    }
  };

  return (
    <div className="bg-bg min-h-screen text-white py-16 px-4">
      <div className="max-w-6xl mx-auto">
        {/* Header Section */}
        <header className="text-center mb-12">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold mb-4"
          >
            Find Your Next Career Move
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 text-lg"
          >
            Join our team and make an impact
          </motion.p>
        </header>

        {/* Search Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-[#1A1F31] rounded-xl p-6 mb-12 shadow-lg"
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Job Title Search */}
            <div className="relative flex flex-col">
              <div className="relative">
                <IoSearchOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 z-10" />
                <input
                  type="text"
                  placeholder="Search jobs..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className={`w-full bg-[#242942] text-white pl-10 pr-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                    errors.searchQuery ? 'border-red-500 border-2' : ''
                  }`}
                />
              </div>
              {errors.searchQuery && (
                <p className="text-red-500 text-sm mt-1">{errors.searchQuery}</p>
              )}
            </div>

            {/* Location Input */}
            <div className="relative flex flex-col">
              <div className="relative">
                <IoLocationOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 z-10" />
                <input
                  type="text"
                  placeholder="Location..."
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className={`w-full bg-[#242942] text-white pl-10 pr-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                    errors.location ? 'border-red-500 border-2' : ''
                  }`}
                />
              </div>
              {errors.location && (
                <p className="text-red-500 text-sm mt-1">{errors.location}</p>
              )}
            </div>

            {/* Job Type Filter */}
            <div className="relative">
              <MdWork className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <select
                value={jobType}
                onChange={(e) => setJobType(e.target.value)}
                className="w-full bg-[#242942] text-white pl-10 pr-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 appearance-none"
              >
                <option value="">All Job Types</option>
                <option value="full-time">Full Time</option>
                <option value="part-time">Part Time</option>
                <option value="contract">Contract</option>
              </select>
            </div>
          </div>

          <button 
            onClick={handleSearch}
            disabled={isSearching}
            className="w-full mt-4 bg-gradient-to-r from-purple-600 to-purple-800 text-white py-3 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50"
          >
            {isSearching ? 'Searching...' : 'Search Jobs'}
          </button>
        </motion.div>

        {/* No Results Section */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="text-center py-12"
        >
          <div className="bg-[#1A1F31] rounded-xl p-8 max-w-2xl mx-auto">
            <img
              src="https://illustrations.popsy.co/gray/success.svg"
              alt="No jobs found"
              className="w-48 h-48 mx-auto mb-6"
            />
            <h2 className="text-2xl font-bold mb-4">No Jobs Found</h2>
            <p className="text-gray-400">
              We're currently updating our job listings. Please check back later or
              adjust your search criteria.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SearchAllJobs; 