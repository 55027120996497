import React, { useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Industries/PublicService/_coverImg.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents'
import { ContactContent } from '../../components/resuable/SapComponents'
import Footer from '../../components/Footer'
import { useDocumentTitle } from '../../utils/useDocumentTitle'
import { ChevronRight, ChevronLeft, ChevronDown } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

// Import images
import _img1 from '../../assets/Industries/PublicService/_img1.png'
import _img2 from '../../assets/Industries/PublicService/_img2.png'
import _img3 from '../../assets/Industries/PublicService/_img3.png'
import _img4 from '../../assets/Industries/PublicService/_img4.png'
import _img5 from '../../assets/Industries/PublicService/_img5.png'
import _img6 from '../../assets/Industries/PublicService/_img6.png'
import _img7 from '../../assets/Industries/PublicService/_img7.png'
import _img8 from '../../assets/Industries/PublicService/_img8.png'
import _img9 from '../../assets/Industries/PublicService/_img9.png'
import Public_Img1 from '../../assets/Industries/PublicService/Public_Img1.png'
import Public_Img2 from '../../assets/Industries/PublicService/Public_Img2.png'
// import _img10 from '../../assets/Industries/PublicService/_img10.png'

import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'

function PublicService() {
    useDocumentTitle("PublicService");

    const PublicServiceStats = () => {
        const stats = [
            { value: "150%", description: "Increase in global disruptions due to crises and challenges over the past five years." },
            { value: "1 in 3", description: "Public sector employees in G20 nations are expected to retire within the next decade, creating an urgent need for workforce transformation." },
            { value: "42%", description: "Of citizens across 12 countries report dissatisfaction with government digital services, citing lack of user-friendliness and accessibility." }
        ];
        return <StatsGrid title="Public Service Evolution Today" stats={stats} />;
    };

    const HLTH24Section = () => {
        return (
            <div className="rounded-2xl p-8 flex flex-col md:flex-row items-center gap-8">
                <div className="w-full md:w-1/2">
                    <img
                        src={_img1} // Replace with HLTH24 specific image
                        alt="Diaa corp Health at HLTH 24"
                        className="rounded-lg w-full h-auto"
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <h3 className="text-2xl font-bold mb-4">Diaa corp Health at HLTH 24!</h3>
                    <p className="text-gray-300 mb-6">
                        We are excited to participate and share our vision for the future of healthcare.
                    </p>
                    <button className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-full">
                        Learn more
                    </button>
                </div>
            </div>
        );
    };

    const SegmentsWeSupport = () => {
        const [activeIndex, setActiveIndex] = useState(null);
        const [showDetails, setShowDetails] = useState(false);
        const [selectedSegment, setSelectedSegment] = useState(null);

        const toggleSegment = (index) => {
            setActiveIndex(activeIndex === index ? null : index);
        };

        const toggleDetails = (segment) => {
            setSelectedSegment(segment);
            setShowDetails(true);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 },
        };

        const segments = [
            {
                title: "Industrial and Electrical Equipment",
                description: "Crafting the engineered product of tomorrow—from machinery and industrial equipment to electrical components and beyond.",
                image: _img3,
                details: {
                    keyFeatures: [
                        "Advanced automation solutions",
                        "Smart manufacturing integration",
                        "IoT-enabled equipment monitoring",
                        "Predictive maintenance systems"
                    ],
                    benefits: "Increase operational efficiency by 40% with our smart manufacturing solutions",
                    caseStudy: "Leading manufacturer achieved 65% reduction in downtime"
                }
            },
            {
                title: "Industrial and Automotive Suppliers",
                description: "Revolutionizing automotive manufacturing with cutting-edge solutions for suppliers, focusing on precision, efficiency and quality control.",
                image: _img2,
                details: {
                    keyFeatures: [
                        "Supply chain optimization",
                        "Quality control automation",
                        "Just-in-time inventory management",
                        "Connected factory solutions",
                        "Real-time production monitoring"
                    ],
                    benefits: "Streamline production with 30% improved efficiency and 25% reduced inventory costs",
                    caseStudy: "Major auto supplier reduced waste by 45% and improved quality metrics by 60%"
                }
            },
            {
                title: "Heavy Equipment",
                description: "Empowering construction and mining industries with intelligent heavy machinery solutions focused on productivity and safety.",
                image: _img1,
                details: {
                    keyFeatures: [
                        "Equipment tracking and fleet management",
                        "Predictive maintenance analytics",
                        "Performance optimization systems",
                        "Remote monitoring and diagnostics",
                        "Safety compliance automation"
                    ],
                    benefits: "Enhance equipment lifespan by 35% while reducing operational costs by 25%",
                    caseStudy: "Construction firm saved $2M in maintenance costs and improved safety metrics by 40%"
                }
            },
            {
                title: "Consumer Durables",
                description: "Transforming consumer appliance manufacturing with smart technologies and efficient production processes.",
                image: _img4,
                details: {
                    keyFeatures: [
                        "Smart appliance integration",
                        "Energy efficiency optimization",
                        "Quality assurance automation",
                        "Connected device management",
                        "Sustainable manufacturing practices"
                    ],
                    benefits: "Reduce production costs by 30% while improving product quality by 45%",
                    caseStudy: "Appliance manufacturer increased production efficiency by 50%"
                }
            },
            {
                title: "Freight and Logistics",
                description: "Optimizing supply chain operations with intelligent logistics solutions and real-time tracking capabilities.",
                image: _img5,
                details: {
                    keyFeatures: [
                        "Real-time fleet tracking",
                        "Route optimization",
                        "Warehouse automation",
                        "Inventory management",
                        "Delivery optimization"
                    ],
                    benefits: "Improve delivery efficiency by 40% and reduce operational costs by 35%",
                    caseStudy: "Logistics company reduced delivery times by 50% and fuel costs by 30%"
                }
            },
            {
                title: "Construction, Engineering and Real Estate",
                description: "Delivering innovative solutions for construction projects and property management through digital transformation.",
                image: _img6,
                details: {
                    keyFeatures: [
                        "Project management automation",
                        "BIM integration",
                        "Site safety monitoring",
                        "Resource optimization",
                        "Property management systems"
                    ],
                    benefits: "Reduce project delays by 45% and improve resource utilization by 35%",
                    caseStudy: "Engineering firm reduced project completion time by 40%"
                }
            },
            {
                title: "Business Services",
                description: "Enabling service excellence through digital transformation and process automation solutions.",
                image: _img7,
                details: {
                    keyFeatures: [
                        "Service automation",
                        "Customer relationship management",
                        "Process optimization",
                        "Data analytics"
                    ]
                }
            }
        ];

        return (
            <div className="w-full">
                <h2 className="text-4xl font-bold mb-8 text-center">Segments we support</h2>
                <div className="text-white p-4 sm:p-8 rounded-2xl">
                    {segments.map((segment, index) => (
                        <div key={index} className="mb-4">
                            <div
                                className="flex justify-between items-center cursor-pointer p-4 bg-[#14192E] rounded-t-xl"
                                onClick={() => toggleSegment(index)}
                            >
                                <span className="font-semibold">{segment.title}</span>
                                <motion.div
                                    animate={{ rotate: activeIndex === index ? 180 : 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <ChevronDown />
                                </motion.div>
                            </div>
                            <motion.div
                                animate={{
                                    height: activeIndex === index ? "auto" : 0,
                                    opacity: activeIndex === index ? 1 : 0
                                }}
                                initial={{ height: 0, opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                style={{ overflow: "hidden" }}
                            >
                                <div className="p-4 bg-[#14192E] rounded-b-xl">
                                    <div className="flex flex-col sm:flex-row items-start">
                                        {/* <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                            <div className="bg-purple-900 rounded-lg overflow-hidden">
                                                <img
                                                    src={segment.image}
                                                    alt={segment.title}
                                                    className="w-full h-auto object-cover"
                                                />
                                            </div>
                                        </div> */}
                                        <div className="flex-grow">
                                            <p className="text-gray-400 mb-4">{segment.description}</p>
                                            <div className="flex justify-end">
                                                {/* <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="border-2 border-theme/50 rounded-full p-2"
                                                    onClick={() => toggleDetails(segment)}
                                                >
                                                    <ChevronRight className="text-theme cursor-pointer" size={24} />
                                                </motion.div> */}
                                            </div>
                                            {showDetails && selectedSegment === segment && (
                                                <motion.div
                                                    initial="hidden"
                                                    animate="visible"
                                                    exit="exit"
                                                    variants={slideVariants}
                                                    className="mt-4 p-4 bg-[#1a2036] rounded-xl"
                                                >
                                                    <h3 className="text-xl font-bold mb-3">Key Features:</h3>
                                                    <ul className="list-disc pl-5 mb-4">
                                                        {segment.details.keyFeatures.map((feature, i) => (
                                                            <li key={i} className="mb-2">{feature}</li>
                                                        ))}
                                                    </ul>
                                                    <p className="mb-3"><strong>Benefits:</strong> {segment.details.benefits}</p>
                                                    <p><strong>Case Study:</strong> {segment.details.caseStudy}</p>
                                                </motion.div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const ReinventPublicService = () => {
        const cards = [
            {
                title: 'Modernizing Technology Infrastructure',
                description: 'Transform your technology foundation to meet evolving public needs. Legacy systems are no longer sufficient to support rapid changes. Implement scalable cloud solutions to accelerate service delivery and future-proof public services.',
                hasButton: false,
            },
            {
                title: 'Enhancing Citizen-Centric Services',
                description: 'Focus on delivering services that prioritize the needs of individuals. With 53% of citizens dissatisfied with public services, focus on providing secure, personalized experiences that foster trust and engagement.',
                hasButton: false,
            },
            {
                title: 'Driving Sustainable Public Policy',
                description: 'Lead initiatives for a greener, more sustainable future. As stewards of public policy, the government plays a pivotal role in promoting sustainability. Take charge in advancing net-zero goals and environmental responsibility.',
                hasButton: false,
            },
            {
                title: 'Workforce Innovation and Upskilling',
                description: 'Prepare the workforce of tomorrow for the challenges of the future. Public sector organizations must embrace workforce transformation, including upskilling programs and adapting work structures, to create an inclusive and resilient labor force.',
                hasButton: false,
            },
            {
                title: 'Optimizing Back Office Operations',
                description: 'Streamline back-office functions for enhanced efficiency. With limited resources, public services must rethink back-office processes. A comprehensive approach to technology and process optimization can improve performance and reduce costs.',
                hasButton: false,
            },
            {
                title: 'Strengthening Cybersecurity',
                description: 'Protect sensitive data and reinforce public trust. Governments face rising threats from cyberattacks targeting sensitive information. Implement robust cybersecurity frameworks to safeguard data and ensure transparency and trust.',
                hasButton: false,
            },
            {
                title: 'Harnessing Data and AI for Smarter Services',
                description: 'Leverage data and AI for better decision-making and service delivery. By integrating advanced data analytics and AI technologies, public sector organizations can make more informed decisions, improve operational efficiency, and better meet the needs of their communities.',
                hasButton: false,
            }
        ];

        return <ReinventGrid title="Reimagining Public Service for a New Era" cards={cards} />;
    };

    const PublicServiceTrendingCarousel = () => {
        const slides = [
            {
                title: "Leveraging AI for Smarter Healthcare in Public Systems",
                description: "Improving Patient Care with AI-Driven Solutions. Public healthcare systems are turning to AI to revolutionize service delivery and patient care. By using AI-powered tools, healthcare providers can streamline diagnoses, predict patient outcomes, and personalize treatment plans, ensuring faster and more accurate interventions. These technologies also enhance patient engagement through virtual assistants, predictive alerts, and data-driven recommendations, ultimately improving accessibility and the overall quality of care for diverse populations.",
                image: _img4,
                gradient: "from-purple-600 to-pink-500"
            },
            {
                title: "Sustainable Public Services: The Green Shift",
                description: "Creating a Cleaner, Greener Public Sector. Sustainability is becoming a cornerstone of public service strategies, as organizations prioritize eco-friendly practices to build a greener future. Initiatives such as implementing green IT solutions, adopting renewable energy, and enforcing carbon-neutral policies are reducing environmental impacts while enhancing operational efficiency. By championing sustainability, public sector organizations are leading by example, fostering healthier communities and contributing to global environmental goals.",
                image: _img5,
                gradient: "from-blue-600 to-cyan-500"
            },
            {
                title: "Revolutionizing Social Welfare with Digital Solutions",
                description: "Empowering Citizens Through Technology. Digital transformation is reshaping social welfare systems, making them more accessible, transparent, and efficient. With the introduction of user-friendly platforms and data-driven processes, citizens can easily apply for and manage welfare benefits, while agencies can ensure timely and accurate support. These advancements not only empower individuals by reducing barriers to access but also enhance the credibility and effectiveness of public welfare programs.",
                image: _img6,
                gradient: "from-indigo-600 to-purple-500"
            },
            {
                title: "Enhancing Public Safety with Predictive Analytics",
                description: "Using Data to Prevent and Respond to Crises. Predictive analytics is redefining public safety by enabling proactive measures in crisis prevention and response. By analyzing historical and real-time data, law enforcement and emergency response teams can anticipate incidents, allocate resources effectively, and mitigate risks. From crime prevention strategies to disaster management, predictive analytics equips public safety agencies with the tools needed to act swiftly, ensuring the well-being of communities.",
                image: Public_Img1,
                gradient: "from-green-600 to-teal-500"
            },
            {
                title: "Transforming Public Service Operations with Automation",
                description: "Streamlining Government Workflows for Greater Efficiency. Automation is revolutionizing government operations by simplifying workflows, reducing manual errors, and accelerating service delivery. From automated document processing to AI-powered chatbots for citizen inquiries, public service organizations are enhancing efficiency and cutting operational costs. These innovations enable government departments to focus on strategic initiatives while delivering faster and more reliable services to citizens, setting a new standard for governance in the digital age.",
                image: Public_Img2,
                gradient: "from-red-600 to-orange-500"
            }
        ];

        return <TrendingCarousel title="Emerging Trends in Public Service" slides={slides} />;
    };



    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Innovation Award 2024",
                image: _img7,
                alt: "Innovation Award"
            },
            {
                title: "Technology Leader - Forrester",
                image: _img8,
                alt: "Technology Leadership"
            },
            {
                title: "Best Solutions Provider",
                image: _img9,
                alt: "Solutions Provider"
            },
            // {
            //     title: "Excellence in Digital Transformation",
            //     image: _img10,
            //     alt: "Digital Transformation Excellence"
            // }
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "John Smith", title: "Global PublicService Lead", imageUrl: Cloud_leader_img1 },
            { name: "Sarah Johnson", title: "Digital Innovation Director", imageUrl: Cloud_leader_img1 },
            { name: "Michael Chen", title: "Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Emma Davis", title: "Solutions Lead", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Shaping the Future of Public Services for Thriving Communities"
                description="In an era of resource constraints, rapid technological evolution, and constant challenges, public service organizations that prioritize adaptability and innovation can drive significant positive change. By embracing continuous transformation, these organizations can improve outcomes for individuals and strengthen the fabric of entire communities."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <PublicServiceStats />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <HLTH24Section />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventPublicService />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <PublicServiceTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <GlobalRecognition />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent title="PublicService careers" subtitle="Help public sector organizations ranging from federal to local governments, higher education institutions and non-profit organizations embrace a strategy of continuous reinvention." />
            </div>
            <Footer />
        </div>
    );
}

export default PublicService
