import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/DataAndArtificialIntelligence_cover.png'
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import dataAi_img1 from '../../assets/Capabilities/dataAi_img1.png'
import dataAi_img2 from '../../assets/Capabilities/dataAi_img2.png'
import dataAi_img3 from '../../assets/Capabilities/dataAi_img3.png'
import dataAi_img4 from '../../assets/Capabilities/dataAi_img4.png'
import dataAi_img5 from '../../assets/Capabilities/dataAi_img5.png'
import dataAi_img6 from '../../assets/Capabilities/dataAi_img6.png'
import dataAi_img7 from '../../assets/Capabilities/dataAi_img7.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { motion, AnimatePresence } from 'framer-motion';
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import microsoftLogo from '../../assets/Capabilities/Cloud_partner_img2.png'
import awsLogo from '../../assets/Capabilities/Cloud_partner_img3.png'
import googleLogo from '../../assets/Capabilities/Cloud_partner_img4.png'
import sapLogo from '../../assets/Capabilities/sap-logo.png'
import ibmLogo from '../../assets/Capabilities/ibm-logo.png'
import servicenowLogo from '../../assets/Capabilities/Cloud_partner_img1.png'
import databricksLogo from '../../assets/Capabilities/databricks-logo.png'
import oracleLogo from '../../assets/Capabilities/oracle-logo.png'
import { useDocumentTitle } from '../../utils/useDocumentTitle';

const PartnersSection = () => {
  const partners = [
    { name: 'Microsoft', logo: microsoftLogo },
    { name: 'AWS', logo: awsLogo },
    { name: 'Google', logo: googleLogo },
    { name: 'SAP', logo: sapLogo },
    { name: 'IBM', logo: ibmLogo },
    { name: 'ServiceNow', logo: servicenowLogo },
    { name: 'Databricks', logo: databricksLogo },
    { name: 'Oracle', logo: oracleLogo },
  ];

  return (
    <div className="bg-[#08082A] py-12">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-white text-3xl font-bold text-center mb-10">Partners in change</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 sm:gap-6 md:gap-8 items-center justify-items-center">
          {partners.map((partner) => (
            <img
              key={partner.name}
              src={partner.logo}
              alt={`${partner.name} logo`}
              className="h-6 sm:h-8 object-contain"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

function DataAndArtificialIntelligence() {
    useDocumentTitle("Data and Artificial Intelligence");

    const DataAIComponent = () => {
        return (
            <div className="bg-[#08082A] text-white p-4 sm:p-6 md:p-8 font-sans flex flex-col md:flex-row">
                <div className="w-full md:w-1/3 mb-6 md:mb-0 md:pr-8 flex items-center justify-center">
                    <img
                        src={dataAi_img1}
                        alt="Person wearing VR headset"
                        className="w-full max-w-xs sm:max-w-sm md:max-w-full object-cover rounded-lg"
                    />
                </div>
                <div className="w-full md:w-2/3">
                    <h2 className="text-2xl font-bold mb-6 md:mb-12 lg:mb-16">Data & AI Today</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                        <StatCard
                            value="75%"
                            description="of companies are actively investing in reskilling their teams to adapt to the rise of AI and machine learning."
                        />
                        <StatCard
                            value="50%"
                            description="of businesses identify data quality and management as the biggest challenge to implementing AI."
                        />
                        <StatCard
                            value="90%"
                            description="of organizations agree that generative AI will significantly reshape their operations and industries."
                        />
                        <StatCard
                            value="80%"
                            description="of companies intend to increase their investments in technology, with a particular focus on data-driven and AI solutions."
                        />
                    </div>
                </div>
            </div>
        );
    };

    const StatCard = ({ value, description }) => (
        <div className="bg-gradient-to-br from-[#21213B] to-[#08082A] rounded-lg p-6 relative overflow-hidden">
            <div className="absolute top-0 left-0 w-16 h-16 bg-purple-600 opacity-20 rounded-br-full"></div>
            <div className="absolute bottom-0 right-0 w-16 h-16 bg-purple-600 opacity-20 rounded-tl-full"></div>
            <p className="text-3xl font-bold mb-2">{value}</p>
            <p className="text-sm">{description}</p>
        </div>
    );

    const ReinventWithDataAndAI = () => {
        const cards = [
            {
                title: 'AI-Driven Cloud Foundations',
                description: 'Data fuels AI, and AI empowers innovation. Build a solid cloud infrastructure to unlock the full potential of AI and drive business transformation.',
                hasButton: true,
            },
            {
                title: 'Ethical & Responsible AI',
                description: 'Integrate responsible AI practices across your enterprise to ensure fairness, transparency, and accountability in your AI initiatives.',
                hasButton: true,
            },
            {
                title: 'Maximizing AI ROI',
                description: 'Identify high-impact areas where generative AI can deliver the greatest return on investment, optimizing processes and boosting performance.',
                hasButton: true,
            },
            {
                title: 'Data as a Competitive Edge',
                description: 'In the age of generative AI, your proprietary data is a powerful asset—unlock its true potential to differentiate your business in the market.',
                hasButton: true,
            },
            {
                title: 'Empowering Talent Through AI Training',
                description: 'Invest in talent transformation and upskilling to equip your workforce with the skills to harness the power of AI, driving innovation and success.',
                hasButton: true,
            },
        ];

        return <ReinventGrid title="Reinvent with Data & AI" cards={cards} />;
    };
    const slides = [
        {
            title: "The Art of AI Maturity",
            image: dataAi_img2,
            gradient: "from-purple-600 to-pink-500",
            description: "Whitepaper: Achieving Cloud Excellence\n\nExplore how organizations are evolving their cloud strategies to enhance scalability, agility, and innovation in today's fast-paced business landscape."
        },
        {
            title: "A Collection of data & generative AI",
            image: dataAi_img3,
            gradient: "from-blue-600 to-cyan-500",
            description: "Whitepaper: Navigating the AI-First Future\n\nDive into the transformative role of AI in cloud environments, uncovering the key factors to building a successful AI strategy in the cloud."
        },
        {
            title: "The New Data Economy",
            image: dataAi_img4,
            gradient: "from-indigo-600 to-purple-500",
            description: "Whitepaper: Harnessing the Power of Data in the Cloud\n\nLearn how companies are leveraging cloud-based data solutions to unlock real-time insights, optimize operations, and drive data-driven decision-making."
        }
    ];

    const CloudTrendingCarousel = () => {
        return <TrendingCarousel title="What's trending with cloud?" slides={slides} />;
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Leader in Multicloud Managed Services - Forrester",
                image: dataAi_img5,
                alt: "Multicloud Managed Services"
            },
            {
                title: "Leader for Worldwide Cloud Professional Services - IDC",
                image: dataAi_img6,
                alt: "Worldwide Cloud Professional Services"
            },
            {
                title: "Leader in data center outsourcing & hybrid",
                image: dataAi_img7,
                alt: "Data Center Outsourcing & Hybrid"
            },
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };
    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Johnson", title: "Lead - Data & AI", imageUrl: Cloud_leader_img1 },
            { name: "Shakriya", title: "Chief AI Officer", imageUrl: Cloud_leader_img1 },
            { name: "Chandran", title: "Chief Responsible AI Officer", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Transform Your Business with Generative AI"
                description="Generative AI is poised to redefine industries, creating opportunities for unprecedented innovation and growth. As businesses strive for transformation, AI is becoming the key driver of change across all sectors. However, the success of AI initiatives hinges on data readiness—ensuring your data is clean, accessible, and actionable is crucial to unlocking the full potential of AI and revolutionizing your operations."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <DataAIComponent />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithDataAndAI />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <CloudTrendingCarousel />
            </div>
            <div className='w-full mt-8 md:mt-16 lg:mt-20'>
                <PartnersSection />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <GlobalRecognition />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20 mb-8 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
            {/* Add more components and content here */}
        </div>
    )
}

export default DataAndArtificialIntelligence