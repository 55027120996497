import React, { useState } from 'react'
import { Header } from '../components/resuable/SapSolution'
import coverImage from '../assets/SAP/migrationSapCover.png'
import MigrationFactory from '../components/SAP/MigrationFactoryDiagram';
import MigrationInfo from '../components/SAP/MigrantInfo';
import { AnimatePresence, motion } from 'framer-motion';
import MigrationFactoryBenefits from '../components/SAP/MigrationFactoryBenefits';
import LocationMap from '../components/SAP/Map';
import awsIcon from '../assets/SAP/sap-cloud-aws.webp'
import gcpIcon from '../assets/SAP/sap-cloud-gcp.webp'
import azureIcon from '../assets/SAP/sap-cloud-migration-azure.webp'
import successStoryImg from '../assets/SAP/foodImage.png'
import Footer from '../components/Footer';
import { ContactContent, FAQItem, FeatureGrid } from '../components/resuable/SapComponents';
import { useDocumentTitle } from '../utils/useDocumentTitle';

function SAPMigrationFactory() {
    const headerData = {
        title: "Diaa Corp’s Cloud Migration Solutions",
        description: "Accelerate your digital transformation with Diaa Corp’s advanced, standardized migration approach. Our cloud migration services are designed to deliver high performance, unmatched availability, and seamless transitions, ensuring zero disruptions during your journey to the cloud."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const migrationInfoData = {
        stats: [
            { title: "75%", subtitle: "of SAP migrations use automated Greenfield approach" },
            { title: "80%", subtitle: "of businesses rely on experienced migration partners" }
        ],
        mainContent: {
            heading: "Unlock the Power of the Cloud with Diaa Corp’s Expertise",
            paragraphs: [
                "At Diaa Corp, we specialize in large-scale migrations, application modernizations, and managed cloud services. As a global leader in SAP HANA Enterprise Cloud services, we provide seamless migration of on-prem SAP workloads to custom cloud architectures, including public, private, hybrid, and multi-cloud solutions.",
                "With our SAP-certified expertise, we ensure that your data is always secure and your business experiences minimal disruption during the transition."
            ]
        },
        infoBoxText: [
            "Diaa Corp’s Migration Factory is a standardized, fully automated approach to SAP migration. Designed to streamline complex cloud transitions, this approach leverages the power of intelligent automation, reducing manual effort and ensuring a smoother transition with maximum uptime and no data loss. We offer tailored solutions, such as SAP Switch2Cloud and SAP RISE Switch2Cloud, to simplify your migration and improve operational efficiency.",
            "Incorporating cutting-edge technologies, Diaa Corp enhances your SAP cloud migration with intelligent Robotic Process Automation (RPA). Our AIOps-powered managed services ensure that all workflows are automated, allowing you to focus on business innovation while we handle the technical complexities. With expert support from our SAP Center of Excellence (COE), you can trust Diaa Corp to deliver an agile, cost-effective transformation with a surprisingly low Total Cost of Ownership (TCO)."
        ],
        sapWorkloadsContent: {
            heading: "Migration Made Simple: The SAP Migration Factory Approach",
            description: "Diaa Corp's unique Factory-based approach to large-scale cloud migration, modernization, and managed services has led to its rise as a world leader in application-focused cloud services and the largest global premium supplier of SAP HANA Enterprise Cloud. With SAP-certified expertise in HANA Operations, Cloud Services, Hosting Services, Application Management, and DevOps, Diaa Corp streamlines the transition of on-prem SAP workloads, applications, and databases to tailored public, private, hybrid, or multi-cloud architectures. This is achieved with high uptimes, negligible disruption, and zero data loss."
        }
    };

    const infoCardItems = [
        {
            title: "300+",
            sub: "Successful Cloud Migrations"
        },
        {
            title: "2500+ TB",
            sub: "Data Managed in Cloud Environments"
        },
        {
            title: "7000+",
            sub: "Enterprise Systems Optimized"
        },
        {
            title: "1200+",
            sub: "Cloud Workloads Managed"
        }
    ];

    const InfoCard = ({ title, sub, isLengthy }) => (
        <div className={`flex flex-col ${title === '300+' ? 'bg-custom-gradient' : 'bg-[#191F38]'} m-2 sm:m-0 rounded-lg drop-shadow-xl w-40 h-40 text-center justify-center`}>
            <h2 className="text-2xl font-semibold mb-4">{title}</h2>
            <p className='text-xs'>{sub}</p>
        </div>
    );

    const SapCenterOfExcellence = () => {
        return (
            <div className="text-white p-8">
                <div className="text-center mb-16">
                    <h1 className="text-xl md:text-3xl font-bold">Cloud Excellence Hub at Diaa Corp</h1>
                </div>
                <div className="space-y-4 mb-24">
                    <div className="bg-[#1A1F31] md:w-1/2 py-8 px-6 rounded-full text-center mx-auto text-sm md:text-lg">Digital Transformation Consulting</div>
                    <div className="bg-[#1A1F31] md:w-1/2 py-8 px-6 rounded-full text-center mx-auto text-sm md:text-lg">Cloud Infrastructure Optimization Services</div>
                    <div className="bg-[#1A1F31] md:w-1/2 py-8 px-6 rounded-full text-center mx-auto text-sm md:text-lg">System Integration for Cloud Deployments (Greenfield/Brownfield/Hybrid)</div>
                    <div className="bg-[#1A1F31] md:w-1/2 py-8 px-6 rounded-full text-center mx-auto text-sm md:text-lg">Custom Application Development for Cloud Environments</div>
                    <div className="bg-[#1A1F31] md:w-1/2 py-8 px-6 rounded-full text-center mx-auto text-sm md:text-lg">Cloud Modernization and Migration Factory (Upgrades, Migrations, and Conversions)</div>
                </div>
                <div className="text-center mb-16">
                    <h2 className="text-2xl font-bold">Multi-Cloud Solutions (Private, Public, Hybrid, and Multi-Cloud Architectures)</h2>
                </div>
                <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-24">
                    <div className="bg-[#1A1F31] py-16 md:w-72 px-6 rounded-2xl text-center text-sm md:text-lg">Diaa Corp Cloud Platforms (Dedicated and Global DCs)</div>
                    <div className="bg-[#1A1F31] py-16 md:w-72 px-6 rounded-2xl text-center text-sm md:text-lg">Any Cloud Provider (AWS, Azure, GCP, Alibaba)</div>
                    <div className="bg-[#1A1F31] py-16 md:w-72 px-6 rounded-2xl text-center text-sm md:text-lg">On-Premise Cloud Solutions (Customer's Data Centers)</div>
                </div>
            </div>
        );
    };

    const SapServiceGrid = () => {
        const services = [
            'Cloud Advisory and Consulting Services',
            'Cloud Migration Services Using Advanced Automation Tools',
            'Strategic Cloud Solution Design and Integration',
            'Cloud Security, Compliance, and Data Protection',
        ];

        return (
            <div className="bg-[#14192E] w-full lg:w-5/6 xl:w-4/6 text-white p-4 lg:p-6 xl:p-8 flex justify-center items-center rounded-2xl border-4/90 border-custom-gradient shadow-[0_0_20px_#a100ff]">
                <div className="relative rounded-lg shadow-lg p-4 lg:p-6 xl:p-8">
                    <div className="rounded-lg"></div>
                    <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 xl:gap-8 z-10">
                        {services.map((service, index) => (
                            <div key={index} className="bg-[#070727] p-3 lg:p-4 xl:p-6 h-24 lg:h-28 xl:h-32 text-xs lg:text-sm xl:text-base rounded-2xl text-center flex flex-col items-center justify-center">
                                {service}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const SapMigrations = () => {
        return (
            <div className="bg-[#14192E] text-white p-8 flex justify-center items-center md:my-12 xl:my-24 3xl:my-48">
                <div className="max-w-6xl mx-auto">
                    <div className="flex flex-col md:flex-row justify-between items-center mb-8">
                        <div className="md:w-1/2 pr-4">
                            <h1 className="text-xl md:text-3xl font-bold mb-4">Strengthening the Core: Seamless SAP Migrations for Any Cloud, Any Architecture</h1>
                        </div>
                        <div className="md:w-1/2 md:pl-4 mt-8 md:mt-0">
                            <p>In today’s dynamic market landscape, businesses must remain adaptable to constant change. This flexibility often comes with challenges such as managing diverse IT architectures and meeting frequent demands for upgrades and modernization. Achieving this level of agility requires adopting a digital-first strategy, powered by SAP Business Suite. By hosting SAP workloads, applications, and databases in the cloud, organizations can unlock the full potential of their SAP investments and drive operational excellence.</p>
                        </div>
                    </div>
                    <div className="md:text-center mb-8">
                        <p>Our SAP Migration Specialists and Cloud Engineers ensure effortless transitions to a cloud-enabled SAP experience, regardless of operational complexity or compliance requirements. Leveraging DIAA Corp’s automation-driven, secure, and high-speed Migration Factory Approach, we deliver seamless migrations to your preferred cloud platform with end-to-end coverage—from infrastructure to application layer—under a unified SLA.</p>
                    </div>
                    <div className="flex justify-center space-x-4 mt-16 md:mt-24">
                        <div className="p-8 rounded-lg">
                            <img src={awsIcon} alt="Logo" className="mx-auto" />
                        </div>
                        <div className="p-8 rounded-lg">
                            <img src={azureIcon} alt="Logo" className="mx-auto" />
                        </div>
                        <div className="p-8 rounded-lg">
                            <img src={gcpIcon} alt="Logo" className="mx-auto" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const MigrationFactory2 = () => {
        const steps = [
            {
                number: 1,
                title: 'Discovery and Strategy',
                items: ['Define Business Objectives', 'Assess Current IT Landscape', 'Develop a Transformation Roadmap']
            },
            {
                number: 2,
                title: 'Planning and Preparation',
                items: ['Conduct Risk and Impact Analysis', 'Set Up Cloud-Ready Infrastructure', 'Build a Pilot Test Environment']
            },
            {
                number: 3,
                title: 'Migration Execution',
                items: ['Migrate Workloads in Phases', 'Implement Data and Application Modernization', 'Ensure Secure and Compliant Transitions']
            },
            {
                number: 4,
                title: 'Optimization and Operations',
                items: ['Integrate Advanced Monitoring Tools', 'Fine-Tune Cloud Resources for Cost and Performance', 'Ensure Continuous Uptime and System Reliability']
            },
            {
                number: 5,
                title: 'Innovation and Growth',
                items: [
                    'Leverage AI and Analytics for Insights',
                    'Build Scalable and Future-Ready Applications',
                    'Empower Teams with Advanced Collaboration Tools'
                ]
            }
        ];

        return (
            <div className="text-white p-8 mt-12">
                <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center">Unlocking the Power of DIAA Corp's Cloud Transformation Framework</h1>
                <p className="text-sm mb-8 text-center max-w-3xl mx-auto text-[#F1F1F1] mobile:py-2">
                    DIAA Corp's Cloud Transformation Framework empowers organizations to seamlessly transition their IT systems, applications, and data to the cloud. With a structured and automated approach, the framework ensures speed, scalability, and reliability while maintaining business continuity and enhancing operational efficiency.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:4/5">
                    {steps.map((step) => (
                        <div key={step.number} className="bg-[#1B2032] rounded-2xl p-4 mt-2 md:mt-12 relative w-full md:w-72 mx-auto">
                            <div className="absolute -top-3 -left-3 bg-custom-gradient text-white text-xs font-bold px-2 py-1 rounded">
                                Step {step.number}
                            </div>
                            <h2 className="text-md font-semibold mb-2">{step.title}</h2>
                            <ul className="list-disc list-inside">
                                {step.items.map((item, index) => (
                                    <li key={index} className="text-xs text-gray-400">{item}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const MigrationFactoryMap = () => {
        const migrations = [
            {
                icon: '⇄',
                title: 'Seamless Offline Transitions',
                points: [
                    'Planned downtime with database set to read-only mode.',
                    'Data migrated, followed by application switch-over to the new database environment.'
                ]
            },
            {
                icon: '⚡',
                title: 'Efficient Online Transitions',
                points: [
                    'Minimal downtime with continuous data synchronization.',
                    'Initial data load ensures replication from source to target until the system is ready for seamless cut-over.'
                ]
            },
            {
                icon: '🔄',
                title: 'Straightforward Homogeneous Migrations',
                points: [
                    'Source and target databases remain the same.',
                    'Lift-and-shift approach: Data is restored from on-premises backup to a pre-configured virtual machine in the cloud.'
                ]
            },
            {
                icon: '🔀',
                title: 'Customizable Heterogeneous Migrations',
                points: [
                    'Different source and target database systems.',
                    'In-depth assessment of database objects, schemas, stored procedures, data types, and required code adjustments for compatibility.'
                ]
            },
            {
                icon: '🔁',
                title: 'Handling Complex, Large-Scale Migrations',
                points: [
                    'Entire data transfer executed in a single, strategic move.',
                    'Solutions designed to manage edge cases, with integration of new systems into the IT landscape.'
                ]
            }
        ];

        return (
            <div className="text-white p-8 ">
                <h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-8 text-center my-12">
                    Cloud Migration Framework: Flexible Solutions for Every Need
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {migrations.map((migration, index) => (
                        <div key={index} className="relative p-[1px] rounded-lg bg-custom-gradient mobile:mt-12 md:mt-16">
                            <div className="bg-bg rounded-lg p-6 h-full flex flex-col px-16">
                                <div className="text-4xl mb-4 self-center">{migration.icon}</div>
                                <h2 className="text-xl font-semibold mb-4 text-center">{migration.title}</h2>
                                <ul className="list-none space-y-2 flex-grow">
                                    {migration.points.map((point, idx) => (
                                        <li key={idx} className="flex items-start">
                                            <span className="inline-block w-2 h-2 rounded-full bg-gradient-to-r from-[#a100ff] to-[#57CFFF] mr-2 mt-1.5 flex-shrink-0"></span>
                                            <span className="text-sm text-gray-300">{point}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const SAPCloudEvolution = () => {
        const switchCloudData = [
            {
                title: "Unlock Business Agility with DIAA Corp's Digital Transformation Suite",
                included: [
                    "Comprehensive IT Modernization",
                    "Cloud Migration Services",
                    "End-to-End Application Management"
                ],
                benefits: [
                    "Cost Optimization",
                    "Centralized Operations Hub",
                    "AI-Powered Efficiency",
                    "Cross-Platform Management"
                ]
            },
            {
                title: "Accelerate Growth with DIAA Corp's Smart Enterprise Suite",
                included: [
                    "Smart Enterprise Platform Implementation",
                    "Seamless ERP Migration",
                    "Integrated Application Services"
                ],
                benefits: [
                    "Lower Operational Costs",
                    "Enhanced Transparency",
                    "Intelligent Automation",
                    "Future-Ready Architecture"
                ]
            }
        ];

        return (
            <div className=" text-white p-8 mt-12 md:mt-24">
                <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center">
                    Embrace Seamless Digital Transformation with DIAA Corp's Expertise
                </h1>
                <p className="text-sm mb-12 text-left mx-auto">
                    In today's fast-paced digital landscape, businesses need to adapt quickly to remain competitive. DIAA Corp, a trusted global leader in enterprise solutions, empowers organizations to achieve their digital transformation goals with cutting-edge tools and strategies.
                    From modernizing legacy systems to optimizing operations through automation, DIAA Corp offers a
                </p>

                {switchCloudData.map((data, index) => (
                    <div key={index} className="mb-12">
                        <h2 className="text-2xl font-semibold mb-6 text-center mt-12 md:mt-24">{data.title}</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto mt-6 md:mt-12">
                            <div className="relative">
                                <div className="absolute inset-0 bg-gradient-to-br from-[#a100ff] to-[#57CFFF] opacity-50 rounded-lg  -left-1 -bottom-1"></div>
                                <div className="relative bg-[#14192E] p-6 rounded-lg h-full">
                                    <h3 className="text-xl font-semibold mb-4">What's Included</h3>
                                    <ul className="space-y-2">
                                        {data.included.map((item, idx) => (
                                            <li key={idx} className="flex items-start">
                                                <span className="text-blue-400 mr-2">•</span>
                                                <span>{item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="relative">
                                <div className="absolute inset-0 bg-custom-gradient opacity-50 rounded-lg -left-1 -bottom-1"></div>
                                <div className="relative bg-[#14192E] p-6 rounded-lg h-full">
                                    <h3 className="text-xl font-semibold mb-4">Benefits</h3>
                                    <ul className="space-y-2">
                                        {data.benefits.map((item, idx) => (
                                            <li key={idx} className="flex items-start">
                                                <span className="text-blue-400 mr-2">•</span>
                                                <span>{item}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const SAPSecurityAssessment = () => {
        const hexagonItems = [
            { text: 'Identify', position: 'col-start-3 row-start-1' },
            { text: 'Analyse', position: 'col-start-2 row-start-2' },
            { text: 'Plan', position: 'col-start-4 row-start-2' },
            { text: 'Track', position: 'col-start-1 row-start-3' },
            { text: 'Control', position: 'col-start-5 row-start-3' },
        ];

        return (
            <div className="text-white p-8 mt-12 md:mt-24">
                <h1 className="text-xl md:text-3xl font-bold text-center max-w-4xl mx-auto">
                    Securing the SAP Environment on
                </h1>
                <p className='text-xl md:text-3xl font-bold mb-12 text-center max-w-4xl mx-auto'>Cloud: Continuous Monitoring and Risk Assessment</p>

                <div className="relative max-w-3xl mx-auto mt-16">
                    {/* Central "Communicate" text */}
                    <div className="absolute inset-0 flex items-center justify-center z-10">
                        <span className="text-theme font-semibold text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">Communicate</span>
                    </div>

                    {/* Hexagon grid */}
                    <div className="grid grid-cols-5 grid-rows-3 gap-4">
                        {hexagonItems.map((item, index) => (
                            <div key={index} className={`${item.position} flex items-center justify-center`}>
                                <div className="relative w-24 h-28 sm:w-28 sm:h-32 md:w-32 md:h-36">
                                    <svg viewBox="0 0 100 100" className="w-full h-full">
                                        <polygon
                                            points="50 1 95 25 95 75 50 99 5 75 5 25"
                                            fill="transparent"
                                            stroke="url(#custom-gradient)"
                                            strokeWidth="1"
                                        />
                                        <text x="50" y="50" dominantBaseline="middle" textAnchor="middle" fill="white" fontSize="14">
                                            {item.text}
                                        </text>
                                        <defs>
                                            <linearGradient id="custom-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                <stop offset="0%" stopColor="#4F75FF" />
                                                <stop offset="100%" stopColor="#a100ff" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const KnowledgeDesk = () => {
        const [activeStory, setActiveStory] = useState('Falconpack');

        const cards = [
            {
                title: 'Building an Innovation-first organization with SAP on AWS',
                description: 'Explore how to deploy SAP architecture on AWS cloud to build a scalable, agile enterprise for relentless production at full disruption.',
            },
            {
                title: 'SAP Application Modernization on Cloud',
                description: 'Explore the nuances of modifying, modernizing SAP applications on the cloud. Learn how to achieve the same fail-proof.',
            },
            {
                title: 'SAP Apps Migration to Microsoft Azure',
                description: 'Decode the Azure destination for your mission-critical SAP workloads and applications. Learn best practices and KPIs.',
            },
            // Add other cards here
        ];

        const stories = {
            Falconpack: {
                title: 'Falconpack',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: successStoryImg,
            },
            'Oil and Gas major': {
                title: 'Oil and Gas major',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: successStoryImg,
            },
            'Global Ceramics Brand': {
                title: 'Global Ceramics Brand',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: successStoryImg,
            },
            'Jaleel Holdings': {
                title: 'Jaleel Holdings',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: successStoryImg,
            },
            // Add other stories here
        };

        return (
            <div className="text-white mobile:px-4 md:p-8 mt-12 md:mt-24">
                <h2 className="text-xl md:text-3xl font-bold mb-6 text-center">From our Knowledge Desk</h2>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-12 px-4 sm:px-6">
                    {cards.map((card, index) => (
                        <div key={index} className="bg-[#14192E] p-6 rounded-2xl w-full">
                            <h3 className="text-base sm:text-lg lg:text-xl font-semibold mb-4">{card.title}</h3>
                            <p className="mb-4 text-sm sm:text-base">{card.description}</p>
                            <button className="text-theme hover:text-blue-300 transition-colors duration-300 text-sm sm:text-base">
                                Read more <span className="ml-2">&raquo;</span>
                            </button>
                        </div>
                    ))}
                </div>

                <h2 className="text-3xl font-bold mb-6 text-center md:mt-36">Our Success Stories</h2>

                <div className="flex flex-wrap gap-4 mb-8 justify-center mt-12 md:my-12 md:space-x-8">
                    {Object.keys(stories).map((storyKey) => (
                        <button
                            key={storyKey}
                            className={`px-4 md:px-6 py-2 rounded-full text-[#F1F1F1] ${activeStory === storyKey
                                ? 'bg-custom-gradient'
                                : 'border border-[#F1F1F1] hover:bg-gray-600'
                                } transition-colors duration-300`}
                            onClick={() => setActiveStory(storyKey)}
                        >
                            {storyKey}
                        </button>
                    ))}
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeStory}
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.3 }}
                        className="md:flex items-center"
                    >
                        <img
                            src={stories[activeStory].image}
                            alt={stories[activeStory].title}
                            className="md:w-1/2 rounded-lg md:mr-8 mobile mb-2"
                        />
                        <div>
                            <h3 className="text-2xl font-semibold mb-4">{stories[activeStory].title}</h3>
                            <p>{stories[activeStory].description}</p>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        );
    };

    const features = [
        { icon: '🌐', text: "Global Leaders in Cloud Transformation - Trusted by enterprises worldwide for seamless SAP migrations, modernization, and managed services across leading cloud platforms like AWS, Azure, Google Cloud, and OCI" },
        { icon: '📋', text: "Comprehensive Expertise - Certified in SAP Application Management, S/4HANA Operations, and Cloud Services, ensuring top-notch service delivery tailored to your business needs" },
        { icon: '👨‍💻', text: "Unmatched Talent Pool - A team of 250+ SAP specialists and 2000+ certified cloud experts, ready to drive your digital transformation" },
        { icon: '📈', text: "Proven Track Record - Successfully deployed 565 SAP projects, completed 1000+ SAP migrations, and managed 15,000+ SAP instances globally" },
        { icon: '🚀', text: "Cutting-Edge Innovation - Leaders in SAP HANA workload management with 2+ petabytes of data, extensive system refreshes, and reduced workloads by 50%" },
        { icon: '🔒', text: "Security and Compliance Excellence - Offering geography and industry-specific compliance services, including SAP-certified solutions for GxP, HIPAA, and more" },
        { icon: '☁️', text: "End-to-End Cloud Solutions - Dedicated SAP on AWS Center of Excellence with a focus on cloud-powered managed services and third-party integrations" },
        { icon: '🏆', text: "Unparalleled Commitment to Quality - Delivering the industry's highest SLAs (99.9%) and ensuring optimal application performance up to the login layer" }
    ];

    const ImpactDifference = () => {
        return (
            <FeatureGrid
                title="Why Choose DIAA Corp for Your SAP Cloud Journey?"
                features={features}
            />
        );
    };

    const FAQSection = () => {
        const faqs = [
            {
                question: "What is the SAP Migration Factory Approach?",
                answer: "DIAA Corp’s SAP Migration Factory employs an automated, systematic method to migrate SAP workloads to the cloud. This approach often includes application and database modernization or transitioning from ECC to SAP S/4HANA. Inspired by assembly-line efficiency, it ensures seamless, high-availability outcomes with minimal disruption."
            },
            {
                question: "Why Move to SAP on Cloud Instead of On-Premises?",
                answer: "Cloud-based SAP solutions offer unmatched scalability, cost savings, and simplified maintenance. With enhanced accessibility and flexibility, the cloud enables businesses to collaborate seamlessly and stay agile in today’s competitive landscape."
            },
            {
                question: "How Does DIAA Corp’s SAP Migration Compare to SAP RISE?",
                answer: "DIAA Corp’s SAP Migration is a fully customizable solution, designed to meet unique business requirements with flexibility and tailored services. In contrast, SAP RISE is a bundled offering from SAP, providing an integrated package with pre-defined features for cloud migration."
            },
        ];

        return (
            <div className="flex items-center justify-center p-4">
                <div className="max-w-3xl w-full">
                    <h1 className="text-xl md:text-3xl font-bold text-white text-center mb-8 md:mb-16">
                        SAP Migration Factory - Your Questions Answered
                    </h1>
                    {faqs.map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
            </div>
    );
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} isExploreBtn={true} backgroundImage={coverImage} />

            <div>
                <MigrationInfo {...migrationInfoData} isMigrationFactory={true} />
            </div>

            <div className="container mx-auto md:px-4 py-16">
                <MigrationFactory />
            </div>

            <div>
                <ContactContent title={`Begin your SAP transformation journey with DIAA Corp. Connect with us today.`} />
            </div>

            <div>
                <MigrationFactoryBenefits />
            </div>

            <div>
                <ContactContent title={`Evolve your SAP Workflows on the Cloud at Zero Loss. Connect with us for a custom Discovery Workshop`} />
            </div>

            <div className='flex flex-col justify-center items-center text-center mt-12 sm:mt-24 bg-[#14192E] min-h-[200px] sm:h-[293px] px-4 sm:px-8 border-l-4 border-l-theme'>
                <div className='w-full sm:w-11/12'>
                    <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6">Global Leader in Cloud Transformation for Enterprises</h2>
                    {/* <p className="text-sm sm:text-base">for 180+ Enterprises of SAP HEC</p> */}
                </div>
            </div>

            <div className="relative z-10 mt-8 sm:-mt-20 container mx-auto px-4">
                <div className="flex flex-wrap justify-center items-center gap-4 sm:gap-6">
                    {infoCardItems.map((items, index) => (
                        <InfoCard key={index} title={items.title} sub={items.sub} />
                    ))}
                </div>
            </div>

            <div className='mx-auto md:px-4 py-16'>
                <SapCenterOfExcellence />
            </div>

            <div className='mx-auto md:px-4 md:py-16 flex flex-col justify-center items-center '>
                <SapServiceGrid />
            </div>

            <div className='mx-auto 4xl:py-16'>
                <LocationMap />
            </div>

            <div className='mx-auto  4xl:py-16'>
                <SapMigrations />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <MigrationFactory2 />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <MigrationFactoryMap />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <SAPCloudEvolution />
            </div>

            <div>
                <ContactContent title={`Let DIAA Corp charter a unique SAP evolution template on the cloud for your business. Connect with us today.`} />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <SAPSecurityAssessment />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <KnowledgeDesk />
            </div>

            <div className='mx-auto md:w-11/12 4xl:py-16'>
                <ImpactDifference />
            </div>

            <div className='mx-auto mt-12 md:mt-24'>
                <ContactContent title={`Sign up for a Discovery workshop to assess cloud maturity of your existing SAP workloads. Charter a fail-proof SAP transformation blueprint on the cloud.`} />
            </div>

            <div className='mx-auto mt-12 md:mt-24'>
                <FAQSection />
            </div>

            <div>
                <Footer />
            </div>

        </div>
    )
}

export default SAPMigrationFactory