import React from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/TechnologyTransformation_cover.png'
import { LeaderGrid, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import TechnologyTransformation_img1 from '../../assets/Capabilities/TechnologyTransformation_img1.png'
import TechnologyTransformation_img2 from '../../assets/Capabilities/TechnologyTransformation_img2.png'
import TechnologyTransformation_img3 from '../../assets/Capabilities/TechnologyTransformation_img3.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { useDocumentTitle } from '../../utils/useDocumentTitle';

function TechnologyTransformation() {
    useDocumentTitle("Technology Transformation");
    const TechTransformationStats = () => {
        const stats = [
            { value: '92%', description: 'of executives believe their business must accelerate digital transformation to stay competitive' },
            { value: '4x', description: 'increase in organizations prioritizing digital tools and technologies' },
            { value: '180%', description: 'growth in the adoption of AI, IoT, and blockchain to drive business innovation' },
        ];

        return <StatsGrid title="Tech Transformation Today" stats={stats} />;
    };


    const ReinventWithCloud = () => {
        const cards = [
            {
                title: 'Digital Backbone',
                description: 'Establish a robust digital foundation to support ongoing innovation and agility.',
                hasButton: true,
            },
            {
                title: 'Tech Strategy & Innovation',
                description: 'Drive value and adaptability by aligning technology with evolving business needs.',
                hasButton: true,
            },
            {
                title: 'Talent, Culture, & Agility',
                description: 'Foster a culture of growth and transformation by upskilling and reskilling your workforce.',
                hasButton: true,
            },
            {
                title: 'Next-Gen Tech Blueprint',
                description: 'Develop a roadmap for future-ready solutions that enhance collaboration and meet customer expectations.',
                hasButton: true,
            },
        ];

        return <ReinventGrid title="Reinvent with Tech Transformation" cards={cards} />;
    };

    const slides = [
        {
            title: "The Future of AI",
            description: "Explore how artificial intelligence is reshaping industries and creating new opportunities for innovation.",
            image: TechnologyTransformation_img1,
            gradient: "from-purple-400 to-purple-600"
        },
        {
            title: "Sustainable Tech Innovations",
            description: "Discover how technology is driving sustainability and helping businesses reduce their environmental impact.",
            image: TechnologyTransformation_img2,
            gradient: "from-blue-400 to-blue-600"
        },
        {
            title: "The Rise of Decentralized Systems",
            description: "Learn how blockchain and decentralized networks are transforming business operations and security.",
            image: TechnologyTransformation_img3,
            gradient: "from-cyan-400 to-cyan-600"
        }
    ];

    const MetaverseTrendingCarousel = () => {
        return <TrendingCarousel title="What's Trending?" slides={slides} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };
    return (
        <div className='bg-bg text-white'>
            <Header
                title="Accelerate Your Digital Transformation"
                description="Empower your business to rapidly evolve by embracing the right strategies, skilled talent, and modern methodologies. Enhance agility, optimize ROI, and strategically invest in innovative solutions that accelerate growth and position your organization for the future."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <TechTransformationStats />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithCloud />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <MetaverseTrendingCarousel />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20 mb-8 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>
    )
}

export default TechnologyTransformation
