import React, { useEffect, useState } from 'react'
import image1 from '../assets/SapS4hana/loio92c4b32791cf432ab5693d65879a39bc_LowRes.png'; // Replace with your actual image paths
import image2 from '../assets/SapS4hana/sap-s4hana-dashboard-screenshot.png';
import image3 from '../assets/SapS4hana/Customers-Overview-2-1.png';
import logo1 from '../assets/SapS4hana/logo1.png';
import imageCover from '../assets/SapS4hana/SapBusinessCover.png';

// import Footer from '../components/Footer';

// pages/SapS4hanaDemo.js
import { Header } from '../components/resuable/SapSolution';
import { InfoCards } from '../components/resuable/SapSolution';
import { Gallery } from '../components/resuable/SapSolution';
import { PaymentCards } from '../components/resuable/SapSolution';
import { BenefitsSection } from '../components/resuable/SapSolution';
import { PartnerLogos } from '../components/resuable/SapSolution';
import { FooterComponent } from '../components/resuable/SapSolution';
import { useDocumentTitle } from '../utils/useDocumentTitle';

const SapBusinessBydesign = () => {
    const headerData = {
        title: "What Is SAP Business ByDesign",
        description: "SAP Business ByDesign is a cloud-based ERP solution designed for fast-growing businesses like Diaa Corp. Built from the ground up with flexible, best-practice business processes, it enables organizations to scale efficiently while maintaining control and visibility across all functions."
    };
    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const infoCardsData = [
        {
            title: "SAP Business ByDesign Industries :",
            items: ['Energy & Natural Resources', 'Government & Public Sector', 'Wholesale & Distribution', 'Retail & Hospitality', 'Engineering, Construction & Operations (EC&O)', 'Professional Services']
        },
        {
            title: "SAP Business ByDesign Availablity:",
            items: ['On Prime', 'Cloud'],
            icon: 'M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z'
        },
        {
            title: "SAP Business ByDesign Employees:",
            items: ['SMB'],
            icon: 'M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z'
        }
    ];

    const galleryData = {
        images: [image1, image2, image3],
        description: "Dive into the powerful features and functionalities of SAP Business ByDesign through our interactive gallery. Discover how Diaa Corp can streamline its operations, enhance collaboration, and drive growth with a comprehensive, cloud-based ERP solution. See how SAP Business ByDesign helps businesses like yours optimize everything from finance to supply chain management, ensuring efficiency and scalability across all processes."
    };

    const benefitsData = {
        title: "SAP Business ByDesign Benefits",
        description: "",
        benefits: [
            {
                icon: '📊',
                title: 'BUILT FOR GROWTH',
                description: 'This Cloud-based ERP is designed to adapt to how you work and scale as you add employees, add business units, add new markets—add new ways of working, and new lines of business.'
            },
            {
                icon: '⏱️',
                title: 'SUITE IN A BOX',
                description: 'End-to-end solution for all facets and functions of your business delivered in an affordable, quick-to-implement package.'
            },
            {
                icon: '📄',
                title: 'INSIGHTFUL',
                description: 'Share and see accurate, real-time data across functions. SAP Business ByDesign provides transparency into every corner of your business to empower you to make data-driven decisions.'
            },
            {
                icon: '📈',
                title: 'CONTINUOUS INNOVATION',
                description: "It's always improving—with a proven track record of continuous innovation and delivering ERP in the cloud for companies like yours.."
            },
        ]
    };

    const partnerLogosData = {
        title: "SAP Business ByDesign Partners",
        logos: [logo1, logo1, logo1, logo1]
    };

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} backgroundImage={imageCover} />
            <InfoCards cards={infoCardsData} />
            <Gallery {...galleryData} />
            <PaymentCards title={`SAP S/4 HANA Payment Models`} />
            <BenefitsSection {...benefitsData} />
            <PartnerLogos {...partnerLogosData} />
            <FooterComponent />
        </div>
    );
};

export default SapBusinessBydesign;