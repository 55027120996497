import React from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/CommunicationsAndMedia_cover.png'
import communications_img1 from '../../assets/Capabilities/communications_img1.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import { ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Communications_img2 from '../../assets/Capabilities/communications_img2.png'
import Communications_img3 from '../../assets/Capabilities/communications_img3.png'
import Communications_img4 from '../../assets/Capabilities/communications_img4.png'
import communications_img5 from '../../assets/Capabilities/communications_img5.png'
import communications_img6 from '../../assets/Capabilities/communications_img6.png'
import communications_img7 from '../../assets/Capabilities/communications_img7.png'
import communications_img8 from '../../assets/Capabilities/communications_img8.png'
import Communications_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import Segment_img2 from '../../assets/Capabilities/Segment_img2.png'
import Segment_img3 from '../../assets/Capabilities/Segment_img3.png'
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { useDocumentTitle } from '../../utils/useDocumentTitle';


function CommunicationsAndMedia() {
    
    useDocumentTitle("Communication and Media");

    const CommunicationsAndMediaStats = () => {
        const stats = [
            { value: '$45B', description: 'Total Addressable Market: $45 billion' },
            { value: '35%', description: 'Investment in Innovation: 35% of companies are channeling funds into AI and machine learning to enhance customer experience.' },
            { value: '86%', description: 'Consumer Willingness: 86% of consumers are ready to pay a premium for a superior customer experience.' },
            { value: '$1.7T', description: 'Economic Impact of 5G: Expected to contribute $1.7 trillion to the global economy by 2030.' },
        ];

        return <StatsGrid title="The Future of Communications & Media" stats={stats} />;
    };


    const SegmentsWeSupport = () => {
        const [currentSlide, setCurrentSlide] = React.useState(0);
        const slides = [
            {
                category: "MEDIA",
                title: "Communications",
                description: "Communication providers enable mobile, scalable connectivity and information sharing between consumers, businesses and governments.",
                image: communications_img1,
            },
            {
                category: "ENTERTAINMENT",
                title: "Media & Entertainment",
                description: "Media and entertainment companies create, produce, and distribute content across various platforms to engage and entertain audiences.",
                image: Segment_img2, // Using the same image for demonstration
            },
            {
                category: "TECHNOLOGY",
                title: "Digital Services",
                description: "Digital service providers offer innovative solutions and platforms that enhance connectivity, productivity, and user experiences.",
                image: Segment_img3, // Using the same image for demonstration
            },
        ];

        const nextSlide = () => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 }
        };

        return (
            <>
                <h2 className="text-2xl font-bold mb-6 xl:mb-12 text-center">Sectors We Support</h2>
                <div className="bg-[#14192E] text-white p-4 sm:p-8 rounded-2xl">
                    <div className="bg-navy-800 rounded-lg p-4 sm:p-6">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentSlide}
                                variants={slideVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                            >
                                <div className="flex items-center mb-4">
                                    <div className="w-3 h-3 bg-purple-500 rounded-full mr-2"></div>
                                    <span className="text-purple-500 font-semibold">{slides[currentSlide].category}</span>
                                </div>
                                <div className="flex flex-col sm:flex-row items-start">
                                    <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                        <div className="bg-purple-900 rounded-lg overflow-hidden">
                                            <img
                                                src={slides[currentSlide].image}
                                                alt={slides[currentSlide].title}
                                                className="w-full h-auto object-cover"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-grow">
                                        <h3 className="text-xl font-semibold mb-2">{slides[currentSlide].title}</h3>
                                        <p className="text-gray-400 mb-4">
                                            {slides[currentSlide].description}
                                        </p>
                                        <div className="flex justify-end">
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="border-2 border-theme/50 rounded-full p-2"
                                            >
                                                <ChevronRight
                                                    className="text-theme cursor-pointer"
                                                    size={24}
                                                    onClick={nextSlide}
                                                />
                                            </motion.div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </>
        );
    };

    const ReinventWithCloud = () => {
        const cards = [
            {
                title: 'Innovative Growth Strategies',
                description: 'Drive new revenue streams while optimizing investments in innovation and growth.',
                hasButton: false,
            },
            {
                title: 'Customer-Centric Experience',
                description: 'Deliver seamless and instant access to what your customers want, wherever they are.',
                hasButton: false,
            },
            {
                title: 'Network Evolution',
                description: 'Speed up the deployment and scaling of your network to meet the demands of tomorrow.',
                hasButton: false,
            },
            {
                title: 'Business, IT, and Operational Transformation',
                description: 'Create agile and innovative business models that enhance both core functions and unique differentiators.',
                hasButton: false,
            },
            {
                title: 'Monetizing Attention & Advertising',
                description: 'In an age of constant media, attention is your most valuable asset—leverage it effectively.',
                hasButton: false,
            },
            {
                title: 'SMB Expansion',
                description: 'Small and medium-sized businesses play a vital role in driving economic growth and innovation.',
                hasButton: false,
            },
            {
                title: 'Strategic Ecosystem Development',
                description: 'Maximize value by optimizing and unifying assets, services, and operational processes.',
                hasButton: false,
            },
        ];




        return <ReinventGrid title="Revolutionize Communication and Media" cards={cards} />;
    };
    const slides = [
        {
            title: "Innovate for Success: Bold Moves Drive Sustainable Growth",
            image: Communications_img2,
            gradient: "from-purple-600 to-pink-500",
            description: "In an era where rapid change is the only constant, innovation becomes the cornerstone of success. From adopting new technologies to reimagining customer engagement, sustainable growth comes from breaking boundaries and leading with purpose. Companies must invest in emerging trends, prioritize environmental and social responsibility, and leverage creativity to stay ahead of the competition. The future belongs to those who innovate fearlessly while addressing the evolving demands of their audiences."
        },
        {
            title: "From Adapting to Leading: Digital Transformation for Future Enterprises",
            image: Communications_img3,
            gradient: "from-blue-600 to-cyan-500", 
            description: "Digital transformation is no longer just an option—it's a mandate for enterprises aiming to lead the future of communications and media. Whether through AI-driven content personalization, cloud-based infrastructure, or data analytics, businesses must turn transformation into a strategic advantage. By fostering a culture of innovation and prioritizing customer-centric digital solutions, enterprises can position themselves as industry leaders and redefine the benchmarks for success."
        },
        {
            title: "Transforming M&A Strategies with the Power of AI",
            image: Communications_img4,
            gradient: "from-indigo-600 to-purple-500",
            description: "Artificial intelligence is revolutionizing mergers and acquisitions, reshaping the way deals are identified, evaluated, and executed in the communications and media industries. From predicting market trends to enhancing due diligence processes, AI equips decision-makers with unparalleled insights. By integrating AI into their M&A strategies, businesses can drive smarter, faster, and more impactful deals, creating a competitive edge in a dynamic market landscape."
        }
    ];

    const CommunicationsMediaTrendingCarousel = () => {
        return <TrendingCarousel title="What's Shaping the Future of Technology & Innovation" slides={slides} />;
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "2024 Microsoft Media and Telco Partner of the Year",
                image: communications_img5,
                alt: "Microsoft Partner of the Year Award"
            },
            {
                title: "Databricks GSI partner of the year 2023, 4th year in a row",
                image: communications_img6,
                alt: "Databricks GSI Partner Award"
            },
            {
                title: "TM Forum 2023 Catalyst: AI & Innovation Excellence Award",
                image: communications_img7,
                alt: "TM Forum Catalyst Award"
            },
            {
                title: "Everest Group 5G Engineering Services PEAK Matrix® Assessment 2023",
                image: communications_img8,
                alt: "Everest Group 5G Services Assessment"
            },
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };
    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Communications_leader_img1 },
            { name: "Johnson", title: "Lead - Data & AI", imageUrl: Communications_leader_img1 },
            { name: "Shakriya", title: "Chief AI Officer", imageUrl: Communications_leader_img1 },
            { name: "Chandran", title: "Chief Responsible AI Officer", imageUrl: Communications_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };


    return (
        <div className='bg-bg text-white'>
            <Header
                title="Empower Seamless Connections for Communications & Media"
                description="In a world where people and businesses are constantly connected—whether creating, collaborating, or driving transformative growth—success depends on uninterrupted experiences. Stay ahead by providing the dynamic solutions and continuous engagement they demand to thrive in this ever-evolving landscape."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <CommunicationsAndMediaStats />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventWithCloud />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <CommunicationsMediaTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <GlobalRecognition />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>
    )
}

export default CommunicationsAndMedia
