import React, { useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Industries/HighTech/_coverImg.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents'
import { ContactContent } from '../../components/resuable/SapComponents'
import Footer from '../../components/Footer'
import { useDocumentTitle } from '../../utils/useDocumentTitle'
import { ChevronRight, ChevronLeft } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

// Import images
import _img1 from '../../assets/Industries/HighTech/_img1.png'
import _img2 from '../../assets/Industries/HighTech/_img2.png'
import _img3 from '../../assets/Industries/HighTech/_img3.png'
import _img4 from '../../assets/Industries/HighTech/_img4.png'
import _img5 from '../../assets/Industries/HighTech/_img5.png'
import _img6 from '../../assets/Industries/HighTech/_img6.png'
import _img7 from '../../assets/Industries/HighTech/_img7.png'
import _img8 from '../../assets/Industries/HighTech/_img8.png'
import _img9 from '../../assets/Industries/HighTech/_img9.png'
import _img10 from '../../assets/Industries/HighTech/_img10.png'

import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'

function HighTech() {
    useDocumentTitle("HighTech");

    const HighTechStats = () => {
        const stats = [
            { 
                value: "75%", 
                description: "of executives are focusing on integrating AI-driven automation to streamline operations and enhance efficiency in 2024."
            },
            { 
                value: "$150B+", 
                description: "is being allocated globally for green tech initiatives, driving sustainable innovation and reshaping supply chains."
            },
            { 
                value: "35%", 
                description: "of tech graduates are entering non-tech roles, intensifying the need to upskill and attract specialized talent in the sector."
            },
            { 
                value: "92%", 
                description: "of leaders believe that quantum computing will be a game-changer for business strategy within the next decade."
            }
        ];
        return <StatsGrid title="Tech Trends Shaping the Future" stats={stats} />;
    };

    const SegmentsWeSupport = () => {
        const [currentSlide, setCurrentSlide] = React.useState(0);
        const slides = [
            {
                category: " SEGMENT 1",
                title: "HighTech Solutions",
                description: "Leading digital transformation with innovative solutions.",
                image: _img2,
            },
            {
                category: " SEGMENT 2",
                title: "Digital Innovation",
                description: "Transforming operations through cutting-edge digital tools.",
                image: _img6,
            },
            {
                category: " SEGMENT 3",
                title: "Technology Solutions",
                description: "Modernizing systems to reduce costs and improve efficiency.",
                image: _img7,
            },
        ];

        const nextSlide = () => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 }
        };

        return (
            <>
                <h2 className="text-2xl font-bold mb-6 xl:mb-12 text-center">Segments we support</h2>
                <div className="bg-[#14192E] text-white p-4 sm:p-8 rounded-2xl">
                    <div className="bg-navy-800 rounded-lg p-4 sm:p-6">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentSlide}
                                variants={slideVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                            >
                                <div className="flex items-center mb-4">
                                    <div className="w-3 h-3 bg-purple-500 rounded-full mr-2"></div>
                                    <span className="text-purple-500 font-semibold">{slides[currentSlide].category}</span>
                                </div>
                                <div className="flex flex-col sm:flex-row items-start">
                                    <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                        <div className="bg-purple-900 rounded-lg overflow-hidden">
                                            <img
                                                src={slides[currentSlide].image}
                                                alt={slides[currentSlide].title}
                                                className="w-full h-auto object-cover"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-grow">
                                        <h3 className="text-xl font-semibold mb-2">{slides[currentSlide].title}</h3>
                                        <p className="text-gray-400 mb-4">
                                            {slides[currentSlide].description}
                                        </p>
                                        <div className="flex justify-end">
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="border-2 border-theme/50 rounded-full p-2"
                                            >
                                                <ChevronRight
                                                    className="text-theme cursor-pointer"
                                                    size={24}
                                                    onClick={nextSlide}
                                                />
                                            </motion.div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </>
        );
    };

    const ReinventHighTech = () => {
        const cards = [
            {
                title: 'Subscription Economy',
                description: 'Transform with subscription-based models to maximize value. Shift to subscription-based services to achieve up to 3x revenue growth. By offering tailored B2B and B2C experiences, high-tech companies can build customer loyalty and drive long-term profitability.',
                buttonText: 'Discover how to lead the change',
                hasButton: true,
            },
            {
                title: 'Smart Manufacturing',
                description: 'Boost operations with cutting-edge technology. With substantial global investments, modernize manufacturing to enhance efficiency, reduce waste, and adopt sustainable practices. Leverage automation and IoT for faster, smarter operations.',
                buttonText: 'Explore the future of smart factories',
                hasButton: true,
            },
            {
                title: 'SMB Relationships',
                description: 'Bridge the trust gap with small businesses. 43% of SMBs feel underserved by their tech partners. By addressing their specific challenges, high-tech enterprises can become trusted allies, fostering deeper collaboration and loyalty.',
                buttonText: 'Learn how to build stronger SMB partnerships',
                hasButton: true,
            },
            {
                title: 'Dynamic Supply Chains',
                description: 'Redefine supply chains for agility and resilience. Leaders are embracing digital twins and AI-powered logistics to revolutionize supply networks. Stay competitive with adaptable and efficient systems designed for today’s demands.',
                buttonText: 'Find out how to modernize your supply chain',
                hasButton: true,
            },
            {
                title: 'AI at Scale',
                description: "Unlock AI's full potential to lead the market. While 95% of executives see AI as transformative, only 34% have scaled it effectively. Gain a competitive edge by adopting responsible, scalable AI solutions tailored to your business needs.",
                buttonText: 'Learn how to thrive with AI',
                hasButton: true,
            }
        ];
        return <ReinventGrid title="Revolutionizing High-Tech: Strategies for Growth" cards={cards} />;
    };

    const HighTechTrendingCarousel = () => {
        const slides = [
            {
                title: "Subscription-Based Models: The Future of High Tech",
                description: "High-tech companies are embracing subscription-based models to cater to the dynamic needs of modern consumers. By offering scalable and flexible solutions, these models provide customers with access to cutting-edge technologies without significant upfront costs. This shift allows businesses to deliver continuous value, foster customer loyalty, and generate recurring revenue streams. Subscription services also enable rapid innovation, allowing companies to respond quickly to market demands and ensure long-term growth in an ever-evolving industry landscape.",
                image: _img3,
                gradient: "from-purple-600 to-pink-500"
            },
            {
                title: "AI Transforming Chip Innovation",
                description: "Artificial intelligence is revolutionizing chip design, unlocking unparalleled advancements in performance and efficiency. This topic explores how AI-driven tools optimize every stage of chip development, from architectural design to testing and production. By leveraging AI, companies can significantly reduce development timelines and costs while achieving higher levels of precision. These innovations are paving the way for next-generation semiconductors, powering advanced technologies like 5G, autonomous vehicles, and edge computing.",
                image: _img4,
                gradient: "from-blue-600 to-cyan-500"
            },
            {
                title: "Digital Ecosystems Powering Transformation",
                description: "The convergence of cloud computing, artificial intelligence, and the Internet of Things (IoT) is creating powerful digital ecosystems that drive business transformation. This topic delves into how interconnected systems enhance operational efficiency, improve decision-making, and enable seamless collaboration. From smart factories to predictive maintenance, these ecosystems empower businesses to adapt and thrive in a connected world. The integration of these technologies not only boosts productivity but also unlocks new opportunities for innovation and growth.",
                image: _img8,
                gradient: "from-indigo-600 to-purple-500"
            },
            {
                title: "Generative AI Accelerates Tech Evolution",
                description: "Generative AI is revolutionizing the high-tech industry by accelerating innovation across various domains. This topic examines its impact on product design, where AI-generated models reduce development cycles and costs, and predictive analytics, where data-driven insights optimize decision-making. By enabling businesses to explore new possibilities, generative AI is transforming how high-tech companies deliver value. Its influence spans industries, driving breakthroughs in healthcare, manufacturing, and beyond, shaping a future defined by rapid technological evolution.",
                image: _img9,
                gradient: "from-indigo-600 to-purple-500"
            },
            {
                title: "Sustainability Leads the Way in Tech Innovation",
                description: "Sustainability is becoming a driving force behind technological innovation, as companies prioritize eco-friendly practices and green investments. This topic highlights how high-tech businesses are adopting energy-efficient data centers, renewable energy solutions, and sustainable supply chain practices to reduce their environmental impact. By aligning growth strategies with environmental responsibility, these organizations are setting new industry standards. Sustainability is not only a moral imperative but also a competitive advantage, as it attracts eco-conscious consumers and fosters long-term resilience.",
                image: _img10,
                gradient: "from-indigo-600 to-purple-500"
            }
        ];
        return <TrendingCarousel title="Emerging Trends in High Tech" slides={slides} />;
    };

    const ScrollableCards = () => {
        const [activeIndex, setActiveIndex] = useState(2);

        const cards = [
            { id: 1, logo: Cloud_partner_img1, content: 'Leading technology solutions provider' },
            { id: 2, logo: Cloud_partner_img2, content: 'Innovative platform partner' },
            { id: 3, logo: Cloud_partner_img3, content: 'AI and analytics solutions' },
            { id: 4, logo: Cloud_partner_img4, content: 'Digital integration partner' },
            { id: 5, logo: Cloud_partner_img1, content: 'Data management solutions' },
        ];

        const handlePrev = () => {
            setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cards.length - 1));
        };

        const handleNext = () => {
            setActiveIndex((prevIndex) => (prevIndex < cards.length - 1 ? prevIndex + 1 : 0));
        };

        return (
            <div className="flex flex-col items-center justify-center p-4 overflow-hidden">
                <h1 className="text-white text-2xl sm:text-3xl font-bold md:mb-12 text-center">Partners in HighTech Innovation</h1>
                <div className="relative w-full max-w-5xl">
                    <div className="flex justify-center items-center h-64 sm:h-80 md:h-96 overflow-visible">
                        <AnimatePresence initial={false}>
                            {cards.map((card, index) => {
                                const isActive = index === activeIndex;
                                const offset = (index - activeIndex) * (window.innerWidth < 640 ? 200 : 300);
                                const zIndex = cards.length - Math.abs(activeIndex - index);
                                return (
                                    <motion.div
                                        key={card.id}
                                        className="absolute bg-[#14192E] rounded-lg shadow-lg pt-4 sm:pt-8"
                                        initial={{ opacity: 0, scale: 0.8 }}
                                        animate={{
                                            opacity: isActive ? 1 : 0.6,
                                            scale: isActive ? 1 : 0.8,
                                            x: offset,
                                            zIndex: zIndex,
                                        }}
                                        exit={{ opacity: 0, scale: 0.8 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <div className="p-2 sm:p-4 md:p-6 h-full flex flex-col">
                                            <img 
                                                src={card.logo} 
                                                alt={`Partner logo ${card.id}`} 
                                                className="h-8 sm:h-12 object-contain mb-2 sm:mb-4 mx-auto" 
                                            />
                                            <p className="text-gray-300 text-xs sm:text-sm flex-grow overflow-hidden text-center">
                                                {card.content}
                                            </p>
                                        </div>
                                    </motion.div>
                                );
                            })}
                        </AnimatePresence>
                    </div>
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex gap-4 mt-4 sm:mt-8">
                        <motion.button
                            onClick={handlePrev}
                            className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                            aria-label="Previous card"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <ChevronLeft size={20} />
                        </motion.button>
                        <motion.button
                            onClick={handleNext}
                            className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                            aria-label="Next card"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <ChevronRight size={20} />
                        </motion.button>
                    </div>
                </div>
            </div>
        );
    };

    const PartnersInChange = () => {
        return <ScrollableCards />;
    };


    const LeadershipSection = () => {
        const leaders = [
            { name: "John Smith", title: "Global HighTech Lead", imageUrl: Cloud_leader_img1 },
            { name: "Sarah Johnson", title: "Digital Innovation Director", imageUrl: Cloud_leader_img1 },
            { name: "Michael Chen", title: "Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Emma Davis", title: "Solutions Lead", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Innovate to Lead: Shaping the Future of Technology"
                description="The demand for cutting-edge tech solutions is reshaping industries worldwide. Success hinges on developing groundbreaking products and services, optimizing global supply chains and production systems, and tapping into emerging talent pools. Companies that embrace reinvention today will define the world of tomorrow."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <HighTechStats />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventHighTech />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <HighTechTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <PartnersInChange />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent title="HighTech careers" subtitle="Grow your career at the heart of  transformation" />
            </div>
            <Footer />
        </div>
    );
}

export default HighTech
