import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import headerImage from '../assets/SAP/AMSCover.png'
import AMS_img_1 from '../assets/SAP/AMS_img_1.png'
import AMS_img_2 from '../assets/SAP/AMS_img_2.png'
import AMS_img_3 from '../assets/SAP/AMS_img_3.png'
import AMS_img_4 from '../assets/SAP/AMS_img_4.png'
import AMS_Bussiness_cover from '../assets/SAP/AMS_Bussiness_cover.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoice,
  faLink,
  faHandshake,
  faDatabase,
  faUsers,
  faChartLine,
  faWarehouse,
  faUserTie,
  faMoneyBillWave
} from '@fortawesome/free-solid-svg-icons';
import { Database, Monitor, Server, Columns, Grid, Table, HardDrive, Layout, PieChart, Globe, BarChart } from 'lucide-react';
import { ContactContent, scrollToBottom } from '../components/resuable/SapComponents'
import { AnimatePresence, motion } from 'framer-motion'
import { FaCloud } from 'react-icons/fa';
import { IoCloudOutline } from 'react-icons/io5';
import { MdCloudDone } from 'react-icons/md';
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'



function SAPApplicationManagementServices() {

  useDocumentTitle("SAP Application Management Services(AMS)");


  const InformationSystemOverview = () => {
    return (
      <div className={`relative z-10 mobile:mt-10 -mt-20 sm:-mt-24 lg:-mt-32 lg:-mt-40 xl:-mt-24 container mx-auto px-4 bg-[#49537780] rounded-lg mb-12 md:w-11/12 xl:w-10/12`}>
        <div className="lg:px-12 py-12 text-center flex flex-col items-center">
          <p className="mb-4">"In today's digital landscape, organizations implementing enterprise software solutions must focus on maximizing the long-term value of their investments."</p>
          <p className="text-theme font-bold">Strategic implementation is key to success!</p>
        </div>
      </div>
    );
  }

  const BusinessTechLayout = () => {
    return (
      <div className="w-11/12 mx-auto mt-8 sm:mt-12 xl:mt-24 text-white p-4 sm:p-8 flex flex-col lg:flex-row">
        <div className="w-full lg:w-2/3 lg:pr-8 mb-8 lg:mb-0">
          <h2 className="text-xl sm:text-2xl font-bold mb-4">Cloud Technology: Driving Innovation and Growth</h2>
          <p className="text-sm sm:text-base mb-4 text-[#F1F1F1]">The shift to cloud computing is revolutionizing industries, enabling organizations to innovate faster, adapt to market changes, and reduce operational costs. Real-time data processing, advanced analytics, and seamless integration are no longer optional but critical for success.</p>

          <p className="text-sm sm:text-base mb-4 text-[#F1F1F1]">Transitioning to the cloud requires strategic planning, a robust roadmap, and alignment with experienced partners. DIAA CORP ensures a seamless journey to the cloud by leveraging cutting-edge tools, proven methodologies, and a wealth of industry knowledge.</p>

          <p className="text-sm sm:text-base mb-4 text-[#F1F1F1]">Misconceptions often arise, such as assuming migration to the cloud is a one-time process or that it automatically resolves all challenges. Addressing these misconceptions is essential to unlock the full potential of cloud solutions.</p>

          <p className="text-sm sm:text-base mb-4 text-[#F1F1F1]">Data visualization tools, collaborative workspaces, and advanced security frameworks are critical components in maximizing the benefits of cloud technology.</p>
        </div>

        <div className="w-full lg:w-1/3 flex flex-wrap justify-center items-center gap-4">
          <div className="w-full h-48 sm:h-64 rounded-lg overflow-hidden shadow-lg">
            <img src={AMS_img_1} alt="Business chart" className="w-full h-full object-cover" />
          </div>

          <div className="w-5/12 h-36 sm:h-48 rounded-lg overflow-hidden shadow-lg">
            <img src={AMS_img_2} alt="Business meeting" className="w-full h-full object-cover" />
          </div>

          <div className="w-5/12 h-36 sm:h-48 rounded-lg overflow-hidden shadow-lg">
            <img src={AMS_img_3} alt="Technology concept" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    );
  };

  const infoCardItems = [
    {
      title: "Trusted by 70 of Fortune 500 Companies",
    },
    {
      title: "Presence in 30 Countries 60 Global Locations",
    },
    {
      title: "Serving 30 Industry Verticals",
    },
  ]
  const infoCardItems2 = [
    { title: "Advanced Manufacturing" },
    { title: "Retail Innovation" },
    { title: "Healthcare & Pharmaceuticals" },
    { title: "Technology Solutions" },
    { title: "Financial & Banking Services" },
  ]

  const InfoCard = ({ title }) => (
    <div className="flex flex-col bg-[#191F38] m-2 sm:m-4 md:m-6 rounded-lg drop-shadow-xl w-full sm:w-60 h-40 text-center justify-center p-4">
      <h2 className="text-base sm:text-lg md:text-xl font-semibold">{title}</h2>
    </div>
  );

  const DiaaDashboard = () => {
    const services = [
      "Cloud Migration & Integration",
      "Multi-Cloud Management",
      "Security and Compliance Solutions",
      "Data Analytics and AI Integration",
      "Infrastructure as a Service (IaaS)",
      "Platform as a Service (PaaS)",
      "Disaster Recovery and Backup",
      "Networking and Connectivity Solutions",
      "Our Commitment to Excellence",
      "End-to-End Cloud Management",
      "Continuous Performance Optimization"
    ];

    const managementAreas = [
      { title: "Quality Standards", value: "Certified ISO 9001 processes ensure top-tier project execution." },
      { title: "Security Practices", value: "Adhering to global standards like ISO/IEC 27001 for data security and risk management." },
      { title: "Continuity & Resilience", value: "Business continuity assured through compliance with ISO 22301 protocols." },
      { title: "Service Optimization", value: "ITIL and ISO 20000 practices enhance service efficiency." },
      { title: "Integrated Governance", value: "Operational excellence aligned with COBIT frameworks." },
      { title: "Uptime Guarantee", value: "Achieving 99.5% availability across services." },
      { title: "Round-the-Clock Assistance", value: "Dedicated global support available 24/7." }
    ];

    return (
      <div className="text-white p-8 w-11/12 mx-auto mt-12 xl:mt-24">
        <h1 className="text-2xl font-bold mb-6 md:mb-12 text-center">
          DIAA CORP Comprehensive Cloud Services
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12 mx-auto max-w-5xl">
          <ul className="flex flex-col items-center">
            {services.slice(0, 8).map((service, index) => (
              <li key={index} className="flex items-start mb-3 w-full">
                <span className="w-3 h-3 rounded-full mr-3 mt-1 bg-gradient-to-r from-purple-400 to-blue-500"></span>
                {service}
              </li>
            ))}
          </ul>
          <ul className="flex flex-col items-center">
            {services.slice(8).map((service, index) => (
              <li key={index} className="flex items-start mb-3 w-full">
                <span className="w-3 h-3 rounded-full mr-3 mt-1 bg-gradient-to-r from-purple-400 to-blue-500"></span>
                {service}
              </li>
            ))}
          </ul>
        </div>

        <h2 className="text-xl font-bold mb-6 text-center mt-12 xl:mt-24">
          DIAA CORP Commitment to Excellence
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-12 w-11/12 mx-auto">
          {managementAreas.map((area, index) => (
            <div key={index} className="bg-[#1C1E3B] rounded-lg p-4 text-center">
              <p className="text-xs sm:text-sm md:text-base mb-2 text-white">{area.title}</p>
              <p className="text-sm sm:text-base md:text-lg text-theme font-semibold">{area.value}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const LinesOfBusinesses = () => {
    return (
      <div className="relative w-full bg-cover bg-center flex items-center justify-center pt-8 sm:pt-12 md:pt-24 mt-8 sm:mt-12 xl:mt-24">
        <div className="absolute top-0 left-0 w-full h-full">
          <div
            style={{
              background: `url(${AMS_Bussiness_cover})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              filter: 'blur(2px)',
              opacity: 0.7,
            }}
            className="w-full h-full"
          ></div>
          <div className="absolute inset-0 bg-black opacity-10"></div>
        </div>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-white text-center w-full px-4 md:w-4/5 mx-auto">
          <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-4 sm:mb-8">Driving Excellence with Technology</h1>
          <p className="text-sm sm:text-base md:text-lg mb-6">Our robust service offerings cater to key operational areas to empower businesses with innovation and agility:</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4 sm:my-8 md:my-16 lg:my-24">
            {[
              { icon: <FontAwesomeIcon icon={faFileInvoice} />, title: 'Enterprise Resource Planning (ERP)' },
              { icon: <FontAwesomeIcon icon={faLink} />, title: 'Supply Chain Optimization (SCM)' },
              { icon: <FontAwesomeIcon icon={faHandshake} />, title: 'Vendor Relationship Management (VRM)' },
              { icon: <FontAwesomeIcon icon={faDatabase} />, title: 'Data Governance Solutions (MDG)' },
              { icon: <FontAwesomeIcon icon={faUsers} />, title: 'Customer Experience Platforms (CXP)' },
              { icon: <FontAwesomeIcon icon={faChartLine} />, title: 'Business Analytics and Insights (BAI)' },
              { icon: <FontAwesomeIcon icon={faWarehouse} />, title: 'Warehouse Automation (WA)' },
              { icon: <FontAwesomeIcon icon={faUserTie} />, title: 'Workforce Management Systems (WMS)' },
              { icon: <FontAwesomeIcon icon={faMoneyBillWave} />, title: 'Financial Operations & Reporting (FOR)' },
            ].map((item, index) => (
              <div key={index} className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-md p-4 rounded-lg flex flex-col items-center h-full">
                <span className='text-theme text-2xl mb-4'>
                  {item.icon}
                </span>
                <div className="text-center">
                  <h3 className="font-semibold text-sm sm:text-base md:text-lg">{item.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const SupportedSystemsDisplay = () => {
    const osDbSystems = [
      { name: 'Unix Variants', icon: Database },
      { name: 'Microsoft Windows', icon: Monitor },
      { name: 'SAP HANA', icon: Server },
      { name: 'Oracle Database', icon: Columns },
      { name: 'MaxDB and DB2', icon: Grid },
      { name: 'Microsoft SQL Server', icon: Table },
      { name: 'Sybase', icon: HardDrive },
    ];

    const sapComponents = [
      { name: 'Frontend Technologies including Persona, Fiori & UIS, SAPUI5', icon: Layout },
      { name: 'SAP Business Suite', icon: PieChart },
      { name: 'SAP NetWeaver Technology', icon: Globe },
      { name: 'SAP HANA Technology', icon: BarChart },
    ];

    const SystemButton = ({ name, Icon, fullWidth = false }) => (
      <div className={`bg-gray-800 rounded-t-2xl rounded-b-2xl p-4 flex flex-col items-center space-y-2 ${fullWidth ? 'col-span-2' : ''}`}>
        <div className="w-12 h-12 bg-custom-gradient rounded-full flex items-center justify-center mb-2">
          <Icon className="text-white" size={24} />
        </div>
        <span className="text-white text-center text-sm">{name}</span>
      </div>
    );

    const ContactContent3 = ({ title, sub }) => {
      return (
        <div className='flex flex-col justify-center items-center text-center bg-[#14192E] mobile:h-[293px] md:h-[471px] px-4 relative'>
          <div className='md:w-11/12 relative z-10'>
            <h2 className="text-lg md:text-2xl font-bold mb-4">{title}</h2>
            <p className='mb-12 mobile:text-sm'>{sub}</p>
            <motion.button
              className="bg-custom-gradient text-white font-normal py-2 px-12 rounded-full hover:bg-blue-600 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={scrollToBottom}
            >
              Contact us
            </motion.button>
          </div>
        </div>
      );
    }

    return (
      <div className="p-4 sm:p-8 text-white w-11/12 mx-auto mt-8 sm:mt-12 xl:mt-24">
        <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">OS/DB We Support</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-12">
          {osDbSystems.map((system, index) => (
            <SystemButton key={index} name={system.name} Icon={system.icon} />
          ))}
        </div>

        <h2 className="text-2xl font-bold mb-6">SAP Platform Components We Support</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {sapComponents.map((component, index) => (
            <div key={index} className="bg-gray-800 rounded-2xl p-6 flex flex-col items-center space-y-4">
              <div className="w-16 h-16 rounded-full flex items-center justify-center bg-custom-gradient">
                <component.icon className="text-white" size={32} />
              </div>
              <span className="text-white text-center text-sm font-medium">{component.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const FlexibleEngagementModels = () => {
    return (
      <div className="text-white p-4 sm:p-8 flex flex-col items-center mt-8 sm:mt-12 xl:mt-24">
        <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center">Tailored Service Models for SAP Application Management</h2>
        <p className="text-sm sm:text-base mb-4 sm:mb-6 max-w-3xl text-center text-[#F1F1F1]">
          DIAA Corp offers four distinct service models for managing SAP applications, designed to help organizations efficiently handle regular software updates, enhance system capabilities, and reduce operational costs.
        </p>
        <p className="text-sm sm:text-base mb-6 sm:mb-8 max-w-3xl text-center text-[#F1F1F1]">
          These models vary in terms of responsibility distribution, team location, benefits, pricing structures, and accountability for business and IT outcomes—both standard and customized. To learn more, please download the DIAA Corp SAP Application Management Services brochure.
        </p>
        <div className="flex flex-wrap justify-center gap-6 w-full max-w-4xl">
          {[
            { title: "🖼️ Supplementary Support", color: "bg-purple-600" },
            { title: "💱 Collaborative Partnership", color: "bg-blue-600" },
            { title: "🔧 Comprehensive Managed Service", color: "bg-green-600" },
            { title: "🔄 End-to-End Outsourcing", color: "bg-red-600" },
          ].map((item, index) => (
            <div key={index} className="bg-[#14192E] rounded-2xl p-4 flex flex-col items-center space-y-3 border border-gray-700 w-[calc(50%-12px)] sm:w-[calc(25%-18px)]">
              <div className={`${item.color} rounded-full p-3 w-12 h-12 flex items-center justify-center`}>
                {/* <span className="text-2xl">{item.title}</span> */}
              </div>
              <span className="text-sm font-semibold text-center">{item.title}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const Switch2Cloud = () => {
    return (
      <div className="text-white flex items-center justify-center p-4 mt-8 sm:mt-12 xl:mt-24">
        <div className="w-11/12 2xl:w-10/12 mx-auto">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-4 sm:mb-6 xl:mb-12">
            Accelerate Your Digital Transformation with DIAA Corp’s Switch2Cloud
          </h1>
          <p className="text-sm sm:text-base text-center mb-6 sm:mb-8 text-gray-300 xl:mb-12">
            DIAA Corp’s Switch2Cloud services streamline your migration and management process, helping you transition quickly to SAP S/4HANA. Our comprehensive services enable faster execution, greater business agility, and enhanced productivity—without disrupting your daily operations. This package combines SAP Managed Services, S/4HANA Transformation, and SAP Application Management Services to ensure a seamless journey for our clients.
          </p>

          <div className="bg-[#10142C] rounded-3xl p-8 mb-12 py-12 xl:py-24 backdrop-filter backdrop-blur-sm">
            <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8">
              Solutions to Fast-Track Your SAP Adoption:
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-5xl mx-auto">
              {[
                { title: "SAP Managed Services", Icon: FaCloud },
                { title: "SAP S/4HANA Conversion", Icon: IoCloudOutline },
                { title: "Application Management Services", Icon: MdCloudDone },
              ].map((item, index) => (
                <div key={index} className="relative group">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-600 rounded-3xl blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                  <div className="relative bg-[#1C2237] rounded-3xl p-8 flex flex-col items-center space-y-6 h-full">
                    <div className="w-24 h-24 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 flex items-center justify-center">
                      <item.Icon className="text-white text-5xl" />
                    </div>
                    <span className="text-lg font-medium text-center text-white">{item.title}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DashboardItem = ({ number, text, className }) => (
    <div className={`bg-gray-800 rounded-lg p-4 flex flex-col justify-between ${className}`}>
      <div className="text-purple-500 text-3xl font-bold">{number}</div>
      <div className="text-white text-sm mt-2">{text}</div>
    </div>
  );

  const Dashboard = () => (
    <div className="text-white p-4 sm:p-8 text-center">
      <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-8">Why Choose DIAA Corp for SAP Application Management Services?</h1>
      <p className="text-lg sm:text-xl mb-8 sm:mb-12">Partnering with DIAA Corp brings exceptional value through our vast expertise and proven track record in SAP application management. Here’s how we’ve made an impact:</p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
        {[
          { number: "300+", text: "SAP Professionals worldwide", className: "lg:col-span-1" },
          { number: "30+", text: "Centers of Excellence focused on application innovation and development", className: "lg:col-span-2" },
          { number: "1200+", text: "SAP Projects delivered successfully", className: "lg:col-span-1" },
          { number: "80+", text: "Application Management & Support engagements", className: "lg:col-span-2" },
          { number: "120+", text: "Advisory and Consulting Services with over 80 Optimization Initiatives", className: "lg:col-span-2" },
          { number: "90+", text: "Upgrade Planning & Strategy projects, with 30+ Upgrades completed", className: "lg:col-span-1" },
          { number: "1500+", text: "SAP Production Workloads handled", className: "lg:col-span-2" },
          { number: "600+", text: "Migration & Managed Services projects delivered", className: "lg:col-span-1" },
          { number: "500+", text: "SAP Implementations successfully completed", className: "lg:col-span-2" },
        ].map((item, index) => (
          <div key={index} className={`bg-[#1C1E3B] rounded-lg p-6 lg:py-12 flex flex-col justify-between ${item.className}`}>
            <div className="text-4xl font-bold mb-4 bg-custom-gradient bg-gradient-to-b from-theme to-transparent bg-clip-text text-transparent">
              {item.number}
            </div>
            <div className="text-sm text-gray-300">{item.text}</div>
          </div>
        ))}
      </div>
    </div>
  );

  const ServiceCard = ({ text }) => (
    <div className="bg-[#1C1E3B] rounded-lg p-4 flex items-center justify-center h-24">
      <p className="text-white text-center text-sm">{text}</p>
    </div>
  );

  const SupportServices = () => (
    <div className="p-8">
      <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto text-center mb-8">
        <h2 className="text-2xl font-semibold text-center mb-4">
          Transforming Your Digital Operations
        </h2>
        <p className="text-base leading-7 mb-6 text-[#F1F1F1]">
          Our seasoned experts deliver tailored IT solutions, focusing on system implementation, modernization, performance optimization, and lifecycle support. From cloud-native applications to legacy system upgrades, we leverage proven methodologies to accelerate outcomes.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <ServiceCard text="System Deployment & Migration: End-to-end services for seamless transitions and integrations" />
        <ServiceCard text="Managed Support Services: Offering proactive L1 to L4 incident handling" />
        <ServiceCard text="Release & Change Management: Streamlined processes for smoother operations" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <ServiceCard text="Quality Assurance: Comprehensive testing for all major and minor updates" />
        <ServiceCard text="Performance Optimization: Continuous improvement and enhancement services" />
      </div>
    </div>
  );


  return (
    <div className="bg-bg text-white">
      <Header
        title="DIAA CORP AI Powered SAP Application Management Services(AMS)"
        description="DIAA CORP’s SAP AMS are designed to help your organization use smart, efficient ways to optimize and generate more value from application portfolios. The services can range from on-demand SAP support through full application outsourcing and managed infrastructure (on-premise and/or cloud)."
        backgroundImage={headerImage}
        isExploreBtn={true}
      />
      <InformationSystemOverview />
      <div>
        <BusinessTechLayout />
      </div>


      <div>

        <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
          <h2 className="text-2xl font-semibold text-center mb-4">
            Why DIAA CORP is Your Trusted Cloud Partner?
          </h2>
          <h3 className="text-lg font-medium mb-6 text-[#C9C9C9F1]">
            Delivering Tailored Cloud Strategies for Business Excellence
          </h3>

          <p className="text-base leading-7 mb-6 text-[#F1F1F1]">
            DIAA CORP’s Cloud Center of Excellence (CCOE) specializes in crafting resilient and adaptable cloud strategies that align with your business goals. Our global delivery network ensures access to expert resources and the latest innovations to meet your unique challenges.
          </p>
        </div>

      </div>

      <div>
        <div className='flex flex-col justify-center items-center text-center mt-12 sm:mt-24 bg-[#14192E] min-h-[200px] sm:h-[293px] px-4 sm:px-8'>
          <div className='w-full sm:w-11/12'>
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6">One of the leading SAP partners with implementations across the world</h2>
          </div>
        </div>

        <div className="relative z-10 -mt-16 sm:-mt-20 container mx-auto px-4">
          <div className="flex flex-wrap justify-center items-center gap-4 sm:gap-6">
            {infoCardItems.map((item, index) => (
              <InfoCard key={index} title={item.title} />
            ))}
          </div>
        </div>
      </div>

      <div>
        <DiaaDashboard />
      </div>

      <div>
        <div className="w-11/12 text-white md:p-8 rounded-lg mx-auto text-center mt-12 xl:mt-24">
          <h2 className="text-2xl font-semibold text-center mb-4">
            DIAA Corp Services Portfolio          </h2>
          <h3 className="text-lg font-medium mb-6 text-[#C9C9C9F1]">
            Accelerating Your SAP Deployments & Migrations          </h3>

          <p className="text-base leading-7 mb-6 text-[#F1F1F1]">
            Our rich pool of certified Functional and Technical consultants provide Implementation, Development, Upgrade, Production support and Maintenance services for SAP ECC, S4H versions, SCM, Business Warehouse, CRM, GRC, Solution Manager, NetWeaver Applications, BI/BO, BPC, HANA etc., on a global delivery model. We have robust templates for Estimation, Implementation, Upgrade, Migration & support. Our accelerators around SAP System Deployments and Migrations, AMS help us to serve enterprises of any size.          </p>
        </div>
      </div>

      <div>
        <SupportServices />
      </div>


      <div>
        <div className='flex flex-col justify-start items-center text-center mt-12 sm:mt-24 bg-[#14192E] min-h-[200px] sm:h-[293px] px-4 sm:px-8'>
          <div className='w-full sm:w-11/12 mt-12 pb-12 md:pb-0'>
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6">Unlocking Potential Across Diverse Sectors</h2>
            <p className="text-sm sm:text-base text-[#C9C9C9F1] mb-6">Techfinity's expertise spans various industries, enabling us to deliver customized, high-impact solutions</p>
          </div>
        </div>

        <div className="relative z-10 mt-12 md:mt-0 lg:-mt-20 container mx-auto px-4">
          <div className="flex flex-wrap justify-center items-center gap-6 sm:gap-8">
            {infoCardItems2.map((item, index) => (
              <InfoCard key={index} title={item.title} />
            ))}
          </div>
        </div>
      </div>

      <div>
        <LinesOfBusinesses />
      </div>

      <div>
        <SupportedSystemsDisplay />
      </div>

      <div>
        <FlexibleEngagementModels />
      </div>

      <div className='mt-12 xl:mt-24'>
        <ContactContent title={`Not sure which engagement model is right for you?`} subtitle={`DIAA CORP offers the flexibility you’ve been looking for. We can start small with a pilot program and then customize a program that meets your specific needs and budget to ensure that your applications are running smoothly, and satisfaction levels of your end users would be our priority. Schedule a Free assessment to realize easily up to 40% reduced TCO on your SAP workloads`} />
      </div>

      <div>
        <Switch2Cloud />
      </div>

      <div>
        <Dashboard />
      </div>

      <div className='mt-12 xl:mt-24'>
        <ContactContent title={`Experience SAP And Its Impact On Business Transformation`} />
      </div>

      <div>
        <Footer />
      </div>

    </div>
  )
}

export default SAPApplicationManagementServices