import React, { lazy, Suspense } from 'react';
import { FaAngleRight } from 'react-icons/fa6';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useDocumentTitle } from '../utils/useDocumentTitle';

// Lazy-loaded components
const Boxes = lazy(() => import('../components/Boxes'));
const ValueComponent = lazy(() => import('../components/ValueComponent'));
const GlobalRecognition = lazy(() => import('../components/GlobalRecognition'));
const CareersSection = lazy(() => import('../components/CarriersSection'));
const NewsCarousel = lazy(() => import('../components/NewsCarousel'));
const Footer = lazy(() => import('../components/Footer'));

const AnimatedText = React.memo(({ text }) => {
    const words = text.split(" ");

    return (
        <motion.span>
            {words.map((word, index) => (
                <motion.span
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 0.5,
                        delay: index * 0.1,
                    }}
                    style={{ display: 'inline-block', marginRight: '0.3em' }}
                >
                    {word}
                </motion.span>
            ))}
        </motion.span>
    );
});

const headingVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.2,
            duration: 0.8,
            ease: "easeOut"
        }
    }
};

const paragraphVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.6,
            duration: 0.8,
            ease: "easeOut"
        }
    }
};

const buttonVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delay: 1,
            duration: 0.5,
            ease: "easeOut"
        }
    },
    hover: {
        scale: 1.05,
        transition: {
            duration: 0.2
        }
    }
};

const AnimatedSection = ({ children, variants, delay = 0 }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={variants}
            transition={{ duration: 0.8, delay }}
        >
            {children}
        </motion.div>
    );
};

const fadeInUpVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
};

const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
};

function Home() {
    useDocumentTitle('DIAA Corp : Bringing Innovations to Life with Our Clients');
    return (
        <div>
            <AnimatedSection variants={fadeInUpVariants}>
                <div className="flex flex-col items-center text-white my-24 w-11/12 mx-auto">
                    <motion.h1
                        className="text-3xl sm:text-4xl md:text-5xl font-semibold text-center"
                    >
                        <AnimatedText text="Bringing Innovations to Life with Our Clients" />
                    </motion.h1>
                    <motion.p
                        className="text-lg sm:text-xl md:text-2xl text-gray-300 font-normal text-center mt-12"
                    >
                        Our achievers bring transformative innovation to life.
                    </motion.p>
                    {/* <motion.button
                        className="bg-custom-gradient text-white font-semibold mt-4 py-2 px-6 2xl:px-12 rounded-full flex items-center justify-center space-x-2"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        Explore more
                        <FaAngleRight className="ml-2" />
                    </motion.button> */}
                </div>
            </AnimatedSection>

            <Suspense fallback={<div>Loading...</div>}>
                <AnimatedSection variants={fadeInVariants} delay={0.2}>
                    <Boxes />
                </AnimatedSection>
            </Suspense>

            <AnimatedSection variants={fadeInUpVariants}>
                <div className="flex flex-col items-center text-white py-24">
                    <h1 className="text-3xl sm:text-4xl md:text-6xl font-semibold text-center">
                        EXTENSIVE VALUE
                    </h1>
                    <p className="text-lg sm:text-xl md:text-2xl text-gray-300 font-normal text-center mt-12">
                        Every day, we adapt to create valuable outcomes for stakeholders worldwide.
                    </p>
                </div>
            </AnimatedSection>

            <Suspense fallback={<div>Loading...</div>}>
                <AnimatedSection variants={fadeInVariants} delay={0.2}>
                    <ValueComponent />
                </AnimatedSection>
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
                <AnimatedSection variants={fadeInVariants} delay={0.2}>
                    <GlobalRecognition />
                </AnimatedSection>
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
                <AnimatedSection variants={fadeInVariants} delay={0.2}>
                    <CareersSection />
                </AnimatedSection>
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
                <AnimatedSection variants={fadeInVariants} delay={0.2}>
                    <NewsCarousel />
                </AnimatedSection>
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
                <AnimatedSection variants={fadeInVariants} delay={0.2}>
                    <Footer />
                </AnimatedSection>
            </Suspense>
        </div>
    )
}

export default React.memo(Home);