import React, { useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Industries/Retail/_coverImg.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents'
import { ContactContent } from '../../components/resuable/SapComponents'
import Footer from '../../components/Footer'
import { useDocumentTitle } from '../../utils/useDocumentTitle'
import { ChevronRight, ChevronLeft, ChevronDown } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

// Import images
import _img1 from '../../assets/Industries/Retail/_img1.png'
import _img2 from '../../assets/Industries/Retail/_img2.png'
import _img3 from '../../assets/Industries/Retail/_img3.png'
import _img4 from '../../assets/Industries/Retail/_img4.png'
import _img5 from '../../assets/Industries/Retail/_img5.png'
import _img6 from '../../assets/Industries/Retail/_img6.png'
import _img7 from '../../assets/Industries/Retail/_img7.png'
import _img8 from '../../assets/Industries/Retail/_img8.png'
import _img9 from '../../assets/Industries/Retail/_img9.png'
import _img10 from '../../assets/Industries/Retail/_img10.png'
import Retail_Img1 from '../../assets/Industries/Retail/Retail_Img1.png'
import Retail_Img2 from '../../assets/Industries/Retail/Retail_Img2.png'

import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'

import microsoftLogo from '../../assets/Capabilities/Cloud_partner_img2.png'
import awsLogo from '../../assets/Capabilities/Cloud_partner_img3.png'
import googleLogo from '../../assets/Capabilities/Cloud_partner_img4.png'
import sapLogo from '../../assets/Capabilities/sap-logo.png'
import ibmLogo from '../../assets/Capabilities/ibm-logo.png'
import servicenowLogo from '../../assets/Capabilities/Cloud_partner_img1.png'
import databricksLogo from '../../assets/Capabilities/databricks-logo.png'
import oracleLogo from '../../assets/Capabilities/oracle-logo.png'

const PartnersSection = () => {
  const partners = [
    { name: 'Microsoft', logo: microsoftLogo },
    { name: 'AWS', logo: awsLogo },
    { name: 'Google', logo: googleLogo },
    { name: 'SAP', logo: sapLogo },
    { name: 'IBM', logo: ibmLogo },
    { name: 'ServiceNow', logo: servicenowLogo },
    { name: 'Databricks', logo: databricksLogo },
    { name: 'Oracle', logo: oracleLogo },
  ];

  return (
    <div className="bg-[#08082A] py-12">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-white text-3xl font-bold text-center mb-10">Partners in change</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 sm:gap-6 md:gap-8 items-center justify-items-center">
          {partners.map((partner) => (
            <img
              key={partner.name}
              src={partner.logo}
              alt={`${partner.name} logo`}
              className="h-6 sm:h-8 object-contain"
            />
          ))}
        </div>
      </div>
    </div>
  );
};


function Retail() {
    useDocumentTitle("Retail");

    const RetailStats = () => {
        const stats = [
            { value: "50%", description: "of retail leaders believe AI will revolutionize customer experience in the next five years" },
            { value: "62%", description: "of global consumers prioritize sustainability when choosing where to shop" },
            { value: "35%", description: "of consumers plan to increase spending on technology and smart home products" },
            { value: "7/10", description: "shoppers say convenience and speed are the most critical factors in their purchasing decisions" }
        ];
        return (
            <StatsGrid 
                title="The Evolving Retail Landscape" 
                stats={stats} 
                description="Now is the moment to transform retail with sustainability at its core—focusing on meeting the evolving needs of customers, empowering employees, and enriching the communities they serve. The challenge lies in harmonizing digital innovation with physical retail experiences to deliver value while maintaining long-term growth and positive impact."
            />
        );
    };

    const SegmentsWeSupport = () => {
        const [activeIndex, setActiveIndex] = useState(null);
        const [showDetails, setShowDetails] = useState(false);
        const [selectedSegment, setSelectedSegment] = useState(null);

        const toggleSegment = (index) => {
            setActiveIndex(activeIndex === index ? null : index);
        };

        const toggleDetails = (segment) => {
            setSelectedSegment(segment);
            setShowDetails(true);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 },
        };

        const segments = [
            {
                title: "Empowering Retail Teams",
                description: "Strengthen your workforce by equipping them with advanced tools and real-time insights. Enable teams to adapt swiftly, meet customer demands, and drive meaningful interactions.",
                image: _img3,
                details: {
                    keyFeatures: [
                        "Advanced tools and insights",
                        "Real-time interaction capabilities",
                        "Adaptability to customer demands"
                    ],
                    benefits: "Enhance team efficiency and customer satisfaction",
                    caseStudy: "Retailer improved service speed by 30% with real-time data access"
                }
            },
            {
                title: "Sustainability as a Retail Priority",
                description: "Integrate sustainable practices into your retail strategy to meet customer expectations and regulatory standards. Achieve profitability while contributing to a greener future with innovative solutions.",
                image: _img2,
                details: {
                    keyFeatures: [
                        "Supply chain optimization",
                        "Quality control automation",
                        "Just-in-time inventory management",
                        "Connected factory solutions",
                        "Real-time production monitoring"
                    ],
                    benefits: "Streamline production with 30% improved efficiency and 25% reduced inventory costs",
                    caseStudy: "Major auto supplier reduced waste by 45% and improved quality metrics by 60%"
                }
            },
            {
                title: "Harnessing AI and Analytics",
                description: "Leverage cutting-edge AI and data analytics to optimize decision-making and unlock new growth opportunities. Build a data-driven foundation to transform retail operations and stay ahead of the curve.",
                image: _img1,
                details: {
                    keyFeatures: [
                        "Equipment tracking and fleet management",
                        "Predictive maintenance analytics",
                        "Performance optimization systems",
                        "Remote monitoring and diagnostics",
                        "Safety compliance automation"
                    ],
                    benefits: "Enhance equipment lifespan by 35% while reducing operational costs by 25%",
                    caseStudy: "Construction firm saved $2M in maintenance costs and improved safety metrics by 40%"
                }
            },
            {
                title: "Consumer Durables",
                description: "Transforming consumer appliance manufacturing with smart technologies and efficient production processes.",
                image: _img4,
                details: {
                    keyFeatures: [
                        "Smart appliance integration",
                        "Energy efficiency optimization",
                        "Quality assurance automation",
                        "Connected device management",
                        "Sustainable manufacturing practices"
                    ],
                    benefits: "Reduce production costs by 30% while improving product quality by 45%",
                    caseStudy: "Appliance manufacturer increased production efficiency by 50%"
                }
            },
            {
                title: "Freight and Logistics",
                description: "Optimizing supply chain operations with intelligent logistics solutions and real-time tracking capabilities.",
                image: _img5,
                details: {
                    keyFeatures: [
                        "Real-time fleet tracking",
                        "Route optimization",
                        "Warehouse automation",
                        "Inventory management",
                        "Delivery optimization"
                    ],
                    benefits: "Improve delivery efficiency by 40% and reduce operational costs by 35%",
                    caseStudy: "Logistics company reduced delivery times by 50% and fuel costs by 30%"
                }
            },
            {
                title: "Construction, Engineering and Real Estate",
                description: "Delivering innovative solutions for construction projects and property management through digital transformation.",
                image: _img3,
                details: {
                    keyFeatures: [
                        "Project management automation",
                        "BIM integration",
                        "Site safety monitoring",
                        "Resource optimization",
                        "Property management systems"
                    ],
                    benefits: "Reduce project delays by 45% and improve resource utilization by 35%",
                    caseStudy: "Engineering firm reduced project completion time by 40%"
                }
            },
            {
                title: "Business Services",
                description: "Enabling service excellence through digital transformation and process automation solutions.",
                image: _img4,
                details: {
                    keyFeatures: [
                        "Service automation",
                        "Customer relationship management",
                        "Process optimization",
                        "Data analytics"
                    ]
                }
            },
            {
                title: "Seamless Retail Operations",
                description: "Unify marketing, product management, and supply chain efforts for an agile, end-to-end customer journey. Enhance efficiency by aligning processes for a consistent retail experience.",
                image: _img5,
                details: {
                    keyFeatures: [
                        "Real-time fleet tracking",
                        "Route optimization",
                        "Warehouse automation",
                        "Inventory management",
                        "Delivery optimization"
                    ],
                    benefits: "Improve delivery efficiency by 40% and reduce operational costs by 35%",
                    caseStudy: "Logistics company reduced delivery times by 50% and fuel costs by 30%"
                }
            },
            {
                title: "Streamlining Retail Processes",
                description: "Automate repetitive tasks and streamline workflows to enhance employee productivity. Focus resources on strategic goals to drive operational excellence and better customer outcomes.",
                image: _img4,
                details: {
                    keyFeatures: [
                        "Service automation",
                        "Customer relationship management",
                        "Process optimization",
                        "Data analytics"
                    ]
                }
            },
            {
                title: "Connecting Digital and In-Store Experiences",
                description: "Deliver a unified shopping journey by integrating online and offline touchpoints. Create personalized, seamless experiences that build customer loyalty and increase sales.",
                image: _img6,
                details: {
                    keyFeatures: [
                        "Real-time fleet tracking",
                        "Route optimization",
                        "Warehouse automation",
                        "Inventory management",
                        "Delivery optimization"
                    ],
                    benefits: "Improve delivery efficiency by 40% and reduce operational costs by 35%",
                    caseStudy: "Logistics company reduced delivery times by 50% and fuel costs by 30%"
                }
            }
        ];

        return (
            <div className="w-full">
                <h2 className="text-4xl font-bold mb-8 text-center">Segments we support</h2>
                <div className="text-white p-4 sm:p-8 rounded-2xl">
                    {segments.map((segment, index) => (
                        <div key={index} className="mb-4">
                            <div
                                className="flex justify-between items-center cursor-pointer p-4 bg-[#14192E] rounded-t-xl"
                                onClick={() => toggleSegment(index)}
                            >
                                <span className="font-semibold">{segment.title}</span>
                                <motion.div
                                    animate={{ rotate: activeIndex === index ? 180 : 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <ChevronDown />
                                </motion.div>
                            </div>
                            <motion.div
                                animate={{
                                    height: activeIndex === index ? "auto" : 0,
                                    opacity: activeIndex === index ? 1 : 0
                                }}
                                initial={{ height: 0, opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                style={{ overflow: "hidden" }}
                            >
                                <div className="p-4 bg-[#14192E] rounded-b-xl">
                                    <div className="flex flex-col sm:flex-row items-start">
                                        {/* <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                            <div className="bg-purple-900 rounded-lg overflow-hidden">
                                                <img
                                                    src={segment.image}
                                                    alt={segment.title}
                                                    className="w-full h-auto object-cover"
                                                />
                                            </div>
                                        </div> */}
                                        <div className="flex-grow">
                                            <p className="text-gray-400 mb-4">{segment.description}</p>
                                            <div className="flex justify-end">
                                                {/* <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="border-2 border-theme/50 rounded-full p-2"
                                                    onClick={() => toggleDetails(segment)}
                                                >
                                                    <ChevronRight className="text-theme cursor-pointer" size={24} />
                                                </motion.div> */}
                                            </div>
                                            {showDetails && selectedSegment === segment && (
                                                <motion.div
                                                    initial="hidden"
                                                    animate="visible"
                                                    exit="exit"
                                                    variants={slideVariants}
                                                    className="mt-4 p-4 bg-[#1a2036] rounded-xl"
                                                >
                                                    <h3 className="text-xl font-bold mb-3">Key Features:</h3>
                                                    <ul className="list-disc pl-5 mb-4">
                                                        {segment.details.keyFeatures.map((feature, i) => (
                                                            <li key={i} className="mb-2">{feature}</li>
                                                        ))}
                                                    </ul>
                                                    <p className="mb-3"><strong>Benefits:</strong> {segment.details.benefits}</p>
                                                    <p><strong>Case Study:</strong> {segment.details.caseStudy}</p>
                                                </motion.div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const ReinventRetail = () => {
        const cards = [
            {
                title: 'Empowering Retail Teams',
                description: 'Strengthen your workforce by equipping them with advanced tools and real-time insights. Enable teams to adapt swiftly, meet customer demands, and drive meaningful interactions.',
                hasButton: true,
            },
            {
                title: 'Sustainability as a Retail Priority',
                description: 'Integrate sustainable practices into your retail strategy to meet customer expectations and regulatory standards. Achieve profitability while contributing to a greener future with innovative solutions.',
                hasButton: true,
            },
            {
                title: 'Harnessing AI and Analytics',
                description: 'Leverage cutting-edge AI and data analytics to optimize decision-making and unlock new growth opportunities. Build a data-driven foundation to transform retail operations and stay ahead of the curve.',
                hasButton: true,
            },
            {
                title: 'Seamless Retail Operations',
                description: 'Unify marketing, product management, and supply chain efforts for an agile, end-to-end customer journey. Enhance efficiency by aligning processes for a consistent retail experience.',
                hasButton: true,
            },
            {
                title: 'Streamlining Retail Processes',
                description: 'Automate repetitive tasks and streamline workflows to enhance employee productivity. Focus resources on strategic goals to drive operational excellence and better customer outcomes.',
                hasButton: true,
            },
            {
                title: 'Connecting Digital and In-Store Experiences',
                description: 'Deliver a unified shopping journey by integrating online and offline touchpoints. Create personalized, seamless experiences that build customer loyalty and increase sales.',
                hasButton: true,
            }
        ];

        return <ReinventGrid title="Redefining the Future of Retail" cards={cards} />;
    };

    const RetailTrendingCarousel = () => {
        const slides = [
            {
                title: "Sustainable Shopping: Driving Eco-Friendly Innovation in Retail",
                description: "Consumers are becoming more conscious of their environmental impact, prompting retailers to prioritize sustainability. This trend explores how businesses are adopting eco-friendly practices, such as offering sustainable product lines, reducing packaging waste, and sourcing responsibly. By aligning with consumer values, retailers not only contribute to environmental preservation but also strengthen brand loyalty and appeal to the growing market of eco-conscious shoppers.",
                image: _img4,
                gradient: "from-purple-600 to-pink-500"
            },
            {
                title: "Hyper-Personalized Experiences: Strengthening Customer Connections with AI",
                description: "Retailers are leveraging advanced AI tools to deliver hyper-personalized experiences that cater to individual preferences and behaviors. From tailored product recommendations to customized promotions, this approach fosters stronger connections with customers. By using real-time data insights, businesses can enhance engagement, improve customer satisfaction, and drive repeat purchases, setting themselves apart in a competitive marketplace.",
                image: _img5,
                gradient: "from-blue-600 to-cyan-500"
            },
            {
                title: "The Rise of Resale: Embracing Circular Fashion and Affordability",
                description: "Secondhand shopping is transforming the retail landscape as customers increasingly embrace resale platforms for their affordability and eco-friendly appeal. This topic highlights the growing popularity of circular fashion, where pre-owned items are given new life. Retailers are tapping into this trend by launching resale marketplaces and encouraging buy-back programs, addressing both sustainability goals and shifting consumer preferences.",
                image: _img6,
                gradient: "from-indigo-600 to-purple-500"
            },
            {
                title: "Convenience-Driven Retail: Meeting the Demand for Seamless Shopping",
                description: "In today’s fast-paced world, convenience is king. From one-click checkouts to same-day delivery, customers expect frictionless shopping experiences. Retailers are rising to the challenge by streamlining payment systems, optimizing logistics, and offering omnichannel options. This shift towards convenience-driven retail not only enhances customer satisfaction but also ensures businesses remain competitive in an era defined by instant gratification.",
                image: Retail_Img1,
                gradient: "from-indigo-600 to-purple-500"
            },
            {
                title: "Augmented Reality in Retail: Revolutionizing Customer Engagement",
                description: "Augmented Reality (AR) is transforming how customers interact with brands, offering immersive and engaging experiences. From virtual try-ons for apparel and accessories to visualizing furniture in a home setting, AR is bridging the gap between online and in-store shopping. This technology empowers retailers to enhance customer confidence in their purchases, reduce returns, and elevate the overall shopping journey.",
                image: Retail_Img2,
                gradient: "from-indigo-600 to-purple-500"
            }
        ];

        return <TrendingCarousel title="Retail Trends to Watch" slides={slides} />;
    };



    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Innovation Award 2024",
                image: _img7,
                alt: "Innovation Award"
            },
            {
                title: "Technology Leader - Forrester",
                image: _img8,
                alt: "Technology Leadership"
            },
            {
                title: "Best Solutions Provider",
                image: _img9,
                alt: "Solutions Provider"
            },
            {
                title: "Excellence in Digital Transformation",
                image: _img10,
                alt: "Digital Transformation Excellence"
            }
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "John Smith", title: "Global Retail Lead", imageUrl: Cloud_leader_img1 },
            { name: "Sarah Johnson", title: "Digital Innovation Director", imageUrl: Cloud_leader_img1 },
            { name: "Michael Chen", title: "Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Emma Davis", title: "Solutions Lead", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Transforming Retail: A Sustainable Approach"
                description="Now is the moment to transform retail with sustainability at its core—focusing on meeting the evolving needs of customers, empowering employees, and enriching the communities they serve. The challenge lies in harmonizing digital innovation with physical retail experiences to deliver value while maintaining long-term growth and positive impact."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <RetailStats />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventRetail />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <RetailTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <PartnersSection />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <GlobalRecognition />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent title="Retail careers" subtitle="Grow your career at the heart of  transformation" />
            </div>
            <Footer />
        </div>
    );
}

export default Retail
