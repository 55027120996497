import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


export const scrollToBottom = () => {
    window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
    });
};


export const ContactContent = ({ title, subtitle }) => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col justify-center items-center text-center bg-[#14192E] min-h-[293px] px-4 py-8'>
            <div className='md:w-11/12 max-w-7xl'>
                <h2 className="text-lg md:text-2xl font-bold mb-6">{title}</h2>
                {subtitle && <p className="text-sm md:text-lg text-gray-300 mb-8">{subtitle}</p>}
                <motion.button
                    className="bg-custom-gradient text-white font-normal py-2 px-12 rounded-full hover:bg-blue-600 transition duration-300"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => navigate('/contact-us')}
                >
                    Contact us
                </motion.button>
            </div>
        </div>
    );
}


export const FeatureGrid = ({ title, features, isIconPresent }) => {
    return (
        <div className="text-white p-4 sm:p-8 mt-8 sm:mt-12 md:mt-24">
            <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-center mb-6 sm:mb-8">{title}</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4">
                {features.map((feature, index) => (
                    <div key={index} className="border border-transparent bg-custom-gradient p-[1px] rounded-lg w-full sm:w-11/12 md:w-4/5 h-auto min-h-[200px] sm:min-h-[225px] mx-auto mt-4 sm:mt-6 md:mt-12">
                        <div className="bg-bg h-full p-3 sm:p-4 rounded-lg flex flex-col items-center justify-center text-center">
                            <span className={`${isIconPresent === false ? "text-lg sm:text-xl font-semibold" : "text-3xl sm:text-4xl"} my-3 sm:my-4`}>{feature.icon}</span>
                            <p className="text-xs sm:text-sm text-left text-gray-200 overflow-hidden">{feature.text}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const PlusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
        <path fillRule="evenodd" d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z" clipRule="evenodd" />
    </svg>
);

const MinusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
        <path fillRule="evenodd" d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z" clipRule="evenodd" />
    </svg>
);

export const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mb-4">
            <motion.div
                className="bg-[#222841]/50 p-4 rounded-lg cursor-pointer flex justify-between items-center"
                onClick={() => setIsOpen(!isOpen)}
                initial={false}
                animate={{ backgroundColor: isOpen ? "#222841" : "#222841" }}
                transition={{ duration: 0.3 }}
            >
                <h3 className="text-white font-semibold">{question}</h3>
                <motion.div
                    initial={false}
                    animate={{
                        rotate: isOpen ? 180 : 0,
                        color: isOpen ? "#a100ff" : "#a100ff"
                    }}
                    transition={{ duration: 0.3 }}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 24, height: 24 }}
                >
                    {isOpen ? <MinusIcon /> : <PlusIcon />}
                </motion.div>
            </motion.div>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                        className="bg-[#222841]/50 overflow-hidden"
                    >
                        <div className="p-4">
                            <p className="text-gray-300">{answer}</p>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};



export const Slider = ({ tabs, content, isHeader }) => {
    const [activeTab, setActiveTab] = useState(0);
    const scrollContainerRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const touchStartX = useRef(null);
    const [shouldScroll, setShouldScroll] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const checkMobile = () => setIsMobile(window.innerWidth <= 639);
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const changeTab = (direction) => {
        const newIndex = (activeTab + direction + tabs.length) % tabs.length;
        setActiveTabWithScroll(newIndex);
        setCurrentSlide(newIndex);
    };

    const setActiveTabWithScroll = (index) => {
        setActiveTab(index);
        setShouldScroll(true);
    };

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
        if (!touchStartX.current) return;
        const touchEndX = e.changedTouches[0].clientX;
        const diff = touchStartX.current - touchEndX;
        if (Math.abs(diff) > 50) {
            const direction = diff > 0 ? 1 : -1;
            const newIndex = (currentSlide + direction + tabs.length) % tabs.length;
            setCurrentSlide(newIndex);
            setActiveTabWithScroll(newIndex);
        }
        touchStartX.current = null;
    };

    const scrollToActiveTab = () => {
        if (scrollContainerRef.current) {
            const activeElement = scrollContainerRef.current.children[activeTab];
            if (activeElement) {
                activeElement.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
            }
        }
    };

    useEffect(() => {
        if (shouldScroll) {
            scrollToActiveTab();
        }
    }, [activeTab, shouldScroll]);

    return (
        <div className="w-full md:w-11/12 mx-auto p-4 md:p-6 bg-darkBlue-900 text-white rounded-lg">
            {isHeader && (
                <h2 className="text-xl md:text-3xl font-bold md:text-center mb-8">
                    SHOP makes DIAA Corp the World's largest Application-focused Managed Services provider
                </h2>
            )}

            <div className='bg-[#14192E] px-4 md:px-8 py-4 my-4 md:my-12 rounded-lg relative'>
                {!isMobile && (
                    <button onClick={() => changeTab(-1)} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-darkBlue-800 p-2 rounded-full">
                        <FaChevronLeft />
                    </button>
                )}
                <div ref={scrollContainerRef} className="flex overflow-x-auto no-scrollbar scroll-smooth">
                    {tabs.map((tab, index) => (
                        <motion.div
                            key={index}
                            className={`cursor-pointer px-3 md:px-4 py-2 whitespace-nowrap ${(isMobile ? index === currentSlide : index === activeTab) ? 'text-theme' : ''
                                }`}
                            onClick={() => {
                                setActiveTabWithScroll(index);
                                setCurrentSlide(index);
                            }}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            {tab}
                            {(isMobile ? index === currentSlide : index === activeTab) && (
                                <motion.div
                                    className="h-0.5 bg-custom-gradient"
                                    layoutId="underline"
                                />
                            )}
                        </motion.div>
                    ))}
                </div>
                {!isMobile && (
                    <button onClick={() => changeTab(1)} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-darkBlue-800 p-2 rounded-full">
                        <FaChevronRight />
                    </button>
                )}
                <motion.div
                    className="my-6 md:my-12 p-4 bg-darkBlue-800 rounded-lg"
                    key={currentSlide}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                >
                    {content[currentSlide]}
                </motion.div>
            </div>
        </div>
    );
};
