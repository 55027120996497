import React, { useState, useMemo } from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { motion } from 'framer-motion';
import Footer from '../../components/Footer';

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

const initialMarkers = [
    { name: 'Middle East', coordinates: [45, 25], region: 'Middle East' },
    { name: 'India', coordinates: [80, 20], region: 'Asia' },
    { name: 'USA', coordinates: [-100, 40], region: 'North America' },
    { name: 'LATAM', coordinates: [-60, -10], region: 'South America' },
    { name: 'Europe', coordinates: [15, 50], region: 'Europe' },
    { name: 'Australia', coordinates: [135, -25], region: 'Australia' },
];

const additionalRegions = ['India', 'KSA', 'US', 'UAE'];

const Locations = () => {
    const [markers, setMarkers] = useState(initialMarkers);

    const handleAddRegion = () => {
        const newMarkers = additionalRegions.map((region, index) => ({
            name: region,
            coordinates: [Math.random() * 360 - 180, Math.random() * 180 - 90],
            region: `Region ${index + 1}`,
        }));
        setMarkers([...markers, ...newMarkers]);
    };

    const memoizedMarkers = useMemo(() => markers.map((marker) => (
        <Marker key={marker.name} coordinates={marker.coordinates}>
            <circle r={6} fill="#a100ff" opacity={0.8} />
        </Marker>
    )), [markers]);

    return (
        <div className="bg-bg text-white py-16 px-4">
            <div className="mx-auto md:px-4 mobile:max-w-6xl md:w-3/4 2xl:w-2/3">
                <header className="text-center mb-12">
                    <motion.h1
                        className="mobile:text-6xl text-8xl font-bold mb-4 bg-gradient-to-b from-white to-transparent bg-clip-text text-transparent"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        LOCATIONS
                    </motion.h1>
                    <motion.p
                        className="mobile:text-base text-xl mb-4"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        "Expanding Horizons, Connecting Continents"
                    </motion.p>
                </header>
                <div className="w-full lg:h-[900px] mobile:h-[400px] mb-12">
                    <ComposableMap
                        projection="geoMercator"
                        projectionConfig={{
                            scale: 140,
                            center: [0, 30]
                        }}
                    >
                        <Geographies geography={geoUrl}>
                            {({ geographies }) =>
                                geographies.map((geo) => (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill="#1E213A"
                                        stroke="#2A2E5A"
                                        strokeWidth={0.5}
                                    />
                                ))
                            }
                        </Geographies>
                        {memoizedMarkers}
                    </ComposableMap>
                </div>
                {/* <div className="text-center mb-12">
          <button
            className="bg-custom-gradient text-white font-bold py-2 px-4 rounded-full"
            onClick={handleAddRegion}
          >
            Add More Regions
          </button>
        </div> */}
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 xl:py-12 text-center">
                    {markers.map((marker, index) => (
                        <div key={index} className="bg-[#1A1F31] p-4 rounded-lg">
                            <h3 className="text-lg font-bold">{marker.name}</h3>
                            <p className="text-sm text-gray-400">{marker.region}</p>
                        </div>
                    ))}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Locations;
