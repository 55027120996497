import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MdLocationOn, MdPhone, MdEmail } from 'react-icons/md';
import { FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa';

const API_URL = 'https://diaacorp-backend.ew.r.appspot.com/api/email/send';
// const API_URL = 'http://localhost:5000/api/email/send';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    subject: '',
    message: ''
  });

  const [status, setStatus] = useState({
    message: '',
    type: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [hasSubmitAttempt, setHasSubmitAttempt] = useState(false);

  const contactInfo = [
    {
      icon: <MdLocationOn className="w-6 h-6" />,
      title: "Visit Us",
      details: ["123 Business Avenue", "Tech District", "Dubai, UAE"]
    },
    {
      icon: <MdPhone className="w-6 h-6" />,
      title: "Call Us",
      details: ["+971 XX XXX XXXX", "+1 XXX XXX XXXX"]
    },
    {
      icon: <MdEmail className="w-6 h-6" />,
      title: "Email Us",
      details: [<a href="mailto:contact@diaacorp.com" className="hover:text-purple-500 transition-colors">contact@diaacorp.com</a>]
    }
  ];

  const validateInput = (name, value) => {
    if (!value.trim()) return `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;

    switch (name) {
      case 'name':
        if (!/^[A-Za-z\s]+$/.test(value)) return 'Name can only contain letters and spaces';
        if (value.length > 50) return 'Name must be less than 50 characters';
        break;
      case 'email':
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return 'Invalid email format';
        break;
      case 'mobile':
        if (!/^\d+$/.test(value)) return 'Mobile number can only contain digits';
        if (value.length < 8 || value.length > 15) return 'Mobile number must be between 8 and 15 digits';
        break;
      case 'message':
        if (value.length < 10) return 'Message must be at least 10 characters long';
        break;
      default:
        break;
    }
    return '';
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (hasSubmitAttempt) {
      setErrors(prev => ({
        ...prev,
        [name]: validateInput(name, value)
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasSubmitAttempt(true);

    // Validate all fields
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateInput(key, formData[key]);
      if (error) newErrors[key] = error;
    });

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;

    setIsLoading(true);
    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          subject: `Contact Form: ${formData.subject}`,
        })
      });

      const data = await response.json();

      if (data.success) {
        setStatus({
          message: 'Thank you for your message. We will contact you soon!',
          type: 'success'
        });
        setFormData({
          name: '',
          email: '',
          mobile: '',
          subject: '',
          message: ''
        });
      } else {
        setStatus({
          message: 'Failed to send message. Please try again.',
          type: 'error'
        });
      }
    } catch (error) {
      setStatus({
        message: 'An error occurred. Please try again later.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => setStatus({ message: '', type: '' }), 5000);
    }
  };

  return (
    <div className="bg-bg min-h-screen text-white py-16 px-4">
      <div className="max-w-6xl mx-auto">
        {/* Header Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6">Get in Touch</h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Have questions? We'd love to hear from you. Send us a message and we'll respond as soon as possible.
          </p>
        </motion.div>

        {/* Contact Information Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {contactInfo.map((info, index) => (
            <motion.div
              key={info.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-[#1A1F31] p-6 rounded-xl text-center hover:bg-[#242942] transition-colors duration-300"
            >
              <div className="flex justify-center mb-4 text-purple-500">
                {info.icon}
              </div>
              <h3 className="text-xl font-bold mb-4">{info.title}</h3>
              {info.details.map((detail, i) => (
                <p key={i} className="text-gray-400">{detail}</p>
              ))}
            </motion.div>
          ))}
        </div>

        {/* Contact Form */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="bg-[#1A1F31] rounded-xl p-8 max-w-3xl mx-auto"
        >
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Your Name"
                  className={`w-full p-3 bg-[#242942] rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                    errors.name ? 'border border-red-500' : ''
                  }`}
                />
                {errors.name && (
                  <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                )}
              </div>

              <div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Your Email"
                  className={`w-full p-3 bg-[#242942] rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                    errors.email ? 'border border-red-500' : ''
                  }`}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <input
                  type="tel"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  placeholder="Your Phone"
                  className={`w-full p-3 bg-[#242942] rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                    errors.mobile ? 'border border-red-500' : ''
                  }`}
                />
                {errors.mobile && (
                  <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  placeholder="Subject"
                  className={`w-full p-3 bg-[#242942] rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                    errors.subject ? 'border border-red-500' : ''
                  }`}
                />
                {errors.subject && (
                  <p className="text-red-500 text-xs mt-1">{errors.subject}</p>
                )}
              </div>
            </div>

            <div>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Your Message"
                rows="5"
                className={`w-full p-3 bg-[#242942] rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                  errors.message ? 'border border-red-500' : ''
                }`}
              />
              {errors.message && (
                <p className="text-red-500 text-xs mt-1">{errors.message}</p>
              )}
            </div>

            <AnimatePresence>
              {status.message && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  className={`text-sm ${
                    status.type === 'success' ? 'text-green-400' : 'text-red-400'
                  } text-center`}
                >
                  {status.message}
                </motion.div>
              )}
            </AnimatePresence>

            <div className="text-center">
              <motion.button
                type="submit"
                disabled={isLoading}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gradient-to-r from-purple-600 to-purple-800 text-white px-8 py-3 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50"
              >
                {isLoading ? 'Sending...' : 'Send Message'}
              </motion.button>
            </div>
          </form>
        </motion.div>

        {/* Social Media Links */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="flex justify-center space-x-6 mt-12"
        >
          <a href="#" className="text-gray-400 hover:text-purple-500 transition-colors">
            <FaLinkedin className="w-6 h-6" />
          </a>
          <a href="#" className="text-gray-400 hover:text-purple-500 transition-colors">
            <FaTwitter className="w-6 h-6" />
          </a>
          <a href="#" className="text-gray-400 hover:text-purple-500 transition-colors">
            <FaInstagram className="w-6 h-6" />
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default ContactUs; 