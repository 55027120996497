import React, { useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Industries/Travel/_coverImg.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents'
import { ContactContent } from '../../components/resuable/SapComponents'
import Footer from '../../components/Footer'
import { useDocumentTitle } from '../../utils/useDocumentTitle'
import { ChevronRight, ChevronLeft } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

// Import images
import _img1 from '../../assets/Industries/Travel/_img1.png'
import _img2 from '../../assets/Industries/Travel/_img2.png'
import _img3 from '../../assets/Industries/Travel/_img3.png'
import _img4 from '../../assets/Industries/Travel/_img4.png'
import _img5 from '../../assets/Industries/Travel/_img5.png'
import _img6 from '../../assets/Industries/Travel/_img6.png'

import Travel_img1 from '../../assets/Industries/Travel/Travel_img1.png'
import Travel_img2 from '../../assets/Industries/Travel/Travel_img2.png'
import Travel_img3 from '../../assets/Industries/Travel/Travel_img3.png'
import Travel_img4 from '../../assets/Industries/Travel/Travel_img4.png'
import Travel_img5 from '../../assets/Industries/Travel/Travel_img5.png'

import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'

function Travel() {
    useDocumentTitle("Travel");

    const TravelStats = () => {
        const stats = [
            { value: "82%", description: "of consumers are prioritizing eco-friendly travel options in the next 12 months." },
            { value: "50%", description: "of travelers seek personalized and immersive travel experiences tailored to their interests." },
            { value: "95%", description: "of travel industry leaders believe digital transformation is essential for staying competitive." },
            { value: "40%", description: "of travel companies cite outdated infrastructure as a key obstacle to achieving innovation." }
        ];
        return <StatsGrid title="The Future of Travel: Trends and Insights" stats={stats} />;
    };

    const SegmentsWeSupport = () => {
        const [currentSlide, setCurrentSlide] = React.useState(0);
        const slides = [
            {
                category: " SEGMENT 1",
                title: "Travel Solutions",
                description: "Leading digital transformation with innovative solutions.",
                image: _img3,
            },
            {
                category: " SEGMENT 2",
                title: "Digital Innovation",
                description: "Transforming operations through cutting-edge digital tools.",
                image: _img3,
            },
            {
                category: " SEGMENT 3",
                title: "Technology Solutions",
                description: "Modernizing systems to reduce costs and improve efficiency.",
                image: _img3,
            },
        ];

        const nextSlide = () => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 }
        };

        return (
            <>
                <h2 className="text-2xl font-bold mb-6 xl:mb-12 text-center">Segments we support</h2>
                <div className="bg-[#14192E] text-white p-4 sm:p-8 rounded-2xl">
                    <div className="bg-navy-800 rounded-lg p-4 sm:p-6">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentSlide}
                                variants={slideVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                            >
                                <div className="flex items-center mb-4">
                                    <div className="w-3 h-3 bg-purple-500 rounded-full mr-2"></div>
                                    <span className="text-purple-500 font-semibold">{slides[currentSlide].category}</span>
                                </div>
                                <div className="flex flex-col sm:flex-row items-start">
                                    <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                        <div className="bg-purple-900 rounded-lg overflow-hidden">
                                            <img
                                                src={slides[currentSlide].image}
                                                alt={slides[currentSlide].title}
                                                className="w-full h-auto object-cover"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-grow">
                                        <h3 className="text-xl font-semibold mb-2">{slides[currentSlide].title}</h3>
                                        <p className="text-gray-400 mb-4">
                                            {slides[currentSlide].description}
                                        </p>
                                        <div className="flex justify-end">
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="border-2 border-theme/50 rounded-full p-2"
                                            >
                                                <ChevronRight
                                                    className="text-theme cursor-pointer"
                                                    size={24}
                                                    onClick={nextSlide}
                                                />
                                            </motion.div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </>
        );
    };

    const ReinventTravel = () => {
        const cards = [
            {
                title: 'Personalized Traveler Journeys',
                description: 'Advanced AI and machine learning create tailored experiences that resonate with individual preferences.',
                hasButton: false,
            },
            {
                title: 'Digital Innovation for Growth',
                description: 'Upgrading to modern technologies accelerates transformation, driving innovation and efficiency.',
                hasButton: false,
            },
            {
                title: 'Data-Driven Decision Making',
                description: 'Real-time analytics empower travel companies to optimize operations and improve customer satisfaction.',
                hasButton: false,
            },
            {
                title: 'Eco-Friendly Travel Solutions',
                description: 'Adopting green initiatives and sustainable practices fosters trust and loyalty among environmentally conscious travelers.',
                hasButton: false,
            },
            {
                title: 'Building a Future-Ready Workforce',
                description: 'Investing in employee training and development ensures readiness for evolving industry demands.',
                hasButton: false,
            },
            {
                title: 'Resilient Growth Strategies',
                description: 'Exploring collaborative partnerships and alternative revenue models strengthens adaptability and growth.',
                hasButton: false,
            }
        ];

        return <ReinventGrid title="Revolutionizing the Travel Industry" cards={cards} />;
    };

    const TravelTrendingCarousel = () => {
        const slides = [
            {
                title: "AI-Powered Productivity: Unlocking Business Potential",
                description: "Generative AI is transforming industries by automating routine tasks, improving decision-making processes, and uncovering new growth opportunities. Businesses are leveraging AI to enhance operational efficiency, reduce human error, and provide innovative solutions that boost productivity. This technological advancement enables companies to stay competitive in a rapidly changing market, allowing them to allocate resources to more strategic, high-value activities.",
                image: _img4,
                gradient: "from-purple-600 to-pink-500"
            },
            {
                title: "The Retail Renaissance: Merging E-Commerce with Immersive Technologies",
                description: "The future of shopping is becoming more dynamic and interactive as e-commerce integrates immersive technologies like augmented reality (AR) and virtual reality (VR). These innovations are enhancing the online shopping experience by enabling customers to visualize products in 3D, try on items virtually, and explore virtual stores. This transformation is bridging the gap between physical and digital retail, offering a more engaging and personalized shopping experience.",
                image: _img5,
                gradient: "from-blue-600 to-cyan-500"
            },
            {
                title: "Smart Homes, Smarter Lives: Transforming Homes with IoT",
                description: "The rise of Internet of Things (IoT) devices is revolutionizing how we live at home. From smart thermostats that optimize energy consumption to personalized lighting and voice-controlled appliances, these innovations are making homes more connected, efficient, and tailored to individual needs. As IoT technology evolves, homes are becoming smarter, offering enhanced convenience, safety, and energy savings.",
                image: _img6,
                gradient: "from-indigo-600 to-purple-500"
            },
            {
                title: "Cybersecurity: Adapting to a New Threat Landscape",
                description: "As cyber threats become more sophisticated, businesses are turning to advanced technologies like artificial intelligence and blockchain to enhance their security measures. AI helps identify and neutralize potential threats in real-time, while blockchain adds an extra layer of transparency and trust in digital transactions. These innovations are essential for protecting sensitive data, ensuring compliance, and fostering digital trust in an increasingly interconnected world.",
                image: Travel_img1,
                gradient: "from-purple-600 to-pink-500"
            },
            {
                title: "Electric Futures: The EV Revolution",
                description: "Electric vehicles (EVs) are rapidly gaining traction as they become more accessible and sustainable. Driven by advancements in battery technology and the expansion of charging infrastructure, EVs are poised to become the mainstream mode of transportation. This shift not only contributes to reducing carbon emissions but also creates a cleaner and more energy-efficient future for transportation, aligning with global sustainability goals.",
                image: Travel_img2,
                gradient: "from-blue-600 to-cyan-500"
            }
        ];

        return <TrendingCarousel title="Revolutionizing Everyday Life" slides={slides} />;
    };



    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Innovation Award 2024",
                image: Travel_img3,
                alt: "Innovation Award"
            },
            {
                title: "Technology Leader - Forrester",
                image: Travel_img4,
                alt: "Technology Leadership"
            },
            {
                title: "Best Solutions Provider",
                image: Travel_img5,
                alt: "Solutions Provider"
            },
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "John Smith", title: "Global Travel Lead", imageUrl: Cloud_leader_img1 },
            { name: "Sarah Johnson", title: "Digital Innovation Director", imageUrl: Cloud_leader_img1 },
            { name: "Michael Chen", title: "Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Emma Davis", title: "Solutions Lead", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Explore Endless Horizons with Travel"
                description="The travel industry thrives on adaptability and innovation. In today's dynamic world, travel companies must embrace resilience and creativity to navigate challenges while delivering seamless, unforgettable journeys for their customers. Rediscover the joy of exploration with a reimagined travel experience."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <TravelStats />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventTravel />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <TravelTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <GlobalRecognition />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent 
                    title="The Future of Travel" 
                    subtitle="Embrace innovation and sustainable growth in the evolving travel landscape" 
                />
            </div>
            <Footer />
        </div>
    );
}

export default Travel
