import React, { useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Industries/HighTech/_coverImg.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents'
import { ContactContent } from '../../components/resuable/SapComponents'
import Footer from '../../components/Footer'
import { useDocumentTitle } from '../../utils/useDocumentTitle'
import { ChevronRight, ChevronLeft } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

// Import images
import _img1 from '../../assets/Industries/Utilities/_img1.png'
import _img2 from '../../assets/Industries/Utilities/_img2.png'
import _img3 from '../../assets/Industries/Utilities/_img3.png'
import _img4 from '../../assets/Industries/Utilities/_img4.png'
import _img5 from '../../assets/Industries/Utilities/_img5.png'
import _img6 from '../../assets/Industries/Utilities/_img6.png'
import _img7 from '../../assets/Industries/Utilities/_img7.png'
import _img8 from '../../assets/Industries/Utilities/_img8.png'
import _img9 from '../../assets/Industries/Utilities/_img9.png'
import _img10 from '../../assets/Industries/Utilities/_img10.png'   
 
import utilities_img1 from '../../assets/Industries/Utilities/utilities_img1.png'    
import utilities_img2 from '../../assets/Industries/Utilities/utilities_img2.png'    
import utilities_img3 from '../../assets/Industries/Utilities/utilities_img3.png'    

import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'

function Utilities() {
    useDocumentTitle("Utilities");

    const UtilitiesStats = () => {
        const stats = [
            { 
                value: "33%", 
                description: "Global greenhouse gas emissions are driven by the energy sector, highlighting the urgent need for transformation."
            },
            { 
                value: "80%", 
                description: "Of energy companies risk falling short of their sustainability targets without accelerated action."
            },
            { 
                value: "$150T", 
                description: "Estimated global investment potential by 2050, fueled by the rise of renewable energy, electric mobility, and clean power markets."
            },
            { 
                value: "12M", 
                description: "New jobs anticipated by 2050, driven by the growth of green energy technologies and innovations in sustainability."
            }
        ];
        return <StatsGrid title="The Future of Energy: Challenges and Opportunities" stats={stats} />;
    };

    const SegmentsWeSupport = () => {
        const [currentSlide, setCurrentSlide] = React.useState(0);
        const slides = [
            {
                category: " SEGMENT 1",
                title: "Scaling EV Charging Infrastructure",
                description: "Building robust electric vehicle charging solutions to support the transition to sustainable mobility.",
                image: _img3,
            },
            {
                category: " SEGMENT 2",
                title: "Blueprint for a Carbon-Neutral Energy Sector",
                description: "Practical strategies to achieve decarbonization targets while ensuring energy reliability and efficiency.",
                image: _img4,
            },
            {
                category: " SEGMENT 3",
                title: "Affordable Energy Solutions for All",
                description: "Designing systems that deliver clean energy access without burdening consumers financially.",
                image: _img5,
            },
        ];

        const nextSlide = () => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 }
        };

        return (
            <>
                <h2 className="text-2xl font-bold mb-6 xl:mb-12 text-center">Segments we support</h2>
                <div className="bg-[#14192E] text-white p-4 sm:p-8 rounded-2xl">
                    <div className="bg-navy-800 rounded-lg p-4 sm:p-6">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentSlide}
                                variants={slideVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                            >
                                <div className="flex items-center mb-4">
                                    <div className="w-3 h-3 bg-purple-500 rounded-full mr-2"></div>
                                    <span className="text-purple-500 font-semibold">{slides[currentSlide].category}</span>
                                </div>
                                <div className="flex flex-col sm:flex-row items-start">
                                    <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                        <div className="bg-purple-900 rounded-lg overflow-hidden">
                                            <img
                                                src={slides[currentSlide].image}
                                                alt={slides[currentSlide].title}
                                                className="w-full h-auto object-cover"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-grow">
                                        <h3 className="text-xl font-semibold mb-2">{slides[currentSlide].title}</h3>
                                        <p className="text-gray-400 mb-4">
                                            {slides[currentSlide].description}
                                        </p>
                                        <div className="flex justify-end">
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="border-2 border-theme/50 rounded-full p-2"
                                            >
                                                <ChevronRight
                                                    className="text-theme cursor-pointer"
                                                    size={24}
                                                    onClick={nextSlide}
                                                />
                                            </motion.div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </>
        );
    };

    const ReinventUtilities = () => {
        const cards = [
            {
                title: 'Adopting Renewable Energy for a Sustainable Future',
                description: 'Clean Energy Transition\nPropel your organization toward a sustainable future by adopting renewable energy and decarbonization strategies.\nEfficient Infrastructure Development.',
                buttonText: 'Discover how to lead the change',
                hasButton: false,
            },
            {
                title: 'Innovative Methods for Sustainable Energy Projects',
                description: 'Execute large-scale, sustainable projects with innovative methods to minimize risks and maximize long-term benefits. Empowered Workforce of Tomorrow.',
                buttonText: 'Explore the future of smart factories',
                hasButton: false,
            },
            {
                title: 'Enhancing Corporate Fleets for Sustainability',
                description: 'Redesign work models to foster adaptability, resilience, and alignment with the evolving energy sector.Enhanced Consumer Engagement',
                buttonText: 'Explore the future of smart factories',
                hasButton: false,
            },
            {
                title: 'Personalized Energy Solutions with AI',
                description: 'Deliver personalized and innovative energy solutions that cater to the changing demands of consumers.Advanced Data Integration',
                buttonText: 'Learn how to build stronger SMB partnerships',
                hasButton: false,
            },
            {
                title: 'Optimizing Operations with Advanced Analytics',
                description: 'Leverage cutting-edge analytics to unlock insights that optimize operations and strategic decision-making.Resilient Supply Chains',
                buttonText: 'Learn how to build stronger SMB partnerships',
                hasButton: false,
            },
            {
                title: 'Building Sustainable Supply Chain Networks',
                description: 'Build adaptable, sustainable supply chain networks to meet the diverse needs of stakeholders in a dynamic environment.',
                buttonText: 'Learn how to build stronger SMB partnerships',
                hasButton: false,
            },
        ];
        return <ReinventGrid title="Transforming Energy Systems for a Sustainable Future" cards={cards} />;
    };

    const UtilitiesTrendingCarousel = () => {
        const slides = [
            {
                title: "Scaling EV Charging Infrastructure: Paving the Way for Sustainable Mobility",
                description: "The transition to electric vehicles (EVs) requires a robust charging network to support widespread adoption. Developing scalable and reliable EV charging solutions ensures convenience for users and aligns with global sustainability goals. By investing in advanced infrastructure, governments and companies can accelerate the shift to eco-friendly transportation, reduce carbon emissions, and foster a cleaner, greener future.",
                image: _img4,
                gradient: "from-purple-600 to-pink-500"
            },
            {
                title: "Blueprint for a Carbon-Neutral Energy Sector: Strategies for Decarbonization and Efficiency", 
                description: "Achieving a carbon-neutral energy sector demands practical and innovative strategies that balance decarbonization targets with energy reliability and efficiency. From integrating renewable energy sources to modernizing grids, this blueprint outlines actionable steps to transition to sustainable energy systems without compromising economic and operational stability.",
                image: _img5,
                gradient: "from-blue-600 to-cyan-500"
            },
            {
                title: "Affordable Energy Solutions for All: Ensuring Access to Clean and Cost-Effective Energy",
                description: "Equitable access to affordable and clean energy is critical for fostering sustainable communities. By designing systems that leverage renewable resources and innovative technologies, energy providers can reduce costs and minimize financial burdens on consumers. These solutions prioritize inclusivity while contributing to global sustainability efforts.",
                image: _img6,
                gradient: "from-indigo-600 to-purple-500"
            },
            {
                title: "Driving Decarbonization with Financial Viability: Balancing Emissions Reduction and Economic Growth",
                description: "Accelerating decarbonization requires actionable plans that maintain financial viability and economic growth. This involves integrating green technologies, implementing carbon pricing strategies, and fostering public-private partnerships. By aligning environmental goals with economic priorities, businesses and governments can drive impactful change while ensuring long-term success.",
                image: utilities_img1,
                gradient: "from-green-600 to-emerald-500"
            },
            {
                title: "Greening Corporate Fleets: Leading the Charge in Sustainable Transportation",
                description: "Corporations are at the forefront of sustainable transportation initiatives by integrating low-carbon vehicles into their fleets. This shift not only reduces greenhouse gas emissions but also demonstrates leadership in environmental stewardship. By adopting innovative fleet management practices and leveraging incentives, companies are setting the standard for sustainable mobility.",
                image: utilities_img2,
                gradient: "from-yellow-600 to-amber-500"
            },
            {
                title: "Revolutionizing Energy Retail with AI: Innovating for Operational Efficiency and Value Creation",
                description: "Artificial intelligence is transforming the energy retail sector by enhancing operational efficiency, optimizing pricing strategies, and personalizing customer experiences. AI-driven insights allow energy retailers to predict demand, streamline processes, and deliver tailored solutions that create value for customers. This revolution positions the energy sector for a dynamic and sustainable future.",
                image: utilities_img3,
                gradient: "from-red-600 to-rose-500"
            }
        ];
        return <TrendingCarousel title="Innovations Shaping the Future of Energy" slides={slides} />;
    };

    const ScrollableCards = () => {
        const [activeIndex, setActiveIndex] = useState(2);

        const cards = [
            { id: 1, logo: Cloud_partner_img1, content: 'Leading technology solutions provider' },
            { id: 2, logo: Cloud_partner_img2, content: 'Innovative platform partner' },
            { id: 3, logo: Cloud_partner_img3, content: 'AI and analytics solutions' },
            { id: 4, logo: Cloud_partner_img4, content: 'Digital integration partner' },
            { id: 5, logo: Cloud_partner_img1, content: 'Data management solutions' },
        ];

        const handlePrev = () => {
            setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cards.length - 1));
        };

        const handleNext = () => {
            setActiveIndex((prevIndex) => (prevIndex < cards.length - 1 ? prevIndex + 1 : 0));
        };

        return (
            <div className="flex flex-col items-center justify-center p-4 overflow-hidden">
                <h1 className="text-white text-2xl sm:text-3xl font-bold md:mb-12 text-center">Partners in HighTech Innovation</h1>
                <div className="relative w-full max-w-5xl">
                    <div className="flex justify-center items-center h-64 sm:h-80 md:h-96 overflow-visible">
                        <AnimatePresence initial={false}>
                            {cards.map((card, index) => {
                                const isActive = index === activeIndex;
                                const offset = (index - activeIndex) * (window.innerWidth < 640 ? 200 : 300);
                                const zIndex = cards.length - Math.abs(activeIndex - index);
                                return (
                                    <motion.div
                                        key={card.id}
                                        className="absolute bg-[#14192E] rounded-lg shadow-lg pt-4 sm:pt-8"
                                        initial={{ opacity: 0, scale: 0.8 }}
                                        animate={{
                                            opacity: isActive ? 1 : 0.6,
                                            scale: isActive ? 1 : 0.8,
                                            x: offset,
                                            zIndex: zIndex,
                                        }}
                                        exit={{ opacity: 0, scale: 0.8 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <div className="p-2 sm:p-4 md:p-6 h-full flex flex-col">
                                            <img 
                                                src={card.logo} 
                                                alt={`Partner logo ${card.id}`} 
                                                className="h-8 sm:h-12 object-contain mb-2 sm:mb-4 mx-auto" 
                                            />
                                            <p className="text-gray-300 text-xs sm:text-sm flex-grow overflow-hidden text-center">
                                                {card.content}
                                            </p>
                                        </div>
                                    </motion.div>
                                );
                            })}
                        </AnimatePresence>
                    </div>
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex gap-4 mt-4 sm:mt-8">
                        <motion.button
                            onClick={handlePrev}
                            className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                            aria-label="Previous card"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <ChevronLeft size={20} />
                        </motion.button>
                        <motion.button
                            onClick={handleNext}
                            className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                            aria-label="Next card"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <ChevronRight size={20} />
                        </motion.button>
                    </div>
                </div>
            </div>
        );
    };

    const PartnersInChange = () => {
        return <ScrollableCards />;
    };

    const GlobalRecognition = () => {
        const awards = [
            { title: "Innovation Award 2024", image: _img7, alt: "Innovation Award" },
            { title: "Technology Leadership", image: _img8, alt: "Technology Leadership" },
            { title: "Solutions Provider", image: _img9, alt: "Solutions Provider" },
            { title: "Best Solutions Provider", image: _img10, alt: "Best Solutions Provider" },

        ];
        return <RecognitionSection title="Global Recognition" awards={awards} />;
    };


    const LeadershipSection = () => {
        const leaders = [
            { name: "John Smith", title: "Global HighTech Lead", imageUrl: Cloud_leader_img1 },
            { name: "Sarah Johnson", title: "Digital Innovation Director", imageUrl: Cloud_leader_img1 },
            { name: "Michael Chen", title: "Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Emma Davis", title: "Solutions Lead", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };


    return (
        <div className='bg-bg text-white'>
            <Header
                title="Transforming Utilities for a Carbon-Neutral Future"
                description="
Utilities play a pivotal role in driving the shift to a sustainable, carbon-neutral world. Reaching net-zero goals demands bold transformations and innovative approaches that deliver enduring value for all stakeholders."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <UtilitiesStats />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventUtilities />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <UtilitiesTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <PartnersInChange />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <GlobalRecognition />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent title="HighTech careers" subtitle="Grow your career at the heart of  transformation" />
            </div>
            <Footer />
        </div>
    );
}

export default Utilities
