import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import SapManagedCover from '../assets/SAP/SapMangedCover.png'

import SapConsultingImg1 from '../assets/SAP/sapConsultionImg1.png'
import SapConsultingImg2 from '../assets/SAP/SapConsultingImg2.png'
import SapConsultingImg3 from '../assets/SAP/SapConsultingImg3.png'
import SapManagedImg3 from '../assets/SAP/SapManagedImg2.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faClock, faExpand, faCircleUp, faPlay, faShield, faUserTie, faLock } from '@fortawesome/free-solid-svg-icons';
import { ContactContent, FeatureGrid } from '../components/resuable/SapComponents'
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'

function SapManagedServices() {
    const headerData = {
        title: "SAP Cloud Solutions By DIAA CORP - Transforming Your SAP Environment with Reliability and Innovation",
        description: "Elevate and optimize your mission-critical business operations through cutting-edge cloud technology, ensuring superior performance, scalability, and security."
    };

    useDocumentTitle(headerData?.title.replace(/\b(what|is)\b/gi, ''));

    const migrationInfoData = {
        stats: [
            { title: "$5600", subtitle: "The average cost for a single minute of downtime is around" },
            { title: "42 %", subtitle: "Application managed services boosts employee productivity" },
        ],
        mainContent: {
            heading: "Maximum Efficiency, Maximized Operations: Run your Enterprise Applications on a Robust Cloud Backend",
            paragraphs: [
                "Modern enterprises are heavily reliant on business applications to automate, augment, or modernize key operations such as workflow management, billing, project delivery, content management, supply chain control, data management, strategic governance, customer services, and more. However, as IT landscapes get more complicated each day, on-prem application management has become a major headache for businesses.",
                "With DIAA Corp, the world’s largest end-to-end application-focused cloud managed service provider, avail a transformative application managed service experience. Relieve your organization of all the application backend hassles including infra hosting, server management, networks, middleware, and platform complexities and leverage the complete prowess of your adopted application systems such as ERP, CRM, CMS, DBMS apps on cloud at maximum availability, security, and continuity. Evolve your organizational functionality beyond traditional IT boundaries with advanced analytics and intelligence.",
            ]
        },
    };

    const SAPServiceOrg = () => {
        return (
            <div className="text-white p-8 md:mb-24">
                <div className="container mx-auto flex flex-col lg:flex-row md:space-x-12">
                    <div className="lg:w-2/3 pr-8 ">
                        <p className="mb-4 mobile:text-base">
                            Your SAP environment demands flawless performance and continuous uptime. As businesses evolve, so do their needs—making it imperative to maintain and enhance your SAP infrastructure. Managing SAP landscapes can be complex and resource-intensive. Partnering with an SAP Managed Services Provider simplifies the process, reduces Total Cost of Ownership (TCO), and allows you to focus on your core business objectives while freeing your team from routine SAP management tasks.
                        </p>
                        <p className="mb-4 mobile:text-base">
                            <a href="/" className="text-theme">DIAA CORP</a> is a global leader in SAP services and automation-driven managed solutions. As one of the largest SAP partners in the world, we specialize in managing and optimizing SAP environments across diverse industries. With a team of highly trained and certified professionals, we bring unparalleled expertise and industry insights, offering you strategic solutions for your SAP landscape.
                        </p>
                    </div>
                    <div className="lg:w-1/3 mt-8 lg:mt-0">
                        <div className="relative h-80">
                            <img src={SapConsultingImg1} alt="Meeting" className="absolute top-0 right-0 w-3/4 h-3/4 object-cover rounded-lg shadow-lg" />
                            <img src={SapConsultingImg2} alt="Analysis" className="absolute bottom-0 left-0 w-3/4 h-3/4 object-cover rounded-lg shadow-lg" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const DifferentiatingPillars = () => {
        return (
            <div className="relative w-full bg-cover bg-center flex items-center justify-center pt-12 md:pt-24">
                <div className="absolute top-0 left-0 w-full h-full"
                    style={{
                        background: `url(${SapManagedImg3})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        filter: 'blur(2px)',
                    }}></div>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative z-10 text-white text-center w-full px-4 md:w-4/5 mx-auto">
                    <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-8">Advantages of Our SAP Managed Services</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 my-8 md:my-16 lg:my-24">
                        {[
                            { icon: <FontAwesomeIcon icon={faCoins} />, title: 'Lower TCO & Maintenance Costs', subtitle: 'Cut down on your SAP-related expenses and optimize ownership costs with our cost-effective SAP solutions.' },
                            { icon: <FontAwesomeIcon icon={faClock} />, title: '24/7 Support Coverage', subtitle: 'Receive continuous support in your preferred regional language to ensure your SAP operations run smoothly at all times.' },
                            { icon: <FontAwesomeIcon icon={faExpand} />, title: 'Scalable Solutions', subtitle: 'Effortlessly adjust your SAP services to accommodate your growing business needs with flexible scaling options.' },
                            { icon: <FontAwesomeIcon icon={faCircleUp} />, title: 'High Uptime & Availability', subtitle: 'Benefit from guaranteed high availability and minimal downtime, ensuring your SAP solutions are always up and running.' },
                            { icon: <FontAwesomeIcon icon={faPlay} />, title: 'Business Continuity with Zero Disruptions', subtitle: 'Keep your business operating seamlessly with our reliable SAP services and solutions.' },
                            { icon: <FontAwesomeIcon icon={faShield} />, title: 'Comprehensive Security Audits', subtitle: 'Regular security checks and audits safeguard your SAP applications and databases from potential vulnerabilities.' },
                            { icon: <FontAwesomeIcon icon={faUserTie} />, title: 'Expert Administration & Monitoring', subtitle: 'Ensure optimal performance of your SAP environment with expert monitoring and administration.' },
                            { icon: <FontAwesomeIcon icon={faLock} />, title: 'Advanced 24x7 Security Protection', subtitle: 'With our round-the-clock security services, your SAP environment remains safe and secure.' },
                        ].map((item, index) => (
                            <div key={index} className="bg-[#52527E] bg-opacity-40 p-4 rounded-lg flex flex-col items-center h-full">
                                <span className='text-theme text-2xl mb-4'>
                                    {item.icon}
                                </span>
                                <div className="text-center">
                                    <h3 className="font-semibold text-sm sm:text-base md:text-lg mb-2">{item.title}</h3>
                                    <p className="text-xs sm:text-sm text-gray-300">{item.subtitle}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const SAPConsultingInfos = () => {
        const cards = [
            {
                title: "Managed Infrastructure & Cloud Migration",
                content: "We oversee on-premise and cloud infrastructure, managing and migrating SAP workloads to major cloud platforms like AWS, Azure, and Google Cloud using industry-leading practices.",
            },
            {
                title: "SAP New Implementation & Ongoing Support",
                content: "Our experts identify opportunities to optimize your business with SAP solutions, providing roadmaps for implementation, scaling, and ongoing support for both new and existing functionalities.",
            },
            {
                title: "Proactive Monitoring & Audits",
                content: "Receive 24/7 operational support, identifying potential issues early and resolving them before they impact your operations. We conduct thorough audits to ensure compliance and security across your SAP applications.",
            },
            {
                title: "Administration & Quality Assurance (Upgrades, Health Checks)",
                content: "We assume full responsibility for SAP database administration, installations, upgrades, and enhancements. Our proactive health checks and incident management ensure everything functions seamlessly.",
            },
            {
                title: "SAP Security & Governance Solutions",
                content: "Our services include comprehensive security strategies, vulnerability assessments, and robust governance models. Our 24/7 Security Operations Center (SOC) ensures continuous protection of your SAP infrastructure.",
            },
            {
                title: "Disaster Recovery & Business Continuity",
                content: "We provide automated backup and disaster recovery solutions to minimize downtime, ensuring that your SAP applications recover quickly and effectively after an outage. We guarantee business continuity with minimal disruptions.",
            },
        ];

        return (
            <div className="text-white md:w-11/12 mx-auto my-12 md:my-24 mobile:px-2">
                <div className="mx-auto">
                    <h1 className="text-xl md:text-4xl font-bold md:text-center mb-4 md:mb-6">DIAA CORP SAP Managed Services Offerings</h1>
                    <p className="mobile:text-sm md:text-center text-gray-400 mb-12 md:mb-24">DIAA CORP offers a comprehensive suite of SAP managed services designed to ensure maximum availability, security, and operational continuity for your business. From cloud infrastructure management and migration to security audits and disaster recovery, our team provides end-to-end solutions tailored to your needs.</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 space-y-4">
                        {cards.map((card, index) => (
                            <div key={index} className="relative p-6 rounded-lg overflow-hidden md:w-4/5 mx-auto shadow-[0_0_10px_#a100ff]">
                                <div className="absolute inset-0 bg-gradient-to-r from-[#a100ff] to-[#57CFFF] opacity-80 rounded-lg"></div>
                                <div className="absolute inset-[1px] bg-bg rounded-lg"></div>
                                <div className="relative z-10">
                                    <h2 className="text-lg md:text-xl font-semibold mb-4 text-center">{card.title}</h2>
                                    <p className="text-sm text-gray-300 mb-4">{card.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };


    function SAPServicesComponent() {
        const services = [
            {
                title: "Cloud Services",
                points: [
                    "Cloud Infrastructure Management",
                    "Virtual Machine Setup",
                    "Cloud Resource Allocation",
                    "Cloud Storage Solutions"
                ],
                position: "top-[-5%] left-1/2 -translate-x-1/2"
            },
            {
                title: "Cloud Security",
                points: [
                    "Cloud Access Management",
                    "Data Encryption Services",
                    "Disaster Recovery Planning"
                ],
                position: "top-[10%] left-[10%]"
            },
            {
                title: "Cloud Migration",
                points: [
                    "Cloud Data Transfer",
                    "Multi-Cloud Architecture",
                    "Platform-as-a-Service (PaaS) Setup"
                ],
                position: "top-[10%] right-[10%]"
            },
            {
                title: "Cloud Performance Optimization",
                points: [
                    "Load Balancing",
                    "Auto-Scaling Solutions",
                    "Cloud Resource Monitoring"
                ],
                position: "top-[35%] left-[5%]"
            },
            {
                title: "IT Operations",
                points: [
                    "System Health Monitoring",
                    "Server Health Check",
                    "Network Monitoring",
                    "Application Performance Metrics"
                ],
                position: "top-[35%] right-[5%]"
            },
            {
                title: "Network Optimization",
                points: [
                    "Bandwidth Allocation",
                    "Traffic Management",
                    "VPN Configuration"
                ],
                position: "bottom-[20%] left-[10%]"
            },
            {
                title: "System Administration",
                points: [
                    "Server Patch Management",
                    "Backup and Recovery",
                    "Database Optimization"
                ],
                position: "bottom-[20%] right-[10%]"
            },
            {
                title: "Automation & DevOps",
                points: [
                    "Continuous Integration/Continuous Delivery (CI/CD)",
                    "Automated Build Pipelines",
                    "Automated Testing",
                    "Version Control Management"
                ],
                position: "bottom-[0%] left-1/2 -translate-x-1/2"
            },
        ];

        return (
            <div className="text-white p-4 md:p-8 flex items-center justify-center md:my-12 lg:my-24 xl:my-32">
                <div className="w-full max-w-7xl relative">
                    {/* Desktop view (only for screens wider than 1320px) */}
                    <div className="hidden xl:block relative aspect-[16/9]">
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className={`absolute ${service.position} w-64 bg-[#14192E] rounded-lg p-4 flex flex-col items-start justify-start`}
                                style={{
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                    borderImage: 'linear-gradient(90deg, #4F75FF -55%, #a100ff) 1',
                                }}
                            >
                                <h3 className="text-sm font-bold mb-2">{service.title}</h3>
                                <ul className="text-xs space-y-1">
                                    {service.points.map((point, idx) => (
                                        <li key={idx}>{point}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[30%] aspect-square">
                            <div className="absolute inset-0 bg-blue-900 rounded-full flex items-center justify-center">
                                <div className="text-2xl font-bold text-white text-center">SAP Services</div>
                            </div>
                        </div>
                    </div>

                    {/* Mobile and Tablet view (for screens smaller than 1320px) */}
                    <div className="xl:hidden flex flex-col items-center space-y-4">
                        <div className="bg-blue-900 rounded-full p-4 md:p-6 mb-4 md:mb-6">
                            <div className="text-xl md:text-2xl font-bold text-white">SAP Services</div>
                        </div>
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className="bg-[#14192E] rounded-lg p-3 md:p-4 w-full max-w-md"
                                style={{
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderImage: 'linear-gradient(90deg, #4F75FF -55%, #a100ff) 1',
                                }}
                            >
                                <h3 className="text-sm md:text-base font-bold mb-2">{service.title}</h3>
                                <ul className="text-xs md:text-sm space-y-1">
                                    {service.points.map((point, idx) => (
                                        <li key={idx}>{point}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
    );
    }
    const ServiceOfferings = () => {
        const services = [
            {
                title: 'E-Commerce',
                description: 'DIAA CORP empowers a leading E-commerce platform with advanced analytics and seamless integration through a custom cloud-based solution',
            },
            {
                title: 'Financial Services',
                description: 'A prominent financial institution enhances customer engagement and operational efficiency using DIAA CORP\'s AI-driven SAP solutions',
            },
            {
                title: 'Automotive',
                description: 'DIAA CORP streamlines production and supply chain management for a major automotive manufacturer with SAP S/4HANA on Microsoft Azure',
            },
        ];

        return (
            <div className="py-12 sm:py-16 md:py-20 lg:py-24 flex items-center justify-center bg-[#14192E80] bg-opacity-50 mt-8 sm:mt-12 md:mt-16 lg:mt-24">
                <div className="container mx-auto px-4">
                    <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white text-center mb-6 sm:mb-8 md:mb-10 lg:mb-12">
                        Some of our Success Stories
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mt-8 sm:mt-12 md:mt-16 lg:mt-20 justify-items-center">
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className="bg-[#51566E] bg-opacity-30 rounded-lg p-4 sm:p-6 flex flex-col justify-between w-full max-w-[344px] h-[240px] sm:h-[260px] text-center"
                            >
                                <div>
                                    <h3 className="text-lg sm:text-xl font-semibold text-white mb-2 sm:mb-4">{service.title}</h3>
                                    <p className="text-gray-300 mb-2 sm:mb-4 text-xs sm:text-sm">{service.description}</p>
                                </div>
                                <button className="py-3 sm:py-4 px-4 text-xs sm:text-sm mx-auto hover:bg-custom-gradient" style={{
                                    border: "2px solid #a100ff",
                                    borderRadius: "9999px",
                                    backgroundOrigin: "border-box",
                                }}>
                                    Download Case Study
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const features = [
        {
            icon: "Innovation",
            text: "DIAA CORP Expertise in Next-Generation IT Solutions: At the forefront of innovation, DIAA CORP designs and implements next-gen solutions in AI, cloud computing, and big data, helping businesses drive growth in a rapidly evolving digital world."
        },
        {
            icon: "Scalability",
            text: "Our solutions are designed to scale with your business needs, from small startups to large enterprises, offering flexible deployment models, including hybrid, private, and public clouds."
        },
        {
            icon: "Security",
            text: "We prioritize security with advanced encryption, access control, and threat detection systems to safeguard your business-critical data and maintain compliance with industry regulations."
        },
        {
            icon: "Customer-Centric Approach",
            text: "Our dedicated teams work closely with clients to understand their unique requirements, ensuring customized solutions that drive measurable business outcomes."
        },
        {
            icon: "Sustainability",
            text: "DIAA CORP integrates sustainability into our solutions, helping businesses reduce their environmental impact through energy-efficient technologies and green IT practices."
        },
        {
            icon: "Global Reach",
            text: "With a presence in over 30 countries and a network of 60+ locations, we provide localized support and strategic insights to our global clients."
        },
        {
            icon: "End-to-End Service Delivery",
            text: "From consultation and design to implementation and ongoing support, we offer end-to-end services to ensure the success of your IT projects."
        },
    ];

    const ManagedServicesBoxes = () => {
        return (
            <FeatureGrid
                title="DIAA CORP Expertise in Next-Generation IT Solutions"
                features={features}
                isIconPresent={false}
            />
        );
    };

    const certifications = [
        { title: 'Cloud Services', subtitle: 'SAP Certified in Cloud Services' },
        { title: 'Microsoft Azure', subtitle: 'Certified Partner' },
        { title: 'Google Cloud', subtitle: 'Platform (GCP) Certified' },
        { title: 'DevOps', subtitle: 'Certified in CI/CD' },
        { title: 'ISO 27001', subtitle: 'Certified Security Standards' },
    ];

    const CertificationCard = ({ title, subtitle }) => (
        <div className="bg-opacity-10 bg-white p-6 rounded-2xl shadow-md h-[154px] text-center flex flex-col justify-center">
            <h3 className="text-xl md:text-2xl font-bold text-white mb-2">{title}</h3>
            <p className="text-base md:text-lg font-medium text-gray-300">{subtitle}</p>
        </div>
    );

    const Certifications = () => (
        <div className="py-12 md:py-24 flex flex-col items-center justify-center">
            <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-white mb-8 md:mb-24">SAP Certified Provider</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
                {certifications.map((cert, index) => (
                    <CertificationCard key={index} title={cert.title} subtitle={cert.subtitle} />
                ))}
            </div>
        </div>
    );

    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} isExploreBtn={true} backgroundImage={SapManagedCover} />

            <div className='md:w-11/12 mt-12 md:mt-24'>
                <SAPServiceOrg />
            </div>

            <div>
                <DifferentiatingPillars />
            </div>

            <div>
                <SAPConsultingInfos />
            </div>

            <div>
                <SAPServicesComponent />
            </div>

            <div>
                <ServiceOfferings />
            </div>

            <div>
                <ManagedServicesBoxes />
            </div>

            <div>
                <Certifications />
            </div>

            <div>
                <ContactContent title={`Experience SAP And Its Impact On Business Transformation`}/>
            </div>

            <div><Footer /></div>

        </div>
    )
}

export default SapManagedServices