import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const ExperiencedProfessionals = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleViewPositions = () => {
    setIsLoading(true);
    // Simulate loading
    setTimeout(() => {
      setIsLoading(false);
      toast.success('Redirecting to open positions');
      navigate('/we-hire/search-all-jobs');
    }, 1000);
  };

  return (
    <div className="bg-bg min-h-screen text-white py-16 px-4">
      <div className="max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Experienced Professionals
          </h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Join our team of experts and take your career to the next level
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-[#1A1F31] rounded-xl p-6"
          >
            <h2 className="text-2xl font-bold mb-4">Why Join Us?</h2>
            <ul className="space-y-4 text-gray-300">
              <li>• Global opportunities and exposure</li>
              <li>• Continuous learning and development</li>
              <li>• Work with cutting-edge technologies</li>
              <li>• Competitive compensation packages</li>
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
            className="bg-[#1A1F31] rounded-xl p-6"
          >
            <h2 className="text-2xl font-bold mb-4">What We Look For</h2>
            <ul className="space-y-4 text-gray-300">
              <li>• Strong technical expertise</li>
              <li>• Problem-solving abilities</li>
              <li>• Leadership potential</li>
              <li>• Innovation mindset</li>
            </ul>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="text-center"
        >
          <button 
            onClick={handleViewPositions}
            disabled={isLoading}
            className="bg-gradient-to-r from-purple-600 to-purple-800 text-white px-8 py-3 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50"
          >
            {isLoading ? 'Loading...' : 'View Open Positions'}
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default ExperiencedProfessionals; 