import React from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/Metaverse_cover.png'
import Metaverse_img1 from '../../assets/Capabilities/Metaverse_img1.png'
import Metaverse_img2 from '../../assets/Capabilities/Metaverse_img2.png'
import Metaverse_img3 from '../../assets/Capabilities/Metaverse_img3.png'
import Metaverse_img4 from '../../assets/Capabilities/Metaverse_img4.png'
import Metaverse_img5 from '../../assets/Capabilities/Metaverse_img5.png'
import Metaverse_img6 from '../../assets/Capabilities/Metaverse_img6.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { motion, AnimatePresence } from 'framer-motion';
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import microsoftLogo from '../../assets/Capabilities/Cloud_partner_img2.png'
import awsLogo from '../../assets/Capabilities/Cloud_partner_img3.png'
import googleLogo from '../../assets/Capabilities/Cloud_partner_img4.png'
import sapLogo from '../../assets/Capabilities/sap-logo.png'
import ibmLogo from '../../assets/Capabilities/ibm-logo.png'
import servicenowLogo from '../../assets/Capabilities/Cloud_partner_img1.png'
import databricksLogo from '../../assets/Capabilities/databricks-logo.png'
import oracleLogo from '../../assets/Capabilities/oracle-logo.png'
import { useDocumentTitle } from '../../utils/useDocumentTitle'

function Metaverse() {
    useDocumentTitle("Metaverse");
    const MetaverseStats = () => {
        const stats = [
            { value: '78%', description: 'Executives believe the metaverse will drive significant growth and innovation within their organizations.' },
            { value: '85%', description: 'Consumers are eager to explore the metaverse and its potential to transform their daily experiences.' },
            { value: '$1.5T', description: 'Projected annual revenue opportunity from the metaverse across industries by 2030, opening new avenues for learning and development.' },
        ];

        return <StatsGrid title="Why Transform Learning for the Metaverse?" stats={stats} />;
    };

    const ReinventWithEnterprisePlatforms = () => {
        const cards = [
            {
                title: 'Blockchain & Web3',
                description: 'Unlock the power of decentralized systems to revolutionize business operations and drive transparency.',
                hasButton: true,
            },
            {
                title: 'Industrial Spatial Computing',
                description: 'Harness the next wave of industrial innovation, blending physical and digital worlds for smarter operations.',
                hasButton: true,
            },
            {
                title: 'Consumer Spatial Computing',
                description: 'Create immersive, interactive experiences that redefine how consumers engage with your brand.',
                hasButton: true,
            },
            {
                title: 'Decentralized Identity',
                description: 'Enable secure, user-controlled digital identities, empowering individuals with greater ownership and privacy.',
                hasButton: true,
            },
        ];

        return <ReinventGrid title="Transform with Us" cards={cards} />;
    };

    const slides = [
        {
            title: "The Metaverse Beyond the Hype",
            description: "Real strategies for adopting and integrating the metaverse into business and everyday life.",
            image: Metaverse_img1,
            gradient: "from-purple-400 to-purple-600"
        },
        {
            title: "Building a Responsible Metaverse",
            description: "How to create an ethical, inclusive, and sustainable metaverse experience for all users.",
            image: Metaverse_img2,
            gradient: "from-blue-400 to-blue-600"
        },
        {
            title: "Leveraging the Metaverse for Climate Action",
            description: "Exploring how virtual spaces can address real-world environmental challenges and promote sustainability.",
            image: Metaverse_img3,
            gradient: "from-cyan-400 to-cyan-600"
        }
    ];

    const MetaverseTrendingCarousel = () => {
        return <TrendingCarousel title="What's Shaping the Future?" slides={slides} />;
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "DIAA CORP Named a Leader in Innovation Consulting by Forrester, Q2 2024",
                image: Metaverse_img4,
                alt: "DIAA CORP Innovation Consulting Leader"
            },
            {
                title: "Leader in enterprise blockchain services by everest group 2023",
                image: Metaverse_img5,
                alt: "Enterprise Blockchain Services Leader"
            },
            {
                title: "Recognized as Market Leader for Metaverse Service by HFS Horizons",
                image: Metaverse_img6,
                alt: "Metaverse Service Market Leader"
            },
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };


    return (
        <div className='bg-bg text-white'>
            <Header
                title="Embrace the Future of Innovation"
                description="The boundaries between the physical and virtual worlds are dissolving. The metaverse opens up endless possibilities to connect, create, and engage. It’s an opportunity to shape inclusive, responsible, and transformative experiences for all."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <MetaverseStats />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithEnterprisePlatforms />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <MetaverseTrendingCarousel />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <GlobalRecognition />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20 mb-8 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
            {/* Add more components and content here */}
        </div>
    )
}

export default Metaverse
