import React, { useEffect, useState } from 'react'
import { Header } from '../components/resuable/SapSolution'
import coverImage from '../assets/SAP/SAPCloudCover.png'
import coverImage2 from '../assets/SAP/cloudManagedCover2.png'
import clientImg from '../assets/SAP/ClientImg.png'
import cloudImg1 from '../assets/SAP/cloudManagedimg.png'
import ourSuccessImg1 from '../assets/SAP/ourSuccessImg2.png'
import contactCover from '../assets/SAP/contactCover.png'
import MigrationInfo from '../components/SAP/MigrantInfo';
import { ContactContent, FAQItem, FeatureGrid, scrollToBottom, Slider } from '../components/resuable/SapComponents';
import { AnimatePresence, motion } from 'framer-motion'
import { FaGlobe, FaCloud, FaLock, FaClock, FaGraph, FaRobot, FaDatabase, FaChartBar } from 'react-icons/fa';
import Footer from '../components/Footer'
import { useDocumentTitle } from '../utils/useDocumentTitle'


function CloudManagedServices() {
    useDocumentTitle('Managed Cloud Services');

    const headerData = {
        title: "Managed Cloud Services",
        description: "Cloud Transformation Solutions: Transform your business with cloud-powered innovation. Leverage the flexibility, security, and scalability of the cloud to streamline operations, enhance collaboration, and drive growth in the digital age."
    };

    const migrationInfoData = {
        stats: [
            { title: "$300 Billion", subtitle: "Global spending on public cloud managed services is expected to exceed $300 billion by 2030, reflecting the growing adoption of cloud technology across industries." },
            { title: "80 %", subtitle: "Around 80% of businesses currently utilizing managed cloud services are planning to significantly increase their investment in the coming years to stay competitive and leverage cloud's full potential." },
            { title: "65 %", subtitle: "A large number of organizations, approximately 65%, cite the shortage of skilled cloud professionals as a key challenge in successfully executing their cloud strategies and achieving business goals." }
        ],
        mainContent: {
            heading: "The Future of IT: Revolutionizing Business with Cloud Solutions",
            paragraphs: [
                "Cloud technology is shaping the future of business by providing unprecedented performance, flexibility, and cost-efficiency. With businesses across the globe rapidly modernizing their IT infrastructures, cloud adoption is becoming essential to stay competitive. Experts predict that by 2025, more than 80% of global data will be hosted on the cloud, driving digital transformation at an extraordinary pace.",
                "Managed Cloud Service Providers (MCSPs) play a critical role in this evolution, acting as the crucial link between businesses and the cloud ecosystem. As a comprehensive, application-centric managed cloud service provider, DIAA Corp takes full responsibility for your cloud operations. We offer tailored public, private, and hybrid cloud solutions leveraging top-tier platforms like AWS, Azure, Google Cloud Platform (GCP), Oracle, and IBM Cloud. By harnessing the power of these cloud architectures, businesses benefit from unmatched agility, uptime, scalability, and seamless control. Additionally, DIAA Corp provides round-the-clock consulting and support, ensuring your digital transformation is smooth and successful."
            ]
        },
    };


    const DIAACorpRecognition = () => {
        return (
            <div className="bg-bg text-white py-8 px-4 mt-12 md:mt-24">
                <div className="md:w-11/12 mx-auto text-center">
                    <h2 className="text-2xl md:text-3xl mobile:text-xl font-bold mb-4">
                        DIAA Corp Named a Visionary in 2021 Gartner® Magic Quadrant™ for Public Cloud IT Transformation Services
                    </h2>
                    <p className="text-sm md:text-base mobile:text-xs leading-relaxed">
                        This is a testament to the unparalleled value DIAA Corp’s cloud-native automation-driven agile solutions such as SHOP or Self-healing Operations Platform add. Across industries, a greater need is felt for new skills, tools, strategies, and processes to materialize the transformation towards a cloud-native future. DIAA Corp’s unfaltering commitment to be instrumental in accelerating the journey and driving business outcomes has reaffirmed our position as a trusted public cloud IT transformation partner in the global arena. This recognition strengthens our belief that our approach is finding resonance among the leading analysts, clients as well as the wider market.
                    </p>
                </div>
            </div>
        );
    };


    const OrganizationsServed = () => {
        return (
            <div className="relative h-screen w-full bg-cover bg-center flex items-center justify-center mt-12 md:mt-24"
                style={{
                    background: `url(${coverImage2})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative z-10 text-white text-center">
                    <h1 className="text-4xl mobile:text-2xl font-bold mb-8">Trusted by Leading Innovators Across the Globe</h1>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-12 md:my-24">
                        {[
                            { icon: '🌍', title: 'Global Presence', subtitle: 'Empowering over 4,000 organizations in 25 countries to achieve excellence.' },
                            { icon: '🏦', title: 'Banking Excellence', subtitle: 'Partnering with 3 of the top 5 global banks for innovative solutions.' },
                            { icon: '🚗', title: 'Driving Automotive Innovation', subtitle: 'Collaborating with 2 of the top 5 car manufacturers worldwide.' },
                            { icon: '🎬', title: 'Media Powerhouses', subtitle: 'Supporting 2 of the top 5 global media giants to deliver captivating experiences.' },
                            { icon: '💊', title: 'Healthcare Leaders', subtitle: 'Enabling 3 of the top 5 pharmaceutical companies to revolutionize healthcare.' },
                            { icon: '💰', title: 'Financial Expertise', subtitle: 'Trusted by 2 of the top 5 financial services providers to drive growth.' },
                        ].map((item, index) => (
                            <div key={index} className="bg-[#52527E] bg-opacity-50 p-4 rounded-lg flex items-center lg:py-12 lg:px-12">
                                <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-r from-[#a100ff] to-[#57CFFF] mr-4">
                                    {item.icon}
                                </div>
                                <div className="text-left">
                                    <h3 className="font-semibold">{item.title}</h3>
                                    <p className="text-sm">{item.subtitle}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };




    const ClientTestimonials = () => {
        const [currentSlide, setCurrentSlide] = useState(1);
        const testimonials = [
            { name: 'Roman Alex', position: 'President of SAP', image: clientImg, text: 'I am pleased and honor to recognize congratulate DIAA CORP as a recipient of the sap partner.' },
            { name: 'Jane Doe', position: 'CTO of TechCorp', image: clientImg, text: 'Working with DIAA CORP has been a game-changer for our cloud strategy.' },
            { name: 'John Smith', position: 'CEO of InnovateCo', image: clientImg, text: 'DIAA CORPs expertise in cloud solutions is unparalleled in the industry.' },
            // Add more testimonials as needed
        ];

        const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % testimonials.length);
        const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + testimonials.length) % testimonials.length);

        useEffect(() => {
            const timer = setInterval(nextSlide, 5000);
            return () => clearInterval(timer);
        }, []);

        return (
            <div className=" text-white py-16 mobile:px-2">
                <div className="mx-auto">
                    <h2 className="text-3xl md:text-4xl mobile:text-2xl font-bold text-center mb-12">What Our Clients Say ?</h2>
                    <div className="relative mb-16 md:w-11/12 mx-auto max-w-6xl">
                        <div className="overflow-hidden">
                            <div className="flex transition-transform duration-300 ease-in-out"
                                style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                                {testimonials.map((testimonial, index) => (
                                    <div key={index} className="w-full flex-shrink-0 px-4">
                                        <div className={`transition-all duration-300 
                                                ${index === currentSlide ? 'scale-100 opacity-100' : 'scale-90 opacity-50'}`}>
                                            <div className="bg-[#14192E] bg-opacity-50 p-6 rounded-lg flex items-center">
                                                <img src={testimonial.image} alt={testimonial.name} className="w-20 h-20 rounded-full mr-6" />
                                                <div>
                                                    <h3 className="font-bold text-xl">{testimonial.name}</h3>
                                                    <p className="text-sm text-gray-400 mb-2">{testimonial.position}</p>
                                                    <p className="mobile:text-sm text-lg">{testimonial.text}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex justify-center mt-8 space-x-4">
                            <button onClick={prevSlide} className="bg-transparent border border-white rounded-full p-2 hover:bg-white hover:bg-opacity-20">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>
                            </button>
                            <button onClick={nextSlide} className="bg-transparent border border-white rounded-full p-2 hover:bg-white hover:bg-opacity-20">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
                            </button>
                        </div>
                    </div>

                    {/* Roadblocks Section */}
                    <div className="bg-[#14192E] p-8 rounded-lg mt-12 md:mt-24 py-12 md:py-24">
                        <h2 className="mobile:text-xl md:text-3xl font-bold text-center mb-8">
                            Overcoming Challenges in Your Cloud Transformation Journey
                        </h2>
                        <div className="grid md:grid-cols-2 gap-6 md:w-11/12 mx-auto">
                            {[
                                "Struggling with multi-vendor lock-in and disconnected systems? Streamlining operations can be daunting.",
                                "Fragmented SLAs often lead to delays, penalties, and missed milestones.",
                                "Limited scalability and suboptimal workload performance can hinder growth.",
                                "Inadequate oversight of IT processes impacts governance and decision-making.",
                                "Disjointed applications and systems create a complicated IT landscape.",
                                "Failed implementations with minimal vendor support derail transformation efforts.",
                                "Absence of proper consulting and advisory services limits cloud journey success.",
                                "Unplanned outages lead to operational disruptions and financial losses.",
                                "Compromised systems and limited risk management hinder organizational resilience.",
                                "Failure to align digital transformation with business goals hampers success."
                            ].map((point, index) => (
                                <div key={index} className="flex items-start md:text-center">
                                    <div className="w-3 h-3 mt-1.5 mr-3 rounded-full bg-gradient-to-r from-[#a100ff] to-[#57CFFF] flex-shrink-0"></div>
                                    <p className="text-sm">{point}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const DIAACorpCloudComponent = () => {
        return (
            <div className="bg-darkBlue-900 text-white flex flex-col items-center justify-center p-6">
                <div className="md:text-center mb-8 md:w-11/12">
                    <h1 className="text-2xl md:text-4xl mobile:text-xl font-bold mb-4">
                        Empower Your Business with DIAA Corp Managed Cloud Services
                    </h1>
                    <div className="flex mobile:flex-col items-center md:space-x-24 my-12 md:my-24">
                        <div className="relative mb-6 p-1 rounded-lg bg-custom-gradient md:w-1/2 ">
                            <img
                                src={cloudImg1}
                                alt="Enterprise Transformation"
                                className="rounded-lg "
                            />
                        </div>
                        <ul className="text-left space-y-2">
                            <li className="flex items-start">
                                <span className="mr-2 bg-custom-gradient text-transparent bg-clip-text">➤</span>
                                Ensure enterprise resilience with high-performance cloud-native architecture offering 99.95% uptime.
                            </li>
                            <li className="flex items-start">
                                <span className="mr-2 bg-custom-gradient text-transparent bg-clip-text">➤</span>
                                Accelerate operations with agile workload management and optimized asset performance.
                            </li>
                            <li className="flex items-start">
                                <span className="mr-2 bg-custom-gradient text-transparent bg-clip-text">➤</span>
                                Leverage strategic alliances with global hyperscalers and top-rated data centers for unmatched reliability and scalability.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="md:text-center md:w-11/12">
                    <h2 className="text-xl md:text-3xl font-bold mb-4">
                        DIAA Corp Self Healing Operations Platform (SHOP): Automation Enabled Managed Services
                    </h2>
                    <p className='mt-6 md:mt-12'>
                        DIAA Corp SHOP is a low code AI-powered platform that seamlessly integrates different tools and solutions necessary to deliver managed cloud services to enterprises. The intelligent platform brings dozens of diverse operational platforms, applications together including auto-remediation and self-healing onto a single system. This enables the entire infrastructure and applications landscape to be auto-managed through a single pane of glass while providing customers with a holistic view of their IT environments. Guaranteeing concept to delivery in six months, the platform improves engineers’ efficiency while also allowing engineers with less experience, to handle more complex tasks. Avail a universal view and control on your cloud platform and connected IT architecture.
                    </p>
                </div>
            </div>
        );
    };

    const tabs = [
        'Transformative Automation for Intelligent Operations',
        'Harnessing Predictive and Preventive Strategies',
        'Collective Knowledge for Informed Decision-Making',
        'Real-Time Situational Awareness',
        'From Remedial to Autonomous Solutions'
    ];

    const content = [
        <p>
            DIAA Corp, now the world's largest application-focused managed services provider, introduces SHOP to redefine intelligent automated operations. SHOP leverages advanced algorithms and AI-driven tools to streamline workflows, reduce manual intervention, and ensure seamless service delivery. This transformation empowers businesses to achieve unparalleled efficiency and operational excellence.
        </p>,
        <p>
            With SHOP, DIAA Corp employs cutting-edge predictive analytics to foresee potential disruptions and implement preventive measures. By analyzing historical data and real-time insights, SHOP anticipates issues before they arise, minimizing downtime and enhancing system reliability for clients across diverse industries.
        </p>,
        <p>
            SHOP integrates collective knowledge from diverse sources, enabling teams to make data-driven decisions swiftly. By unifying information across global systems, SHOP fosters collaboration and innovation, ensuring every decision is backed by comprehensive expertise and actionable intelligence.
        </p>,
        <p>
            Situational awareness is at the core of SHOP's capabilities. It provides real-time insights into operational environments, enabling teams to quickly adapt to dynamic challenges. This heightened awareness ensures that organizations can respond proactively, mitigating risks and capitalizing on emerging opportunities.
        </p>,
        <p>
            SHOP goes beyond remedial actions by incorporating autonomous solutions to resolve issues without human intervention. Powered by AI and machine learning, SHOP's autonomous systems ensure consistent performance, reducing errors and driving higher productivity while allowing human resources to focus on strategic initiatives.
        </p>
    ];


    const ContactContent2 = ({ title, sub }) => {
        return (
            <div className='flex flex-col justify-center items-center text-center bg-[#14192E] mobile:h-[293px] md:h-[471px] px-4 relative'>
                <div className='absolute top-0 left-0 w-full h-full'
                    style={{
                        background: `url(${contactCover})`,
                        backgroundSize: 'cover',
                        backgroundPosition: '25% 25%',
                        backgroundRepeat: 'no-repeat',
                        filter: 'blur(5px)',
                    }}></div>
                <div className='md:w-11/12 relative z-10'>
                    <h2 className="text-lg md:text-2xl font-bold mb-4">{title}</h2>
                    <p className='mb-12 mobile:text-sm'>{sub}</p>
                    <motion.button
                        className="bg-custom-gradient text-white font-normal py-2 px-12 rounded-full hover:bg-blue-600 transition duration-300"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={scrollToBottom}
                    >
                        Contact us
                    </motion.button>
                </div>
            </div>
        );
    }

    const tab1 = [
        'Effortless and Secure Cloud Migration',
        'AIOps-Powered Managed Services for Enhanced Efficiency',
        'Hybrid and Multi-Cloud Management Simplified',
        'Comprehensive Cloud Monitoring and Reporting',
        'End-to-End Cloud Security Management'
    ];

    const tab2 = [
        'Robust Business Continuity with Cloud Disaster Recovery',
        'Comprehensive IT Modernization for Enhanced Performance',
        'Optimized Application Management for Maximum Efficiency',
        'Seamless Cloud Transformation for Business Growth'
    ];

    const tab3 = [
        'Intelligent Data Storage for Modern Enterprises',
        'Virtual Workshop Management for Global Collaboration',
        'Automation-Driven Managed Services',
        'DIAA Corp CloudX: A Unified Platform for Business Agility'
    ];

    const content1 = [
        <p>DIAA Corp’s migration factory approach ensures a smooth, fail-proof transition to the cloud with just a few clicks. Businesses can migrate seamlessly to various cloud services like SaaS, IaaS, PaaS, DaaS, and DRaaS under a single, flexible pay-per-use SLA. Whether it's AWS, GCP, Azure, IBM, S/4 HANA, or Oracle Cloud, we build tailored IT environments that meet your specific needs, ensuring a hassle-free and secure migration process.</p>,
        <p>With DIAA Corp’s advanced cloud solutions, businesses benefit from AIOps-powered, globally compliant managed services. Our services simplify infrastructure setup for maximum availability, scalability, and agility. Enjoy automated reporting, IT infrastructure modernization, and uninterrupted business continuity under a single SLA, ensuring cost-effective, smart management of your cloud environments.</p>,
        <p>Managing multiple cloud platforms has never been easier with DIAA Corp’s hybrid multi-cloud solution. Control all your private and public cloud platforms from a single, intuitive dashboard. Gain visibility, assign roles, and use cutting-edge analytics to monitor infrastructure performance, optimize processes, and assess business impact, all while ensuring smooth and unified cloud operations across industries and sectors.</p>,
        <p>DIAA Corp provides 24/7 monitoring of your multi-cloud platforms, virtual infrastructure, networks, and applications. With centralized visibility, automated alerts, and detailed analytical reports, you gain valuable insights to track performance and make data-driven decisions. Our cloud health monitoring ensures that you can focus on business growth, knowing your cloud environment is continuously optimized for success.</p>,
        <p>DIAA Corp offers comprehensive cloud security solutions to protect your business from evolving threats. With managed detection and response, SIEM-SOAR capabilities, and intelligent threat prediction, detection, and mitigation powered by leading platforms, we ensure robust security across your cloud infrastructure.</p>
    ];

    const content2 = [
        <p>Protect your organization from disruptions with DIAA Corp’s advanced disaster recovery solutions. Our cloud-based disaster recovery ensures business continuity, offering quick recovery times and automated backup solutions. With comprehensive failover support and compliance with global standards, your data is always secure, allowing you to focus on what matters most—driving business success.</p>,
        <p>Unlock the full potential of your IT infrastructure with DIAA Corp’s IT modernization services. From upgrading your datacenter to transitioning legacy applications to the cloud, we help businesses achieve superior performance and scalability. Our modernization services are designed to enhance agility, reduce costs, and future-proof your IT assets.</p>,
        <p>DIAA Corp offers end-to-end application management services that ensure your business applications run at peak performance. Whether you’re migrating, modernizing, or managing enterprise software like SAP, we deliver seamless cloud solutions that enhance functionality, increase productivity, and improve collaboration.</p>,
        <p>Transformative cloud services enable businesses to embrace the future of technology with ease. Our cloud solutions are designed to accelerate growth, boost operational efficiency, and drive innovation, ensuring your business remains agile and competitive in the fast-evolving digital landscape.</p>
    ];

    const content3 = [
        <p>DIAA Corp provides intelligent and scalable data storage solutions tailored to your business needs. Harness the power of the cloud to simplify data management, improve accessibility, and enhance disaster recovery strategies. Our hybrid storage models seamlessly integrate on-premises infrastructure with public clouds, ensuring secure and high-speed data transfers.</p>,
        <p>Revolutionize the way you manage workshops with DIAA Corp’s virtual management solutions. Create immersive, interactive digital environments that bring teams together regardless of location. Empower your workforce with tools for real-time collaboration, resource sharing, and streamlined project execution.</p>,
        <p>Elevate your business operations with DIAA Corp’s cutting-edge automation and RPA solutions. Automate repetitive tasks and optimize workflows with tools like real-time monitoring, automated backups, advanced threat detection, and self-healing systems.</p>,
        <p>Simplify cloud operations and drive innovation with DIAA Corp CloudX, an all-in-one platform designed to streamline your digital transformation journey. Manage multi-cloud environments, deploy advanced tools, and unlock unparalleled scalability and flexibility.</p>
    ];

    const CloudManagedTabs = ({ }) => {
        return (
            <div className="w-full md:w-11/12 mx-auto p-4 md:p-6 bg-darkBlue-900 text-white rounded-lg mt-8 md:mt-24">
                <h2 className="text-xl md:text-3xl font-bold text-center mb-4 md:mb-8">
                    DIAA Corp End-to-end Intelligent Managed Cloud Services
                </h2>
                <p className='mt-6 md:mt-12 text-center '>
                    Focus on the Core Offering. Leave all your IT operations woes to us. Succeed, scale, and innovate on the cloud.
                </p>
            </div>
        );
    }


    const features = [
        { icon: <FaGlobe />, text: "15+ years of excellence delivering innovative cloud solutions across APAC, MEA, and the Americas." },
        { icon: <FaCloud />, text: "Trusted partner for global enterprises, specializing in seamless cloud transformation powered by AI and advanced analytics." },
        { icon: <FaLock />, text: "Accelerated Cloud Migration Framework ensuring efficient transition with zero downtime, managing 30,000+ applications and databases to date." },
        { icon: <FaClock />, text: "Flexible, cost-efficient subscription models tailored to individual business needs, ensuring maximum ROI under a single SLA." },
        { icon: <FaGlobe />, text: "Global presence spanning 30+ countries, enabling hybrid, multi-cloud, and edge cloud deployments across diverse industries." },
        { icon: <FaCloud />, text: "Empowering 5,000+ enterprise clients, including 75 Fortune 500 companies and 8 of the Top 20 Global Banks." },
        { icon: <FaClock />, text: "Around-the-clock support provided by 3,000+ certified cloud specialists, operating from 25 Centers of Excellence worldwide." },
        { icon: <FaDatabase />, text: "Expertise in enterprise resource planning with 12,000+ SAP instances managed and 3,000+ TB of HANA database optimized." },
        { icon: <FaLock />, text: "Advanced disaster recovery and business continuity services with automated failover, secure backup, and real-time risk mitigation." },
        { icon: <FaCloud />, text: "Industry-leading cloud security with 50+ compliance frameworks and intelligent SOC services for proactive threat detection and resolution." },
        { icon: <FaRobot />, text: "Proprietary automation platforms enabling predictive operations, real-time infrastructure optimization, and streamlined workflows." },
        { icon: <FaChartBar />, text: "Over 2 billion hours of fail-safe hosting, ensuring 99.99% uptime and robust scalability for mission-critical operations." },
        { icon: <FaChartBar />, text: "Tailored solutions leveraging the latest cloud-native technologies to drive innovation and accelerate digital transformation." },
        { icon: <FaCloud />, text: "Rigorous adherence to international and local compliance standards, ensuring seamless operations across all regions." },
    ];

    const CloudDifference = () => {
        return (
            <FeatureGrid
                title="Elevate Your Business with DIAA Corp Managed Cloud Services :"
                features={features}
            />
        );
    };

    const TechStack = () => {
        const categories = [
            { name: 'Network Infrastructure', items: 'Advanced networking solutions including VPNs, MPLS, load balancers, switches, routers, and Network Time Protocol for seamless connectivity.' },
            { name: 'Virtualization Platforms', items: 'Expertise in virtualization tools like VDI, KVM, Hyper-V, Xen, and OVM for optimal resource utilization and scalability.' },
            { name: 'Operating Environment', items: 'Support for diverse operating systems, including Windows, Linux, Solaris, AIX, and HP-UX, tailored for enterprise needs.' },
            { name: 'Database Management', items: 'Proficient in managing relational and non-relational databases such as Oracle, Sybase, MS SQL, MySQL, Hadoop, and MongoDB.' },
            { name: 'Middleware Solutions', items: 'Robust middleware platforms like IIS, Apache, and Tomcat to enhance application interoperability and performance.' },
            { name: 'Enterprise Applications', items: 'Streamlined ERP and CRM solutions with expertise in SAP, Oracle, Dynamics, and Axapta, ensuring efficient business operations.' },
            { name: 'Collaboration Tools', items: 'Management of platforms like SharePoint, JBoss, WebLogic, and other app ecosystems to foster team productivity.' },
            { name: 'Cloud Desktops', items: 'Desktop-as-a-Service (DaaS) offerings, including Citrix VDI, Microsoft WVD, Azure Virtual Desktop, and Amazon Workspaces for remote workforce enablement.' },
            { name: 'Performance Monitoring', items: 'Real-time monitoring using tools like Zabbix, Syslog, and advanced cloud analytics for optimized infrastructure oversight.' },
            { name: 'Server Administration', items: 'Secure server management using jump servers for controlled and streamlined operations.' },
            { name: 'Virtual Instance Management', items: 'Expertise in virtual environments, including container technologies like Docker, Kubernetes, Azure Kubernetes Services, and big data storage platforms.' },
            { name: 'Regulatory Compliance', items: 'Adherence to global and regional compliance frameworks, including ISO standards, PCI-DSS, GDPR, HIPAA, and more, ensuring data security and sovereignty.' },
            { name: 'Cloud Operations Excellence', items: 'End-to-end cloud platform management with Infrastructure as Code (IaC), performance optimization, and automated governance tools.' },
            { name: 'Automation & System Operations', items: 'Utilize Terraform, Ansible, Azure Automation, and CI/CD pipelines for streamlined deployment and system management.' },
            { name: 'Development and Data Continuity', items: 'Robust solutions like Commvault, Veeam, and native replication tools for efficient disaster recovery and high availability.' },
            { name: 'Security Management', items: 'Advanced threat intelligence systems including SIEM, SOAR, ATP, and container security tools to safeguard enterprise environments.' },
            { name: 'Proprietary Platforms', items: 'Innovative platforms like Self-Healing Operations (SHOP), Universal Cloud Management Portal (UCP), and MyShift for seamless operations and user management.' }
        ]

        return (
            <div className='mt-12 md:mt-24'>
                <h2 className="text-xl md:text-3xl font-bold text-center mb-4 md:mb-8">
                    DIAA Corp’s Comprehensive Cloud Management Toolkit
                </h2>
                <p className='mt-2 md:mt-6 text-center mb-12'>
                    DIAA Corp Intelligent Managed Services Core Tools and Processes
                </p>
                <div className="flex flex-col lg:flex-row flex-wrap justify-center items-start bg-[#070727] p-4 gap-4">
                    {categories.map((category, index) => (
                        <div key={index} className="w-full lg:w-auto flex flex-col lg:flex-row gap-4 mb-4">
                            <div className="bg-[#232940] p-4 rounded-lg flex justify-center items-center h-24 lg:w-[260px] mb-2 lg:mb-0">
                                <h2 className="text-white text-lg font-bold text-center">{category.name}</h2>
                            </div>
                            <div className="bg-[#070727] p-4 rounded-lg border border-[#232940] flex-1 lg:w-[712px]">
                                <p className="text-white text-sm lg:text-base">{category.items}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    const KnowledgeDeskManagedCloud = () => {
        const [activeStory, setActiveStory] = useState('Panasonic');

        const cards = [
            {
                title: 'Optimizing Cloud Investments in a Rapidly Changing Landscape',
                description: 'As businesses navigate economic uncertainty, mastering the balance between cloud investments and operational resilience becomes critical. Learn how to design cost-effective cloud strategies that ensure business continuity, scalability, and innovation.',
            },
            {
                title: 'Revolutionizing Core Applications Through Modernization',
                description: 'Modernizing core applications is no longer optional; it’s a necessity for staying competitive. Discover strategies to seamlessly migrate and upgrade applications on the cloud, boosting efficiency and unlocking new business opportunities.',
            },
            {
                title: 'Building Resilience with an Agile IT Framework',
                description: 'Agility isn’t just a buzzword—it’s the cornerstone of thriving in a crisis. From accelerating delivery cycles to aligning IT and operations, find out how to build a robust, crisis-resilient business framework.',
            },
            {
                title: 'Strengthening Security Through Collaborative Responsibility',
                description: 'Security is a team sport. Explore how businesses can work with managed cloud security providers to implement advanced tools while fostering internal practices that mitigate risks and ensure compliance.',
            },
            // Add other cards here
        ];

        const stories = {
            "Panasonic": {
                title: 'Panasonic',
                description: 'Globally renowned Japanese manufacturer embraces agile, advanced private cloud managed services to witness breakthrough operational efficiency sustaining 200,000 users concurrently at zero data loss and 95.95% uptime.',
                image: ourSuccessImg1,
            },
            'AbhiBus': {
                title: 'AbhiBus',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg1,
            },
            'Global Ceramics Brand': {
                title: 'Global Ceramics Brand',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg1,
            },
            'TimeOut': {
                title: 'TimeOut',
                description: 'Global Food Packaging Manufacturer leverages customized SAP HANA Architecture on AWS to achieve breakthrough operational efficiency with 99.95% Uptime.',
                image: ourSuccessImg1,
            },
            // Add other stories here
        };

        return (
            <div className="text-white p-8 mt-12 md:mt-24 mobile:px-4">

                <h2 className="text-3xl font-bold mb-6 text-center ">Our Success Stories</h2>

                <div className="flex flex-wrap gap-4 mb-8 justify-center mt-12 md:my-12 md:space-x-8">
                    {Object.keys(stories).map((storyKey) => (
                        <button
                            key={storyKey}
                            className={`px-4 md:px-6 py-2 rounded-full text-[#F1F1F1] ${activeStory === storyKey
                                ? 'bg-custom-gradient'
                                : 'border border-[#F1F1F1] hover:bg-gray-600'
                                } transition-colors duration-300`}
                            onClick={() => setActiveStory(storyKey)}
                        >
                            {storyKey}
                        </button>
                    ))}
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeStory}
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.3 }}
                        className="md:flex items-center"
                    >
                        <img
                            src={stories[activeStory].image}
                            alt={stories[activeStory].title}
                            className="md:w-1/2 rounded-lg mr-8 mobile:mb-2"
                        />
                        <div>
                            <h3 className="text-2xl font-semibold mb-4">{stories[activeStory].title}</h3>
                            <p>{stories[activeStory].description}</p>
                        </div>
                    </motion.div>
                </AnimatePresence>

                <h2 className="text-2xl md:text-3xl font-bold mb-6 md:text-center mt-12 md:mt-24">Cloud Management: The Way Forward :</h2>

                <div className="grid grid-cols-1 md:grid-cols-3 my-12 mobile:mb-6 md:mb-12">
                    {cards.map((card, index) => (
                        <div key={index} className="bg-[#14192E] p-6 rounded-2xl md:w-11/12 mx-auto mobile:mb-4 mb-8">
                            <h3 className="text-xl font-semibold mb-4">{card.title}</h3>
                            <p className="mb-4">{card.description}</p>
                            <button className="text-theme hover:text-blue-300 transition-colors duration-300">
                                Read more <span className="ml-2">&raquo;</span>
                            </button>
                        </div>
                    ))}
                </div>

            </div>
        );
    };


    const FAQSection = () => {
        const faqs = [
            {
                question: "Why do enterprises need cloud managed services?",
                answer: `Cloud platform providers deliver the necessary infrastructure for your organization. 
                However, leveraging hundreds of native technologies and tailoring a cloud architecture that fits your business objectives is critical to achieving scalability, minimizing risks, and ensuring seamless cloud performance.`
            },
            {
                question: "What is included in managed services?",
                answer: `Managed cloud services encompass a comprehensive suite of solutions, including infrastructure setup, 
                performance monitoring, security management, data backup and recovery, compliance adherence, application modernization, 
                and ongoing technical support to ensure smooth operations.`
            },
            {
                question: "What are the benefits of managed IT services?",
                answer: `Managed IT services provide businesses with cost efficiency, operational scalability, improved performance, 
                and access to expert support. These services reduce downtime, enhance security, and free up internal resources 
                to focus on strategic business objectives.`
            },
        ];

        return (
            <div className="flex items-center justify-center p-4">
                <div className="max-w-3xl w-full">
                    <h1 className="text-2xl md:text-3xl font-bold text-white text-center mb-8 md:mb-16">
                        Cloud Managed Services - FAQs
                    </h1>
                    {faqs.map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
            </div>
        );
    };


    return (
        <div className="min-h-screen text-white">
            <Header {...headerData} isExploreBtn={true} backgroundImage={coverImage} />

            <div>
                <MigrationInfo {...migrationInfoData} isMigrationFactory={false} />
            </div>

            <div>
                <ContactContent title={`Embrace the Future with Cloud Innovation. Unlock Intelligent Solutions Today.`} />
            </div>

            <div>
                <DIAACorpRecognition />
            </div>

            <div>
                <OrganizationsServed />
            </div>

            <div>
                <ClientTestimonials />
            </div>

            <div>
                <DIAACorpCloudComponent />
            </div>

            <div className='mt-8 md:mt-24'>
                <Slider tabs={tabs} content={content} isHeader={true} />
            </div>

            <div>
                <ContactContent2 title={`Ready To Embrace The New Potential Of Cloud ?`} sub={`Connect with our Cloud Experts`} />
            </div>

            <div>
                <CloudManagedTabs />

                <Slider tabs={tab1} content={content1} />
                <Slider tabs={tab2} content={content2} />
                <Slider tabs={tab3} content={content3} />
            </div>

            <div className='md:w-11/12 mx-auto'>
                <CloudDifference />
            </div>

            <div className='md:w-11/12 mx-auto'>
                <TechStack />
            </div>

            <div className='md:w-11/12 mx-auto'>
                <KnowledgeDeskManagedCloud />
            </div>

            <div className='md:w-11/12 mx-auto'>
                <FAQSection />
            </div>

            <div>
                <Footer />
            </div>
        </div>
    )
}

export default CloudManagedServices