import React, { useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Industries/Industrial/_imgCover.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents'
import { ContactContent } from '../../components/resuable/SapComponents'
import Footer from '../../components/Footer'
import { useDocumentTitle } from '../../utils/useDocumentTitle'
import { ChevronRight, ChevronLeft, ChevronDown, ChevronUp } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

// Import images
import _img1 from '../../assets/Industries/Industrial/_img1.png'
import _img2 from '../../assets/Industries/Industrial/_img2.png'
import _img3 from '../../assets/Industries/Industrial/_img3.png'
import _img4 from '../../assets/Industries/Industrial/_img4.png'
import _img5 from '../../assets/Industries/Industrial/_img5.png'
import _img6 from '../../assets/Industries/Industrial/_img6.png'
import _img7 from '../../assets/Industries/Industrial/_img7.png'
import _img8 from '../../assets/Industries/Industrial/_img8.png'
import _img9 from '../../assets/Industries/Industrial/_img9.png'
import _img10 from '../../assets/Industries/Industrial/_img10.png'
import _img11 from '../../assets/Industries/Industrial/_img11.png'

import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'

function Industrial() {
    useDocumentTitle("Industrial");

    const IndustrialStats = () => {
        const stats = [
            { value: "85%", description: "of manufacturing leaders are leveraging AI and IoT to enhance operational efficiency and reduce production costs." },
            { value: "88%", description: "of industrial buyers prefer personalized digital sales experiences, boosting conversion rates and customer loyalty." },
            { value: "65%", description: "of industrial revenue is projected to come from smart products and data-driven services within the next decade." },
            { value: "78%", description: "of industrial CEOs are investing in workforce reskilling to prepare for the rapid adoption of robotics and automation." }
        ];
        return <StatsGrid title="Industrial Innovation today" stats={stats} />;
    };

    const SegmentsWeSupport = () => {
        const [activeIndex, setActiveIndex] = useState(null);
        const [showDetails, setShowDetails] = useState(false);
        const [selectedSegment, setSelectedSegment] = useState(null);

        const toggleSegment = (index) => {
            setActiveIndex(activeIndex === index ? null : index);
        };

        const toggleDetails = (segment) => {
            setSelectedSegment(segment);
            setShowDetails(true);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 },
        };

        const segments = [
            {
                title: "Industrial and Electrical Equipment",
                description: "Crafting the engineered product of tomorrow—from machinery and industrial equipment to electrical components and beyond.",
                image: _img1,
                details: {
                    keyFeatures: [
                        "Advanced automation solutions",
                        "Smart manufacturing integration", 
                        "IoT-enabled equipment monitoring",
                        "Predictive maintenance systems"
                    ],
                    benefits: "Increase operational efficiency by 40% with our smart manufacturing solutions",
                    caseStudy: "Leading manufacturer achieved 65% reduction in downtime"
                }
            },
            {
                title: "Industrial and Automotive Suppliers", 
                description: "Revolutionizing automotive manufacturing with cutting-edge solutions for suppliers, focusing on precision, efficiency and quality control.",
                image: _img2,
                details: {
                    keyFeatures: [
                        "Supply chain optimization",
                        "Quality control automation",
                        "Just-in-time inventory management",
                        "Connected factory solutions",
                        "Real-time production monitoring"
                    ],
                    benefits: "Streamline production with 30% improved efficiency and 25% reduced inventory costs",
                    caseStudy: "Major auto supplier reduced waste by 45% and improved quality metrics by 60%"
                }
            },
            {
                title: "Heavy Equipment",
                description: "Empowering construction and mining industries with intelligent heavy machinery solutions focused on productivity and safety.",
                image: _img3,
                details: {
                    keyFeatures: [
                        "Equipment tracking and fleet management",
                        "Predictive maintenance analytics",
                        "Performance optimization systems",
                        "Remote monitoring and diagnostics",
                        "Safety compliance automation"
                    ],
                    benefits: "Enhance equipment lifespan by 35% while reducing operational costs by 25%",
                    caseStudy: "Construction firm saved $2M in maintenance costs and improved safety metrics by 40%"
                }
            },
            {
                title: "Consumer Durables",
                description: "Transforming consumer appliance manufacturing with smart technologies and efficient production processes.",
                image: _img4,
                details: {
                    keyFeatures: [
                        "Smart appliance integration",
                        "Energy efficiency optimization",
                        "Quality assurance automation",
                        "Connected device management",
                        "Sustainable manufacturing practices"
                    ],
                    benefits: "Reduce production costs by 30% while improving product quality by 45%",
                    caseStudy: "Appliance manufacturer increased production efficiency by 50%"
                }
            },
            {
                title: "Freight and Logistics",
                description: "Optimizing supply chain operations with intelligent logistics solutions and real-time tracking capabilities.",
                image: _img5,
                details: {
                    keyFeatures: [
                        "Real-time fleet tracking",
                        "Route optimization",
                        "Warehouse automation",
                        "Inventory management",
                        "Delivery optimization"
                    ],
                    benefits: "Improve delivery efficiency by 40% and reduce operational costs by 35%",
                    caseStudy: "Logistics company reduced delivery times by 50% and fuel costs by 30%"
                }
            },
            {
                title: "Construction, Engineering and Real Estate",
                description: "Delivering innovative solutions for construction projects and property management through digital transformation.",
                image: _img6,
                details: {
                    keyFeatures: [
                        "Project management automation",
                        "BIM integration",
                        "Site safety monitoring",
                        "Resource optimization",
                        "Property management systems"
                    ],
                    benefits: "Reduce project delays by 45% and improve resource utilization by 35%",
                    caseStudy: "Engineering firm reduced project completion time by 40%"
                }
            },
            {
                title: "Business Services",
                description: "Enabling service excellence through digital transformation and process automation solutions.",
                image: _img7,
                details: {
                    keyFeatures: [
                        "Service automation",
                        "Customer relationship management",
                        "Process optimization",
                        "Data analytics"
                    ]
                }
            }
        ];

        return (
            <div className="w-full">
                <h2 className="text-4xl font-bold mb-8 text-center">Segments we support</h2>
                <div className="text-white p-4 sm:p-8 rounded-2xl">
                    {segments.map((segment, index) => (
                        <div key={index} className="mb-4">
                            <div
                                className="flex justify-between items-center cursor-pointer p-4 bg-[#14192E] rounded-t-xl"
                                onClick={() => toggleSegment(index)}
                            >
                                <span className="font-semibold">{segment.title}</span>
                                <motion.div
                                    animate={{ rotate: activeIndex === index ? 180 : 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <ChevronDown />
                                </motion.div>
                            </div>
                            <motion.div
                                animate={{
                                    height: activeIndex === index ? "auto" : 0,
                                    opacity: activeIndex === index ? 1 : 0
                                }}
                                initial={{ height: 0, opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                style={{ overflow: "hidden" }}
                            >
                                <div className="p-4 bg-[#14192E] rounded-b-xl">
                                    <div className="flex flex-col sm:flex-row items-start">
                                        {/* <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                            <div className="bg-purple-900 rounded-lg overflow-hidden">
                                                <img
                                                    src={segment.image}
                                                    alt={segment.title}
                                                    className="w-full h-auto object-cover"
                                                />
                                            </div>
                                        </div> */}
                                        <div className="flex-grow">
                                            <p className="text-gray-400 mb-4">{segment.description}</p>
                                            <div className="flex justify-end">
                                                {/* <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="border-2 border-theme/50 rounded-full p-2"
                                                    onClick={() => toggleDetails(segment)}
                                                >
                                                    <ChevronRight className="text-theme cursor-pointer" size={24} />
                                                </motion.div> */}
                                            </div>
                                            {showDetails && selectedSegment === segment && (
                                                <motion.div
                                                    initial="hidden"
                                                    animate="visible"
                                                    exit="exit"
                                                    variants={slideVariants}
                                                    className="mt-4 p-4 bg-[#1a2036] rounded-xl"
                                                >
                                                    <h3 className="text-xl font-bold mb-3">Key Features:</h3>
                                                    <ul className="list-disc pl-5 mb-4">
                                                        {segment.details.keyFeatures.map((feature, i) => (
                                                            <li key={i} className="mb-2">{feature}</li>
                                                        ))}
                                                    </ul>
                                                    <p className="mb-3"><strong>Benefits:</strong> {segment.details.benefits}</p>
                                                    <p><strong>Case Study:</strong> {segment.details.caseStudy}</p>
                                                </motion.div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const ReinventIndustrial = () => {
        const cards = [
            {
                title: 'Digital Transformation & Lean Innovation',
                description: 'Drive growth by leveraging digital platforms and lean principles to optimize processes, enhance flexibility, and scale operations efficiently.',
                hasButton: true,
            },
            {
                title: 'Smart Products & Services',
                description: 'Create high-value customer experiences by integrating AI, IoT, and real-time data to develop intelligent products and services that deliver superior performance.',
                hasButton: true,
            },
            {
                title: 'Next-Generation B2B Engagement',
                description: 'Redesign B2B sales models by focusing on digital solutions and delivering personalized, seamless experiences that drive deeper customer relationships.',
                hasButton: true,
            },
            {
                title: 'Optimized Operations',
                description: 'Revolutionize business operations by streamlining workflows, fostering supplier collaboration, and utilizing data-driven insights for improved efficiency and productivity.',
                hasButton: true,
            },
            {
                title: 'Sustainable Growth & Responsibility',
                description: 'Embrace sustainable business practices by incorporating green technologies, cloud solutions, and circular economy models, while equipping the workforce with skills for the future.',
                hasButton: true,
            }
        ];

        return <ReinventGrid title="Reinventing the Future of Industry" cards={cards} />;
    };

    const IndustrialTrendingCarousel = () => {
        const slides = [
            {
                title: "Redefining Industrial Automation",
                description: "The Future of Smart Factories: Industrial automation is entering a new era with the rise of smart factories powered by AI and robotics. These intelligent systems enhance productivity, reduce operational costs, and deliver unparalleled precision across production lines. By leveraging advanced automation technologies, industries are streamlining operations, minimizing human error, and responding more effectively to market demands. Smart factories represent the future of manufacturing, where efficiency, adaptability, and innovation come together to drive long-term success.",
                image: _img3,
                gradient: "from-purple-600 to-pink-500"
            },
            {
                title: "Revolutionizing Supply Chain with IoT",
                description: "Transforming Logistics through Connectivity: The Internet of Things (IoT) is revolutionizing supply chains by introducing real-time connectivity and data-driven insights. This transformation enables businesses to track assets, monitor equipment for predictive maintenance, and optimize logistics processes with unprecedented accuracy. By reducing downtime, improving inventory management, and enhancing operational efficiency, IoT is reshaping how goods move from production to delivery. The result is a more agile, transparent, and customer-focused supply chain.",
                image: _img4,
                gradient: "from-blue-600 to-cyan-500"
            },
            {
                title: "Sustainability in Industrial Operations",
                description: "Building Green Factories for a Sustainable Future: Sustainability is becoming a central focus for industrial operations, with green factories at the forefront of this transformation. By integrating renewable energy sources, adopting circular economy models, and prioritizing resource efficiency, industries are significantly reducing their carbon footprint. These sustainable practices not only align with global environmental goals but also drive cost savings and enhance brand reputation. Building green factories is not just about compliance—it’s a pathway to resilient, future-ready operations.",
                image: _img9,
                gradient: "from-indigo-600 to-purple-500"
            },
            {
                title: "Industry 4.0: The Digital Transformation Era",
                description: "Industry 4.0 marks a pivotal shift in manufacturing, characterized by the integration of AI, machine learning, and cloud technologies. These advancements enable industries to optimize production processes, improve operational decision-making, and unlock new opportunities through data-driven insights. By harnessing the power of connected systems, businesses can achieve greater agility, scalability, and efficiency. The digital transformation of manufacturing is setting the stage for a smarter, more competitive industrial landscape.",
                image: _img10,
                gradient: "from-indigo-600 to-purple-500"
            },
            {
                title: "Global Innovation at Hannover Messe 2024 (#HM24)",
                description: "Leading the Future of Industrial Technology: Hannover Messe 2024 is poised to be a global platform for innovation, bringing together industry leaders, visionaries, and pioneers in industrial technology. This event will showcase groundbreaking advancements in automation, robotics, and energy-efficient manufacturing systems. Attendees will explore cutting-edge solutions driving the next wave of industrial transformation, from AI-powered robotics to sustainable production practices. Hannover Messe 2024 is where the future of industrial technology comes to life, fostering collaboration and inspiring progress.",
                image: _img11,
                gradient: "from-indigo-600 to-purple-500"
            }
        ];

        return <TrendingCarousel title="What's Shaping the Future of Industry" slides={slides} />;
    };


    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Innovation Award 2024",
                image: _img6,
                alt: "Innovation Award"
            },
            {
                title: "Technology Leader - Forrester",
                image: _img7,
                alt: "Technology Leadership"
            },
            {
                title: "Best Solutions Provider",
                image: _img8,
                alt: "Solutions Provider"
            },
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "John Smith", title: "Global Industrial Lead", imageUrl: Cloud_leader_img1 },
            { name: "Sarah Johnson", title: "Digital Innovation Director", imageUrl: Cloud_leader_img1 },
            { name: "Michael Chen", title: "Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Emma Davis", title: "Solutions Lead", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Thriving in the Era of Industrial Transformation"
                description="Rapid technological advancements, shifting customer demands, and the rise of software-enabled solutions are reshaping the industrial landscape. To stay competitive, businesses must adopt agile strategies, innovate their product offerings, and integrate value-added services. Now is the time to rethink and redefine industrial business models for sustained growth and impact."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <IndustrialStats />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventIndustrial />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <IndustrialTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <GlobalRecognition />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent title="Industrial careers" subtitle="Grow your career at the heart of  transformation" />
            </div>
            <Footer />
        </div>
    );
}

export default Industrial
